import {SESSION_STORAGE_KEYS, USER_ROLES} from "../../constants/enums";
import {isUserAdmin, isUserCustomer, isUserProvider, parseRestApiResponse, parseUserRole} from "../../helpers/helpers";

export const SET_APP_FIRST_OPEN = 'SET_APP_FIRST_OPEN';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REMEMBER_ME = 'REMEMBER_ME';
export const REMEMBER_ME_CREDENTIALS = 'REMEMBER_ME_CREDENTIALS';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const L10N = 'L10N';
export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_COURSES = 'SET_COURSES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_PROVIDERS_FOLLOWED = 'SET_PROVIDERS_FOLLOWED';
export const SET_COURSES_FOLLOWED = 'SET_COURSES_FOLLOWED';
export const SET_CATEGORIES_FOLLOWED = 'SET_CATEGORIES_FOLLOWED';
export const SET_USERS = 'SET_USERS';
export const SET_PROVIDER_DATA = 'SET_PROVIDER_DATA';
export const SET_COURSE = 'SET_COURSE';
export const SET_ADMIN_DATA = 'SET_ADMIN_DATA';


//const URL_HOSTNAME = 'https://domena.si/3001';
const URL_HOSTNAME = '';
const REST_API_ENDPOINT = '/rest'
export const BE_REST_API = URL_HOSTNAME + REST_API_ENDPOINT;

export const setAdmin = (user) => {
    return {type: SET_ADMIN_DATA, user: user};
};

export const setUsers = (users) => {
    return {type: SET_USERS, users: users};
};

export const setProviders = (providers) => {
    return {type: SET_PROVIDERS, providers: providers};
};

export const setCourses = (courses) => {
    return {type: SET_COURSES, courses: courses};
};

export const setCategories = (categories) => {
    return {type: SET_CATEGORIES, categories: categories};
};

export const setProvidersFollowed = (providers_followed) => {
    return {type: SET_PROVIDERS_FOLLOWED, providers_followed: providers_followed};
};

export const setCoursesFollowed = (courses_followed) => {
    return {type: SET_COURSES_FOLLOWED, courses_followed: courses_followed};
};

export const setCategoriesFollowed = (categories_followed) => {
    return {type: SET_CATEGORIES_FOLLOWED, categories_followed: categories_followed};
};

export const setUserToken = (token) => {
    return {type: SET_USER_TOKEN, token: token};
};

export const setUserData = (user) => {
    return {type: SET_USER_DATA, user: user};
};

export const setProviderData = (provider) => {
    return {type: SET_PROVIDER_DATA, provider: provider};
};
export const setCourse = (course) => {
    return {type: SET_COURSE, course: course};
};

export const setCredentials = (email, password) => {
    return {type: SET_CREDENTIALS, email: email, password: password};
};

export const setRememberMe = (state) => {
    saveRememberMe(state);
    return {type: REMEMBER_ME, rememberMe: state};
};

export const setSignInCredentials = (email, password, shouldWrite) => {
    if (shouldWrite) {
        saveCredentials(email, password);
    }
    return {type: REMEMBER_ME_CREDENTIALS, email: email, password: password};
};

export const setL10N = (l10n, shouldWrite) => {
    if (shouldWrite) {
        setLocalL10N(l10n);
    }
    return {type: L10N, l10n: l10n};
};

export const setLocalL10N = (l10n) => {
    //console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.L10N}:`, JSON.parse(l10n));

    const onSetSessionAction = () => {
        localStorage.setItem(SESSION_STORAGE_KEYS.L10N, l10n);
        console.log(`SET [LOCAL STORAGE] ${SESSION_STORAGE_KEYS.L10N}:`, l10n);
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadLocalL10N = (useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                console.log(value);
                if (value) {
                    dispatch(setL10N(value, true));
                }
            };
            loadLocalStorageData(SESSION_STORAGE_KEYS.L10N, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadLocalStorageData(SESSION_STORAGE_KEYS.L10N);
    }
};


export const loadCredentials = () => {
    return async dispatch => {
        try {
            const email = localStorage.getItem(SESSION_STORAGE_KEYS.REMEMBER_EMAIL);
            const pass = localStorage.getItem(SESSION_STORAGE_KEYS.REMEMBER_PASSWORD);
            console.log("LOAD [STORAGE] REMEMBER ME CREDENTIALS:", email, pass);
            if (email && pass) {
                dispatch(setSignInCredentials(email, pass));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveCredentials = (email, password) => {
    try {
        localStorage.setItem(SESSION_STORAGE_KEYS.REMEMBER_EMAIL, email);
        localStorage.setItem(SESSION_STORAGE_KEYS.REMEMBER_PASSWORD, password);
        console.log("SAVE [STORAGE] REMEMBER ME CREDENTIALS:", email, password);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const isRememberMeEnabled = () => {
    return async dispatch => {
        try {
            const value = await localStorage.getItem(SESSION_STORAGE_KEYS.REMEMBER);
            console.log("LOAD [STORAGE] REMEMBER ME:", value);
            if (!value || Number(value) === 0) {
                dispatch(setRememberMe(false));
            } else {
                dispatch(setRememberMe(true));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveRememberMe = (state) => {
    try {
        localStorage.setItem(SESSION_STORAGE_KEYS.REMEMBER, state ? '1' : '0');
        console.log("SAVE [STORAGE]  REMEMBER ME:", state);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const setSessionStorageData = (onSetSessionAction) => {
    try {
        onSetSessionAction();
    } catch (error) {
        console.error("Error saving data!" + error);
    }
};

export const loadSessionStorageData = (key, onLoadSessionAction) => {
    try {
        const value = sessionStorage.getItem(key);
        console.log(`GET [SESSION STORAGE] ${key}:`, value);
        if (onLoadSessionAction) {
            onLoadSessionAction(value);
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return value
        }
    } catch (error) {
        console.error("Error saving data!" + error);
        return null;
    }
};

export const loadLocalStorageData = (key, onLoadSessionAction) => {
    try {
        const value = localStorage.getItem(key);
        console.log(`GET [LOCAL STORAGE] ${key}:`, value);
        if (onLoadSessionAction) {
            onLoadSessionAction(value);
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return value
        }
    } catch (error) {
        console.error("Error getting data!" + error);
        return null;
    }
};

export const setSessionProvider = (value) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.PROVIDER, value);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.PROVIDER}:`, JSON.parse(value));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionProvider = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setProviderData(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionCourse = (value) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.COURSE, value);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.COURSE}:`, JSON.parse(value));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionCourse = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setCourse(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionUser = (user) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER, user);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER}:`, JSON.parse(user));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUser = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUserData(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionUserToken = (token) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_TOKEN, token);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER_TOKEN}:`, token);
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUserToken = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUserToken(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};


export const setSessionAdmin = (admin) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ADMIN, admin);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ADMIN}:`, admin);
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionAdmin = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setAdmin(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const userLogin = (email, password, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            email: email,
            password: password,
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/login';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET USER LOGIN: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);

                dispatch(setCredentials(email, password));
                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));

                dispatch(loadAllProviders((res) => {
                }, (res) => {
                }));
                dispatch(loadAllCourses((res) => {
                }, (res) => {
                }));
                dispatch(loadAllCategoriesParsed((res) => {
                }, (res) => {
                }));

                if (isUserAdmin(resData)) {
                    dispatch(setAdmin(resData));
                    setSessionAdmin(JSON.stringify(resData));
                    dispatch(loadAllUsers(resData["user_id"]));

                } else if (isUserProvider(resData)) {
                    dispatch(loadProviderByUserId(resData["user_id"], () => {
                    }));
                } else if (isUserCustomer()) {
                    dispatch(loadAllProvidersFollowed({user_id: resData["user_id"]}));
                    dispatch(loadAllCoursesFollowed({user_id: resData["user_id"]}));
                    dispatch(loadAllCategoriesFollowed({user_id: resData["user_id"]}));
                }
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};


export const userRegister = (user, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("REGISTER NEW USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);

                //  dispatch(setCredentials(email, password));
                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed registering new user..', e);
        }
    };
};

// PROVIDER REGISTER
export const providerRegister = (provider, user, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            provider: provider,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/providers/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("REGISTER NEW PROVIDER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionProvider(JSON.stringify(resData));

                dispatch(setProviderData(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed registering new provider..', e);
        }
    };
};


export const updateUserData = (userData, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: userData,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/update/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USER DATA: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setUserData(resData));
                setSessionUser(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users data..', e);
        }
    };
};

export const updateUserActivation = (userData, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: userData,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/update/active';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USER ACTIVATION: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(setUserData(resData));
                //setSessionUser(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users data..', e);
        }
    };
};

export const updateProviderActivation = (provider_id, active, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            provider_id: provider_id,
            active: active
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/providers/update/active';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE PROVIDER ACTIVATION: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(setUserData(resData));
                //setSessionUser(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users data..', e);
        }
    };
};


export const updateProviderData = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            provider: data,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/providers/update/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE PROVIDER DATA: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setProviderData(resData));
                setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users data..', e);
        }
    };
};

export const getDocData = (doc_type, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            doc_type: doc_type,
            l10n: l10n,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/cpanel/doc/get';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET DOC DATA: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining docs data..', e);
        }
    };
};

export const updateAdminControlPanelData = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/cpanel/data/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE ADMIN CONTROL PANEL DATA @ADMIN: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
               // dispatch(setProviderData(resData));
                //setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating admin control panel data..', e);
        }
    };
};


export const getAdminControlPanelData = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/cpanel/data/get';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET ADMIN CONTROL PANEL DATA @ADMIN: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining admin control panel data..', e);
        }
    };
};

export const updateProviderDataL10N = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/providers/update/l10n/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE PROVIDER L10N DATA: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setProviderData(resData));
                setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users data..', e);
        }
    };
};

export const createFaq = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/faq/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE FAQ: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating faq..', e);
        }
    };
};

export const updateFaq = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/faq/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE FAQ: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating faq..', e);
        }
    };
};

export const deleteFaq = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/faq/delete';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("DELETE FAQ: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed deleting faq..', e);
        }
    };
};

export const getFaqAdmin = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/faq/admin';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FAQ @ADMIN: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining faq..', e);
        }
    };
};

export const getFaqProvider = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/faq/provider';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FAQ @PROVIDER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining faq..', e);
        }
    };
};

export const getFaqCourse = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/faq/provider';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FAQ @PROVIDER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining faq..', e);
        }
    };
};

export const loadPackageDates = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/dates/package';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET PACKAGE DATES for COURSE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading package dates..', e);
        }
    };
};


export const createPackageDate = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/dates/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE PACKAGE DATE for COURSE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating package date..', e);
        }
    };
};


export const updatePackageDateData = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/dates/update/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("PACKAGE DATE UPDATE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating package_date for course..', e);
        }
    };
};

export const removePackageDate = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/dates/remove';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("PACKAGE DATE REMOVE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed removing package_date for course..', e);
        }
    };
};

export const loadLecturers = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/lecturers/provider';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET LECTURERS FOR PROVIDER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading lecturers..', e);
        }
    };
};

export const createLecturer = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/lecturers/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("POST LECTURER CREATE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new lecturer for provider..', e);
        }
    };
};

export const updateLecturerData = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/lecturers/update/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("LECTURER UPDATE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating lecturer for provider..', e);
        }
    };
};

export const updateLecturerDataL10N = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/lecturers/update/l10n/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE LECTURER L10N DATA: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                // dispatch(setProviderData(resData));
                //setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating lecturers l10n data..', e);
        }
    };
};

export const deleteLecturer = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/lecturers/remove';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("LECTURER REMOVE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed deleting lecturer for provider..', e);
        }
    };
};


export const createCourse = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/courses/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("COURSE CREATE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                  dispatch(setCourse(resData));
                  setSessionCourse(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating course for provider..', e);
        }
    };
};

export const loadCourseById = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/courses/course';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET COURSE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setCourse(resData));
                setSessionCourse(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining course by course_id..', e);
        }
    };
};

export const updateCourseData = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/courses/update/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE COURSE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setCourse(resData));
                setSessionCourse(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating course data by course_id..', e);
        }
    };
};

export const updateCourseActive = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/courses/update/active';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE COURSE ACTIVE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setCourse(null));
                setSessionCourse(null);
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating course active by course_id..', e);
        }
    };
};

export const loadFollowerByUserId = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower/user';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FOLLOWER [USER]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining follower by user_id..', e);
        }
    };
};

export const loadFollowerByUserIdforProvider = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower/user/provider';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FOLLOWER [USER] FOR PROVIDER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining follower by user_id for provider_id..', e);
        }
    };
};

export const loadFollowersForProvider = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower/provider';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FOLLOWERS [PROVIDER]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining followers by provider_id..', e);
        }
    };
};

export const createFollowerForProvider = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE FOLLOWER [PROVIDER]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating follower by provider_id..', e);
        }
    };
};

export const removeFollowerForProvider = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower/remove';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("REMOVE FOLLOWER [PROVIDER]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed removing follower by provider_id..', e);
        }
    };
};


export const createFollowerForCourse = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower/course/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE FOLLOWER [COURSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating follower by course_id..', e);
        }
    };
};

export const removeFollowerForCourse = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower/course/remove';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("REMOVE FOLLOWER [COURSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed removing follower by course_id..', e);
        }
    };
};

export const loadAllCoursesFollowed = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/course/follower';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET COURSES FOLLOWED: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setCoursesFollowed(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading courses followed..', e);
        }
    };
};

export const loadAllCoursesForFollower = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/course/follower';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET ALL COURSES [FOR USER/FOLLOWER]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining all courses by user_id..', e);
        }
    };
};

export const loadFollowerForCourse = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower/course/user';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET FOLLOWER [COURSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed removing follower by course_id..', e);
        }
    };
};

export const updateCourseL10NData = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/courses/update/l10n/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE L10N COURSE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setCourse(resData));
                setSessionCourse(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating course l10n data by course_id..', e);
        }
    };
};

export const loadCoursesForProvider = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/courses/provider';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET COURSES [PROVIDER]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed obtaining course by course_id..', e);
        }
    };
};

export const loadImage = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/images/image';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET IMAGE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading image..', e);
        }
    };
};

export const loadImages = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/images/images/provider';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET ALL IMAGES PROVIDER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading images..', e);
        }
    };
};

export const loadCourseImages = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/images/images/course';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET ALL IMAGES COURSE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading images..', e);
        }
    };
};

export const createImage = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/images/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE IMAGE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating image..', e);
        }
    };
};

export const updateImage = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/images/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE IMAGE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating image..', e);
        }
    };
};

export const deleteImage = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/images/delete';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("DELETE IMAGE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed deleting image..', e);
        }
    };
};


export const loadProviderData = (user_id, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: {user_id: user_id},
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/providers/get/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET PROVIDER DATA: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setProviderData(resData));
                setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed getting providers data..', e);
        }
    };
};

export const loadProviderDataById = (provider_id, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: provider_id,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/providers/get/data/id';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET PROVIDER DATA BY ID: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setProviderData(resData));
                setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed getting providers data..', e);
        }
    };
};

export const loadCategoryDataById = (data, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/categories/get/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET CATEGORY DATA BY COURSE ID: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
               // dispatch(setProviderData(resData));
               // setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed getting providers data..', e);
        }
    };
};

export const updateCategoryDataById = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/categories/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE CATEGORY: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //   dispatch(setProviderData(resData));
                //   setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating category..', e);
        }
    };
};


export const updateUserPasswordByEmail = (email, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            email: email,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/reset/password';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("RESET USER PASSWORD: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //    setSessionUser(JSON.stringify(resData));
                //    setSessionUserToken(resData["user_id"]);

                //    dispatch(setUserData(resData));
                //    dispatch(setUserToken(resData["user_id"]));

            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users password..', e);
        }
    };
};

export const logoutUser = (navigateAction) => {
    return async dispatch => {
        setSessionUser(null);
        setSessionUserToken(null);
        setSessionProvider(null);
        setSessionCourse(null);

        dispatch(setUserToken(null));
        dispatch(setUserData(null));
        dispatch(setProviderData(null));
        dispatch(setCourse(null));

        if (navigateAction) {
            navigateAction();
        }
    }
}

export const updateUserPassword = (user, l10n, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user,
            l10n: l10n
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/update/password';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USER PASSWORD: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating users password..', e);
        }
    };
};

export const loadProviderByUserId = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/providers/data';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET PROVIDER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setProviderData(resData));
                setSessionProvider(JSON.stringify(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading provider..', e);
        }
    };
};


export const loadAllUsers = (user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: user_id
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET USERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setUsers(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading users..', e);
        }
    };
};


export const loadAllProviders = (onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {}
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/providers';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET PROVIDERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setProviders(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading providers..', e);
        }
    };
};

export const loadAllCourses = (onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {}
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/courses';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET COURSES: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setCourses(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading courses..', e);
        }
    };
};

export const loadAllCategoriesParsed = (onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {}
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/categories';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET CATEGORIES PARSED: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setCategories(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading categories..', e);
        }
    };
};

export const loadAllCategories = (onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {}
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/categories/all';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET ALL CATEGORIES: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(setCategories(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading categories..', e);
        }
    };
};

export const createCategory = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/categories/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("COURSE CREATE: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating category..', e);
        }
    };
};

export const loadAllProvidersFollowed = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/followers/follower';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET PROVIDERS FOLLOWED: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setProvidersFollowed(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading providers followed..', e);
        }
    };
};


export const loadAllCategoriesFollowed = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/categories/follower';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET CATEGORIES FOLLOWED: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setCategoriesFollowed(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading categories followed..', e);
        }
    };
};

export const loadPromoPagesData = (onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {}
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/cpanel';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("GET PROVIDERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setProviders(resData));
            }
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading providers..', e);
        }
    };
};
