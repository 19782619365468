import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import DatePicker from 'react-date-picker';
import {useHistory, useParams} from "react-router-dom";
import {COUNTRIES_ARRAY, L10N, REGIONS, TIER, TIERS, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    createImage, createLecturer, createPackageDate,
    deleteImage, deleteLecturer,
    isRememberMeEnabled, loadAllCategories, loadAllCategoriesParsed, loadCategoryDataById,
    loadCourseById, loadCourseImages, loadCoursesForProvider,
    loadCredentials, loadImage, loadImages, loadLecturers,
    loadLocalL10N, loadPackageDates, loadProviderDataById, removePackageDate,
    setL10N,
    setRememberMe,
    setSignInCredentials, updateCourseActive,
    updateCourseData,
    updateCourseL10NData, updateImage,
    updateLecturerData, updateLecturerDataL10N, updatePackageDateData,
    updateProviderData,
    updateProviderDataL10N,
    userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    findItem,
    findItemValue,
    getIndexOf,
    getIndexOfValue, parsePriceCommaToDecimalValue,
    parseSelectedCountryLabel,
    scrollToDiv
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import Input from "../components/js/Input";
import plusCircleIcon from "../assets/images/plus-circle.png";
import iconStarGray from "../assets/images/i_star.png";

import starBlueFull from "../assets/images/star-blue-full.png";
import iconAcc from "../assets/images/acc.png";
import iconHeart from "../assets/images/heart_blue.png";
import iconHeartFull from "../assets/images/heart-full.png";
import iconHamburger from "../assets/images/hamburger.png";
import iconClose from "../assets/images/close.png";
import iconMessage from "../assets/images/message-circle.png";
import hotelImg from "../assets/images/hotel_bor_slovenia_360.jpg";
import iconMoon from "../assets/images/moon-white.png";
import iconImageExample from "../assets/images/img_example.png";
import iconAddress from "../assets/images/i_address.png";
import iconMapPin from "../assets/images/map-pin.png";
import iconMean from "../assets/images/i_mean.png";
import iconStar from "../assets/images/i_star.png";
import iconSum from "../assets/images/i_sum.png";
import iconNight from "../assets/images/i_night.png";
import courseImg from "../assets/images/course.png";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import cloud from "../assets/images/cloud.png";

import Title from "../components/js/Title";


import Footer from "../components/js/Footer";
import {CREATE_ACTION} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import Button from "../components/js/Button";
import DropdownCircle from "../components/js/DropdownCircle";
import FooterPage from "../components/js/FooterPage";
import GoogleMap from "../components/js/GoogleMap";
import Filters from "../components/js/Filters";
import Search from "../components/js/Search";
import HeaderLanding from "../components/js/HeaderLanding";
import CustomMap from "../components/js/CustomMap";
import Dropdown from "../components/js/Dropdown";
import ScrollableTabsEdit from "../components/js/ScrollableTabsEdit";
import {
    addNewLecturer,
    chooseLecturer,
    removeLecturer,
    updateLecturerFullName,
    updateLecturer
} from "../helpers/lecturersLogic";
import iconImg from "../assets/images/icon_img.png";
import ImageUploading from "react-images-uploading";
import {COURSES} from "./CoursePage";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";

export const CATEGORIES = [
    'Analogna fotografija',
    'Obdelava fotografije',
    'Kozmetika',
]

const FAQ = [
    {question: 'Vprašanje', answer: 'Odgovor..'},
    {question: 'Daljše Vprašanje', answer: 'Odgovor..'},
    {question: 'Še daljše vprašanje za nadaljevanjem?', answer: 'Daljši in podrobnejši odgovor na vprašanje...'},
    {question: 'Še daljše vprašanje za nadaljevanjem?', answer: 'Daljši in podrobnejši odgovor na vprašanje...'},
    {question: 'Vprašanje', answer: 'Odgovor..'},
    {question: 'Vprašanje', answer: 'Odgovor..'},
];

const USER = {
    user_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    full_name: 'Tadej Hiti',
    user_role: USER_ROLES.CUSTOMER,
    password: 'lulček#%hashed',
    email: 'hiti.tadej@gmail.com',
    tel: '+38640306677',
    address: 'Ograde 3',
    l10n_default: L10N.si.value,
    l10n_en: false,
    l10n_si: false,
    l10n_it: false,
};
const PROVIDER = {
    provider_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    images: [iconImageExample, iconImageExample, iconImageExample, iconImageExample, iconImageExample, iconImageExample]
};


const FOLLOWING = [
    {
        provider_id: 'dfgdfgdhfj',
        name: 'Plesna Šola Vzorec',
    },
    {
        provider_id: 'dfgdfg8678dhfj',
        name: 'Plesna Šola Vzorec',
    }
];

const LECTURERS = [
    {
        lecturer_id: 'sdasdasd',
        image: null,
        full_name: 'Tadej Hiti',
        description: 'Opis učitelja Tadej...'
    },
    {
        lecturer_id: 'sdasdasd433',
        image: null,
        full_name: 'Gregor Novak',
        description: 'Opis učitelja Gregor...'
    },
    {
        lecturer_id: 'sdasdasdfg34d',
        image: null,
        full_name: 'Janez Novak',
        description: 'Opis učitelja Janez...'
    },
]

export const renderDescListInput = (input, className) => {
    if (!className) {
        className = 'dynamic-ul';
    }

    let s = input?.split(',');
    if (!s || s.length === 0) {
        return <ul></ul>
    }
    return (
        <ul className={className} style={{alignSelf: 'flex-start'}}>
            {s.map((item, i) => (
                <li key={`${i}_${item}`}>
                    {item}
                </li>
            ))}
        </ul>
    );
}

function CoursePageEdit() {
    let {course_id: course_id} = useParams();

    course_id = course_id?.substring(1);

    console.log(course_id)

    let dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);
    const _course = useSelector(state => state.reducer.course);
    const _provider = useSelector(state => state.reducer.provider);

    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);

    const [course, setCourse] = useState(_course);
    const [provider, setProvider] = useState(_provider);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [allCategories, setAllCategories] = useState([]);

    const [courseName, setCourseName] = useState(course?.l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.l10n)]?.name);
    const [courseLearn, setCourseLearn] = useState(course?.packages[0].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[0].l10n)]?.description_list);
    const [courseLearn1, setCourseLearn1] = useState(course?.packages[1].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[1].l10n)]?.description_list);
    const [courseLearn2, setCourseLearn2] = useState(course?.packages[2].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[2].l10n)]?.description_list);
    const [courseAddress, setCourseAddress] = useState(course.address);
    const [courseCategory, setCourseCategory] = useState();
    const [courseRegion, setCourseRegion] = useState(course?.region);
    const [courseDesc, setCourseDesc] = useState(course?.packages[0].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[0].l10n)]?.description);
    const [courseNeeds, setCourseNeeds] = useState(course.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].requirements_list);

    const [courseYoutubeUrl, setCourseYoutubeUrl] = useState(course.youtube_url);

    const [courseRewards, setCourseRewards] = useState(course.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].included_list);
    const [courseRewards1, setCourseRewards1] = useState(course.packages[1].l10n[getIndexOf("l10n", selectedLanguage, course.packages[1].l10n)].included_list);
    const [courseRewards2, setCourseRewards2] = useState(course.packages[2].l10n[getIndexOf("l10n", selectedLanguage, course.packages[2].l10n)].included_list);

    const [courseVisits, setCourseVisits] = useState(course?.packages[0]?.visits);
    const [courseVisits1, setCourseVisits1] = useState(course?.packages[1]?.visits);
    const [courseVisits2, setCourseVisits2] = useState(course?.packages[2]?.visits);

    const [coursePrice, setCoursePrice] = useState(course?.packages[0]?.price);
    const [coursePrice1, setCoursePrice1] = useState(course?.packages[1]?.price);
    const [coursePrice2, setCoursePrice2] = useState(course?.packages[2]?.price);

    const [coursePriceDiscounted, setCoursePriceDiscounted] = useState(course?.packages[0]?.price_discounted);
    const [coursePriceDiscounted1, setCoursePriceDiscounted1] = useState(course?.packages[1]?.price_discounted);
    const [coursePriceDiscounted2, setCoursePriceDiscounted2] = useState(course?.packages[2]?.price_discounted);

    const [courseTraineeAge, setCourseTraineeAge] = useState(course?.packages[0]?.trainees_age);
    const [courseTraineeAge1, setCourseTraineeAge1] = useState(course?.packages[1]?.trainees_age);
    const [courseTraineeAge2, setCourseTraineeAge2] = useState(course?.packages[2]?.trainees_age);

    const [courseTraineeMax, setCourseTraineeMax] = useState(course?.packages[0]?.trainees_max);
    const [courseTraineeMax1, setCourseTraineeMax1] = useState(course?.packages[1]?.trainees_max);
    const [courseTraineeMax2, setCourseTraineeMax2] = useState(course?.packages[2]?.trainees_max);

    const [accDesc, setAccDesc] = useState(course.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].accommodation_description);
    const [accName, setAccName] = useState(course.packages[0].accommodation_name);
    const [accNights, setAccNights] = useState(course.packages[0].accommodation_nights);
    const [accPrice, setAccPrice] = useState(course.packages[0].accommodation_price);
    const [accLocation, setAccLocation] = useState(course.packages[0].accommodation_address);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(null);


    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [openedFaq, setOpenedFaq] = useState(false);

    const [toggleFilters, setToggleFilters] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState();

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [followCourse, setFollowCourse] = useState();
    const [selectedPackage, setSelectedPackage] = useState(0);

    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);

    const [valueDatePicker, onChangeValueDatePicker] = useState(new Date());
    const [hourRangePicker, onChangeHourRangePicker] = useState();

    const [basicGroupDates, onChangeBasicGroupDates] = useState([]);
    const [advancedGroupDates, onChangeAdvancedGroupDates] = useState([]);
    const [expertGroupDates, onChangeExpertGroupDates] = useState([]);

    const [basicGroupHourRanges, onChangeBasicGroupHourRanges] = useState([]);
    const [advancedGroupHourRanges, onChangeAdvancedGroupHourRanges] = useState([]);
    const [expertGroupHourRanges, onChangeExpertGroupHourRanges] = useState([]);

    const [basicGroupDateAndHourRanges, onChangeBasicGroupDateAndHourRanges] = useState([]);
    const [advancedGroupDateAndHourRanges, onChangeAdvancedGroupDateAndHourRanges] = useState([]);
    const [expertGroupDateAndHourRanges, onChangeExpertGroupDateAndHourRanges] = useState([]);

    const [basicGroupStartDates, onChangeBasicGroupStartDates] = useState([]);
    const [advancedGroupStartDates, onChangeAdvancedGroupStartDates] = useState([]);
    const [expertGroupStartDates, onChangeExpertGroupStartDates] = useState([]);

    const [lecturers, setLecturers] = useState([]);
    const [providerLecturers, setProviderLecturers] = useState([]);

    const [lecturersProfilePictures, setLecturersProfilePictures] = useState([]);

    console.log("PROFILE PICS,", lecturersProfilePictures);
    console.log("LECTURERS,", lecturers);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const [logoProvider, setLogoProvider] = useState([]);
    const [logoAccommodation, setLogoAccommodation] = useState([]);
    const [images, setImages] = useState([]);
    const maxNumberImageUploads = 10;

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, [])

    useEffect(() => {
        console.log(course_id)
        if (_course) {
            dispatch(loadCourseById({course_id: _course?.course_id}, selectedLanguage, (resData) => {
                setCourse(resData);
                dispatch(loadProviderDataById({provider_id: resData.provider_id}, selectedLanguage, (resData2) => {
                    setProvider(resData2);
                }, () => {
                }))
            }, () => {
            }))
        }

    }, []);

    const updateDataHandler = (key, value) => {
        let updateData = course;
        updateData["user_id"] = user.user_id;
        updateData[key] = value;
        dispatch(updateCourseData(updateData, selectedLanguage, () => {
        }));
    };


    const updateL10nDataHandler = (key, value) => {
        let updateData = course;
        updateData["user_id"] = user.user_id;
        updateData[key] = value;
        dispatch(updateCourseL10NData(updateData, selectedLanguage, () => {
        }));
    };


    const updateLecturerDataHandler = (key, value, lecturer, successAction) => {
        let updateData = lecturer;
        updateData["user_id"] = user.user_id;
        updateData[key] = value;
        dispatch(updateLecturerData(updateData, () => {
            if (successAction) {
                successAction();
            }
        }));
    };


    const updateL10nDataHandlerPackage = (key, value, tier) => {
        let updateData = course;
        updateData["user_id"] = user.user_id;

        let j = null;

        switch (selectedLanguage) {
            case L10N.si.value:
                j = 0;
                break;
            case L10N.en.value:
                break;
            case L10N.it.value:
                break;
        }

        for (let i = 0; i < TIERS.length; i++) {
            if (tier === undefined || tier === null || updateData["packages"][i]["tier"] === tier) {
                let index = getIndexOf("l10n", selectedLanguage, updateData["packages"][i]["l10n"]);
                updateData["packages"][i]["l10n"][index][key] = value;
            }
        }

        dispatch(updateCourseL10NData(updateData, selectedLanguage, () => {
        }));
    };

    const updateDataHandlerPackage = (key, value, tier) => {
        let updateData = course;
        updateData["user_id"] = user.user_id;

        let j = null;

        switch (selectedLanguage) {
            case L10N.si.value:
                j = 0;
                break;
            case L10N.en.value:
                break;
            case L10N.it.value:
                break;
        }

        for (let i = 0; i < TIERS.length; i++) {
            if (tier === undefined || tier === null || updateData["packages"][i]["tier"] === tier) {
                updateData["packages"][i][key] = value;
            }
        }

        dispatch(updateCourseData(updateData, selectedLanguage, () => {
        }));
    };

    const updateDataHandlerAccommodation = (key, value) => {
        let updateData = course;
        updateData["user_id"] = user.user_id;
        for (let i = 0; i < 3; i++) {
            updateData["packages"][i][key] = value;
        }

        dispatch(updateCourseData(updateData, selectedLanguage, () => {
        }));
    };

    const renderUploadLecturersProfilePictureContainer = (index) => {

        const renderUploadArea = (i, isDragging, onImageUpload, dragProps) => {

            return (
                <div className={'upload-section col'}>
                    <button
                        className={'upload-area-button'}
                        onClick={onImageUpload}
                        {...dragProps}
                    >
                        <div className={'upload-area-container col'}
                             style={{border: isDragging ? "1px dotted black" : 'none', pointerEvents: 'none'}}>
                            <p className={'p-info'}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                            <div className={'upload-area upload-small col'}>
                                <img src={plusCircleIcon} className={'icon-label'} alt={'img'} width={'28px'}
                                     style={{padding: '6px', paddingBottom: 0}}/>
                            </div>
                        </div>
                    </button>
                </div>

            );
        }
        const renderUploadedItemsArea = (i, imageList, onImageUpdate, onImageRemove) => {


            return (
                <div className={'uploaded-gallery row scroll'}>
                    {lecturersProfilePictures.map((image, index) => (
                        <div key={index} className="uploaded-image-item">

                            {index === i && <>
                                <img src={image} alt="" width="80"/>
                                <div className="image-item__btn-wrapper row center">
                                    <p className={'p-info underline click-light'}
                                       style={{cursor: 'pointer'}}
                                       onClick={onImageUpdate}>{L10N_APP_TEXTS[selectedLanguage].UPDATE}
                                    </p>
                                    {/*
                                       <p className={'p-text remove click'}
                                   style={{position: 'absolute', marginLeft: '90px', marginTop: '-220px'}}
                                   onClick={() => onImageRemove(index)}>x</p>
                                */}
                                </div>
                            </>}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <ImageUploading
                single
                value={lecturersProfilePictures[index]}
                onChange={(imageList) => onChangeLecturersProfilePictureUpload(imageList, index)}
                maxNumber={1}
                dataURLKey="data_url"
                acceptType={["jpg", "png"]}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload-container col logo lecturer">
                        {!lecturersProfilePictures[index] && renderUploadArea(index, isDragging, onImageUpload, dragProps)}
                        {renderUploadedItemsArea(index, lecturersProfilePictures, onImageUpdate, onImageRemove)}
                    </div>
                )}
            </ImageUploading>
        );
    }


    const onChangeLecturersProfilePictureUpload = (imageList, addUpdateIndex) => {
        // data for submit
        console.log("LIST", imageList, "INDEX", addUpdateIndex);

        console.log("lecturersProfilePictures", lecturersProfilePictures);

        let tmp = lecturersProfilePictures;
        tmp[addUpdateIndex] = imageList[0].data_url;

        if (!tmp[addUpdateIndex].image_id) {
            dispatch(createImage({base64: imageList[0].data_url, type: 'LOGO'}, (resData) => {
                updateLecturerDataHandler("image_id", resData.image_id, lecturers[addUpdateIndex]);
            }, () => {
            }))
        } else {
            dispatch(updateImage({image_id: provider.image_id, base64: imageList[0].data_url}, (resData) => {
                updateLecturerDataHandler("image_id", resData.image_id, lecturers[addUpdateIndex]);
            }, () => {
            }))
        }


        setLecturersProfilePictures([...tmp]);
    };

    const onChangeImageUpload = (imageList, addUpdateIndex) => {
        // data for submit

        if (!imageList || imageList.length <= 0) {
            return;
        }

        for (let i = 0; i < imageList.length; i++) {
            if (!images[i]) {
                dispatch(createImage({
                    course_id: course.course_id,
                    base64: imageList[i].data_url,
                    type: 'GALLERY'
                }, (resData) => {
                    //     updateProviderDataHandler("image_id", resData.image_id);

                    imageList[i].image_id = resData.image_id
                    //setImages(imageList);

                }, () => {
                }))
            } else {
                dispatch(updateImage({image_id: images[i]?.image_id, base64: imageList[i].data_url}, (resData) => {
                    //       updateProviderDataHandler("image_id", resData.image_id);
                    imageList[i].image_id = resData.image_id
                    //setImages(imageList);

                }, () => {
                }))
            }
        }

        setImages(imageList);

    };


    const onChangeLogoUploadAccommodation = (imageList, addUpdateIndex) => {
        // data for submit
        setLogoAccommodation(imageList);

        if (!imageList || imageList.length <= 0) {
            return;
        }

        if (!course.image_id) {
            dispatch(createImage({base64: imageList[0].data_url, type: 'LOGO'}, (resData) => {
                updateDataHandler("image_id", resData.image_id);
            }, () => {
            }))
        } else {
            dispatch(updateImage({image_id: course.image_id, base64: imageList[0].data_url}, (resData) => {
                updateDataHandler("image_id", resData.image_id);
            }, () => {
            }))
        }
    };


    const renderUploadLogoContainerAccommodation = () => {
        const renderUploadArea = (isDragging, onImageUpload, dragProps) => {
            return (
                <div className={'upload-section col'}>
                    <button
                        className={'upload-area-button'}
                        onClick={onImageUpload}
                        {...dragProps}
                    >
                        <div className={'upload-area-container col'}
                             style={{border: isDragging ? "1px dotted black" : 'none', pointerEvents: 'none'}}>
                            <p className={'p-info'}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                            <div className={'upload-area upload-small col'}>
                                <img src={plusCircleIcon} className={'icon-label'} alt={'img'} width={'28px'}
                                     style={{padding: '6px', paddingBottom: 0}}/>
                            </div>
                        </div>
                    </button>
                </div>

            );
        }
        const renderUploadedItemsArea = (imageList, onImageUpdate, onImageRemove) => {
            return (
                <div className={'uploaded-gallery row scroll'}>
                    {logoAccommodation.map((image, index) => (
                        <div key={index} className="uploaded-image-item">
                            <img src={image.data_url} alt="" width="80"/>
                            <div className="image-item__btn-wrapper row center">
                                <p className={'p-info underline click-light'}
                                   style={{cursor: 'pointer'}}
                                   onClick={() => onImageUpdate(index)}>{L10N_APP_TEXTS[selectedLanguage].UPDATE}
                                </p>
                                <p className={'p-text remove click'}
                                   style={{position: 'absolute', marginLeft: '90px', marginTop: '-220px'}}
                                   onClick={() => {
                                       onImageRemove(index);

                                       dispatch(deleteImage({image_id: course.image_id}, (resData) => {
                                           updateDataHandler("image_id", null);
                                       }, () => {
                                       }))

                                   }}>x</p>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <ImageUploading
                multiple
                value={logoAccommodation}
                onChange={onChangeLogoUploadAccommodation}
                maxNumber={1}
                dataURLKey="data_url"
                acceptType={["jpg", "png"]}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload-container col logo">
                        {logoAccommodation?.length <= 0 && renderUploadArea(isDragging, onImageUpload, dragProps)}
                        {renderUploadedItemsArea(imageList, onImageUpdate, onImageRemove)}
                    </div>
                )}
            </ImageUploading>
        );
    }


    const renderUploadImagesContainer = () => {
        const renderUploadArea = (isDragging, onImageUpload, dragProps) => {
            return (
                <div className={'upload-section col'}>
                    <button
                        className={'upload-area-button'}
                        onClick={onImageUpload}
                        {...dragProps}
                    >
                        <div className={'upload-area-container'}
                             style={{border: isDragging ? "1px dotted black" : 'none', pointerEvents: 'none'}}>
                            <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                            <div className={'upload-area upload-small col'}>
                                <div className={'col'}>
                                    <p className={'p-info'}>{L10N_APP_TEXTS[selectedLanguage].IMAGE}</p>
                                    <img src={iconImg} className={'icon-label'} alt={'img'} width={'80px'}
                                         style={{padding: '12px'}}/>
                                </div>
                                <img src={plusCircleIcon} className={'icon-label'} alt={'img'} width={'80px'}
                                     style={{padding: '12px'}}/>
                            </div>
                        </div>
                    </button>

                    <ul>
                        <li>{L10N_APP_TEXTS[selectedLanguage].UPLOAD_IMAGES_INFO_1}</li>
                        <li>{L10N_APP_TEXTS[selectedLanguage].UPLOAD_IMAGES_INFO_2}</li>
                    </ul>
                </div>

            );
        }
        const renderUploadedItemsArea = (imageList, onImageUpdate, onImageRemove) => {
            return (
                <div className={'uploaded-gallery row scroll'}>
                    {imageList.map((image, index) => (
                        <div key={index} className="uploaded-image-item">
                            <img src={image.data_url} alt="" width="100"/>
                            <div className="image-item__btn-wrapper row space-between">
                                <p className={'p-info underline click-light'} style={{cursor: 'pointer'}}
                                   onClick={() => onImageUpdate(index)}>{L10N_APP_TEXTS[selectedLanguage].UPDATE}
                                </p>
                                <p className={'p-info remove click'} onClick={() => {

                                    dispatch(deleteImage({image_id: images[index].image_id}, (resData) => {
                                        onImageRemove(index);

                                        dispatch(loadCourseImages({
                                            course_id: provider.course_id,
                                            type: "GALLERY"
                                        }, (resData) => {
                                            //setLogo([{data_url: resData.image}]);

                                            for (let i = 0; i < resData.length; i++) {
                                                resData[i].data_url = resData[i].image;
                                                delete resData[i].image;
                                            }
                                            setImages(resData);


                                        }, () => {
                                            //setLogo([]);
                                            //updateProviderDataHandler("image_id", null);

                                        }))
                                    }, () => {
                                    }))
                                }}>x</p>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <ImageUploading
                multiple
                value={images}
                onChange={onChangeImageUpload}
                maxNumber={maxNumberImageUploads}
                dataURLKey="data_url"
                acceptType={["jpg", "png"]}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload-container col">
                        {renderUploadArea(isDragging, onImageUpload, dragProps)}

                        {imageList.length > 0 ?
                            <p className={'p-info underline remove click-light'} style={{paddingLeft: '24px'}}
                               onClick={onImageRemoveAll}>{L10N_APP_TEXTS[selectedLanguage].REMOVE_ALL_IMAGES}</p> : <></>
                        }
                        {renderUploadedItemsArea(imageList, onImageUpdate, onImageRemove)}
                    </div>
                )}
            </ImageUploading>
        );
    }

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                opacity: 0,
                height: 0,
            },
            to: {
                opacity: toggleFilters ? 1 : 0,
                height: toggleFilters ? 'auto' : 0,
            },
            config: {duration: 100},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    const onInputChange = (value, onInputAction) => {

        setError(null);
        onInputAction(value);
    }

    useEffect(() => {

        if (course.image_id) {
            dispatch(loadImage({image_id: course.image_id, type: "LOGO"}, (resData) => {
                setLogoAccommodation([{data_url: resData.image}]);
            }, () => {
                setLogoAccommodation([]);
                updateDataHandler("image_id", null);
            }))
        }

        dispatch(loadCourseImages({course_id: course.course_id, type: "GALLERY"}, (resData) => {
            //setLogo([{data_url: resData.image}]);

            for (let i = 0; i < resData.length; i++) {
                resData[i].data_url = resData[i].image;
                delete resData[i].image;
            }
            setImages(resData);


        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);

        }));

        dispatch(loadAllCategories((res) => {
            setAllCategories(res);


            let upd = findItem("category_id", course.category_id, res);
            setCourseCategory(upd);

        }, (res) => {
        }));

        dispatchLoadDateTimes();


    }, [course]);

    const dispatchLoadDateTimes = () => {
        for (let i = 0; i < TIERS.length; i++) {
            if (course.packages[i]?.package_id) {
                let tmp = {
                    package_id: course.packages[i]?.package_id
                };
                dispatch(loadPackageDates(tmp, (res) => {
                    console.log("PACKAGE DATES", res);

                    setGroupDateArray(i, res);
                    let u = [];
                    let checkboxes = [];
                    for (let i = 0; i < res.length; i++) {
                        let h = new Date(res[i].start_hour).getHours() - 1;
                        if (!h) {
                            h = 0;
                        }
                        let m = new Date(res[i].start_hour).getMinutes();

                        let h_end = new Date(res[i].end_hour).getHours() - 1;
                        if (!h_end) {
                            h_end = 0;
                        }
                        let m_end = new Date(res[i].end_hour).getMinutes();

                        u.push([`${h}:${m}`, `${h_end}:${m_end}`]);

                        checkboxes.push(res[i].start_date);
                    }
                    console.log(u);

                    setGroupHoursArray(i, u);
                    setGroupCheckboxArray(i, checkboxes);

                }, (res) => {
                    setGroupDateArray(i, []);
                }));
            }
        }
    }

    console.log("PLIS", basicGroupDates, basicGroupHourRanges, advancedGroupDates, advancedGroupHourRanges)

    const dispatchLoadLecturers = (courseOnly) => {
        dispatch(loadLecturers({provider_id: provider.provider_id}, (resData) => {
            console.log(resData);

            setProviderLecturers(resData);

            let lecturersUpdated = [];
            let u = [];

            for (let i = 0; i < resData.length; i++) {
                let lec = resData[i];


                if (lec.course_id !== course.course_id) {
                    continue;
                }
                lecturersUpdated.push(lec);
                console.log("LEC[i]:", lec, course.course_id, lec.course_id)

                if (!lec.image_id) {
                    u.push(null)
                    continue;
                }

                dispatch(loadImage({image_id: lec.image_id, type: "LOGO"}, (resData) => {
                    u.push(resData.image)

                    setTimeout(() => {
                        setLecturersProfilePictures(u);
                        console.log("LEC: ", lecturersUpdated)
                    }, 200);
                }, () => {
                }))


            }
            setLecturers(lecturersUpdated);

        }, () => {
        }));
    }

    useEffect(() => {
        if (provider.image_id) {
            dispatch(loadImage({image_id: provider.image_id, type: "LOGO"}, (resData) => {
                setLogoProvider([{data_url: resData.image}]);
            }, () => {
                setLogoProvider([]);
            }))
        }
        dispatchLoadLecturers(true);

    }, [provider]);

    useEffect(() => {
        if (valueDatePicker) {

        }
    }, [valueDatePicker]);


    const reloadCourseL10NData = (course) => {
        console.log("reload")
        let s = course?.l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.l10n)]?.name;
        setCourseName(s ? s : "");

        s = course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].accommodation_description;
        setAccDesc(s ? s : "");

        s = course?.packages[0].accommodation_name;
        setAccName(s ? s : "");

        s = course?.packages[0].accommodation_address;
        setAccLocation(s ? s : "");

        s = course?.packages[0].accommodation_price;
        setAccPrice(s ? s : "");

        s = course?.packages[0].accommodation_nights;
        setAccNights(s ? s : "");

        s = course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].requirements_list;
        setCourseNeeds(s ? s : "");

        let dl;
        let il;
        let vis;
        let maxNmb;
        let age;
        let p;
        let pD;
        for (let i = 0; i < TIERS.length; i++) {
            dl = course?.packages[i].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[i].l10n)]?.description_list;
            il = course?.packages[i].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[i].l10n)]?.included_list;
            vis = course?.packages[i]?.visits;
            maxNmb = course?.packages[i]?.trainees_max;
            age = course?.packages[i]?.trainees_age;
            p = course?.packages[i]?.price;
            pD = course?.packages[i]?.price_discounted;

            if (i === 0) {
                setCourseLearn(dl ? dl : "");
                setCourseRewards(il ? il : "");
                setCourseVisits(vis ? vis : "");
                setCourseTraineeAge(age ? age : "");
                setCourseTraineeMax(maxNmb ? maxNmb : "");
                setCoursePrice(p ? p : "");
                setCoursePriceDiscounted(pD ? pD : "");
            } else if (i === 1) {
                setCourseLearn1(dl ? dl : "");
                setCourseRewards1(il ? il : "");
                setCourseVisits1(vis ? vis : "");
                setCourseTraineeAge1(age ? age : "");
                setCourseTraineeMax1(maxNmb ? maxNmb : "");
                setCoursePrice1(p ? p : "");
                setCoursePriceDiscounted1(pD ? pD : "");
            } else {
                setCourseLearn2(dl ? dl : "");
                setCourseRewards2(il ? il : "");
                setCourseVisits2(vis ? vis : "");
                setCourseTraineeAge2(age ? age : "");
                setCourseTraineeMax2(maxNmb ? maxNmb : "");
                setCoursePrice2(p ? p : "");
                setCoursePriceDiscounted2(pD ? pD : "");
            }
        }
    }

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);
        if (course) {
            dispatch(loadCourseById({course_id: course.course_id}, selectedLanguage, () => {
            }, () => {
            }))

            reloadCourseL10NData(course);
        }
    }, [l10n, selectedLanguage]);


    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error l10n={selectedLanguage} type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

   const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

       return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const displayCollapsibleFaq = (status, question, answer) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header faq-options'} style={{justifyContent: 'flex-end'}}>
                <div className={'faq-question'}>
                    <p className={'p-title'}>{question}</p>
                    {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
                </div>
                {status ? <div className={'faq-desc'}>
                    <p className={'p-header black'}>{answer}</p>
                </div> : <></>}
            </div>
        );
    }

    const onActionCollapseFaq = (i) => {
        setOpenedFaq(i);
    }

    const renderFaq = () => {
        return (
            <>
                <Title dots title={'FAQs'}/>
                <div className={'faq-container'}>
                    {FAQ.map((faq, f) => (
                        <div key={`${f}_${faq.question}`} className={'collapsible-faq-container'}>
                            <Collapsible className={'faq-options'}
                                         transitionTime={400}
                                         onClosing={() => onActionCollapseFaq(false)}
                                         onOpening={() => onActionCollapseFaq(f)}
                                         open={openedFaq === f}
                                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                                         trigger={displayCollapsibleFaq(false, faq.question, faq.answer)}
                                         triggerWhenOpen={displayCollapsibleFaq(true, faq.question, faq.answer)}>
                            </Collapsible>
                        </div>
                    ))}
                </div>
            </>

        );
    }


    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}
                <ScrollableTabsEdit course/>

                {renderCourseInfo()}
                {renderEditCourseInfo()}
                {renderUploadImagesContainer()}
                {renderCoursePackages()}
                {renderCourseRequirements()}
                {renderCourseAccommodationEdit()}
                {renderLecturers()}
                {renderComparisonSection()}
            </div>
        )
    }

    const renderEditCourseInfo = () => {
        return (
            <div className={'course-edit tab_presentation'}>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].ADD_NEW_COURSE}/>
                <div className={'form-container'} style={{marginBottom: '0'}}>
                    <div className={'form_col form-row'}>
                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                               value={courseName}
                               onChange={(value) => onInputChange(value, setCourseName)}
                               onSubmit={() => updateL10nDataHandler("name", courseName)}
                               onAction={() => updateL10nDataHandler("name", courseName)}

                               placeholder={L10N_APP_TEXTS[selectedLanguage].NAME_COURSE}
                               label={`${L10N_APP_TEXTS[selectedLanguage].NAME_COURSE}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                               value={courseAddress}
                               onChange={(value) => onInputChange(value, setCourseAddress)}
                               onSubmit={() => updateDataHandler("address", courseAddress)}
                               onAction={() => updateDataHandler("address", courseAddress)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].ADDRESS}
                               label={`${L10N_APP_TEXTS[selectedLanguage].ADDRESS}:`}
                               style={{marginBottom: '0px'}}/>


                        {
                            /*
                      <div className={'custom-dropdown'}>
                            <Dropdown grey
                                      regions
                                      style={{marginBottom: '24px'}}
                                      label={`${L10N_APP_TEXTS[selectedLanguage].FILTER_REGION}:`}
                                      value={courseRegion}
                                      onChange={(value) => {
                                          setCourseCategory(value)
                                          updateDataHandler("region", value)
                                      }}
                                      onSubmit={(value) => updateDataHandler("region", value)}
                                      options={REGIONS}
                                      placeholder={courseRegion ? courseRegion : L10N_APP_TEXTS[selectedLanguage].FILTER_REGION}/>
                        </div>
                            * */
                        }


                        <div className={'custom-dropdown'}>
                            <Dropdown grey
                                      categories
                                      style={{marginBottom: '48px'}}
                                      label={`${L10N_APP_TEXTS[selectedLanguage].FILTER_CATEGORY}:`}
                                      value={courseCategory}
                                      onChange={(value) => {
                                          setCourseCategory(value)
                                          updateDataHandler("category_id", value.category_id)
                                      }}
                                      onSubmit={(value) => updateDataHandler("category_id", value.category_id)}
                                      options={allCategories}
                                      placeholder={selectedLanguage === L10N.si.value ? courseCategory?.name_si : selectedLanguage === L10N.en.value ? courseCategory?.name_en : selectedLanguage === L10N.it.value ? courseCategory?.name_it : L10N_APP_TEXTS[selectedLanguage].FILTER_CATEGORY}/>
                        </div>

                        <DropdownCircle text={`${L10N_APP_TEXTS[selectedLanguage].LANGUAGE_SPOKEN_PLURAL}:`}
                                        info={L10N_APP_TEXTS[selectedLanguage].COURSE_LANGUAGES}
                                        course
                                        onChange={(key, value) => {
                                            updateDataHandler(key, value);
                                        }}/>

                        <div style={{paddingTop: '24px'}}/>
                        <Input button={'edit'}
                               value={courseYoutubeUrl}
                               onChange={(value) => onInputChange(value, setCourseYoutubeUrl)}
                               onAction={() => updateDataHandler("youtube_url", courseYoutubeUrl)}
                               onSubmit={() => updateDataHandler("youtube_url", courseYoutubeUrl)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].YOUTUBE}
                               label={`${L10N_APP_TEXTS[selectedLanguage].VIDEO_URL}:`}
                        />

                    </div>
                </div>
                {renderErrorMessage()}

            </div>
        );
    }

    const renderDatePicker = (group, index) => {
        return (
            <div className={'row'}>
                <div className={'row date-edit-container'}>
                    <div className={'date-picker'}>
                        <DatePicker className={'time-picker'} onChange={(value) => {
                            /*
                            if (selectedProduct) {
                                selectedProduct.happy_hour_time_start = value ? value[0] : null;
                                selectedProduct.happy_hour_time_end = value ? value[1] : null;
                                updateProductDispatch(selectedProduct);
                            }
                            */
                            console.log("CHANGE DATE", value);
                            updateCourseDateForGroup(group, index, value);
                            //   updateCourseDateAndHourRangeForGroup(group, index);

                        }} value={new Date(getGroupDateObject(group, index)?.start_hour)}
                        />
                    </div>
                    <div className={'hour-range-picker'}>
                        <TimeRangePicker className={'time-picker'} onChange={(value) => {
                            /*
                            if (selectedProduct) {
                                selectedProduct.happy_hour_time_start = value ? value[0] : null;
                                selectedProduct.happy_hour_time_end = value ? value[1] : null;
                                updateProductDispatch(selectedProduct);
                            }
                            */
                            console.log("CHANGE HOUR RANGE", value);

                            updateCourseHourRangeForGroup(group, index, value);
                            //updateCourseDateAndHourRangeForGroup(group, index);

                        }} value={getGroupHourRangeObject(group, index)}
                        />
                    </div>
                </div>

                <p className={'p-text remove click-light'}
                   onClick={() => onRemoveCourseDateAndHourRangeForGroup(group, index)} style={{
                    fontWeight: '600',
                    marginLeft: '12px',
                    marginRight: '-3px',
                    marginBottom: '4px',
                }}>x</p>
            </div>

        );
    }

    const setGroupDateArray = (group, a) => {
        switch (group) {
            case 0:
                onChangeBasicGroupDates(a);
                break;
            case 1:
                onChangeAdvancedGroupDates(a);
                break;
            case 2:
                onChangeExpertGroupDates(a);
                break;
            default:
                console.warn(`Unknown group: ${group}`)
                return null;
        }
    };
    const setGroupHoursArray = (group, a) => {
        switch (group) {
            case 0:
                onChangeBasicGroupHourRanges(a);
                break;
            case 1:
                onChangeAdvancedGroupHourRanges(a);
                break;
            case 2:
                onChangeExpertGroupHourRanges(a);
                break;
            default:
                console.warn(`Unknown group: ${group}`)
                return null;
        }
    };

    const setGroupCheckboxArray = (group, a) => {
        switch (group) {
            case 0:
                onChangeBasicGroupStartDates(a);
                break;
            case 1:
                onChangeAdvancedGroupStartDates(a);
                break;
            case 2:
                onChangeExpertGroupStartDates(a);
                break;
            default:
                console.warn(`Unknown group: ${group}`)
                return null;
        }
    };

    const getGroupDateArray = (group) => {
        switch (group) {
            case 0:
                return basicGroupDates;
            case 1:
                return advancedGroupDates;
            case 2:
                return expertGroupDates;
            default:
                console.warn(`Unknown group: ${group}`)
                return null;
        }
    };


    const getGroupHoursArray = (group) => {
        switch (group) {
            case 0:
                return basicGroupHourRanges;
            case 1:
                return advancedGroupHourRanges;
            case 2:
                return expertGroupHourRanges;
            default:
                console.warn(`Unknown group: ${group}`)
                return null;
        }
    };

    const getGroupDateObject = (group, index) => {
        switch (group) {
            case 0:
                return basicGroupDates[index];
            case 1:
                return advancedGroupDates[index];
            case 2:
                return expertGroupDates[index];
            default:
                console.warn(`Unknown group: ${group}, for array index: ${index}`)
                return null;
        }
    };


    const getGroupHourRangeObject = (group, index) => {
        switch (group) {
            case 0:
                return basicGroupHourRanges[index];
            case 1:
                return advancedGroupHourRanges[index];
            case 2:
                return expertGroupHourRanges[index];
            default:
                console.warn(`Unknown group: ${group}, for array index: ${index}`)
                return null;
        }
    };

    const updateCourseDateForGroup = (group, index, value) => {
        let updated;
        switch (group) {
            case 0:
                console.log(group, index, value);

                updated = [...basicGroupDates];

                //  onChangeBasicGroupDates(updated);

                //onChangeBasicGroupDateAndHourRanges(updated);
                break;
            case 1:
                console.log(group, index, value);

                updated = [...advancedGroupDates];

                //onChangeAdvancedGroupDates(updated);
                //onChangeAdvancedGroupDateAndHourRanges(updated);
                break;
            case 2:
                console.log(group, index, value);
                updated = [...expertGroupDates];

                // onChangeExpertGroupDates(updated);
                //onChangeExpertGroupDateAndHourRanges(updated);
                break;
            default:
                console.warn("Unknown group: ", group)
                return;
        }

        updated[index].start_hour = value;
        let tmp = updated[index];

        dispatch(updatePackageDateData(tmp, (res) => {
            dispatchLoadDateTimes()
            console.log(res);
        }, (res) => {
        }));
    };

    const updateCourseHourRangeForGroup = (group, index, value) => {
        let updated;
        let updatedDates;
        switch (group) {
            case 0:
                updated = [...basicGroupHourRanges];
                updatedDates = [...basicGroupDates];


                //  onChangeBasicGroupHourRanges(updated);

                //onChangeBasicGroupDateAndHourRanges(updated);
                break;
            case 1:
                updated = [...advancedGroupHourRanges];
                updatedDates = [...advancedGroupDates];
                updated[index] = value;

                //  onChangeAdvancedGroupHourRanges(updated);
                //onChangeAdvancedGroupDateAndHourRanges(updated);
                break;
            case 2:
                updated = [...expertGroupHourRanges];
                updatedDates = [...expertGroupDates];
                updated[index] = value;

                //onChangeExpertGroupHourRanges(updated);
                //onChangeExpertGroupDateAndHourRanges(updated);
                break;
            default:
                console.warn("Unknown group: ", group)
                return;
        }
        console.log("sss", updatedDates[index])

        let h = value[0].split(":")[0];
        let m = value[0].split(":")[1];

        let h_end = value[1].split(":")[0];
        let m_end = value[1].split(":")[1];

        let dateParsed = updatedDates[index].start_hour.split("T")[0] + `T${h}:${m}:00.000Z`
        let dateParsedEnd = updatedDates[index].end_hour.split("T")[0] + `T${h_end}:${m_end}:00.000Z`

        updatedDates[index].start_hour = dateParsed;
        updatedDates[index].end_hour = dateParsedEnd;

        let tmp = updatedDates[index];

        dispatch(updatePackageDateData(tmp, (res) => {
            dispatchLoadDateTimes()
        }, (res) => {
        }));
    };

    const onAddNewCourseDateAndHourRangeForGroup = (group, value) => {
        let gDaHr;
        let gD;
        let gHr;
        let stD;
        console.log(value)
        switch (group) {
            case 0:
                gHr = [...basicGroupHourRanges]
                gD = [...basicGroupDates]
                gDaHr = [...basicGroupDateAndHourRanges];
                stD = [...basicGroupStartDates]

                gD.push(new Date().getUTCDate());
                gHr.push({});
                gDaHr.push(value);
                stD.push(false);

                //onChangeBasicGroupDates(gD);
                // onChangeBasicGroupHourRanges(gHr);
                //onChangeBasicGroupDateAndHourRanges(gDaHr);
                //onChangeBasicGroupStartDates(stD);
                break;
            case 1:
                gHr = [...advancedGroupHourRanges]
                gD = [...advancedGroupDates]
                gDaHr = [...advancedGroupDateAndHourRanges];
                stD = [...advancedGroupStartDates]

                gD.push(new Date().getUTCDate());
                gHr.push({});
                gDaHr.push(value);
                stD.push(false);

               // onChangeAdvancedGroupDates(gD);
                //onChangeAdvancedGroupHourRanges(gHr);
              //  onChangeAdvancedGroupDateAndHourRanges(gDaHr);
                //onChangeAdvancedGroupStartDates(stD);
                break;
            case 2:
                gHr = [...expertGroupHourRanges]
                gD = [...expertGroupDates]
                gDaHr = [...expertGroupDateAndHourRanges];
                stD = [...expertGroupStartDates]

                gD.push(new Date().getUTCDate());
                gHr.push({});
                gDaHr.push(value);
                stD.push(false);

               // onChangeExpertGroupDates(gD);
                //onChangeExpertGroupHourRanges(gHr);
               // onChangeExpertGroupDateAndHourRanges(gDaHr);
               // onChangeExpertGroupStartDates(stD);
                break;
            default:
                console.warn("Unknown group: ", group)
                return;
        }
        let tmp = {
            package_id: course.packages[group].package_id,
            start_hour: value.toISOString(),
            end_hour: value.toISOString(),
        };
        dispatch(createPackageDate(tmp, (res) => {
            dispatchLoadDateTimes()
            console.log(res);
        }, (res) => {
        }));
    }

    const onRemoveCourseDateAndHourRangeForGroup = (group, index) => {
        let gDaHr;
        let gD;
        let gHr;
        let stD;
        console.log(`Group: ${group}, index: ${index}`);
        switch (group) {
            case 0:

                gHr = [...basicGroupHourRanges]
                gD = [...basicGroupDates]
                gDaHr = [...basicGroupDateAndHourRanges];
                stD = [...basicGroupStartDates];

                gDaHr.splice(index, 1);
                gD.splice(index, 1);
                gHr.splice(index, 1);
                stD.splice(index, 1);

                onChangeBasicGroupDates(gD);
                onChangeBasicGroupHourRanges(gHr);
                onChangeBasicGroupDateAndHourRanges(gDaHr);
                onChangeBasicGroupStartDates(stD);
                break;
            case 1:
                gHr = [...advancedGroupHourRanges]
                gD = [...advancedGroupDates]
                gDaHr = [...advancedGroupDateAndHourRanges];
                stD = [...advancedGroupStartDates];

                gDaHr.splice(index, 1);
                gD.splice(index, 1);
                gHr.splice(index, 1);
                stD.splice(index, 1);

                onChangeAdvancedGroupDates(gD);
                onChangeAdvancedGroupHourRanges(gHr);
                onChangeAdvancedGroupDateAndHourRanges(gDaHr);
                onChangeAdvancedGroupStartDates(stD);
                break;
            case 2:
                gHr = [...expertGroupHourRanges]
                gD = [...expertGroupDates]
                gDaHr = [...expertGroupDateAndHourRanges];
                stD = [...expertGroupStartDates];

                gDaHr.splice(index, 1);
                gD.splice(index, 1);
                gHr.splice(index, 1);
                stD.splice(index, 1);

                onChangeExpertGroupDates(gD);
                onChangeExpertGroupHourRanges(gHr);
                onChangeExpertGroupDateAndHourRanges(gDaHr);
                onChangeExpertGroupStartDates(stD);
                break;
            default:
                console.warn("Unknown group: ", group)
                return;
        }

        let tmp = {
            package_date_id: getGroupDateArray(group)[index].package_date_id,
            user_id: user.user_id,
        };

        dispatch(removePackageDate(tmp, (res) => {
            dispatchLoadDateTimes();

            console.log("WTF", res);
        }, (res) => {
        }));
    }

    const toggleCheckbox = (index, value, array, action) => {
        let u = [...array];

        u[index] = value;


        action(u);
    }

    const renderCourseStartDateCheckbox = (index, value, array, setAction, packageDates) => {

        console.log(index, value, array);

        return (
            <div className={'checkbox-container checkbox-start-date'}
                 style={{alignSelf: 'baseline', width: 'fit-content', marginBottom: '-24px'}}>
                <label>
                    <input type="checkbox"
                           checked={value}
                           onChange={() => {
                               toggleCheckbox(index, !value, array, setAction);

                               let tmp = packageDates[index];
                               tmp.start_date = !value;

                               console.log(tmp)

                               dispatch(updatePackageDateData(tmp, (res) => {
                                   dispatchLoadDateTimes()
                               }, (res) => {
                               }));
                           }}
                    />
                </label>
            </div>
        );
    }

    const renderComparisonSection = () => {
        const parseHoursDuration = (dates) => {
            let sum = 0;
            if (!dates || dates.length === 0) {
                return sum;
            }
            for (let i = 0; i <dates.length; i++) {
                let d = dates[i];
                console.log("DATE", i, d);
            }


            return sum;
        }

        return (
            <>
                <div className={'tab_compare'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].COURSE_COMPARISON}/>
                <div className={'comparison-container edit-comparison row'}>
                    <div className={'comparison-legend col start'}>
                        <p className={'p-header black'}
                           style={{
                               alignSelf: 'flex-start',
                               paddingBottom: '120px'
                           }}>{L10N_APP_TEXTS[selectedLanguage].WE_WILL_LEARN}</p>

                        <p className={'p-header black'} style={{
                            alignSelf: 'flex-start',
                            paddingBottom: '12px'
                        }}>{L10N_APP_TEXTS[selectedLanguage].COURSE_DATES}</p>

                        <p className={'p-normal black'}
                           style={{
                               alignSelf: 'flex-start',
                               paddingBottom: '300px',
                               maxWidth: '200px'
                           }}>{L10N_APP_TEXTS[selectedLanguage].START_DATE_INFO}.</p>

                        <div className={'row'} style={{paddingBottom: '60px'}}>
                            <p className={'p-header black'}
                               style={{alignSelf: 'flex-start'}}>{L10N_APP_TEXTS[selectedLanguage].TOTAL_VISITS_HOURS}</p>
                            <img src={iconSum} className={'icon-info'} alt={'img_acc'} height={'18px'} width={'18px'}/>
                        </div>
                        <p className={'p-header black'} style={{
                            alignSelf: 'flex-start',
                            paddingBottom: '170px'
                        }}>{L10N_APP_TEXTS[selectedLanguage].COURSE_RECEIVABLES}</p>
                        <div className={'row'} style={{paddingBottom: '30px'}}>
                            <p className={'p-header black'}
                               style={{
                                   alignSelf: 'flex-start',
                                   paddingRight: '8px'
                               }}>{L10N_APP_TEXTS[selectedLanguage].NUMBER_OF_ATTENDEES}</p>
                        </div>
                        <div className={'row'} style={{paddingBottom: '60px'}}>
                            <p className={'p-header black'}
                               style={{
                                   alignSelf: 'flex-start',
                                   paddingRight: '8px'
                               }}>{L10N_APP_TEXTS[selectedLanguage].AGE_OF_ATTENDEES}</p>
                        </div>
                        <div className={'row'} style={{paddingBottom: '70px'}}>
                            <p className={'p-header black'}
                               style={{alignSelf: 'flex-start'}}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                            <img src={iconMean} className={'icon-info'} alt={'img_acc'} height={'18px'} width={'18px'}/>
                        </div>
                    </div>

                    <div className={'comparison-scroll row scroll'}>
                        <div className={'comparison-section col'}>
                            <div className={'course-package-col'}>
                                <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].BASIC_GROUP}</p>
                                {renderDescListInput(courseLearn, 'scroll')}
                            </div>
                            <div className={'reservations-cloud scroll'}
                                 style={{height: '360px', maxHeight: '360px', overflowY: 'auto', overflowX: 'hidden'}}>

                                <div className={'col center'} style={{alignSelf: 'center', padding: '12px'}}>
                                    <p className={'p-text'}
                                       style={{textAlign: 'center'}}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>

                                    <div className={'image-click click'} onClick={() => {
                                        onAddNewCourseDateAndHourRangeForGroup(0, new Date()/*.toISOString()*/)
                                    }}>
                                        <img src={plusCircleIcon} className={'icon-info'} alt={'sum_img'} width={'36px'}
                                             style={{paddingRight: 0}}/>
                                    </div>
                                </div>

                                {basicGroupDates.map((dateHourRange, d) => (
                                    <div key={`${d}_basic`} className={'date-hour-container'}>
                                        {renderCourseStartDateCheckbox(d, basicGroupStartDates[d], basicGroupStartDates, onChangeBasicGroupStartDates, basicGroupDates)}
                                        {renderDatePicker(0, d)}
                                    </div>
                                ))}

                            </div>
                            <div className={'row-cloud row center double-row'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseVisits}
                                               onChange={(value) => onInputChange(value, setCourseVisits)}
                                               onSubmit={() => updateDataHandlerPackage("visits", courseVisits, TIER.A)}
                                               onAction={() => updateDataHandlerPackage("visits", courseVisits, TIER.A)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].VISITS_REPEATS_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].DAYS}</p>
                                        <Input disabled
                                               value={courseVisits * 2}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].VISITS_HOURS_PLACEHOLDER}
                                               style={{opacity: 0, marginBottom: '0px', paddingRight: 0, marginRight: 0}}/>
                                        <p className={'p-normal'} style={{opacity: 0}}>{L10N_APP_TEXTS[selectedLanguage].HOURS}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'course-package-col'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseRewards}
                                               onChange={(value) => onInputChange(value, setCourseRewards)}
                                               onSubmit={() => updateL10nDataHandlerPackage("included_list", courseRewards, TIER.A)}
                                               onAction={() => updateL10nDataHandlerPackage("included_list", courseRewards, TIER.A)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_RECEIVABLES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                                {renderDescListInput(courseRewards, 'scroll')}
                            </div>
                            <div className={'course-package-col'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseTraineeMax}
                                               onChange={(value) => onInputChange(value, setCourseTraineeMax)}
                                               onSubmit={() => updateDataHandlerPackage("trainees_max", courseTraineeMax, TIER.A)}
                                               onAction={() => updateDataHandlerPackage("trainees_max", courseTraineeMax, TIER.A)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].NUMBER_OF_ATTENDEES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>

                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseTraineeAge}
                                               onChange={(value) => onInputChange(value, setCourseTraineeAge)}
                                               onSubmit={() => updateDataHandlerPackage("trainees_age", courseTraineeAge, TIER.A)}
                                               onAction={() => updateDataHandlerPackage("trainees_age", courseTraineeAge, TIER.A)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].AGE_OF_ATTENDEES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>

                                <div className={'row-cloud row center double-row'}>
                                    <div className={'form-container'} style={{marginBottom: '0'}}>
                                        <div className={'form_col form-row row'}>
                                            <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                                   value={coursePrice}
                                                   onChange={(value) => onInputChange(parsePriceCommaToDecimalValue(value), setCoursePrice)}
                                                   onSubmit={() => updateDataHandlerPackage("price", coursePrice, TIER.A)}
                                                   onAction={() => updateDataHandlerPackage("price", coursePrice, TIER.A)}
                                                   placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE_REGULAR_PLACEHOLDER}
                                                   style={{marginBottom: '0px'}}/>
                                            <p className={'p-normal'}>{selectedLanguageObj?.priceValue}</p>
                                            <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                                   value={coursePriceDiscounted}
                                                   onChange={(value) => onInputChange(parsePriceCommaToDecimalValue(value), setCoursePriceDiscounted)}
                                                   onSubmit={() => updateDataHandlerPackage("price_discounted", coursePriceDiscounted, TIER.A)}
                                                   onAction={() => updateDataHandlerPackage("price_discounted", coursePriceDiscounted, TIER.A)}
                                                   placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE_DISCOUNT_PLACEHOLDER}
                                                   style={{marginBottom: '0px'}}/>
                                            <p className={'p-normal'}>{selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={'comparison-section col'}>
                            <div className={'course-package-col'}>
                                <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].ADVANCED_GROUP}</p>
                                {renderDescListInput(courseLearn1, 'scroll')}
                            </div>
                            <div className={'reservations-cloud scroll'}
                                 style={{height: '360px', maxHeight: '360px', overflowY: 'auto', overflowX: 'hidden'}}>


                                <div className={'col center'} style={{alignSelf: 'center', padding: '12px'}}>
                                    <p className={'p-text'}
                                       style={{textAlign: 'center'}}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                                    <div className={'image-click click'} onClick={() => {
                                        onAddNewCourseDateAndHourRangeForGroup(1, new Date()/*.toISOString()*/)
                                    }}>
                                        <img src={plusCircleIcon} className={'icon-info'} alt={'sum_img'} width={'36px'}
                                             style={{paddingRight: 0}}/>
                                    </div>
                                </div>

                                {advancedGroupDates.map((dateHourRange, d) => (
                                    <div key={`${d}_basic`} className={'date-hour-container'}>
                                        {renderCourseStartDateCheckbox(d, advancedGroupStartDates[d], advancedGroupStartDates, onChangeAdvancedGroupStartDates, advancedGroupDates)}
                                        {renderDatePicker(1, d)}
                                    </div>
                                ))}


                            </div>
                            <div className={'row-cloud row center double-row'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseVisits1}
                                               onChange={(value) => onInputChange(value, setCourseVisits1)}
                                               onSubmit={() => updateDataHandlerPackage("visits", courseVisits1, TIER.B)}
                                               onAction={() => updateDataHandlerPackage("visits", courseVisits1, TIER.B)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].VISITS_REPEATS_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].DAYS}</p>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               disabled
                                               value={courseVisits1 * 2}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].VISITS_HOURS_PLACEHOLDER}
                                               style={{opacity: 0, marginBottom: '0px'}}/>
                                        <p className={'p-normal'} style={{opacity: 0}}>{L10N_APP_TEXTS[selectedLanguage].HOURS}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'course-package-col'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseRewards1}
                                               onChange={(value) => onInputChange(value, setCourseRewards1)}
                                               onSubmit={() => updateL10nDataHandlerPackage("included_list", courseRewards1, TIER.B)}
                                               onAction={() => updateL10nDataHandlerPackage("included_list", courseRewards1, TIER.B)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_RECEIVABLES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                                {renderDescListInput(courseRewards1, 'scroll')}
                            </div>
                            <div className={'course-package-col'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseTraineeMax1}
                                               onChange={(value) => onInputChange(value, setCourseTraineeMax1)}
                                               onSubmit={() => updateDataHandlerPackage("trainees_max", courseTraineeMax1, TIER.B)}
                                               onAction={() => updateDataHandlerPackage("trainees_max", courseTraineeMax1, TIER.B)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].NUMBER_OF_ATTENDEES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>

                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseTraineeAge1}
                                               onChange={(value) => onInputChange(value, setCourseTraineeAge1)}
                                               onSubmit={() => updateDataHandlerPackage("trainees_age", courseTraineeAge1, TIER.B)}
                                               onAction={() => updateDataHandlerPackage("trainees_age", courseTraineeAge1, TIER.B)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].AGE_OF_ATTENDEES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>

                                <div className={'row-cloud row center double-row'}>
                                    <div className={'form-container'} style={{marginBottom: '0'}}>
                                        <div className={'form_col form-row row'}>
                                            <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                                   value={coursePrice1}
                                                   onChange={(value) => onInputChange(parsePriceCommaToDecimalValue(value), setCoursePrice1)}
                                                   onSubmit={() => updateDataHandlerPackage("price", coursePrice1, TIER.B)}
                                                   onAction={() => updateDataHandlerPackage("price", coursePrice1, TIER.B)}
                                                   placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE_REGULAR_PLACEHOLDER}
                                                   style={{marginBottom: '0px'}}/>
                                            <p className={'p-normal'}>{selectedLanguageObj?.priceValue}</p>
                                            <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                                   value={coursePriceDiscounted1}
                                                   onChange={(value) => onInputChange(parsePriceCommaToDecimalValue(value), setCoursePriceDiscounted1)}
                                                   onSubmit={() => updateDataHandlerPackage("price_discounted", coursePriceDiscounted1, TIER.B)}
                                                   onAction={() => updateDataHandlerPackage("price_discounted", coursePriceDiscounted1, TIER.B)}
                                                   placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE_DISCOUNT_PLACEHOLDER}
                                                   style={{marginBottom: '0px'}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={'comparison-section col'}>
                            <div className={'course-package-col'}>
                                <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].EXPERT_GROUP}</p>
                                {renderDescListInput(courseLearn2, 'scroll')}
                            </div>
                            <div className={'reservations-cloud scroll'}
                                 style={{height: '360px', maxHeight: '360px', overflowY: 'auto', overflowX: 'hidden'}}>

                                <div className={'col center'} style={{alignSelf: 'center', padding: '12px'}}>
                                    <p className={'p-text'}
                                       style={{textAlign: 'center'}}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                                    <div className={'image-click click'} onClick={() => {
                                        onAddNewCourseDateAndHourRangeForGroup(2, new Date()/*.toISOString()*/)
                                    }}>
                                        <img src={plusCircleIcon} className={'icon-info'} alt={'sum_img'} width={'36px'}
                                             style={{paddingRight: 0}}/>
                                    </div>
                                </div>

                                {expertGroupDates.map((dateHourRange, d) => (
                                    <div key={`${d}_basic`} className={'date-hour-container'}>
                                        {renderCourseStartDateCheckbox(d, expertGroupStartDates[d], expertGroupStartDates, onChangeExpertGroupStartDates, expertGroupDates)}
                                        {renderDatePicker(2, d)}
                                    </div>
                                ))}

                            </div>
                            <div className={'row-cloud row center double-row'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseVisits2}
                                               onChange={(value) => onInputChange(value, setCourseVisits2)}
                                               onSubmit={() => updateDataHandlerPackage("visits", courseVisits2, TIER.C)}
                                               onAction={() => updateDataHandlerPackage("visits", courseVisits2, TIER.C)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].VISITS_REPEATS_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].DAYS}</p>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               disabled
                                               value={courseVisits2 * 2}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].VISITS_HOURS_PLACEHOLDER}
                                               style={{opacity: 0, marginBottom: '0px'}}/>
                                        <p className={'p-normal'} style={{opacity: 0}}>{L10N_APP_TEXTS[selectedLanguage].HOURS}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'course-package-col'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseRewards2}
                                               onChange={(value) => onInputChange(value, setCourseRewards2)}
                                               onSubmit={() => updateL10nDataHandlerPackage("included_list", courseRewards2, TIER.C)}
                                               onAction={() => updateL10nDataHandlerPackage("included_list", courseRewards2, TIER.C)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_RECEIVABLES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                                {renderDescListInput(courseRewards2, 'scroll')}
                            </div>
                            <div className={'course-package-col'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseTraineeMax2}
                                               onChange={(value) => onInputChange(value, setCourseTraineeMax2)}
                                               onSubmit={() => updateDataHandlerPackage("trainees_max", courseTraineeMax2, TIER.C)}
                                               onAction={() => updateDataHandlerPackage("trainees_max", courseTraineeMax2, TIER.C)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].NUMBER_OF_ATTENDEES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>

                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               value={courseTraineeAge2}
                                               onChange={(value) => onInputChange(value, setCourseTraineeAge2)}
                                               onSubmit={() => updateDataHandlerPackage("trainees_age", courseTraineeAge2, TIER.C)}
                                               onAction={() => updateDataHandlerPackage("trainees_age", courseTraineeAge2, TIER.C)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].AGE_OF_ATTENDEES_PLACEHOLDER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                                <div className={'row-cloud row center double-row'}>
                                    <div className={'form-container'} style={{marginBottom: '0'}}>
                                        <div className={'form_col form-row row'}>
                                            <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                                   value={coursePrice2}
                                                   onChange={(value) => onInputChange(parsePriceCommaToDecimalValue(value), setCoursePrice2)}
                                                   onSubmit={() => updateDataHandlerPackage("price", coursePrice2, TIER.C)}
                                                   onAction={() => updateDataHandlerPackage("price", coursePrice2, TIER.C)}
                                                   placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE_REGULAR_PLACEHOLDER}
                                                   style={{marginBottom: '0px'}}/>
                                            <p className={'p-normal'}>{selectedLanguageObj?.priceValue}</p>
                                            <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                                   value={coursePriceDiscounted2}
                                                   onChange={(value) => onInputChange(parsePriceCommaToDecimalValue(value), setCoursePriceDiscounted2)}
                                                   onSubmit={() => updateDataHandlerPackage("price_discounted", coursePriceDiscounted2, TIER.C)}
                                                   onAction={() => updateDataHandlerPackage("price_discounted", coursePriceDiscounted2, TIER.C)}
                                                   placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE_DISCOUNT_PLACEHOLDER}
                                                   style={{marginBottom: '0px'}}/>
                                            <p className={'p-normal'}>{selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </>

        );
    }

    const addNewLecturer = () => {
        let lec = {
            user_id: provider.provider_id,
            provider_id: provider.provider_id,
            course_id: course.course_id,
            full_name: null,
        };
        dispatch(createLecturer(lec, (resData) => {
            console.log(resData);
            //setLogo([{data_url: resData.image}]);
            let lec = [...lecturers, resData];

            setLecturers(lec);

            //dispatchLoadLecturers(true)

        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);

        }));
    }


    const updateLecturerL10N = (item, i, value) => {
        let lec = [...lecturers];
        lec[i].description = value;

        lec[i].course_id = course.course_id;
        lec[i].user_id = user.user_id;
        dispatch(updateLecturerDataL10N(lec[i], selectedLanguage, (resData) => {
            console.log(resData);
            //setLogo([{data_url: resData.image}]);
            lec[i] = resData;

            setLecturers(lec);

            dispatchLoadLecturers(true)
        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);

        }));
    }

    const updateLecturerFullName = (item, i, value) => {
        let lec = [...lecturers];
        lec[i].full_name = value;

        lec[i].user_id = user.user_id;
        dispatch(updateLecturerData(lec[i], (resData) => {
            console.log(resData);
            //setLogo([{data_url: resData.image}]);
            lec[i] = resData;

            setLecturers(lec);
            dispatchLoadLecturers(true);
        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);

        }));
    }

    const updateLecturerAddToCourse = (item) => {
        item.course_id = course.course_id;
        item.user_id = user.user_id;

        console.log("NOO", item)

        dispatch(updateLecturerData(item, (resData) => {
            console.log(resData);
            item = resData;

            dispatchLoadLecturers(true);
        }, () => {
        }));


    }

    const chooseLecturer = (value) => {
        let foundLecturer = findItem('lecturer_id', value.lecturer_id, lecturers);
        if (foundLecturer) {
            return;
        }
        updateLecturerAddToCourse(value);
    }
    const removeLecturer = (value) => {
        let foundLecturerIndex = getIndexOf('lecturer_id', value.lecturer_id, lecturers);

        console.log(foundLecturerIndex, user);

        if (foundLecturerIndex < 0) {
            return;
        }

        let lec = [...lecturers];

        lec[foundLecturerIndex].user_id = user.user_id;
        lec[foundLecturerIndex].course_id = null;

        updateLecturerDataHandler("course_id", null, lec[foundLecturerIndex], () => {
            dispatchLoadLecturers(true);
        });

        /*
        dispatch(deleteLecturer(lec[foundLecturerIndex], (resData) => {
            console.log(resData);

            lec.splice(foundLecturerIndex, 1);

            setLecturers(lec);

            dispatchLoadLecturers(true);

        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);
        }));
         */


    }

    const renderLecturers = () => {
        console.log(lecturersProfilePictures);

        return (
            <>
                <div className={'row'}>
                    <Title dots title={L10N_APP_TEXTS[selectedLanguage].LECTURERS}/>
                    <div className={'circle-button'}>
                        <img src={plusCircleIcon} className={'icon-info'} onClick={addNewLecturer} alt={'sum_img'}
                             width={'36px'}/>
                    </div>
                </div>

                <div className={'form-container lecturers-dropdown'}>
                    <div className={'form_col form-row'}>
                        <div className={'custom-dropdown'}>
                            <Dropdown grey
                                      lecturers
                                      style={{marginBottom: '48px'}}
                                      label={`${L10N_APP_TEXTS[selectedLanguage].LECTURERS_CHOOSE}:`}
                                      onChange={(value) => chooseLecturer(value)}
                                      options={providerLecturers}
                                      placeholder={L10N_APP_TEXTS[selectedLanguage].LECTURERS_CHOOSE}/>
                        </div>
                    </div>
                </div>

                <div className={'lecturers-container edit'} style={{flexWrap: 'wrap'}}>

                    {lecturers.map((item, i) => (
                        <div key={`${i}_${item}`} className={'lecturers-item'}>
                            <div className={'lecturers-col'}>
                                <p className={'p-text remove click-light'} onClick={() => removeLecturer(item)} style={{
                                    position: 'absolute',
                                    marginLeft: '69px',
                                    marginTop: '-16px',
                                    fontWeight: 400
                                }}>x</p>


                                {renderUploadLecturersProfilePictureContainer(i)}

                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={'edit'}
                                               value={item.full_name}
                                               onChange={() => {
                                               }}
                                               onAction={(value) => onInputChange(value, () => updateLecturerFullName(item, i, value))}
                                               onSubmit={(value) => onInputChange(value, () => updateLecturerFullName(item, i, value))}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].NAME_SURNAME}
                                               style={{marginBottom: '0px', width: 'fit-content', maxWidth: '140px'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'course-packages-container course-description lecturer'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={'edit'}
                                               value={item.l10n[getIndexOf("l10n", selectedLanguage, item.l10n)]?.description ? item.l10n[getIndexOf("l10n", selectedLanguage, item.l10n)]?.description : ""}
                                               textarea
                                               onChange={() => {

                                               }}
                                               onAction={(value) => onInputChange(value, () => updateLecturerL10N(item, i, value))}
                                               onSubmit={(value) => onInputChange(value, () => updateLecturerL10N(item, i, value))}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_LECTURER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>

        );
    }


    const renderCoursePackages = () => {
        return (
            <>
                <div className={'tab_learn'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].COURSE_LEARN}/>

                <div className={'course-packages-container edit'}>
                    <div className={'course-package-col'}>
                        <p className={'p-title'} style={{
                            color: selectedPackage === 0 ? '#29ABE2' : 'black',
                            textDecoration: selectedPackage === 0 ? 'underline' : 'unset'
                        }} onClick={() => {
                            setSelectedPackage(0);
                        }}>{L10N_APP_TEXTS[selectedLanguage].BASIC_GROUP}</p>

                        <div style={{opacity: selectedPackage === 0 ? 1 : 0}}>
                            <div className={'form-container'} style={{marginBottom: '0'}}>
                                <div className={'form_col form-row'}>
                                    <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                           value={courseLearn}
                                           onChange={(value) => onInputChange(value, setCourseLearn)}
                                           onSubmit={() => updateL10nDataHandlerPackage("description_list", courseLearn, TIER.A)}
                                           onAction={() => updateL10nDataHandlerPackage("description_list", courseLearn, TIER.A)}
                                           placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_LEARN}
                                           style={{marginBottom: '0px', width: 'fit-content'}}/>
                                </div>
                            </div>
                            {renderDescListInput(courseLearn)}
                        </div>


                    </div>
                    <div className={'course-package-col'}>
                        <p className={'p-title'}> | </p>
                    </div>
                    <div className={'course-package-col'}>
                        <p className={'p-title'} style={{
                            color: selectedPackage === 1 ? '#29ABE2' : 'black',
                            textDecoration: selectedPackage === 1 ? 'underline' : 'unset'
                        }} onClick={() => {
                            setSelectedPackage(1);
                        }}>{L10N_APP_TEXTS[selectedLanguage].ADVANCED_GROUP}</p>

                        <div style={{opacity: selectedPackage === 1 ? 1 : 0}}>
                            <div className={'form-container'} style={{marginBottom: '0'}}>
                                <div className={'form_col form-row'}>
                                    <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                           value={courseLearn1}
                                           onChange={(value) => onInputChange(value, setCourseLearn1)}
                                           onSubmit={() => updateL10nDataHandlerPackage("description_list", courseLearn1, TIER.B)}
                                           onAction={() => updateL10nDataHandlerPackage("description_list", courseLearn1, TIER.B)}
                                           placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_LEARN}
                                           style={{marginBottom: '0px', width: 'fit-content'}}/>
                                </div>
                            </div>
                            {renderDescListInput(courseLearn1)}
                        </div>


                    </div>
                    <div className={'course-package-col'}>
                        <p className={'p-title'}> | </p>
                    </div>
                    <div className={'course-package-col'}>
                        <p className={'p-title'} style={{
                            color: selectedPackage === 2 ? '#29ABE2' : 'black',
                            textDecoration: selectedPackage === 2 ? 'underline' : 'unset'
                        }} onClick={() => {
                            setSelectedPackage(2);
                        }}>{L10N_APP_TEXTS[selectedLanguage].EXPERT_GROUP}</p>

                        <div style={{opacity: selectedPackage === 2 ? 1 : 0}}>
                            <div className={'form-container'} style={{marginBottom: '0'}}>
                                <div className={'form_col form-row'}>
                                    <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                           value={courseLearn2}
                                           onChange={(value) => onInputChange(value, setCourseLearn2)}
                                           onSubmit={() => updateL10nDataHandlerPackage("description_list", courseLearn2, TIER.C)}
                                           onAction={() => updateL10nDataHandlerPackage("description_list", courseLearn2, TIER.C)}
                                           placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_LEARN}
                                           style={{marginBottom: '0px', width: 'fit-content'}}/>
                                </div>
                            </div>
                            {renderDescListInput(courseLearn2)}
                        </div>


                    </div>
                </div>
                <div className={'course-packages-container course-description'}>
                    <div className={'form-container'} style={{marginBottom: '0'}}>
                        <div className={'form_col form-row'}>
                            <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                   textarea
                                   value={courseDesc}
                                   onChange={(value) => onInputChange(value, setCourseDesc)}
                                   onSubmit={() => updateL10nDataHandlerPackage("description", courseDesc)}
                                   onAction={() => updateL10nDataHandlerPackage("description", courseDesc)}
                                   placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_DESCRIPTION}
                                   style={{marginBottom: '0px'}}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }


    const renderCourseRequirements = () => {
        return (
            <>
                <div className={'tab_needs'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].COURSE_NEEDS}/>
                <div className={'course-packages-container'}>
                    <div className={'course-package-col needs'}>
                        <div className={'form-container'} style={{marginBottom: '0'}}>
                            <div className={'form_col form-row'}>
                                <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                       value={courseNeeds}
                                       onChange={(value) => onInputChange(value, setCourseNeeds)}
                                       onSubmit={() => updateL10nDataHandlerPackage("requirements_list", courseNeeds)}
                                       onAction={() => updateL10nDataHandlerPackage("requirements_list", courseNeeds)}
                                       placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_NEEDS}
                                       style={{marginBottom: '0px', width: 'fit-content'}}/>
                            </div>
                        </div>
                        {renderDescListInput(courseNeeds)}
                    </div>
                </div>
            </>
        );
    }

    const renderCourseAccommodationEdit = () => {
        return (
            <>

                <div className={'tab_acc'}/>

                <Title dots title={L10N_APP_TEXTS[selectedLanguage].COURSE_ACC}/>

                <div className={'course-accommodation-container'}>


                    <div className={'row'}>
                        <p className={'p-label'}
                           style={{width: '150px'}}>{`${L10N_APP_TEXTS[selectedLanguage].ACC_PIC}:`}</p>
                        {renderUploadLogoContainerAccommodation()}
                    </div>
                    <div style={{marginTop: '12px'}}/>

                    <div className={'course-accommodation-row'}>
                        <div className={'accommodation-desc'}>
                            <div className={'course-packages-container course-description'}>
                                <p className={'p-text accommodation'} style={{
                                    paddingLeft: '0'
                                }}>{`${L10N_APP_TEXTS[selectedLanguage].ACC_DESC}:`}</p>

                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               textarea
                                               value={accDesc}
                                               onChange={(value) => onInputChange(value, setAccDesc)}
                                               onSubmit={() => updateL10nDataHandlerPackage("accommodation_description", accDesc)}
                                               onAction={() => updateL10nDataHandlerPackage("accommodation_description", accDesc)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].ACC_DESC}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>


                            </div>

                            <div className={'form-container accommodation'} style={{marginBottom: '0'}}>
                                <div className={'form_col form-row'}>
                                    <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                           value={accName}
                                           label={`${L10N_APP_TEXTS[selectedLanguage].ACC_NAME}:`}
                                           onChange={(value) => onInputChange(value, setAccName)}
                                           onSubmit={() => updateDataHandlerAccommodation("accommodation_name", accName)}
                                           onAction={() => updateDataHandlerAccommodation("accommodation_name", accName)}

                                           placeholder={'Hotel Bor ****'}
                                           style={{marginBottom: '0px', width: 'fit-content'}}/>
                                    <div className={'row space-between'}>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               label={`${L10N_APP_TEXTS[selectedLanguage].PRICE}:`}
                                               value={accPrice}
                                               onChange={(value) => onInputChange(value, setAccPrice)}
                                               onSubmit={() => updateDataHandlerAccommodation("accommodation_price", accPrice)}
                                               onAction={() => updateDataHandlerAccommodation("accommodation_price", accPrice)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].PRICE}
                                               style={{marginBottom: '0px', width: 'fit-content'}}/>
                                        <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                               label={`${L10N_APP_TEXTS[selectedLanguage].ACC_NIGHTS}:`}
                                               value={accNights}
                                               onChange={(value) => onInputChange(value, setAccNights)}
                                               onSubmit={() => updateDataHandlerAccommodation("accommodation_nights", accNights)}
                                               onAction={() => updateDataHandlerAccommodation("accommodation_nights", accNights)}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].ACC_NIGHTS_PLACEHOLDER}
                                               style={{marginBottom: '0px', width: 'fit-content'}}/>
                                    </div>
                                    <Input button={L10N_APP_TEXTS[selectedLanguage].EDIT}
                                           label={`${L10N_APP_TEXTS[selectedLanguage].ACC_LOCATION}:`}
                                           value={accLocation}
                                           onChange={(value) => onInputChange(value, setAccLocation)}
                                           onSubmit={() => updateDataHandlerAccommodation("accommodation_address", accLocation)}
                                           onAction={() => updateDataHandlerAccommodation("accommodation_address", accLocation)}
                                           placeholder={L10N_APP_TEXTS[selectedLanguage].ACC_LOCATION_PLACEHOLDER}
                                           style={{marginBottom: '0px', width: 'fit-content'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const handleEmailClickContact = () => {
        const email = "ask@bravooo.com";
        const subject = L10N_APP_TEXTS[selectedLanguage].CONTACT_REQ;
        const body = '';

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink, '_blank');
    };

    const renderCourseInfo = () => {
        return (
            <div className={'provider-info-container course-info course-edit'}>
                <p className={'p-pageinfo'}>
                    {L10N_APP_TEXTS[selectedLanguage].ADMINISTRATION} > <span>{L10N_APP_TEXTS[selectedLanguage].ADMINISTRATION_COURSE}</span>
                </p>

                <div className={'provider-row'}>
                    <div className={'row_inner_left'}>
                        <div key={"logo_provider"}>
                            <img src={logoProvider[0]?.data_url} alt="" width="80" style={{
                                borderRadius: '45px',
                                height: '90px',
                                width: '90px',
                                objectFit: 'cover',
                                marginRight: '24px'
                            }}/>
                        </div>

                        <p className={'p-xlarge'}>{provider?.name}</p>
                        <div className={'row_inner_right'}>

                        </div>
                    </div>
                    <div className={'row_inner_right'}>
                        <div className={'contact-us-button p-submit'} onClick={() => {
                            handleEmailClickContact()
                        }}>
                            <span>{L10N_APP_TEXTS[selectedLanguage].CONTACT_BUTTON}</span>
                            <img src={iconMessage} className={'icon-info'} alt={'icon_img'} width={'21px'}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const renderPage = () => {
        return <>
            <div ref={ref}/>
            <Header loggedIn/>
            {/*<Header loggedIn/>*/}
            <animated.div style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>
            <FooterPage/>
        </>
    }

    if (loginSuccessful) {
        console.log('redirect');

        onNavigateTo(PAGES.HOME);
        return <></>
    }

    return (
        renderPage()
    );
}

export default CoursePageEdit;