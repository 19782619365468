import '../css/Title.css';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {L10N} from "../../constants/enums";

function Title(props) {

    const renderHeader = () => {
        return (
            <div className={'title-container'} style={{paddingLeft: props.dots ? 0 : '36px'}}>
                {props.dots ? <>
                        <span className="dot"/>
                        <span className="dot"/>
                        <span className="dot space"/>
                    </> :
                    <></>
                }
                <p className={'p-title'}>{props.title}</p>
            </div>
        );

    }

    return renderHeader();
}

export default Title;