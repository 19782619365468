import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {L10N} from "../../constants/enums";
import {scrollToDiv} from "../../helpers/helpers";

export const getTabs = (selectedLanguage) => {
    return [
        {
            text: L10N_APP_TEXTS[selectedLanguage].MY_COURSES_PUBLISHED,
            anchor_id: 'tab_courses_published'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].MY_COURSES_ARCHIVED,
            anchor_id: 'tab_courses_archived'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].MY_COURSES_ADD,
            anchor_id: 'tab_course_add'
        }
    ]
};

function ScrollableTabsCourses(props) {

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [c, setC] = useState(0);

    const [selectedTab, setSelectedTab] = useState();
    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        setSelectedLanguage(l10n)
        console.log(l10n);
        setC(c + 1);
        setTabs(getTabs(selectedLanguage));



    }, [l10n, selectedLanguage]);

    const renderTabs = () => {
        if (!tabs) {
            return null;
        }
        return (
            <div className={'tabs-horizontal tabs-courses'} style={{alignSelf: 'center', height: 'fit-content', padding: 0, paddingBottom: '24px'}}>
                {tabs.map((tab, c) => (
                    <div key={`${c}_${tab.text}`} id={`selected_${tab.text === selectedTab?.text}`}
                         className={'tab-outer-container'}>
                        <p className={'p-tab click'} onClick={() => {
                            setSelectedTab(tab);
                            scrollToDiv(tab.anchor_id);

                            if (props.onChange) {
                                props.onChange(tab);
                            }
                        }}>{tab.text}</p>
                    </div>
                ))}
            </div>
        );
    };

    return renderTabs();
}

export default ScrollableTabsCourses;