import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory} from "react-router-dom";
import {L10N, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    getAdminControlPanelData,
    getFaqAdmin,
    getFaqProvider,
    isRememberMeEnabled,
    loadAllCategories,
    loadAllCategoriesParsed,
    loadAllCoursesFollowed,
    loadAllProvidersFollowed,
    loadCourseById,
    loadCredentials,
    loadLocalL10N,
    logoutUser,
    removeFollowerForProvider,
    setL10N,
    setRememberMe,
    setSignInCredentials,
    updateUserData,
    updateUserPassword,
    updateUserPasswordByEmail,
    userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    displayAlert,
    displayDate,
    getIndexOf,
    getNextCourseStartDate, parseMapCaretChars,
    parseSelectedCountryLabel,
    scrollToDiv
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import Input from "../components/js/Input";
import plusCircleIcon from "../assets/images/plus-circle.png";
import iconPrint from "../assets/images/printer.png";
import iconAcc from "../assets/images/acc.png";
import iconHeart from "../assets/images/heart_blue.png";
import iconMessage from "../assets/images/message-circle.png";
import iconQr from "../assets/images/qr_code.png";

import iconAddress from "../assets/images/i_address.png";
import iconMean from "../assets/images/i_mean.png";
import iconStar from "../assets/images/i_star.png";
import iconSum from "../assets/images/i_sum.png";
import iconNight from "../assets/images/i_night.png";
import courseImg from "../assets/images/course.png";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import Title from "../components/js/Title";
import Footer from "../components/js/Footer";
import {CREATE_ACTION} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import Button from "../components/js/Button";
import DropdownCircle from "../components/js/DropdownCircle";
import FooterPage from "../components/js/FooterPage";
import GoogleMap from "../components/js/GoogleMap";
import Filters from "../components/js/Filters";
import Search from "../components/js/Search";
import HeaderLanding from "../components/js/HeaderLanding";
import CustomMap from "../components/js/CustomMap";
import {COURSES} from "./CoursePage";
import iconCalendar from "../assets/images/calendar.png";
import bcgLayer from "../assets/images/bcg-layer.png";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";
import parse from "html-react-parser";

const CATEGORIES = [
    {parent: 'Profesionalna kozmetika', children: ['Nega obraza', 'Permanenten make-up', 'Podaljševanje trepalnic']},
    {parent: 'Pasje striženje', children: []},
    {parent: 'Fotografija', children: ['Adobe Photoshop', 'Perspektiva fotografije', 'Analogna fotografija']},
];


const FOLLOWING = [
    {
        provider_id: 'dfgdfgdhfj',
        name: 'Plesna Šola Vzorec',
    },
    {
        provider_id: 'dfgdfg8678dhfj',
        name: 'Plesna Šola Vzorec',
    }
];

function PresentationUser() {
    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);


    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [saveData, setSaveData] = useState(false);
    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);

    const [promoUser, setPromoUser] = useState(null);
    const [descShortUser, setDescShortUser] = useState(null);
    const [descLongUser, setDescLongUser] = useState(null);

    const [RT, setRT] = useState("");

    const [openedFaq, setOpenedFaq] = useState(false);
    const [faqs, setFaqs] = useState([]);

    const [categories, setCategories] = useState([]);


    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                opacity: saveData ? 0 : 0,
                bottom: saveData ? '-30px' : '-30px',
                display: saveData ? 'none' : 'none'
            },
            to: {
                opacity: saveData ? 1 : 0,
                bottom: saveData ? '0' : '0',
                display: saveData ? 'flex' : 'none'
            },
            config: {duration: 200},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, [])

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);
    }, [l10n, selectedLanguage]);

    useEffect(() => {
    }, [user]);


    useEffect(() => {
        dispatch(loadAllCategoriesParsed((res) => {
            setCategories(res);
        }, (res) => {
        }));
        /*
        dispatch(loadAllCategories((res) => {
            //setCategories(res);
        }, (res) => {
        }));

         */
    }, []);

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);

    }, [l10n, selectedLanguage]);


    useEffect(() => {
        if (selectedLanguage) {

            dispatch(getAdminControlPanelData({user_id: 3333}, selectedLanguage, (resData) => {
                setPromoUser(resData.promo_user)
                setDescShortUser(resData.desc_short_user)
                setDescLongUser(resData.desc_long_user)
                setRT(resData.rt_user)
            }))

            dispatch(getFaqAdmin({admin_value: true}, selectedLanguage, (resData) => {
                let faqsUsers = resData.filter(faq => faq.providers_faq === false);

                setFaqs(faqsUsers);
            }));
        }

    }, [selectedLanguage]);

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

   const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

       return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }



    const renderPageInfo = () => {
        return <p className={'p-pageinfo'}>
            {L10N_APP_TEXTS[selectedLanguage].HOME} > <span>{L10N_APP_TEXTS[selectedLanguage].HOME_WELCOME}</span>
        </p>
    }

    const renderTexts = () => {
        console.log(RT)

        return <div className={'page-info'}>
            <p className={'promo-text'}>{parseMapCaretChars(promoUser)}</p>
            {renderBackgroundLayer()}
            <p className={'p-des'}>{parseMapCaretChars(descShortUser)}</p>
            <p className={'p-small'}>{parseMapCaretChars(descLongUser)}</p>

            <div>{RT ? parse(RT) : <></>}</div>
        </div>
    }

    const renderBackgroundLayer = () => {
        return (
            <>
                <div className={'bcg_layer bcg_slide'} style={{backgroundImage: `url(${bcgLayer})`}}>
                </div>
            </>
        );
    }


    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}
                {renderPageInfo()}

                <Title title={L10N_APP_TEXTS[selectedLanguage].HOME_WELCOME + " " + L10N_APP_TEXTS[selectedLanguage].SUBSCRIBE_JOIN}/>
                {renderTexts()}
                {renderCategories()}
                {renderFaq()}
            </div>
        )
    }

    const renderMap = () => {
        return (
            <CustomMap/>
        );
    };



    const parseCategoryNameKey = (category, l10n) => {
        switch (l10n) {
            case L10N.en.value:
                return "name_en"
            case L10N.si.value:
                return "name_si"
            case L10N.it.value:
                return "name_it"
        }
    };


    const renderCategories = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_CATEGORIES}/>
                <div className={'categories_container'}>
                    {categories.map((category, c) => (
                        <div key={`${c}_${category.parent}`} className={'cat-row'}>
                            <p className={'p-label click bold'} style={{letterSpacing: '-1px'}} onClick={() => {
                            }}>{category.parent[parseCategoryNameKey(category.parent, selectedLanguage)]}</p>

                            {category.children.map((child, ch) => (
                                <div key={`${ch}_${child}`} className={'child-row'}>
                                    <p className={'p-label click'} onClick={() => {
                                    }}>{child[parseCategoryNameKey(child, selectedLanguage)]}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </>
        );
    };


    const displayCollapsibleFaq = (status, question, answer) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header faq-options'} style={{justifyContent: 'flex-end'}}>
                <div className={'faq-question'}>
                    <p className={'p-title'}>{question}</p>
                    {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
                </div>
                {status ? <div className={'faq-desc'}>
                    <p className={'p-header black'}>{answer}</p>
                </div> : <></>}
            </div>
        );
    }

    const onActionCollapseFaq = (i) => {
        setOpenedFaq(i);
    }

    const renderFaq = () => {
        return (
            <>
                <Title dots title={'FAQs'}/>
                <div className={'faq-container'}>
                    {faqs.map((faq, f) => (
                        <div key={`${f}_${faq.question}`} className={'collapsible-faq-container'}>
                            <Collapsible className={'faq-options'}
                                         transitionTime={400}
                                         onClosing={() => onActionCollapseFaq(false)}
                                         onOpening={() => onActionCollapseFaq(f)}
                                         open={openedFaq === f}
                                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                                         trigger={displayCollapsibleFaq(false, faq.question, faq.answer)}
                                         triggerWhenOpen={displayCollapsibleFaq(true, faq.question, faq.answer)}>
                            </Collapsible>
                        </div>
                    ))}
                </div>
            </>

        );
    }


    const renderPage = () => {
        return <>
            <div ref={ref}/>
            {user && token ? <Header  loggedIn/> : <HeaderLanding/>}
            <animated.div  style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>
            <FooterPage/>
        </>
    }

    return (
        renderPage()
    );
}

export default PresentationUser;