import React, {useEffect, useState} from "react";

import '../css/Search.css';
import '../css/Input.css';
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import iconSearch from "../../assets/images/i_search.png";
import {getIndexOf} from "../../helpers/helpers";
import {useSelector} from "react-redux";
import {L10N} from "../../constants/enums";


function Search(props) {
    const [input, setInput] = useState('');

    const [disabled, setDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('edit');

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const filterCourses = () => {
        let f = [];
        let allCourses = props.allCourses;
        console.log(allCourses)
        for (let i = 0; i < allCourses.length; i++) {
            let course = allCourses[i];
            console.log("aaa", course);
            if (course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name?.toLowerCase()?.includes(input?.toLowerCase())) {
                f.push(course);
            }
        }
        return f;
    }

    const onActionSubmit = (event) => {
        if (props.onSubmit) {
            event.preventDefault();

            props.onSubmit(filterCourses());
        }
    };

    const onInputChange = (value) => {
        const {onChange} = props;

        setInput(value);
        onChange(value);
    }

    let className = 'input-default search';
    let type = 'text';

    let inputContent = () => {
        if (props.textarea) {
            return <textarea
                disabled={props.disabled}
                className={className}
                placeholder={props.placeholder}
                style={props.style}
                value={input ? input : ''} onBlur={props.onBlur}
                onChange={(event) => onInputChange(event.target.value)}/>
        } else {
            return <>
                <input disabled={props.disabled ? props.disabled : disabled} type={type} className={className}
                       placeholder={props.placeholder}
                       style={props.style}
                       value={input ? input : ''}
                       onBlur={props.onBlur}
                       name={""}
                       onChange={(event) => onInputChange(event.target.value)}/>

                <div className={'search-confirm'} onClick={() => {
                    if (props.onSubmit) {
                        props.onSubmit(filterCourses());
                    }
                }}>
                    <img src={iconSearch} className={'icon-info'} alt={'sum_img'} width={'26px'}/>
                </div>

            </>
        }
    };

    return (
        <form onSubmit={onActionSubmit} className={'search-form'}>
            <label className={'input-label'}>
                {props.label &&
                    <p className={'p-label'}>{props.label}</p>
                }
                {inputContent()}
            </label>
        </form>
    );
}

export default Search;