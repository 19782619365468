import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {L10N} from "../../constants/enums";
import {scrollToDiv} from "../../helpers/helpers";


function ScrollableTabs(props) {

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [c, setC] = useState(0);

    const [selectedTab, setSelectedTab] = useState();
    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        setSelectedLanguage(l10n)
        console.log(l10n);
        setC(c + 1);
        setTabs(getTabs());

    }, [l10n, selectedLanguage]);

const getTabs = () => {
    return [
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_ADMIN_1,
            anchor_id: 'tab_admin_presentation_page'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_ADMIN_2,
            anchor_id: 'tab_admin_faq'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_ADMIN_3,
            anchor_id: 'tab_admin_list'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_STATS,
            anchor_id: 'tab_map'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_PERSONAL_DATA,
            anchor_id: 'tab_personal_data'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].EDIT_CATEGORIES,
            anchor_id: 'tab_category_add'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].USERS_LIST,
            anchor_id: 'tab_admin_lists'
        },
    ]
};

    const renderTabs = () => {
        if (!tabs) {
            return null;
        }
        return (
            <div className={'tabs-horizontal'} style={{alignSelf: 'center', height: 'fit-content', padding: 0}}>
                {tabs.map((tab, c) => (
                    <div key={`${c}_${tab.text}`} id={`selected_${tab.text === selectedTab?.text}`}
                         className={'tab-outer-container'}>
                        <p className={'p-tab click'} onClick={() => {
                            setSelectedTab(tab);
                            scrollToDiv(tab.anchor_id);
                        }}>{tab.text}</p>
                    </div>
                ))}
            </div>
        );
    };

    return renderTabs();
}

export default ScrollableTabs;