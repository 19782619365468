import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory} from "react-router-dom";
import {L10N, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    isRememberMeEnabled,
    loadAllCategories,
    loadAllCategoriesParsed,
    loadAllCoursesFollowed,
    loadAllProvidersFollowed,
    loadCourseById,
    loadCredentials,
    loadLocalL10N,
    logoutUser,
    removeFollowerForProvider,
    setL10N,
    setRememberMe,
    setSignInCredentials,
    updateUserData,
    updateUserPassword,
    updateUserPasswordByEmail,
    userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    displayAlert,
    displayDate,
    getIndexOf,
    getNextCourseStartDate, parseHoursDurationFromTimestamps, parseHoursDurationFromTimestampsFormatted,
    parseSelectedCountryLabel,
    scrollToDiv
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import Input from "../components/js/Input";
import plusCircleIcon from "../assets/images/plus-circle.png";
import iconPrint from "../assets/images/printer.png";
import iconAcc from "../assets/images/acc.png";
import iconHeart from "../assets/images/heart_blue.png";
import iconMessage from "../assets/images/message-circle.png";
import iconQr from "../assets/images/qr_code.png";

import iconAddress from "../assets/images/i_address.png";
import iconMean from "../assets/images/i_mean.png";
import iconStar from "../assets/images/i_star.png";
import iconSum from "../assets/images/i_sum.png";
import iconNight from "../assets/images/i_night.png";
import courseImg from "../assets/images/course.png";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";
import Title from "../components/js/Title";
import Footer from "../components/js/Footer";
import {CREATE_ACTION} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import Button from "../components/js/Button";
import DropdownCircle from "../components/js/DropdownCircle";
import FooterPage from "../components/js/FooterPage";
import GoogleMap from "../components/js/GoogleMap";
import Filters from "../components/js/Filters";
import Search from "../components/js/Search";
import HeaderLanding from "../components/js/HeaderLanding";
import CustomMap from "../components/js/CustomMap";
import {COURSES} from "./CoursePage";
import iconCalendar from "../assets/images/calendar.png";

const CATEGORIES = [
    {parent: 'Profesionalna kozmetika', children: ['Nega obraza', 'Permanenten make-up', 'Podaljševanje trepalnic']},
    {parent: 'Pasje striženje', children: []},
    {parent: 'Fotografija', children: ['Adobe Photoshop', 'Perspektiva fotografije', 'Analogna fotografija']},
];

const USER = {
    user_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    full_name: 'Tadej Hiti',
    user_role: USER_ROLES.CUSTOMER,
    password: 'lulček#%hashed',
    email: 'hiti.tadej@gmail.com',
    tel: '+38640306677',
    address: 'Ograde 3',
    l10n_default: L10N.si.value,
    l10n_en: false,
    l10n_si: false,
    l10n_it: false,
};


const FOLLOWING = [
    {
        provider_id: 'dfgdfgdhfj',
        name: 'Plesna Šola Vzorec',
    },
    {
        provider_id: 'dfgdfg8678dhfj',
        name: 'Plesna Šola Vzorec',
    }
];

function Home() {
    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);
    const categories = useSelector(state => state.reducer.categories);


    const courses_followed = useSelector(state => state.reducer.courses_followed);
    const providers_followed = useSelector(state => state.reducer.providers_followed);

    const [fullName, setFullName] = useState(user.full_name);
    const [address, setAddress] = useState(user.address);
    const [tel, setTel] = useState(user.tel);
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [saveData, setSaveData] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState();

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);

    const [allCategories, setAllCategories] = useState([]);

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                opacity: saveData ? 0 : 0,
                bottom: saveData ? '-30px' : '-30px',
                display: saveData ? 'none' : 'none'
            },
            to: {
                opacity: saveData ? 1 : 0,
                bottom: saveData ? '0' : '0',
                display: saveData ? 'flex' : 'none'
            },
            config: {duration: 200},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);
    }, [l10n, selectedLanguage]);

    useEffect(() => {
        dispatch(loadAllCategoriesParsed((res) => {
        }, (res) => {
        }));
        dispatch(loadAllCategories((res) => {
            setAllCategories(res);
        }, (res) => {
        }));
    }, [user]);

    const dispatchLoadCoursesFollowed = () => {
        if (!user) {
            return;
        }
        dispatch(loadAllCoursesFollowed({user_id: user.user_id}, async (resData) => {
        }, () => {
        }));
    }

    const dispatchLoadProvidersFollowed = () => {
        if (!user) {
            return;
        }
        dispatch(loadAllProvidersFollowed({user_id: user.user_id}, async (resData) => {
            console.log("PROVIDERS FOLLOWED", resData)
        }, () => {
        }));
    }

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, [])

    useEffect(() => {
        ref.current.scrollIntoView()
        if (user) {
            dispatchLoadCoursesFollowed();
            dispatchLoadProvidersFollowed();
        }
    }, [user])

    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.UPDATE_DATA:
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error l10n={selectedLanguage} type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

   const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

       return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const renderPageInfo = () => {
        return <p className={'p-pageinfo'}>
            {L10N_APP_TEXTS[selectedLanguage].HOME} > <span>{L10N_APP_TEXTS[selectedLanguage].MY_PROFILE}</span>
        </p>
    }

    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}
                {renderPageInfo()}

                <ScrollableTabs/>

                <Title title={fullName}/>
                {/*renderReservations(COURSES)*/}
                {/*renderReservationsArchive(COURSES)*/}
                {renderCourses(courses_followed)}
                {renderFollowing(providers_followed)}
                {renderMyPersonalData()}
                {renderCategories()}
            </div>
        )
    }

    const renderMap = () => {
        return (
            <CustomMap/>
        );
    };

    const renderFollowing = (following) => {
        return (
            <>
                <div className={'tab_reservations_following'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_FOLLOWING}/>
                <div className={'following_container'}>
                    {following && following.length > 0 ? following.map((provider, f) => (
                        <div key={`${f}_${provider.text}`} id={`${provider.provider_id}`} className={'following-row'}>
                            <div className={'row_inner_left'}>
                                <div key={"logo_provider"}>
                                    <img src={provider?.logo?.image} alt="" width="80" style={{
                                        borderRadius: '45px',
                                        height: '90px',
                                        width: '90px',
                                        objectFit: 'cover',
                                        marginRight: '24px'
                                    }}/>
                                </div>
                                <p className={'p-large click'} onClick={() => {
                                }}>{provider.name}</p>
                            </div>

                            <Button text={L10N_APP_TEXTS[selectedLanguage].UNFOLLOW} onClick={()=> {
                                dispatch(removeFollowerForProvider({provider_id: provider.provider_id, user_id: user.user_id}, async (resData) => {
                                    dispatchLoadProvidersFollowed();
                                }, () => {
                                }));
                            }}/>
                        </div>
                    )) :
                        <>
                            <p className={'p-normal'} style={{paddingBottom: '48px'}}>{L10N_APP_TEXTS[selectedLanguage].FOLLOWING}</p>
                        </>
                    }
                </div>
            </>
        );
    };

    const updateUserDataHandler = (key, value) => {
        let userData = user;
        userData[key] = value;
        dispatch(updateUserData(userData, selectedLanguage, () => {
        }));
    };


    const updateUserPasswordHandler = (key, value) => {
        let userData = user;
        userData[key] = value;
        dispatch(updateUserPassword(userData, selectedLanguage, () => {
            dispatch(logoutUser(() => {
                displayAlert(L10N_APP_TEXTS[selectedLanguage].PASSWORD_RESET_SUCCESS)
                onNavigateTo(PAGES.LOGIN);
            }))
        }));
    };

    const renderMyPersonalData = () => {
        return (
            <>
                <div className={'tab_reservations_personal_data'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_PERSONAL_DATA}/>
                <div className={'form-container'} style={{marginBottom: '0'}}>
                    <div className={'form_col form-row'}>
                        <Input value={fullName}
                               button={'edit'}
                               onChange={(value) => onInputChange(value, setFullName)}
                               onSubmit={() => updateUserDataHandler("full_name", fullName)}
                               onAction={() => updateUserDataHandler("full_name", fullName)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].NAME_SURNAME}
                               label={`${L10N_APP_TEXTS[selectedLanguage].NAME_SURNAME}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input button={'edit'}
                               value={address}
                               onChange={(value) => onInputChange(value, setAddress)}
                               onSubmit={() => updateUserDataHandler("address", address)}
                               onAction={() => updateUserDataHandler("address", address)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].ADDRESS}
                               label={`${L10N_APP_TEXTS[selectedLanguage].ADDRESS}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input button={'edit'}
                               value={tel}
                               onChange={(value) => onInputChange(value, setTel)}
                               onSubmit={() => updateUserDataHandler("tel", tel)}
                               onAction={() => updateUserDataHandler("tel", tel)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].TELEPHONE}
                               label={`${L10N_APP_TEXTS[selectedLanguage].TELEPHONE}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input email={email}
                               value={email}
                               button={'edit'}
                               onChange={(value) => onInputChange(value, setEmail)}
                               onSubmit={() => updateUserDataHandler("email", email)}
                               onAction={() => updateUserDataHandler("email", email)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL}
                               label={`${L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input password={password}
                               button={'edit'}
                               onChange={(value) => onInputChange(value, setPassword)}
                               onSubmit={() => updateUserPasswordHandler("password", password)}
                               onAction={() => updateUserPasswordHandler("password", password)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_PASSWORD}
                               label={`${L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_PASSWORD}:`}
                               style={{marginBottom: '0px'}}/>


                        <DropdownCircle user text={L10N_APP_TEXTS[selectedLanguage].LANGUAGE_SPOKEN} onChange={(key, value) => {
                            updateUserDataHandler(key, value);
                        }}/>

                    </div>
                </div>
                {renderErrorMessage()}
            </>
        );
    };

    const parseCategoryNameKey = (category, l10n) => {
        switch (l10n) {
            case L10N.en.value:
                return "name_en"
            case L10N.si.value:
                return "name_si"
            case L10N.it.value:
                return "name_it"
        }
    };


    const renderCategories = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_CATEGORIES}/>
                <div className={'categories_container'}>
                    {categories.map((category, c) => (
                        <div key={`${c}_${category.parent}`} className={'cat-row'}>
                            <p className={'p-label click bold'} style={{letterSpacing: '-1px'}} onClick={() => {
                            }}>{category.parent[parseCategoryNameKey(category.parent, selectedLanguage)]}</p>

                            {category.children.map((child, ch) => (
                                <div key={`${ch}_${child}`} className={'child-row'}>
                                    <p className={'p-label click'} onClick={() => {
                                    }}>{child[parseCategoryNameKey(child, selectedLanguage)]}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderCourses = (courses) => {
        if (!courses || courses.length <= 0) {
           return <>
               <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_FOLLOWING_COURSES}/>
                <p className={'p-normal'} style={{paddingBottom: '48px'}}>{L10N_APP_TEXTS[selectedLanguage].FOLLOWING_COURSES}</p>
            </>
        }


        return (
            <>
                <div className={'tab_reservations_courses'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_FOLLOWING_COURSES}/>
                <div className={'courses_container scroll'} style={{marginBottom: '48px'}}>
                    {courses.map((course, c) => (

                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'}
                             style={{cursor: "pointer"}} onClick={() => {
                            let data = {
                                course_id: course?.course_id,
                            }
                            dispatch(loadCourseById(data, selectedLanguage, (resData) => {
                                console.log(resData);
                                onNavigateTo(PAGES.COURSE + ":" + course?.course_id);
                            }));
                        }}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'} style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>
                                <p className={'p-desc desc'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{ course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>
                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[0]?.dates)})</p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconStar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            {/*<p className={'p-info'}>{'5.3 (3000)'}</p> */}
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && ((course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{course?.packages[0] && course?.packages[0].price_discounted + " " + selectedLanguageObj?.priceValue}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderReservations = (reservations) => {
        return (
            <>
                <div className={'tab_reservations'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_MY_RESERVATIONS}/>
                <div className={'courses_container reservations scroll'}>
                    {reservations.map((reservation, c) => (
                        <div key={`${c}_${reservation.course_id}`} className={'reservation-col'}>
                            <div className={'course-col-top'}>
                                <img src={courseImg} alt={'course_img'} width={'260px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <div className={'reservation-col-left'}>
                                    <p className={'p-large'}>{reservation.name}</p>
                                    <p className={'p-header black'}>{reservation.provider_name}</p>

                                    <div className={'course-col-bottom-inner-row address'}>
                                        <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'24px'}/>
                                        <p className={'p-desc address'}>{reservation.address}</p>
                                    </div>
                                    <>
                                        {reservation.dates.map((date, d) => (
                                            <div key={`${d}_${date}`} className={'reservation-col-date'}>

                                                <div className={'reservation-row'}>
                                                    <p className={'p-info'}>{date.split(',')[0]}</p>
                                                    <p className={'p-info'}>{date.split(',')[1]}</p>
                                                    <p className={'p-info'}>{date.split(',')[2]}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    <p className={'p-info bold calendar'}>{L10N_APP_TEXTS[selectedLanguage].ADD_TO_CALENDAR}</p>
                                    <p className={'p-header black reservation-number'}>{L10N_APP_TEXTS[selectedLanguage].RESERVATION_NUMBER}
                                        <span>00242840</span></p>
                                </div>
                                <div className={'reservation-col-right'}>
                                    <img src={iconQr} className={'icon-info'} alt={'icon_img'}
                                         style={{marginRight: '-24px'}} width={'200px'}/>
                                    <div className={'contact-us-button p-submit'}>
                                        <span>{L10N_APP_TEXTS[selectedLanguage].CONTACT_BUTTON_SMALL}</span>
                                        <img src={iconMessage} className={'icon-info'} alt={'icon_img'} width={'21px'}/>
                                    </div>
                                    <p className={'p-title reservation-number blue'}>{L10N_APP_TEXTS[selectedLanguage].PAID}
                                        <span>16,99 {selectedLanguageObj?.priceValue}</span></p>
                                </div>

                            </div>

                            <div className={'reservation-bot'}>
                                <div className={'course-col-bottom-inner-row'}>
                                    <p className={'p-header blue click'}>{L10N_APP_TEXTS[selectedLanguage].PRINT}</p>
                                    <img src={iconPrint} className={'icon-info'}
                                         style={{paddingLeft: '8px', paddingTop: '3px'}} alt={'sum_img'}
                                         width={'18px'}/>
                                </div>
                                <p className={'p-header blue click'}>{L10N_APP_TEXTS[selectedLanguage].CANCEL_RESCHEDULE}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderReservationsArchive = (reservations) => {
        return (
            <>
                <div className={'tab_reservations_archive'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_RESERVATIONS_ARCHIVE}/>
                <div className={'courses_container reservations archive scroll'}>
                    {reservations.map((reservation, c) => (
                        <div key={`${c}_${reservation.course_id}`} className={'reservation-col'}>
                            <div className={'course-col-top'}>
                                <img src={courseImg} alt={'course_img'} width={'260px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <div className={'reservation-col-left'}>
                                    <p className={'p-large'}>{reservation.name}</p>
                                    <p className={'p-header black'}>{reservation.provider_name}</p>

                                    <div className={'course-col-bottom-inner-row address'}>
                                        <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'24px'}/>
                                        <p className={'p-desc address'}>{reservation.address}</p>
                                    </div>
                                    <>
                                        {reservation.dates.map((date, d) => (
                                            <div key={`${d}_${date}`} className={'reservation-col-date'}>

                                                <div className={'reservation-row'}>
                                                    <p className={'p-info'}>{date.split(',')[0]}</p>
                                                    <p className={'p-info'}>{date.split(',')[1]}</p>
                                                    <p className={'p-info'}>{date.split(',')[2]}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    <p className={'p-header black reservation-number'}
                                       style={{paddingTop: '16px'}}>{L10N_APP_TEXTS[selectedLanguage].RESERVATION_NUMBER}
                                        <span>00242840</span></p>
                                </div>
                                <div className={'reservation-col-right'}>
                                    <img src={iconQr} className={'icon-info'} alt={'icon_img'}
                                         style={{marginRight: '-24px'}} width={'200px'}/>
                                    <div className={'contact-us-button p-submit'}>
                                        <span>{L10N_APP_TEXTS[selectedLanguage].CONTACT_BUTTON_SMALL}</span>
                                        <img src={iconMessage} className={'icon-info'} alt={'icon_img'} width={'21px'}/>
                                    </div>
                                    <p className={'p-title reservation-number blue'}>{L10N_APP_TEXTS[selectedLanguage].PAID}
                                        <span>16,99 {selectedLanguageObj?.priceValue}</span></p>
                                </div>

                            </div>

                            <div className={'reservation-bot'}>
                                <div className={'course-col-bottom-inner-row'}>
                                    <p className={'p-header blue click'}>{L10N_APP_TEXTS[selectedLanguage].PRINT}</p>
                                    <img src={iconPrint} className={'icon-info'}
                                         style={{paddingLeft: '8px', paddingTop: '3px'}} alt={'sum_img'}
                                         width={'18px'}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderPage = () => {
        return <>
            <div ref={ref}/>
            <Header loggedIn/>
            <animated.div style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>
            <animated.div style={slideUp}>
                <Footer button={L10N_APP_TEXTS[selectedLanguage].SAVE}
                        onClick={() => triggerAction(CREATE_ACTION.UPDATE_DATA)}/>
            </animated.div>
            <FooterPage/>
        </>
    }

    if (loginSuccessful) {
        console.log('redirect');

        onNavigateTo(PAGES.HOME);
        return <></>
    }

    return (
        renderPage()
    );
}

export default Home;