import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory, useParams} from "react-router-dom";
import {L10N, TIERS, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    createFollowerForProvider,
    getFaqProvider,
    isRememberMeEnabled,
    loadAllCategories,
    loadAllCourses,
    loadCourseById,
    loadCoursesForProvider,
    loadCredentials,
    loadFollowerByUserId,
    loadFollowerByUserIdforProvider,
    loadFollowersForProvider,
    loadImage,
    loadImages,
    loadLecturers,
    loadLocalL10N,
    loadProviderData,
    loadProviderDataById,
    removeFollowerForProvider,
    setCourses,
    setL10N,
    setRememberMe,
    setSignInCredentials,
    userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    capitalize,
    displayDate,
    displayDateMonthYear,
    getIndexOf,
    getNextCourseStartDate,
    getVideoIdFromUrl,
    parseHoursDurationFromTimestamps,
    parseHoursDurationFromTimestampsFormatted,
    parseSelectedCountryLabel,
    scrollToDiv
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import Input from "../components/js/Input";
import plusCircleIcon from "../assets/images/plus-circle.png";
import iconPrint from "../assets/images/printer.png";

import starBlueFull from "../assets/images/star-blue-full.png";
import iconAcc from "../assets/images/acc.png";
import iconHeart from "../assets/images/heart_blue.png";
import iconHamburger from "../assets/images/hamburger.png";
import iconClose from "../assets/images/close.png";
import iconMessage from "../assets/images/message-circle.png";
import iconStarGray from "../assets/images/zvezdica.png";

import iconImageExample from "../assets/images/img_example.png";
import iconAddress from "../assets/images/i_address.png";
import iconMapPin from "../assets/images/map-pin.png";
import iconMean from "../assets/images/i_mean.png";
import iconStar from "../assets/images/i_star.png";
import iconSum from "../assets/images/i_sum.png";
import iconNight from "../assets/images/i_night.png";
import courseImg from "../assets/images/course.png";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import Title from "../components/js/Title";
import Footer from "../components/js/Footer";
import {CREATE_ACTION} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import Button from "../components/js/Button";
import DropdownCircle from "../components/js/DropdownCircle";
import FooterPage from "../components/js/FooterPage";
import GoogleMap from "../components/js/GoogleMap";
import Filters from "../components/js/Filters";
import Search from "../components/js/Search";
import HeaderLanding from "../components/js/HeaderLanding";
import CustomMap from "../components/js/CustomMap";
import {COURSES, parseLanguagesSpoken} from "./CoursePage";
import {openInNewTab} from "./ProviderPageEdit";
import ScrollableTabsCourses from "../components/js/ScrollableTabsCourses";
import iconCalendar from "../assets/images/calendar.png";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";
import YouTubeEmbed from "../components/js/YoutubeEmbed";

export const FAQ = [
    {question: 'Vprašanje', answer: 'Odgovor..'},
    {question: 'Daljše Vprašanje', answer: 'Odgovor..'},
    {question: 'Še daljše vprašanje za nadaljevanjem?', answer: 'Daljši in podrobnejši odgovor na vprašanje...'},
    {question: 'Še daljše vprašanje za nadaljevanjem?', answer: 'Daljši in podrobnejši odgovor na vprašanje...'},
    {question: 'Vprašanje', answer: 'Odgovor..'},
    {question: 'Vprašanje', answer: 'Odgovor..'},
];

const USER = {
    user_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    full_name: 'Tadej Hiti',
    user_role: USER_ROLES.CUSTOMER,
    password: 'lulček#%hashed',
    email: 'hiti.tadej@gmail.com',
    tel: '+38640306677',
    address: 'Ograde 3',
    l10n_default: L10N.si.value,
    l10n_en: false,
    l10n_si: false,
    l10n_it: false,
};
const PROVIDER = {
    provider_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    images: [iconImageExample, iconImageExample, iconImageExample, iconImageExample, iconImageExample, iconImageExample]
};

const FOLLOWING = [
    {
        provider_id: 'dfgdfgdhfj',
        name: 'Plesna Šola Vzorec',
    },
    {
        provider_id: 'dfgdfg8678dhfj',
        name: 'Plesna Šola Vzorec',
    }
];

function ProviderPage() {
    let dispatch = useDispatch();
    let {provider_id: provider_id} = useParams();

    provider_id = provider_id.substring(1);

    console.log("PROVIDER_ID", provider_id);

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);

    const prov = useSelector(state => state.reducer.provider);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);


    //const provider = useSelector(state => state.reducer.provider);

    const [provider, setProvider] = useState(prov?.provider_id ? prov : null);

    const [fullName, setFullName] = useState();
    const [address, setAddress] = useState();
    const [tel, setTel] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [providerName, setProviderName] = useState(provider?.name);
    const [providerDesc, setProviderDesc] = useState(provider?.l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, provider.l10n)]?.description);
    const [providerAddress, setProviderAddress] = useState(provider?.address);
    const [providerPostCity, setProviderPostCity] = useState(provider?.post);
    const [country, setCountry] = useState(provider?.country);

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(null);

    const [providerYoutubeUrl, setProviderYoutubeUrl] = useState(provider?.youtube_url);

    const [openedFaq, setOpenedFaq] = useState(false);

    const [toggleFilters, setToggleFilters] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState();

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [logoProvider, setLogoProvider] = useState([]);
    const [logoAccommodation, setLogoAccommodation] = useState([]);
    const [images, setImages] = useState([]);
    const [lecturersProfilePictures, setLecturersProfilePictures] = useState([]);

    const [lecturers, setLecturers] = useState([]);
    const [selectedGalleryIndex, setSelectedGalleryIndex] = useState(0);

    const [faqs, setFaqs] = useState([]);

    const [myCourses, setMyCourses] = useState([]);
    const [myCoursesArchived, setMyCoursesArchived] = useState([]);

    const [followers, setFollowers] = useState("");
    const [userFollower, setUserFollower] = useState(null);

    const [allCourses, setAllCourses] = useState([]);
    const [allCategories, setAllCategories] = useState([]);

    const [isFilterUsed, setIsFilterUsed] = useState(false);
    const [rerender, setRerender] = useState(false);

    const [filteredCourses, setFilteredCourses] = useState([]);

    console.log(userFollower);

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                opacity: 0,
                height: 0,
            },
            to: {
                opacity: toggleFilters ? 1 : 0,
                height: toggleFilters ? 'auto' : 0,
                display: toggleFilters ? 'flex' : 'none',
            },
            config: {duration: 100},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }


    const dispatchLoadCourses = () => {
        dispatch(loadCoursesForProvider({provider_id: provider_id}, async (resData) => {
            if (!resData) {
                return;
            }
            let archived = [];
            let courses = [];

            for (let i = 0; i <= resData.length; i++) {
                let c = resData[i];

                if (!c) {
                    return;
                }

                if (c.active) {
                    courses.push(c);
                } else {
                    archived.push(c);
                }

                setMyCourses(courses);
                setMyCoursesArchived(archived);
            }

        }, () => {
        }));
    }

    const dispatchLoadFollowers = () => {
        dispatch(loadFollowersForProvider({provider_id: provider.provider_id}, async (resData) => {
            if (!resData) {
                return;
            }
            setFollowers(resData.length)

        }, () => {
        }));
    }

    const dispatchLoadFollower = () => {
        dispatch(loadFollowerByUserIdforProvider({user_id: user.user_id, provider_id: provider.provider_id}, async (resData) => {
            if (!resData) {
                setUserFollower(null);
                return;
            }
            console.log("USER FOLLOWER", resData);

            setUserFollower(resData);
        }, () => {
            setUserFollower(null);
        }));
    }

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, [])

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);
        setProviderDesc(provider?.l10n[getIndexOf("l10n", selectedLanguage, provider.l10n)])
    }, [l10n, selectedLanguage, provider]);


    useEffect(() => {
        if (provider_id) {
            dispatch(loadProviderDataById({provider_id: provider_id}, selectedLanguage, (resData) => {
                setProvider(resData);

                dispatchLoadCourses();
                dispatchLoadFollowers();
            }))
        }

    }, [provider_id]);

    useEffect(() => {
        if (provider) {
            dispatchLoadLecturers(true);
            dispatch(getFaqProvider({provider_id: provider.provider_id}, selectedLanguage, (resData) => {
                setFaqs(resData);
            }))
        }

    }, [provider]);


    useEffect(() => {
        if (user) {
            dispatchLoadFollower();
        }
    }, [user])

    console.log("FAQ", faqs)

    useEffect(() => {
        if (provider && selectedLanguage) {
            console.log(provider)

            let desc = provider?.l10n[getIndexOf("l10n", selectedLanguage, provider.l10n)].description;
            setProviderDesc(desc)

            dispatch(getFaqProvider({provider_id: provider.provider_id}, selectedLanguage, (resData) => {
                setFaqs(resData);
            }))

            if (provider.image_id) {
                dispatch(loadImage({image_id: provider.image_id, type: "LOGO"}, (resData) => {
                    setLogoProvider([{data_url: resData.image}]);
                }, () => {
                    setLogoProvider([]);
                }))
            }
            if (provider) {
                dispatch(loadImages({provider_id: provider.provider_id, type: "GALLERY"}, (resData) => {
                    console.log(resData);

                    for (let i = 0; i < resData.length; i++) {
                        resData[i].data_url = resData[i].image;
                        delete resData[i].image;
                    }
                    setImages(resData);


                }, () => {

                }));
                dispatchLoadLecturers();

                reloadProviderL10NData(provider);
            }
        }

    }, [provider, selectedLanguage]);

    useEffect(() => {

        dispatch(loadAllCourses((res) => {
            setAllCourses(res);
        }, (res) => {
        }));
        dispatch(loadAllCategories((res) => {
            setAllCategories(res);
        }, (res) => {
        }));
        return () => {
            setLecturers([]);
            setMyCourses([]);
            setFaqs([]);
            setFollowers("");
        };

    }, [])

    useEffect(() => {
        if (!search || search === "") {
            console.log(search)
            setIsFilterUsed(false)
            setFilteredCourses([]);
        }
    }, [search])

    useEffect(() =>{
        if (!toggleFilters) {
            setFilteredCourses([]);
        }
    }, [toggleFilters])

    const renderCoursesSearch = (courses, title) => {
        if (!selectedLanguage || !courses || courses?.length <= 0) {
            if (filteredCourses === courses && isFilterUsed) {
                return <>
                    <Title dots title={title}/>
                    <p className={'p-normal'} style={{paddingBottom: '80px', fontStyle: 'italic'}}>{L10N_APP_TEXTS[selectedLanguage].NO_SEARCH_RESULTS}</p>
                </>
            }
            return  <></>
        }
        return (
            <>
                <Title dots title={title}/>
                <div className={'courses_container scroll'} style={{marginBottom: '48px'}}>
                    {courses.map((course, c) => (

                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'}
                             style={{cursor: "pointer"}} onClick={() => {
                            let data = {
                                course_id: course?.course_id,
                            }
                            dispatch(loadCourseById(data, selectedLanguage, (resData) => {
                                console.log(resData);
                                onNavigateTo(PAGES.COURSE + ":" + course?.course_id);
                            }));
                        }}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>
                                <p className={'p-desc desc truncate'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{ course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>
                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[0]?.dates)})</p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}>
                                            {/*<img src={iconStar} className={'icon-info'} alt={'sum_img'}
                                                  width={'14px'}/>*/}
                                            {/*<p className={'p-info'}>{'5.3 (3000)'}</p> */}
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && ((course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{course?.packages[0] && course?.packages[0].price_discounted + " " + selectedLanguageObj?.priceValue}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };


    const reloadProviderL10NData = (provider) => {
        console.log("reload")
        let s = provider?.name;
        setProviderName(s ? s : "");

        s = provider?.l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, provider.l10n)]?.description;
        setProviderDesc(s ? s : "");

        s = provider?.address;
        setProviderAddress(s ? s : "");

        s = provider?.post;
        setProviderPostCity(s ? s : "");

        s = provider?.country;
        setCountry(s ? s : "");
    }


    const dispatchLoadLecturers = () => {
        dispatch(loadLecturers({provider_id: provider.provider_id}, (resData) => {
            console.log(resData);
            setLecturers(resData);

            let u = [];

            for (let i = 0; i < resData.length; i++) {
                let lec = resData[i];
                if (!lec.image_id) {
                    u.push(null)
                    continue;
                }
                dispatch(loadImage({image_id: lec.image_id, type: "LOGO"}, (resData) => {
                    u.push(resData.image)

                    setTimeout(() => {
                        setLecturersProfilePictures(u);
                    }, 200);
                }, () => {
                }))
            }
        }, () => {

        }));
    }

    const displayCollapsibleFaq = (status, question, answer) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header faq-options'} style={{justifyContent: 'flex-end'}}>
                <div className={'faq-question'}>
                    <p className={'p-title'}>{question}</p>
                    {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
                </div>
                {status ? <div className={'faq-desc'}>
                    <p className={'p-header black'}>{answer}</p>
                </div> : <></>}
            </div>
        );
    }

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

   const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

       return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const onActionCollapseFaq = (i) => {
        setOpenedFaq(i);
    }

    const renderFaq = () => {
        return (
            <>
                <Title dots title={'FAQs'}/>
                <div className={'faq-container'}>
                    {faqs.map((faq, f) => (
                        <div key={`${f}_${faq.question}`} className={'collapsible-faq-container'}>
                            <Collapsible className={'faq-options'}
                                         transitionTime={400}
                                         onClosing={() => onActionCollapseFaq(false)}
                                         onOpening={() => onActionCollapseFaq(f)}
                                         open={openedFaq === f}
                                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                                         trigger={displayCollapsibleFaq(false, faq.question, faq.answer)}
                                         triggerWhenOpen={displayCollapsibleFaq(true, faq.question, faq.answer)}>
                            </Collapsible>
                        </div>
                    ))}
                </div>
            </>

        );
    }


    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}
                <Search onChange={(value) => onInputChange(value, setSearch)}
                        placeholder={`${L10N_APP_TEXTS[selectedLanguage].FIND_COURSE}..`}
                        allCourses={allCourses}
                        onSubmit={(filtered) => {
                            setFilteredCourses(filtered)
                        }}/>
                <div className={'hamburger_menu'} onClick={() => {
                    setToggleFilters(!toggleFilters);
                }}>
                    <img src={!toggleFilters ? iconHamburger : iconClose} className={'icon-label'} alt={'img'}
                         width={'24px'}/>
                </div>
                <animated.div style={slideUp}>
                    <Filters
                        allCourses={allCourses}
                        categories={allCategories}
                        onSubmit={(filtered) => {
                            setRerender(!rerender);
                            setIsFilterUsed(true);

                            setFilteredCourses(filtered)
                        }}/>
                </animated.div>

                {renderCoursesSearch(filteredCourses, L10N_APP_TEXTS[selectedLanguage].SEARCH, rerender)}

                {renderProviderInfo()}
                {renderGallerySection()}
                {renderLecturers()}
                {/*renderReviews()*/}
                {renderCourses(myCourses, L10N_APP_TEXTS[selectedLanguage].ALL_COURSES)}
                {renderFaq()}
            </div>
        )
    }

    const renderYoutubeVideo = (videoUrl) => {
        if (!videoUrl) {
            return <></>
        }
        let videoId = getVideoIdFromUrl(videoUrl)
        return (
            <div>
                <YouTubeEmbed embedId={videoId} />
            </div>
        );
    }

    const renderGallerySection = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].PROVIDER_ABOUT}/>
                <div className={'provider-info-container'}>
                    <div className={'gallery'}>
                        <img src={images && images.length > 0 ? images[selectedGalleryIndex]?.data_url : ""}
                             className={'gallery_img'} alt={'img'}/>
                    </div>
                    <div className={'gallery_row scroll'}>
                        {images.map((image, p) => (
                            <div key={`${p}_img`} className={'gallery_img_cont'} onClick={() => {
                                setSelectedGalleryIndex(p);
                            }}>
                                <img src={image?.data_url} className={'gallery_img'} alt={'img'} height={'80px'}
                                     style={{borderRadius: '24px'}}/>
                            </div>
                        ))}
                    </div>

                    {renderYoutubeVideo(providerYoutubeUrl)}

                    <div className={'provider-row desc'}>
                        <p className={'p-header black'}>
                            {providerDesc}
                        </p>
                    </div>
                </div>
            </>

        );
    }

    const renderReviews = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].REVIEWS}/>
                <div className={'reviews-container'}>
                    <div className={'review-left'}>
                        <img src={starBlueFull} className={'star-review'} alt={'img'} width={'60px'}/>
                        <p className={'p-review'}>0,0 (4.052)</p>
                    </div>
                    <div className={'review-right'}>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '30%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_SPACE})</p>
                        </div>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '50%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_PRO})</p>
                        </div>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '60%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_SPEED})</p>
                        </div>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '80%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_CONTACT})</p>
                        </div>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '90%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_BANG_FOR_BUCK})</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const renderLecturers = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].LECTURERS}/>
                <div className={'lecturers-container'}>

                    {lecturers.map((lecturer, index) => (
                        <div className={'lecturers-item'}>
                            <div className={'lecturers-col'}>
                                <img src={lecturersProfilePictures[index]} alt={'lecturer-img'}
                                     width={'80px'} style={{
                                    borderRadius: '45px',
                                    height: '90px',
                                    width: '90px',
                                    objectFit: 'cover',
                                    marginRight: '24px'
                                }}/>

                                <p className={'p-header black lecturer'}
                                   style={{paddingLeft: '0'}}>{lecturer.full_name}</p>
                            </div>
                            <div className={'lecturer-desc'}>
                                <p className={'p-text'}
                                   style={{maxWidth: '300px'}}>{lecturer.l10n[getIndexOf("l10n", selectedLanguage, lecturer.l10n)].description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </>

        );
    }

    console.log("user follower /provider_id:", userFollower?.provider_id, provider?.provider_id);

    const handleEmailClickContact = () => {
        const email = provider?.email;
        const subject = L10N_APP_TEXTS[selectedLanguage].CONTACT_REQ;
        const body = '';

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink, '_blank');
    };


    const renderProviderInfo = () => {
        return (
            <div className={'provider-info-container'}>
                <p className={'p-pageinfo'}>
                    {L10N_APP_TEXTS[selectedLanguage].COURSES} > {L10N_APP_TEXTS[selectedLanguage].PROVIDER_COURSES} > <span>{providerName}</span>
                </p>
                <div className={'provider-row'}>
                    <div className={'row_inner_left'}>
                        <div key={"logo_provider"}>
                            <img src={logoProvider[0]?.data_url} alt="" width="80" style={{
                                borderRadius: '45px',
                                height: '90px',
                                width: '90px',
                                objectFit: 'cover',
                                marginRight: '24px'
                            }}/>
                        </div>
                        <p className={'p-xlarge'}>{providerName}</p>
                        <div className={'row_inner_right'}>
                            <div className={'follow p-submit'} onClick={() =>{
                                if (userFollower?.provider_id === provider?.provider_id) {
                                    dispatch(removeFollowerForProvider({provider_id: provider.provider_id, user_id: user.user_id}, async (resData) => {
                                        if (!resData) {
                                            return;
                                        }
                                        dispatchLoadFollowers()
                                        dispatchLoadFollower();
                                    }, () => {
                                        dispatchLoadFollowers()
                                        dispatchLoadFollower();
                                    }));
                                } else {
                                    dispatch(createFollowerForProvider({provider_id: provider.provider_id, user_id: user.user_id}, async (resData) => {
                                        if (!resData) {
                                            return;
                                        }
                                        dispatchLoadFollowers()
                                        dispatchLoadFollower();
                                    }, () => {
                                        dispatchLoadFollowers()
                                        dispatchLoadFollower();
                                    }));
                                }

                            }}>
                                <span>{userFollower?.provider_id === provider?.provider_id ? L10N_APP_TEXTS[selectedLanguage].UNFOLLOW : L10N_APP_TEXTS[selectedLanguage].FOLLOW}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'row_inner_right'}>
                        <div className={'contact-us-button p-submit'}  onClick={() => {
                            handleEmailClickContact()
                        }}>
                            <span>{L10N_APP_TEXTS[selectedLanguage].CONTACT_BUTTON}</span>
                            <img src={iconMessage} className={'icon-info'} alt={'icon_img'} width={'21px'}/>
                        </div>
                    </div>
                </div>
                <div className={'poi'}>
                    <div className={'course-col-bottom-inner-row'} style={{alignItems: 'baseline'}} onClick={() => {
                        let t = providerAddress + " " + providerPostCity;
                        t = t?.split(" ");
                        let s = "";
                        for (let i = 0; i < t.length; i++) {
                            s += (t[i] + "+");
                        }
                        openInNewTab(`https://www.google.com/maps/place/${s}`)
                    }}>
                        <img src={iconMapPin} className={'icon-info'} alt={'sum_img'} width={'24px'}/>
                        <p className={'p-location'}>{providerAddress}, {providerPostCity}</p>
                    </div>
                </div>
                <div className={'provider-row facts'}>
                    <div className={'row_inner_left'}>
                        {/*
                        WORKING, REMOVED TMP:

                        <p className={'p-text'}>{L10N_APP_TEXTS[selectedLanguage].FOLLOWERS}<span
                            style={{
                                color: '#29ABE2',
                                textDecoration: 'unset'
                            }}>{` ${followers}`}</span> {L10N_APP_TEXTS[selectedLanguage].THIS_SCHOOL_FOLLOWS_2}</p>
                        <p className={'p-text del'}> | </p>
                        */}

                        <p className={'p-text'}>{L10N_APP_TEXTS[selectedLanguage].MEMBERSHIP_START_DATE_PRETTY} {displayDateMonthYear(provider?.date_create, selectedLanguage)}</p>

                        {/*
                                     <p className={'p-text del'}> | </p>
                        <img src={iconStarGray} className={'icon-info'} alt={'sum_img'} width={'16px'}/>
                        <p className={'p-info'}>0,0 (4052)</p>
                        */}


                    </div>
                </div>
                <div className={'provider-row languages'}>
                    <div className={'row_inner_left'}>
                        <p className={'p-text'}>{`${L10N_APP_TEXTS[selectedLanguage].IN_SCHOOL_WE_SPEAK}`}</p>
                        <span style={{color: '#29ABE2'}}>{parseLanguagesSpoken(provider)}</span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCourses = (courses, title, anchor) => {

        if (!selectedLanguage || !courses || courses?.length <= 0) {
            return <>
                <Title dots title={title}/>
                <p className={'p-normal'} style={{paddingBottom: '80px', fontStyle: 'italic'}}>{L10N_APP_TEXTS[selectedLanguage].NO_SEARCH_RESULTS}</p>
            </>
            return  <></>
        }
        return (
            <>
                {anchor ? <div className={anchor}/> : <></>}
                <Title dots title={title}/>
                <div className={'courses_container scroll ' + anchor} style={{marginBottom: '48px'}}>
                    {courses.map((course, c) => (

                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'}
                             style={{cursor: "pointer"}} onClick={() => {
                            let data = {
                                course_id: course?.course_id,
                            }
                            dispatch(loadCourseById(data, selectedLanguage, (resData) => {
                                console.log(resData);
                                onNavigateTo(PAGES.COURSE + ":" + course?.course_id);
                            }));
                        }}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'} style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>

                                <p className={'p-desc desc p-desc-list'}>{ course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage ? l10n : L10N.si.value, course?.packages[0].l10n)]?.description_list}</p>
                                <p className={'p-desc desc truncate'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{ course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[0]?.dates)})</p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: 0}}>
                                            <img src={iconStar} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{'5.3 (3000)'}</p>
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && ((course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'} style={{display: course?.packages[0] && course?.packages[0].price_discounted ? "flex" : "none"}}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{(course?.packages[0] && course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) + " " + selectedLanguageObj?.priceValue}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };


    /*
        const renderCourses = (courses) => {
            return (
                <>
                    <Title dots title={L10N_APP_TEXTS[selectedLanguage].ALL_COURSES}/>
                    <div className={'courses_container scroll'}>
                        {courses.map((course, c) => (
                            <div key={`${c}_${course.course_id}`} className={'course-col'}>
                                <div className={'course-col-top'}>
                                    <div className={'icon-heart-container'} onClick={() => {
                                    }}>
                                        <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                    </div>
                                    <img src={courseImg} alt={'course_img'} width={'290px'}/>
                                </div>
                                <div className={'label-accommodation-container'}>
                                    <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                         width={'50px'}/>
                                </div>
                                <div className={'course-col-bottom'}>
                                    <p className={'p-desc'}>{course.provider_name}</p>
                                    <p className={'p-large'}>{course.name}</p>
                                    <p className={'p-desc desc'}>{course.description}</p>

                                    <div className={'course-col-bottom-inner-row'}>
                                        <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                        <p className={'p-info'}>{course.time_period}</p>
                                    </div>
                                    <div className={'course-col-bottom-inner'}>
                                        <div className={'course-col-bottom-inner-col'}>
                                            <div className={'course-col-bottom-inner-row'}>
                                                <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                                <p className={'p-info'}>{course.nights} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({course.hours} {L10N_APP_TEXTS[selectedLanguage].HOURS})</p>
                                            </div>
                                            <div className={'course-col-bottom-inner-row'}>
                                                <img src={iconStar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                                <p className={'p-info'}>{course.stars}</p>
                                            </div>
                                        </div>
                                        <div className={'course-col-bottom-inner-col'}>
                                            <div className={'course-col-bottom-inner-row'}>
                                                <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                                <p className={'p-info'}>{course.price_hour} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR}</p>
                                            </div>
                                            <div className={'course-col-bottom-inner-row'}>
                                                <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                     width={'14px'}/>
                                                <p className={'p-info'}>{course.accommodation_price} {selectedLanguageObj?.priceValue}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-row price'}>
                                        <p className={'p-price'}>{course.price} {selectedLanguageObj?.priceValue}</p>
                                        <p className={'p-discount-price'}>{course.price_discounted} {selectedLanguageObj?.priceValue}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        };


     */

    const renderPage = () => {
        return <>
            <div ref={ref}/>
            <Header/>
            {/*<Header loggedIn/>*/}
            <animated.div style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>
            <FooterPage/>
        </>
    }

    if (loginSuccessful) {
        console.log('redirect');

        onNavigateTo(PAGES.HOME);
        return <></>
    }

    return (
        renderPage()
    );
}

export default ProviderPage;