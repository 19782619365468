import React, {useEffect, useState} from "react";

import '../css/Message.css'
import {L10N} from "../../constants/enums";

export const ErrorType = {
    SUCCESS: -1,
    UNAUTHORIZED: 0,
    ERROR_FETCHING_DATA: 1,
    USER_ACCESS_NOT_GRANTED: 2,
    EMAIL_VERIFICATION_FAIL: 3,
    EMAIL_VERIFICATION_EMPTY: 4,
    NAME_VERIFICATION_EMPTY: 5,
    USER_ALREADY_EXISTS: 6,
    SUBSCRIPTION_SUCCESS: 7,
    SUBSCRIPTION_INSUFFICIENT_FUNDS: 8,
    SUBSCRIPTION_FAIL: 9,
    USER_DOESNT_EXISTS: 10,
    MISSING_DATA: 11
}

function Message(props) {
    let className = 'message';
    if (props.error) {
        className += ' error'
    } else if (props.success) {
        className += ' success'
    }

    const getMessageText = (type, l10n) => {
        switch (l10n) {
            case L10N.si.value:
                switch (type) {
                    case ErrorType.UNAUTHORIZED:
                        return 'Nepravilno uporabniško ime in geslo. Prosimo poskusite znova.';
                    case ErrorType.ERROR_FETCHING_DATA:
                        return 'Napaka pri pridobivanju podatkov. Prosimo poskusite znova kasneje.';
                    case ErrorType.USER_ACCESS_NOT_GRANTED:
                        return 'Uporabnik nima uporabniških pravic za dostop do sistema.';
                    case ErrorType.EMAIL_VERIFICATION_FAIL:
                        return 'Prosimo preverite pravilnost vnešenega e-poštnega naslova in poskusite znova.';
                    case ErrorType.EMAIL_VERIFICATION_EMPTY:
                        return 'Manjkajoč e-poštni naslov. Prosimo vnesite veljavno e-pošto.';
                    case ErrorType.NAME_VERIFICATION_EMPTY:
                        return 'Manjkajoče ime. Prosimo vnesite željeno predstavitveno ime, katerega boste v osebnem profilu videli le vi.';
                    case ErrorType.USER_ALREADY_EXISTS:
                        return 'Uporabnik s tem uporabniškim imenom že obstaja.';
                    case ErrorType.USER_DOESNT_EXISTS:
                        return 'Uporabnik s tem uporabniškim imenom ne obstaja!';
                    case ErrorType.SUBSCRIPTION_SUCCESS:
                        return 'Naročnina uspešna vzpostavljena.\n\n Avtomatsko boste preusmerjeni na domačo stran..';
                    case ErrorType.SUBSCRIPTION_INSUFFICIENT_FUNDS:
                        return 'Napaka pri komuniciranju (nezadostna sredstva).';
                    case ErrorType.SUBSCRIPTION_FAIL:
                        return 'Napaka pri komuniciranju.';
                    case ErrorType.MISSING_DATA:
                        return 'Manjkajoči podatki.. Prosimo preverite in izpolnite vsa manjkajoča polja';
                }
                break;
            case L10N.hr.value:
                switch (type) {
                    case ErrorType.UNAUTHORIZED:
                        return 'Netočno korisničko ime i lozinka. Molimo pokušajte ponovno.';
                    case ErrorType.ERROR_FETCHING_DATA:
                        return 'Pogreška pri dohvaćanju podataka. Molimo pokušajte ponovo kasnije.';
                    case ErrorType.USER_ACCESS_NOT_GRANTED:
                        return 'Korisnik nema korisnička prava za pristup sustavu.';
                    case ErrorType.EMAIL_VERIFICATION_FAIL:
                        return 'Provjerite ispravnost unesene e-mail adrese i pokušajte ponovno.';
                    case ErrorType.EMAIL_VERIFICATION_EMPTY:
                        return 'Nedostaje email adresa. Ponovno unesite e-mail adresu.';
                    case ErrorType.NAME_VERIFICATION_EMPTY:
                        return 'Nedostaje ime. Unesite željeno ime koje ćete vidjeti samo vi u svom osobnom profilu.';
                    case ErrorType.USER_ALREADY_EXISTS:
                        return 'Korisnik s ovim korisničkim imenom već postoji.';
                    case ErrorType.USER_DOESNT_EXISTS:
                        return 'Korisnik s ovim korisničkim imenom ne postoji!';
                    case ErrorType.SUBSCRIPTION_SUCCESS:
                        return 'Pretplata je uspješno uspostavljena.\n\nAutomatski ćete biti preusmjereni na početnu stranicu.';
                    case ErrorType.SUBSCRIPTION_INSUFFICIENT_FUNDS:
                        return 'Greška u komunikaciji (nedovoljno sredstava)';
                    case ErrorType.SUBSCRIPTION_FAIL:
                        return 'Greška u komunikaciji.';
                    case ErrorType.MISSING_DATA:
                        return 'Manjkajoči podatki.. Prosimo preverite in izpolnite vsa manjkajoča polja';
                }
                break;
            case L10N.it.value:
                switch (type) {
                    case ErrorType.UNAUTHORIZED:
                        return 'Nome utente e password errati. Per favore riprova.';
                    case ErrorType.ERROR_FETCHING_DATA:
                        return 'Errore durante il recupero dei dati. Per favore riprova più tardi.';
                    case ErrorType.USER_ACCESS_NOT_GRANTED:
                        return 'L\'utente non dispone dei diritti utente per accedere al sistema.';
                    case ErrorType.EMAIL_VERIFICATION_FAIL:
                        return 'Si prega di verificare la correttezza dell\'indirizzo e-mail inserito e riprovare.';
                    case ErrorType.EMAIL_VERIFICATION_EMPTY:
                        return 'Indirizzo email mancante. Inserisci una email valida.';
                    case ErrorType.NAME_VERIFICATION_EMPTY:
                        return 'Nome mancante. Inserisci il nome della presentazione desiderato, che sarà privato per te nel tuo profilo personale.';
                    case ErrorType.USER_ALREADY_EXISTS:
                        return 'Esiste già un utente con questo nome utente.';
                    case ErrorType.USER_DOESNT_EXISTS:
                        return 'L\'utente con questo nome utente non esiste!';
                    case ErrorType.SUBSCRIPTION_SUCCESS:
                        return 'Abbonamento riuscito.\n\nVerrai reindirizzato automaticamente alla home page.';
                    case ErrorType.SUBSCRIPTION_INSUFFICIENT_FUNDS:
                        return 'Errore di comunicazione (fondi insufficienti).';
                    case ErrorType.SUBSCRIPTION_FAIL:
                        return 'Errore di comunicazione';
                    case ErrorType.MISSING_DATA:
                        return 'Manjkajoči podatki.. Prosimo preverite in izpolnite vsa manjkajoča polja';
                }
                break;
            case L10N.de.value:
            case L10N.at.value:
                switch (type) {
                    case ErrorType.UNAUTHORIZED:
                        return 'Falscher Benutzername und Passwort. Bitte versuche es erneut.';
                    case ErrorType.ERROR_FETCHING_DATA:
                        return 'Fehler beim Abrufen der Daten. Bitte versuchen Sie es später erneut.';
                    case ErrorType.USER_ACCESS_NOT_GRANTED:
                        return 'Der Benutzer hat keine Benutzerrechte, um auf das System zuzugreifen.';
                    case ErrorType.EMAIL_VERIFICATION_FAIL:
                        return 'Bitte überprüfen Sie die Richtigkeit der eingegebenen E-Mail-Adresse und versuchen Sie es erneut.';
                    case ErrorType.EMAIL_VERIFICATION_EMPTY:
                        return 'Fehlende E-Mail-Adresse. Bitte geben Sie eine gültige Email-Adresse ein.';
                    case ErrorType.NAME_VERIFICATION_EMPTY:
                        return 'Fehlender Name. Bitte tragen Sie in Ihrem persönlichen Profil den gewünschten Präsentationsnamen ein, der Ihnen privat bleibt.';
                    case ErrorType.USER_ALREADY_EXISTS:
                        return 'Ein Benutzer mit diesem Benutzernamen existiert bereits.';
                    case ErrorType.USER_DOESNT_EXISTS:
                        return 'Benutzer mit diesem Benutzernamen existiert nicht!';
                    case ErrorType.SUBSCRIPTION_SUCCESS:
                        return 'Abonnement erfolgreich.\n\nSie werden automatisch auf die Startseite weitergeleitet.';
                    case ErrorType.SUBSCRIPTION_INSUFFICIENT_FUNDS:
                        return 'Kommunikationsfehler (unzureichende Mittel).';
                    case ErrorType.SUBSCRIPTION_FAIL:
                        return 'Kommunikationsfehler';
                    case ErrorType.MISSING_DATA:
                        return 'Manjkajoči podatki.. Prosimo preverite in izpolnite vsa manjkajoča polja';
                }
                break;
            case L10N.en.value:
            case L10N.us.value:
                switch (type) {
                    case ErrorType.UNAUTHORIZED:
                        return 'Incorrect username and password. Please try again.';
                    case ErrorType.ERROR_FETCHING_DATA:
                        return 'Error retrieving data. Please try again later.';
                    case ErrorType.USER_ACCESS_NOT_GRANTED:
                        return 'The user does not have user rights to access the system.';
                    case ErrorType.EMAIL_VERIFICATION_FAIL:
                        return 'Please check the correctness of the e-mail address entered and try again.';
                    case ErrorType.EMAIL_VERIFICATION_EMPTY:
                        return 'Missing email address. Please enter a valid email.';
                    case ErrorType.NAME_VERIFICATION_EMPTY:
                        return 'Missing name. Please enter the desired presentation name, which will be private to you in your personal profile.';
                    case ErrorType.USER_ALREADY_EXISTS:
                        return 'A user with this username already exists.';
                    case ErrorType.USER_DOESNT_EXISTS:
                        return 'User with this username does not exist!';
                    case ErrorType.SUBSCRIPTION_SUCCESS:
                        return 'Subscription successful.\n\nYou will be automatically redirected to the home page.';
                    case ErrorType.SUBSCRIPTION_INSUFFICIENT_FUNDS:
                        return 'Communication error (insufficient funds).';
                    case ErrorType.SUBSCRIPTION_FAIL:
                        return 'Communication error';
                        case ErrorType.MISSING_DATA:
                        return 'Manjkajoči podatki.. Prosimo preverite in izpolnite vsa manjkajoča polja';
                }
                break;
        }

    };

    return (
        <div className={className}>
            <p>{getMessageText(props.type, props.l10n)}</p>
        </div>
    );
}

export default Message;