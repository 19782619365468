import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory} from "react-router-dom";
import {COUNTRIES_ARRAY, L10N, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    getFaqAdmin,
    isRememberMeEnabled,
    loadCredentials,
    loadLocalL10N, setL10N,
    setRememberMe,
    setSignInCredentials, userLogin, userRegister
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    displayAlert,
    isNewObjValidCheck,
    parseSelectedCountryLabel,
    validateEmail,
    validatePhoneNumber
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import Input from "../components/js/Input";

import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import Title from "../components/js/Title";
import Dropdown from "../components/js/Dropdown";
import Footer from "../components/js/Footer";
import CustomMap from "../components/js/CustomMap";
import FooterPage from "../components/js/FooterPage";
import {FAQ} from "./ProviderPage";
import {renderErrorMessage} from "./RegisterProvider";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";
import HeaderLanding from "../components/js/HeaderLanding";

export const CREATE_ACTION = {
    REGISTER_USER: 0,
}

function RegisterUser() {
    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);

    const isRememberMeSet = useSelector(state => state.reducer.rememberMe);
    const savedCredentials = useSelector(state => state.reducer.savedCredentials);

    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [password, setPassword] = useState();

    const [address, setAddress] = useState();
    const [telephone, setTelephone] = useState();
    const [companyName, setCompanyName] = useState();
    const [postAndCity, setPostAndCity] = useState();
    const [country, setCountry] = useState();

    const [_rememberMe, _setRememberMe] = useState();

    const [openedFaq, setOpenedFaq] = useState(false);

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [saveData, setSaveData] = useState(false);
    const [registrationSuccessful, setRegistrationSuccessful] = useState();

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [invalidInputs, setInvalidInputs] = useState([]);

    const [faqs, setFaqs] = useState([]);

    const REGISTER_REQUIRED_FIELDS = {
        'email': L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL,
        'full_name': L10N_APP_TEXTS[selectedLanguage].NAME_SURNAME,
        'tel': L10N_APP_TEXTS[selectedLanguage].TELEPHONE,
        'password': L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_PASSWORD,
    }

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                //transform: saveData ? 'translateY(-100%)' : 'translateY(-100%)',
                opacity: saveData ? 0 : 0,
                bottom: saveData ? '-30px' : '-30px',
                display: saveData ? 'none' : 'none'
            },
            to: {
                //transform: saveData ? 'translateY(0%)' : 'translateY(0%)',
                opacity: saveData ? 1 : 0,
                bottom: saveData ? '0' : '0',
                display: saveData ? 'flex' : 'none'
            },
            config: {duration: 200},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, [])

    useEffect(() => {
        dispatch(loadLocalL10N(true));
    }, []);

    useEffect(() => {
        if (selectedLanguage) {
            dispatch(getFaqAdmin({admin_value: true}, selectedLanguage, (resData) => {
                let faqsUsers = resData.filter(faq => faq.providers_faq === false);

                setFaqs(faqsUsers);
            }));
        }
    }, [selectedLanguage]);

    useEffect(() => {
        if (email && name && password) {
            setSaveData(true);
        } else {
            if (saveData) {
                setSaveData(false);
            }
        }
    }, [email, name, telephone, password]);

    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.REGISTER_USER:
                    onConfirmRegisterUser();
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };
    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }


    const onLogin = () => {
        setLoadingSpinner(true);


        /*
        dispatch(userLogin(email, password, (res) => {
            console.log("RegisterProvider new user successfully!", res);

            onSuccessLogin()

        }, (res) => {
            console.warn("Error logging-in new user..");
            UserAccessNotGranted();

        }, () => {
            onErrorUnauthorized();
        }));

         */

    };

    const onConfirmRegisterUser = () => {
        const onCleanup = () => {
            setTimeout(() => {
                setCreateTrigger(false);
                setInvalidInputs([]);
                setLoadingSpinner(false);
                setSaveData(false);
            }, 500)
        }

        const onSuccessLogin = () => {
            console.log('redirect success');
            setRegistrationSuccessful(true);

            onCleanup();
        };
        const UserAccessNotGranted = (resData) => {
            console.log("Error fetching data");
            if (resData.includes("User already exists")) {
                setError(ErrorType.USER_ALREADY_EXISTS);
            } else {
                setError(ErrorType.USER_ACCESS_NOT_GRANTED);
            }

            onCleanup();
        };
        const onErrorUnauthorized = () => {
            console.log("Unauthorized");
            setError(ErrorType.UNAUTHORIZED);
            setLoadingSpinner(false);

            onCleanup();
        };

        let userData = {
            'email': email,
            'full_name': name,
            'tel': telephone,
            'user_role': USER_ROLES.CUSTOMER,
            'password': password,
            'l10n': selectedLanguage
        }

        // check data validity
        let response = isNewObjValidCheck(userData, REGISTER_REQUIRED_FIELDS);

        if (!response.isValid) {
            displayAlert(response.errorMessages.join('\n'));
            setCreateTrigger(false);
            setInvalidInputs(response.invalidFields);
            setLoadingSpinner(false);
            setSaveData(false);

            return;
        }

        if (!validateEmail(email)) {
            displayAlert(L10N_APP_TEXTS[selectedLanguage].ERROR_EMAIL);
            setCreateTrigger(false);
            setInvalidInputs('email');
            setLoadingSpinner(false);
            setSaveData(false);

            return;
        }
        if (!validatePhoneNumber(telephone)) {
            displayAlert(L10N_APP_TEXTS[selectedLanguage].ERROR_TEL);
            setCreateTrigger(false);
            setInvalidInputs('tel');
            setLoadingSpinner(false);
            setSaveData(false);

            return;
        }
        dispatch(userRegister(userData, selectedLanguage, onSuccessLogin, UserAccessNotGranted, onErrorUnauthorized));
    };

    const renderRememberMeCheckBox = () => {
        return (
            <div className={'checkbox-container remember'} style={{alignSelf: 'baseline'}}>
                <label>
                    <input type="checkbox"
                           checked={_rememberMe}
                           onChange={() => {
                               console.log(!_rememberMe);
                               _setRememberMe(!_rememberMe)
                           }}
                    />
                    {L10N_APP_TEXTS[selectedLanguage].LOGIN_REMEMBER}
                </label>
            </div>
        );
    };

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

   const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

       return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const renderFooter = () => {

        return <div className={'footer-container'}>
            {
                <div className={'footer'}>

                </div>
            }
        </div>
    }


    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}


                <Title dots title={L10N_APP_TEXTS[selectedLanguage].REGISTER_TITLE}/>
                <div className={'form-container row'} style={{marginLeft: '36px'}}>
                    <div className={'form_col'} style={{width: 'fit-content', marginRight: '48px'}}>
                        <Title title={L10N_APP_TEXTS[selectedLanguage].REGISTER}/>

                        <Input onChange={(value) => onInputChange(value, setEmail)}
                               onSubmit={() => {
                               }}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL}
                               label={L10N_APP_TEXTS[selectedLanguage].LOGIN_EMAIL}
                               style={{
                                   border: invalidInputs.includes('email') ? "1px solid red" : "none"
                               }}
                        />
                        <Input onChange={(value) => onInputChange(value, setName)}
                               onSubmit={() => {
                               }}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].NAME_SURNAME}
                               label={L10N_APP_TEXTS[selectedLanguage].REGISTER_NAME}
                               style={{
                                   border: invalidInputs.includes('full_name') ? "1px solid red" : "none"
                               }}
                        />
                        <Input onChange={(value) => onInputChange(value, setTelephone)}
                               onSubmit={() => {
                               }}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].TELEPHONE}
                               label={L10N_APP_TEXTS[selectedLanguage].ENTER_TELEPHONE}
                               style={{
                                   border: invalidInputs.includes('tel') ? "1px solid red" : "none"
                               }}
                        />
                        <Input onChange={(value) => onInputChange(value, setPassword)}
                               password
                               login
                               onSubmit={() => {
                               }}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_PASSWORD}
                               label={L10N_APP_TEXTS[selectedLanguage].LOGIN_PASSWORD}
                               style={{
                                   border: invalidInputs.includes('password') ? "1px solid red" : "none"
                               }}
                        />
                    </div>

                    {renderErrorMessage(error, selectedLanguage)}
                </div>
                <p className={'p-small click'} onClick={() => {
                    onNavigateTo(PAGES.LOGIN)
                }}>{L10N_APP_TEXTS[selectedLanguage].REGISTER_ALREADY_HAVE_ACCOUNT}</p>


            </div>
        )
    }

    const displayCollapsibleFaq = (status, question, answer) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header faq-options'} style={{justifyContent: 'flex-end'}}>
                <div className={'faq-question'}>
                    <p className={'p-title'}>{question}</p>
                    {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
                </div>
                {status ? <div className={'faq-desc'}>
                    <p className={'p-header black'}>{answer}</p>
                </div> : <></>}
            </div>
        );
    }

    const onActionCollapseFaq = (i) => {
        setOpenedFaq(i);
    }

    const renderFaq = () => {
        return (
            <>
                <Title dots title={'FAQs'}/>
                <div className={'faq-container'}>
                    {faqs.map((faq, f) => (
                        <div key={`${f}_${faq.question}`} className={'collapsible-faq-container'}>
                            <Collapsible className={'faq-options'}
                                         transitionTime={400}
                                         onClosing={() => onActionCollapseFaq(false)}
                                         onOpening={() => onActionCollapseFaq(f)}
                                         open={openedFaq === f}
                                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                                         trigger={displayCollapsibleFaq(false, faq.question, faq.answer)}
                                         triggerWhenOpen={displayCollapsibleFaq(true, faq.question, faq.answer)}>
                            </Collapsible>
                        </div>
                    ))}
                </div>
            </>

        );
    }

    const renderMap = () => {
        return (
            <>
                <div style={{padding: '24px 20px'}}>
                    <Title title={L10N_APP_TEXTS[selectedLanguage].REGISTER_PROMO}/>
                </div>
                <CustomMap/>
            </>
        );
    };

    const renderPage = () => {
        return <>
            <div ref={ref}/>
            {user && token ? <Header loggedIn/> : <Header/>}
            <animated.div style={fadeIn} className={'page register'}>
                {renderBody()}
            </animated.div>
            <animated.div style={fadeIn}>
                <div style={{width: 'inherit', padding: '48px 55px'}}>
                    {renderFaq()}
                </div>

            </animated.div>
            <FooterPage/>

            <animated.div style={slideUp}>
                <Footer button={L10N_APP_TEXTS[selectedLanguage].SAVE}
                        onClick={() => triggerAction(CREATE_ACTION.REGISTER_USER)}/>
            </animated.div>
        </>

    }

    if (registrationSuccessful) {
        console.log('redirect');

        onNavigateTo(PAGES.HOME);
        return <></>
    }

    return (
        renderPage()
    );
}

export default RegisterUser;