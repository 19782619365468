import React from 'react';
import '../css/VideoResponsive.css';

const YouTubeEmbed = ({ embedId }) => (
    <div className="video-responsive youtube-embedded-player">
        <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="Embedded youtube"
        />
    </div>
);

export default YouTubeEmbed;
