import '../css/Button.css';


function Button(props) {

    const renderContent = () => {
        return (
            <div className={'custom-button'} style={props.style} onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}>
                {props.text}
            </div>
        );

    }

    return renderContent();
}

export default Button;
