import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory} from "react-router-dom";
import {COUNTRIES_ARRAY, L10N, parseCountryObject, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp, IoIosLock, IoIosUnlock} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    BE_REST_API,
    createCategory, createCourse,
    createFaq,
    deleteFaq, getAdminControlPanelData, getDocData, getFaqAdmin,
    getFaqProvider,
    isRememberMeEnabled, loadAllCategories, loadAllCategoriesParsed, loadAllCourses,
    loadAllCoursesFollowed, loadAllProviders,
    loadAllProvidersFollowed, loadAllUsers, loadCategoryDataById,
    loadCourseById,
    loadCredentials,
    loadImage,
    loadImages,
    loadLocalL10N,
    logoutUser,
    removeFollowerForProvider,
    setL10N,
    setRememberMe,
    setSignInCredentials, updateAdminControlPanelData, updateCategoryDataById,
    updateFaq, updateProviderActivation,
    updateProviderData, updateUserActivation,
    updateUserData,
    updateUserPassword,
    updateUserPasswordByEmail,
    userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    displayAlert,
    displayDate, findItem,
    getIndexOf,
    getNextCourseStartDate, isUserAdmin, isUserCustomer, parseMapCaretChars,
    parseSelectedCountryLabel,
    scrollToDiv
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import Input from "../components/js/Input";
import plusCircleIcon from "../assets/images/plus-circle.png";
import iconPrint from "../assets/images/printer.png";
import iconAcc from "../assets/images/acc.png";
import iconHeart from "../assets/images/heart_blue.png";
import iconMessage from "../assets/images/message-circle.png";
import iconQr from "../assets/images/qr_code.png";

import iconAddress from "../assets/images/i_address.png";
import iconMean from "../assets/images/i_mean.png";
import iconStar from "../assets/images/i_star.png";
import iconSum from "../assets/images/i_sum.png";
import iconNight from "../assets/images/i_night.png";
import courseImg from "../assets/images/course.png";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import Title from "../components/js/Title";
import Footer from "../components/js/Footer";
import {CREATE_ACTION} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import Button from "../components/js/Button";
import DropdownCircle from "../components/js/DropdownCircle";
import FooterPage from "../components/js/FooterPage";
import GoogleMap from "../components/js/GoogleMap";
import Filters from "../components/js/Filters";
import Search from "../components/js/Search";
import HeaderLanding from "../components/js/HeaderLanding";
import CustomMap from "../components/js/CustomMap";
import iconCalendar from "../assets/images/calendar.png";
import iconInfo from "../assets/images/info.png";
import plusBlack from "../assets/images/plus-black.png";
import Dropdown from "../components/js/Dropdown";
import iconPlus from "../assets/images/plus.png";
import iconCategory from "../assets/images/kategorija.png";
import ScrollableTabsAdmin from "../components/js/ScrollableTabsAdmin";
import UploadFile from "../components/js/UploadFile";
import axios from "axios";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";
import RTEditor from "../components/js/RTEditor";

const USER = {
    user_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    full_name: 'Tadej Hiti',
    user_role: USER_ROLES.CUSTOMER,
    password: 'lulček#%hashed',
    email: 'hiti.tadej@gmail.com',
    tel: '+38640306677',
    address: 'Ograde 3',
    l10n_default: L10N.si.value,
    l10n_en: false,
    l10n_si: false,
    l10n_it: false,
};

function AdminControlPanel() {
    let dispatch = useDispatch();

    const admin = useSelector(state => state.reducer.admin);
    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);
    const categories = useSelector(state => state.reducer.categories);

    const allProviders = useSelector(state => state.reducer.providers);
    const allUsers = useSelector(state => state.reducer.users);

    const courses_followed = useSelector(state => state.reducer.courses_followed);
    const providers_followed = useSelector(state => state.reducer.providers_followed);

    const [fullName, setFullName] = useState(user.full_name);
    const [address, setAddress] = useState(user.address);
    const [tel, setTel] = useState(user.tel);
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [saveData, setSaveData] = useState(false);

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [faqs, setFaqs] = useState([]);
    const [faqsProviders, setFaqsProviders] = useState([]);

    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);

    const [controlPanelData, setControlPanelData] = useState(null);

    const [promoUser, setPromoUser] = useState(null);
    const [descShortUser, setDescShortUser] = useState(null);
    const [descLongUser, setDescLongUser] = useState(null);

    const [promoProvider, setPromoProvider] = useState(null);
    const [descShortProvider, setDescShortProvider] = useState(null);
    const [descLongProvider, setDescLongProvider] = useState(null);

    const [RTProvider, setRTProvider] = useState(null);
    const [RTUser, setRTUser] = useState(null);


    const [parsedUsers, setParsedUsers] = useState(allUsers && allUsers.length > 0 ? allUsers : []);
    const [parsedProviders, setParsedProviders] = useState(allProviders && allProviders.length > 0 ? allProviders : []);


    const [allCategories, setAllCategories] = useState(categories);
    const [allCategoriesList, setAllCategoriesList] = useState([]);

    const [category_EN, setCategory_EN] = useState(null);
    const [category_SI, setCategory_SI] = useState(null);
    const [category_IT, setCategory_IT] = useState(null);
    const [category_DE, setCategory_DE] = useState(null);
    const [category_HR, setCategory_HR] = useState(null);

    const [categoryState, setCategoryState] = useState(null);
    const [category, setCategory] = useState(null);

    const [termsDoc, setTermsDoc] = useState(null);

    console.log(category_SI, category_EN, category_IT)


    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                opacity: saveData ? 0 : 0,
                bottom: saveData ? '-30px' : '-30px',
                display: saveData ? 'none' : 'none'
            },
            to: {
                opacity: saveData ? 1 : 0,
                bottom: saveData ? '0' : '0',
                display: saveData ? 'flex' : 'none'
            },
            config: {duration: 200},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    useEffect(() => {
        if (categoryState === "edit") {

        } else if (categoryState === "create") {

        }
    }, [categoryState])

    useEffect(() => {
        if (category && categoryState === "edit") {
            /*
            dispatch(loadCategoryDataById({category_id: category.category_id}, selectedLanguage, (resData) => {
                setCategory(resData)
            }, () => {
            }))

             */
        } else if (categoryState === "create") {

        }

    }, [category])

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);
    }, [l10n, selectedLanguage]);

    useEffect(() => {
        if (admin) {
            dispatch(loadAllUsers(admin.user_id, (res) => {
               setParsedUsers(filterCustomers(res));
            }, (res) => {
            }));
            dispatch(loadAllProviders((res) => {
            }, (res) => {
            }));

            dispatch(loadAllCategoriesParsed((res) => {
                setAllCategories(res);
            }, (res) => {
            }));
        }
    }, [user]);

    useEffect(() => {
        if (admin && selectedLanguage) {

            dispatch(getFaqAdmin({user_id: admin.user_id, admin_value: true}, selectedLanguage, (resData) => {
                let faqsUsers = resData.filter(faq => faq.providers_faq === false);
                let faqsProviders = resData.filter(faq => faq.providers_faq === true);

                setFaqs(faqsUsers);
                setFaqsProviders(faqsProviders);

            }));
            dispatch(getAdminControlPanelData({user_id: admin.user_id}, selectedLanguage, (resData) => {
                setPromoUser(resData.promo_user)
                setPromoProvider(resData.promo_provider)
                setDescShortUser(resData.desc_short_user)
                setDescShortProvider(resData.desc_short_provider)
                setDescLongUser(resData.desc_long_user)
                setDescLongProvider(resData.desc_long_provider)
                setRTUser(resData.rt_user)
                setRTProvider(resData.rt_provider)
            }))
            dispatch(loadAllCategories((res) => {
                setAllCategoriesList(res);
            }, (res) => {
            }));
        }

    }, [admin, selectedLanguage]);

    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.UPDATE_DATA:
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };

    const filterCustomers = (list) => {
        if (!list || list?.length === 0) {
            return [];
        }
        return list.filter(isUserCustomer);
    };

    const renderUserActivation = (item, status) => {

        let arrowStyle = {width: '20px', height: '20px', color: 'red', opacity: 0.38};
        return (
            <div className={'click-light'} style={{justifyContent: 'flex-start', marginRight: '22px'}} onClick={() => {
                dispatch(updateProviderActivation(item.provider_id, !status, () => {
                    dispatch(loadAllProviders((res) => {
                    }, (res) => {
                    }));
                }, () => {
                }));
            }}>
                {status ? <IoIosLock style={arrowStyle}/> : <IoIosUnlock style={arrowStyle}/>}
            </div>
        );
    }

    const updateAdminControlPanelDescriptions = (key, value) => {
        let updateData =
            {
                promo_user: promoUser,
                desc_short_user: descShortUser,
                desc_long_user: descLongUser,
                promo_provider: promoProvider,
                desc_short_provider: descShortProvider,
                desc_long_provider: descLongProvider,
                rt_provider: RTProvider,
                rt_user: RTUser,
                user_id: admin.user_id
            };

        updateData[key] = value;
        dispatch(updateAdminControlPanelData(updateData, selectedLanguage, (resData) => {
            setPromoUser(resData.promo_user)
            setPromoProvider(resData.promo_provider)
            setDescShortUser(resData.desc_short_user)
            setDescShortProvider(resData.desc_short_provider)
            setDescLongUser(resData.desc_long_user)
            setDescLongProvider(resData.desc_long_provider)
            setRTUser(resData.rt_user)
            setRTProvider(resData.rt_provider)
        }));
    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.MISSING_DATA:
                return <Message error l10n={selectedLanguage} type={ErrorType.MISSING_DATA}/>
            case ErrorType.UNAUTHORIZED:
                return <Message error l10n={selectedLanguage} type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

        return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const renderPageInfo = () => {
        return <p className={'p-pageinfo'}>
            {L10N_APP_TEXTS[selectedLanguage].HOME} > <span>{L10N_APP_TEXTS[selectedLanguage].ADMIN_PROFILE}</span>
        </p>
    }

    const renderControlPanelTextInputs = () => {
        return (
            <div className={'course-edit provider-edit tab_admin_presentation_page'}>

                <Title dots
                       title={L10N_APP_TEXTS[selectedLanguage].TAB_ADMIN_1 + L10N_APP_TEXTS[selectedLanguage].FOR_USER}/>
                <div className={'form-container'} style={{marginBottom: '0'}}>
                    <div className={'form_col form-row'}>
                        <Input button={'edit'}
                               value={promoUser}
                               onChange={(value) => onInputChange(value, setPromoUser)}
                               onSubmit={() => updateAdminControlPanelDescriptions("promo_user", promoUser)}
                               onAction={() => updateAdminControlPanelDescriptions("promo_user", promoUser)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PROMO_TEXT}
                               label={`${L10N_APP_TEXTS[selectedLanguage].PROMO_TEXT}:`}
                               style={{marginBottom: '0px'}}/>

                        <Input button={'edit'}
                               textarea
                               value={descShortUser}
                               onChange={(value) => onInputChange(value, setDescShortUser)}
                               onSubmit={() => updateAdminControlPanelDescriptions("desc_short_user", descShortUser)}
                               onAction={() => updateAdminControlPanelDescriptions("desc_short_user", descShortUser)}
                               label={`${L10N_APP_TEXTS[selectedLanguage].SHORT_DESC}:`}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].SHORT_DESC}
                               style={{marginBottom: '0px', width: '480px'}}/>
                        <Input button={'edit'}
                               textarea
                               value={descLongUser}
                               onChange={(value) => onInputChange(value, setDescLongUser)}
                               onSubmit={() => updateAdminControlPanelDescriptions("desc_long_user", descLongUser)}
                               onAction={() => updateAdminControlPanelDescriptions("desc_long_user", descLongUser)}
                               label={`${L10N_APP_TEXTS[selectedLanguage].LONG_DESC}:`}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].LONG_DESC}
                               style={{marginBottom: '0px', width: '480px'}}/>

                        <div style={{paddingTop: '24px'}}/>


                    </div>
                </div>
                {renderErrorMessage()}

                <RTEditor title={"Oblikovni tekst za tečajnike"} text={RTUser} onSubmit={(html) => {
                    updateAdminControlPanelDescriptions("rt_user", html)
                }}/>

                <Title dots
                       title={L10N_APP_TEXTS[selectedLanguage].TAB_ADMIN_1 + L10N_APP_TEXTS[selectedLanguage].FOR_PROVIDER}/>
                <div className={'form-container'} style={{marginBottom: '0'}}>
                    <div className={'form_col form-row'}>
                        <Input button={'edit'}
                               value={promoProvider}
                               onChange={(value) => onInputChange(value, setPromoProvider)}
                               onSubmit={() => updateAdminControlPanelDescriptions("promo_provider", promoProvider)}
                               onAction={() => updateAdminControlPanelDescriptions("promo_provider", promoProvider)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PROMO_TEXT}
                               label={`${L10N_APP_TEXTS[selectedLanguage].PROMO_TEXT}:`}
                               style={{marginBottom: '0px'}}/>

                        <Input button={'edit'}
                               textarea
                               value={descShortProvider}
                               onChange={(value) => onInputChange(value, setDescShortProvider)}
                               onSubmit={() => updateAdminControlPanelDescriptions("desc_short_provider", descShortProvider)}
                               onAction={() => updateAdminControlPanelDescriptions("desc_short_provider", descShortProvider)}
                               label={`${L10N_APP_TEXTS[selectedLanguage].SHORT_DESC}:`}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].SHORT_DESC}
                               style={{marginBottom: '0px', width: '480px'}}/>
                        <Input button={'edit'}
                               textarea
                               value={descLongProvider}
                               onChange={(value) => onInputChange(value, setDescLongProvider)}
                               onSubmit={() => updateAdminControlPanelDescriptions("desc_long_provider", descLongProvider)}
                               onAction={() => updateAdminControlPanelDescriptions("desc_long_provider", descLongProvider)}
                               label={`${L10N_APP_TEXTS[selectedLanguage].LONG_DESC}:`}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].LONG_DESC}
                               style={{marginBottom: '0px', width: '480px'}}/>

                        <div style={{paddingTop: '24px'}}/>

                    </div>
                </div>
                {renderErrorMessage()}

                <RTEditor title={"Oblikovni tekst za izvajalce tečajev"} text={RTProvider} onSubmit={(html) => {
                    updateAdminControlPanelDescriptions("rt_provider", html)
                }}/>

            </div>
        );
    }


    function UserItem(props) {
        // console.log(props.value);
        const item = props.value;

        if (!isUserCustomer(item)) {
            return <></>
        }

        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row-date'}>{parseMapCaretChars(item.full_name)}</div>
                <div className={'row-date'}>{parseMapCaretChars(item.email)}</div>
            </li>
        );
    }

    function renderUsersActive(items) {
        const header = () => {
            return (
                <div className={'row dynamic-header'}>
                    <div className={'row-date'}>{L10N_APP_TEXTS[selectedLanguage].USER}</div>
                    <div className={'row-date'}>{L10N_APP_TEXTS[selectedLanguage].EMAIL}</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list dynamic'}>
                    {items.map((item, index) => <UserItem key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    function ProviderItem(props) {
        // console.log(props.value);
        const item = props.value;

        return (
            <li onClick={() => {
            }} className={'row'}>
                <div className={'row'} style={{opacity: item.active ? 1 : 0.4}}>
                    {renderUserActivation(item, item.active)}
                    <div className={'row-date click-light row'} onClick={() => {
                        onNavigateTo(PAGES.PROVIDER + ":" + item?.provider_id);
                    }}>
                        {parseMapCaretChars(item?.name)}
                    </div>
                </div>


                <div className={'row-date'}>{parseMapCaretChars(item?.address + ", " + item?.post)}</div>
                <div className={'row-date'}>{parseMapCaretChars(item?.email)}</div>
            </li>
        );
    }

    function renderProvidersActive(items) {
        const header = () => {
            return (
                <div className={'row dynamic-header'}>
                    <div className={'row-date'}>{L10N_APP_TEXTS[selectedLanguage].PROVIDER_LIST}</div>
                    <div className={'row-date'}>{L10N_APP_TEXTS[selectedLanguage].PROVIDER_ADDRESS}</div>
                    <div className={'row-date'}>{L10N_APP_TEXTS[selectedLanguage].EMAIL}</div>
                </div>
            );
        };
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <ul className={'item-list dynamic'}>
                    {items.map((item, index) => <ProviderItem key={index} value={item}/>)}
                </ul>
            );
        }
        return (
            <>
                {header()}
                {list(items)}
            </>
        );
    }

    const renderLists = () => {
        return (
            <div className={'list-container tab_admin_lists'}>


                <div className={'container-column-2'}>

                    <div className={'column-2'}>
                        <Title dots
                               title={L10N_APP_TEXTS[selectedLanguage].PROVIDERS_LIST}/>
                        {renderProvidersActive(allProviders)}
                        <p className={'p-info'} style={{
                            paddingTop: '12px',
                            paddingBottom: '24px'
                        }}>{L10N_APP_TEXTS[selectedLanguage].PROVIDERS_TOTAL + ": " + Number(allProviders?.length)} </p>
                    </div>
                    <div className={'column-2'}>
                        <Title dots
                               title={L10N_APP_TEXTS[selectedLanguage].USERS_LIST}/>
                        {renderUsersActive(allUsers)}
                        <p className={'p-info'} style={{
                            paddingTop: '12px',
                            paddingBottom: '24px'
                        }}>{L10N_APP_TEXTS[selectedLanguage].USERS_TOTAL + ": " + Number(parsedUsers?.length)} </p>
                    </div>
                </div>
            </div>
        )
    }

    const onUploadFile = async (file, doc_type, l10n) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("doc_type", doc_type);
        formData.append("l10n", l10n);

        try {
            const res = await axios.post(
                BE_REST_API + '/cpanel/doc/create',
                formData
            );

            console.log("FILE UPLOAD [RESPONSE]", res);
            displayAlert(`Datoteka uspešno naložena!`);
        } catch (e) {
            console.log(e);
            displayAlert(`Napaka pri nalaganju:`, e);
        }
    };

    const onPreviewFile = async (doc_type, l10n) => {
        dispatch(getDocData(doc_type, l10n, (res) => {
            // Set the data URI state with the Base64-encoded PDF data

            /*
            console.log(res)

            // Open the PDF in a new tab using an <a> tag
            const link = document.createElement('a');
            link.href = res;
            link.target = '_blank';
            link.click();

             */

            let byteCharacters = atob(res.data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], {type: 'application/pdf;base64'});
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }, (res) => {
        }));
    };

    const renderUploadDocuments = () => {

        return (<>
            <Title dots
                   title={L10N_APP_TEXTS[selectedLanguage].UPLOAD_DOCUMENTS}/>

            <div className={"row docs"}>
                <div className={"col"}>
                    <p>{L10N_APP_TEXTS[selectedLanguage].TERMS_OF_USE}</p>
                    <UploadFile
                        style={{display: 'flex'}}
                        handleFile={(file) => {
                            onUploadFile(file, "TERMS_OF_USE", selectedLanguage).then(() => {
                                console.log("done uploading:", file.name);
                            })
                        }}/>
                    <p className={'p-info p-doc'}
                       onClick={() => onPreviewFile("TERMS_OF_USE", selectedLanguage)}>{L10N_APP_TEXTS[selectedLanguage].PREVIEW_DOC}</p>
                </div>
                <div className={"col"}>
                    <p>{L10N_APP_TEXTS[selectedLanguage].PRIVACY_POLICY}</p>
                    <UploadFile
                        style={{display: 'flex'}}
                        handleFile={(file) => {
                            onUploadFile(file, "PRIVACY_POLICY", selectedLanguage).then(() => {
                                console.log("done uploading:", file.name);
                            })
                        }}/>
                    <p className={'p-info p-doc'}
                       onClick={() => onPreviewFile("PRIVACY_POLICY", selectedLanguage)}>{L10N_APP_TEXTS[selectedLanguage].PREVIEW_DOC}</p>
                </div>
                <div className={"col"}>
                    <p>{L10N_APP_TEXTS[selectedLanguage].COOKIES}</p>
                    <UploadFile
                        style={{display: 'flex'}}
                        handleFile={(file) => {
                            onUploadFile(file, "COOKIES", selectedLanguage).then(() => {
                                console.log("done uploading:", file.name);
                            })
                        }}/>
                    <p className={'p-info p-doc'}
                       onClick={() => onPreviewFile("COOKIES", selectedLanguage)}>{L10N_APP_TEXTS[selectedLanguage].PREVIEW_DOC}</p>
                </div>

                <div className={"col"}>
                    <p style={{paddingRight: '24px'}}>{L10N_APP_TEXTS[selectedLanguage].PRIVACY_POLICY_PROVIDERS}</p>
                    <UploadFile
                        style={{display: 'flex'}}
                        handleFile={(file) => {
                            onUploadFile(file, "TERMS_OF_USE_PROVIDERS", selectedLanguage).then(() => {
                                console.log("done uploading:", file.name);
                            })
                        }}/>
                    <p className={'p-info p-doc'}
                       onClick={() => onPreviewFile("TERMS_OF_USE_PROVIDERS", selectedLanguage)}>{L10N_APP_TEXTS[selectedLanguage].PREVIEW_DOC}</p>
                </div>
                <div className={"col"}>
                    <p>{L10N_APP_TEXTS[selectedLanguage].PRIVACY_POLICY_USERS}</p>
                    <UploadFile
                        style={{display: 'flex'}}
                        handleFile={(file) => {
                            onUploadFile(file, "TERMS_OF_USE_USERS", selectedLanguage).then(() => {
                                console.log("done uploading:", file.name);
                            })
                        }}/>
                    <p className={'p-info p-doc'}
                       onClick={() => onPreviewFile("TERMS_OF_USE_USERS", selectedLanguage)}>{L10N_APP_TEXTS[selectedLanguage].PREVIEW_DOC}</p>
                </div>
            </div>
        </>)
    }

    const renderBody = () => {
        return (
            <div className={'flex-page-container admin-panel'} style={{width: 'inherit'}}>
                {renderL10NOptions()}
                {renderPageInfo()}

                <ScrollableTabsAdmin/>
                <Title title={fullName}/>

                {renderControlPanelTextInputs()}
                {renderFaqEdit()}
                {renderFaqEditProviders()}
                {renderMap()}
                {renderAddNewOrEditCategory()}
                {renderCategories()}
                {renderMyPersonalData()}
                {renderLists()}
                {renderUploadDocuments()}
            </div>
        )
    }

    const renderMap = () => {
        return (
            <>
                <div className={'tab_map'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_ADMIN_3}/>
                <CustomMap/>
            </>
        );
    };

    const updateUserDataHandler = (key, value) => {
        let userData = user;
        userData[key] = value;
        dispatch(updateUserData(userData, selectedLanguage, () => {
        }));
    };


    const updateUserPasswordHandler = (key, value) => {
        let userData = user;
        userData[key] = value;
        dispatch(updateUserPassword(userData, selectedLanguage, () => {
            dispatch(logoutUser(() => {
                displayAlert(L10N_APP_TEXTS[selectedLanguage].PASSWORD_RESET_SUCCESS)
                onNavigateTo(PAGES.LOGIN);
            }))
        }));
    };


    const addNewCategory = () => {
        if (!category_SI || !category_EN || !category_IT || category_SI === "" || category_SI === "" || category_SI === "") {
            setError(ErrorType.MISSING_DATA);
            return;
        }

        let data = {
            user_id: user.user_id,
            name_default: category_SI.toString().toLowerCase().replace(/\s+/g, '_'),
            name_si: category_SI,
            name_en: category_EN,
            name_it: category_IT,
            name_hr: category_HR,
            name_de: category_DE,
            parent_id: category ? category?.category_id : null
        };
        dispatch(createCategory(data, () => {
            setCategory(null)
            setCategory_EN("");
            setCategory_SI("");
            setCategory_IT("");
            setCategory_HR("");
            setCategory_DE("");
            setCategoryState(null)
            dispatch(loadAllCategoriesParsed((res) => {
                setAllCategories(res);
            }, (res) => {
            }));
            dispatch(loadAllCategories((res) => {
                setAllCategoriesList(res);
            }, (res) => {
            }));
        }));
    };

    const updateSelectedCategory = () => {
        if (!category_SI || !category_EN || !category_IT || !category_DE || !category_HR || category_SI === "" || category_EN === "" || category_IT === "" || category_HR === "" || category_DE === "") {
            setError(ErrorType.MISSING_DATA);
            return;
        }

        let data = category;
        category.user_id = user.user_id;
        category.name_si = category_SI;
        category.name_en = category_EN;
        category.name_it = category_IT;
        category.name_hr = category_HR;
        category.name_de = category_DE;

        dispatch(updateCategoryDataById(data, (categoryUpdated) => {
            setCategory(categoryUpdated);

            dispatch(loadAllCategoriesParsed((res) => {
                setAllCategories(res);
            }, (res) => {
            }));
        }));
    };


    const renderMyPersonalData = () => {
        return (
            <>
                <div className={'tab_personal_data'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_PERSONAL_DATA}/>
                <div className={'form-container'} style={{marginBottom: '0'}}>
                    <div className={'form_col form-row'}>
                        <Input value={fullName}
                               button={'edit'}
                               onChange={(value) => onInputChange(value, setFullName)}
                               onSubmit={() => updateUserDataHandler("full_name", fullName)}
                               onAction={() => updateUserDataHandler("full_name", fullName)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].NAME_SURNAME}
                               label={`${L10N_APP_TEXTS[selectedLanguage].NAME_SURNAME}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input button={'edit'}
                               value={address}
                               onChange={(value) => onInputChange(value, setAddress)}
                               onSubmit={() => updateUserDataHandler("address", address)}
                               onAction={() => updateUserDataHandler("address", address)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].ADDRESS}
                               label={`${L10N_APP_TEXTS[selectedLanguage].ADDRESS}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input button={'edit'}
                               value={tel}
                               onChange={(value) => onInputChange(value, setTel)}
                               onSubmit={() => updateUserDataHandler("tel", tel)}
                               onAction={() => updateUserDataHandler("tel", tel)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].TELEPHONE}
                               label={`${L10N_APP_TEXTS[selectedLanguage].TELEPHONE}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input email={email}
                               value={email}
                               button={'edit'}
                               onChange={(value) => onInputChange(value, setEmail)}
                               onSubmit={() => updateUserDataHandler("email", email)}
                               onAction={() => updateUserDataHandler("email", email)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL}
                               label={`${L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input password={password}
                               button={'edit'}
                               onChange={(value) => onInputChange(value, setPassword)}
                               onSubmit={() => updateUserPasswordHandler("password", password)}
                               onAction={() => updateUserPasswordHandler("password", password)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_PASSWORD}
                               label={`${L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_PASSWORD}:`}
                               style={{marginBottom: '0px'}}/>

                    </div>
                </div>
                {renderErrorMessage()}
            </>
        );
    };

    const addCategoryButton = () => {
        let className = 'add-course-card';
        className += categoryState === "create" ? " selected" : "";
        return (
            <>
                <div className={className}>
                    <div className={'add-course-card-top'}>
                        <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                    </div>
                    <div className={'add-course-card-bottom'}>
                        <div className={'image-click click'} onClick={() => {
                            setCategoryState(categoryState === "create" ? null : "create");
                            setCategory(null)
                            setCategory_EN("");
                            setCategory_SI("");
                            setCategory_IT("");
                            setCategory_DE("");
                            setCategory_HR("");
                        }}>
                            <img src={iconPlus} className={'icon-label'} alt={'img'} width={'120px'}/>
                        </div>
                    </div>
                    <p className={'p-info'}>{L10N_APP_TEXTS[selectedLanguage].ADD_INFO}</p>
                </div>

            </>
        );
    }

    const editCategoryButton = () => {
        let className = 'add-course-card';
        className += categoryState === "edit" ? " selected" : "";
        return (
            <>
                <div className={className}>
                    <div className={'add-course-card-top'}>
                        <p className={'p-title'}
                           style={{textTransform: 'capitalize'}}>{L10N_APP_TEXTS[selectedLanguage].EDIT}</p>
                    </div>
                    <div className={'add-course-card-bottom'}>
                        <div className={'image-click click'} onClick={() => {
                            setCategoryState(categoryState === "edit" ? null : "edit");

                            setCategory_EN("");
                            setCategory_SI("");
                            setCategory_IT("");
                            setCategory_DE("");
                            setCategory_HR("");
                            setCategory_DE("");
                            setCategory_HR("");
                        }}>
                            <img src={iconCategory} className={'icon-label'} alt={'img'} width={'120px'}/>
                        </div>

                    </div>
                    <p className={'p-info'}>{L10N_APP_TEXTS[selectedLanguage].EDIT_INFO}</p>
                </div>
            </>
        );
    }

    const renderAddNewOrEditCategory = () => {
        return (
            <>
                <div className={'tab_category_add'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].EDIT_CATEGORIES}/>

                <div className={'admin-category-edit row'}>
                    {addCategoryButton()}
                    {editCategoryButton()}
                </div>

                {!categoryState ? <></> :

                    <>
                        {
                            categoryState !== "edit" ? <></> :
                                <div className={'custom-dropdown'}>
                                    <Dropdown grey
                                              categories
                                              style={{marginBottom: '60px'}}
                                              label={`${L10N_APP_TEXTS[selectedLanguage].FILTER_CATEGORY}:`}
                                              value={category}
                                              onChange={(value) => {
                                                  setCategory(value)
                                                  dispatch(loadCategoryDataById({category_id: value.category_id}, selectedLanguage, (resData) => {
                                                      setCategory(resData);
                                                      setCategory_EN(resData.name_en);
                                                      setCategory_SI(resData.name_si);
                                                      setCategory_IT(resData.name_it);
                                                      setCategory_DE(resData.name_de);
                                                      setCategory_HR(resData.name_hr);
                                                  }, () => {
                                                  }))
                                              }}
                                              onSubmit={(value) => {
                                                  setCategory(value)

                                                  dispatch(loadCategoryDataById({category_id: value.category_id}, selectedLanguage, (resData) => {
                                                      setCategory(resData)
                                                      setCategory_EN(resData.name_en);
                                                      setCategory_SI(resData.name_si);
                                                      setCategory_IT(resData.name_it);
                                                      setCategory_DE(resData.name_de);
                                                      setCategory_HR(resData.name_hr);
                                                  }, () => {
                                                  }))
                                              }
                                              }
                                              options={allCategoriesList}
                                              placeholder={selectedLanguage === L10N.si.value ? category?.name_si : selectedLanguage === L10N.en.value ? category?.name_en : selectedLanguage === L10N.it.value ? category?.name_de : selectedLanguage === L10N.hr.value ? category?.name_hr : L10N_APP_TEXTS[selectedLanguage].FILTER_CATEGORY}/>
                                </div>
                        }
                        {
                            categoryState !== "create" ? <></> :
                                <div className={'custom-dropdown'}>
                                    <Dropdown grey
                                              categories
                                              style={{marginBottom: '60px'}}
                                              label={`${L10N_APP_TEXTS[selectedLanguage].FILTER_CATEGORY_PARENT}:`}
                                              value={category}
                                              onChange={(value) => {
                                                  setCategory(value)
                                                  dispatch(loadCategoryDataById({category_id: value.category_id}, selectedLanguage, (resData) => {
                                                      setCategory(resData);

                                                  }, () => {
                                                  }))
                                                  setCategory_EN("");
                                                  setCategory_SI("");
                                                  setCategory_IT("");
                                                  setCategory_HR("");
                                                  setCategory_DE("");
                                              }}
                                              onSubmit={(value) => {
                                                  setCategory(value)
                                                  dispatch(loadCategoryDataById({category_id: value.category_id}, selectedLanguage, (resData) => {
                                                      setCategory(resData)
                                                  }, () => {
                                                  }))
                                                  setCategory_EN("");
                                                  setCategory_SI("");
                                                  setCategory_IT("");
                                                  setCategory_HR("");
                                                  setCategory_DE("");
                                              }
                                              }
                                              options={allCategoriesList.filter(category => category.parent_id === null)}
                                              placeholder={selectedLanguage === L10N.si.value ? category?.name_si : selectedLanguage === L10N.en.value ? category?.name_en : selectedLanguage === L10N.it.value ? category?.name_de : selectedLanguage === L10N.hr.value ? category?.name_hr : L10N_APP_TEXTS[selectedLanguage].FILTER_CATEGORY}/>
                                </div>
                        }

                        <div className={'form-container admin-category'} style={{marginBottom: '48px'}}>
                            <div className={'form_col form-row'}>
                                <>
                                    <div
                                        className={'country-label-container'}
                                        style={{
                                            alignSelf: 'flex-start',
                                            paddingLeft: '22px'
                                        }}>
                                        <img src={countrySI} alt={L10N.si.value} width={'27px'}/>
                                    </div>
                                    <Input value={category_SI}
                                           button={'edit'}
                                           onChange={(value) => onInputChange(value, setCategory_SI)}
                                           onSubmit={(value) => onInputChange(value, setCategory_SI)}
                                           onAction={(value) => onInputChange(value, setCategory_SI)}
                                           placeholder={L10N_APP_TEXTS.SI.NAME}
                                           label={``}
                                           style={{marginBottom: '0px'}}/>


                                    <div
                                        className={'country-label-container'}
                                        style={{
                                            alignSelf: 'flex-start',
                                            paddingLeft: '22px'
                                        }}>
                                        <img src={countryEN} alt={L10N.en.value} width={'27px'}/>
                                    </div>
                                    <Input value={category_EN}
                                           button={'edit'}
                                           onChange={(value) => onInputChange(value, setCategory_EN)}
                                           onSubmit={(value) => onInputChange(value, setCategory_EN)}
                                           onAction={(value) => onInputChange(value, setCategory_EN)}
                                           placeholder={L10N_APP_TEXTS.EN.NAME}
                                           label={``}
                                           style={{marginBottom: '0px'}}/>
                                    <div
                                        className={'country-label-container'}
                                        style={{
                                            alignSelf: 'flex-start',
                                            paddingLeft: '22px'
                                        }}>
                                        <img src={countryIT} alt={L10N.it.value} width={'27px'}/>
                                    </div>
                                    <Input value={category_IT}
                                           button={'edit'}
                                           onChange={(value) => onInputChange(value, setCategory_IT)}
                                           onSubmit={(value) => onInputChange(value, setCategory_IT)}
                                           onAction={(value) => onInputChange(value, setCategory_IT)}
                                           placeholder={L10N_APP_TEXTS.IT.NAME}
                                           label={``}
                                           style={{marginBottom: '0px'}}/>

                                    <div
                                        className={'country-label-container'}
                                        style={{
                                            alignSelf: 'flex-start',
                                            paddingLeft: '22px'
                                        }}>
                                        <img src={countryHR} alt={L10N.hr.value} width={'27px'}/>
                                    </div>
                                    <Input value={category_HR}
                                           button={'edit'}
                                           onChange={(value) => onInputChange(value, setCategory_HR)}
                                           onSubmit={(value) => onInputChange(value, setCategory_HR)}
                                           onAction={(value) => onInputChange(value, setCategory_HR)}
                                           placeholder={L10N_APP_TEXTS.HR.NAME}
                                           label={``}
                                           style={{marginBottom: '0px'}}/>

                                    <div
                                        className={'country-label-container'}
                                        style={{
                                            alignSelf: 'flex-start',
                                            paddingLeft: '22px'
                                        }}>
                                        <img src={countryDE} alt={L10N.de.value} width={'27px'}/>
                                    </div>
                                    <Input value={category_DE}
                                           button={'edit'}
                                           onChange={(value) => onInputChange(value, setCategory_DE)}
                                           onSubmit={(value) => onInputChange(value, setCategory_DE)}
                                           onAction={(value) => onInputChange(value, setCategory_DE)}
                                           placeholder={L10N_APP_TEXTS.DE.NAME}
                                           label={``}
                                           style={{marginBottom: '0px'}}/>


                                    <>
                                        {(!category_SI || !category_EN || !category_IT || !category_DE || !category_HR || category_SI === "" || category_EN === "" || category_IT === "" || category_HR === "" || category_DE === "") ? <></> :
                                            <Button
                                                text={categoryState === "create" ? L10N_APP_TEXTS[selectedLanguage].SUBMIT : L10N_APP_TEXTS[selectedLanguage].UPDATE}
                                                onClick={() => {
                                                    categoryState === "create" ? addNewCategory() : updateSelectedCategory()
                                                }}/>
                                        }
                                    </>

                                </>
                            </div>
                        </div>

                    </>
                }
                {renderErrorMessage()}
            </>
        );
    };


    const updateFaqQuestion = (array, setAction, item, i, value) => {
        let updated = [...array];
        updated[i].question = value;

        let u = updated[i];
        u.user_id = user.user_id;

        dispatch(updateFaq(u, selectedLanguage, (resData) => {
            updated[i] = resData;
            //setFaqs(updated);
            setAction(updated);
        }));
    }

    const updateFaqAnswer = (array, setAction, item, i, value) => {
        let updated = [...array];
        updated[i].answer = value;

        console.log(updated[i], value)

        let u = updated[i];
        u.user_id = user.user_id;

        dispatch(updateFaq(u, selectedLanguage, (resData) => {
            updated[i] = resData;
            setAction(updated);
        }));
    }

    const onRemoveFAQPair = (array, setAction, index) => {
        let updated = [...array];

        let u = updated[index]
        u.user_id = user.user_id;

        dispatch(deleteFaq(u, selectedLanguage, (resData) => {
            updated.splice(index, 1);
            setAction(updated);
        }));
    }

    const onAddNewFaq = (array, setAction, providers_faq) => {
        let updated = [...array];
        let u = {
            question: null, answer: null,
            provider_id: null,
            user_id: user.user_id,
            admin_user_id: user.user_id,
            admin_value: true,
            providers_faq: providers_faq
        }
        dispatch(createFaq(u, selectedLanguage, (resData) => {
            updated.push(resData);
            setAction(updated);
        }))
    }

    const renderFaqEdit = () => {
        return (
            <>
                <div className={'tab_faq tab_admin_faq'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_ADMIN_2}/>
                <div className={'faq-container'}>
                    <p className={'p-text'} style={{fontWeight: 500}}>
                        {L10N_APP_TEXTS[selectedLanguage].USERS_FAQ}
                    </p>
                    <br/>
                    <div className={'row'} style={{paddingBottom: '36px'}}>
                        <p className={'p-text'}>
                            {L10N_APP_TEXTS[selectedLanguage].FAQ_INFO}
                        </p>
                        <img src={iconInfo} className={'icon-label'} alt={'img'} width={'16px'}
                             style={{paddingLeft: '12px'}}/>
                    </div>

                    {faqs.map((faq, f) => (
                        <div key={`${f}_${faq.question}`} className={'collapsible-faq-container'}>

                            <p className={'p-text remove click-light'}
                               onClick={() => onRemoveFAQPair(faqs, setFaqs, f)} style={{
                                fontWeight: '600',
                                position: 'absolute',
                                marginLeft: '-36px',
                                marginTop: '-2px'
                            }}>x</p>

                            <div className={'faq-edit'}>
                                <p className={'p-faq-numbered'}>
                                    {f + 1}.
                                </p>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={'edit'}
                                               value={faq.question}
                                               onChange={() => {
                                               }}
                                               onSubmit={(value) => onInputChange(value, () => updateFaqQuestion(faqs, setFaqs, faq, f, value))}
                                               onAction={(value) => onInputChange(value, () => updateFaqQuestion(faqs, setFaqs, faq, f, value))}
                                               label={`${L10N_APP_TEXTS[selectedLanguage].QUESTION}:`}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].QUESTION}
                                               style={{marginBottom: '0px'}}/>

                                        <Input button={'edit'}
                                               value={faq.answer}
                                               onChange={() => {
                                               }}
                                               onSubmit={(value) => onInputChange(value, () => updateFaqAnswer(faqs, setFaqs, faq, f, value))}
                                               onAction={(value) => onInputChange(value, () => updateFaqAnswer(faqs, setFaqs, faq, f, value))}
                                               label={`${L10N_APP_TEXTS[selectedLanguage].ANSWER}:`}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].ANSWER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className={'row add'} style={{paddingBottom: '36px', width: 'fit-content'}} onClick={() => {
                        onAddNewFaq(faqs, setFaqs, false);
                    }}>
                        <img src={plusBlack} className={'icon-label image-click'} alt={'img'} width={'24px'}
                             style={{paddingRight: '12px'}}/>
                        <p className={'p-text click-light'}>
                            {L10N_APP_TEXTS[selectedLanguage].ADD_QUESTION}
                        </p>
                    </div>
                </div>
            </>

        );
    }

    const renderFaqEditProviders = () => {
        return (
            <>
                <div className={'tab_faq tab_admin_faq'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_ADMIN_2}/>
                <div className={'faq-container'}>
                    <p className={'p-text'} style={{fontWeight: 500}}>
                        {L10N_APP_TEXTS[selectedLanguage].PROVIDERS_FAQ}
                    </p>
                    <br/>
                    <div className={'row'} style={{paddingBottom: '36px'}}>
                        <p className={'p-text'}>
                            {L10N_APP_TEXTS[selectedLanguage].FAQ_INFO}
                        </p>
                        <img src={iconInfo} className={'icon-label'} alt={'img'} width={'16px'}
                             style={{paddingLeft: '12px'}}/>
                    </div>

                    {faqsProviders.map((faq, f) => (
                        <div key={`${f}_${faq.question}`} className={'collapsible-faq-container'}>

                            <p className={'p-text remove click-light'}
                               onClick={() => onRemoveFAQPair(faqsProviders, setFaqsProviders, f)} style={{
                                fontWeight: '600',
                                position: 'absolute',
                                marginLeft: '-36px',
                                marginTop: '-2px'
                            }}>x</p>

                            <div className={'faq-edit'}>
                                <p className={'p-faq-numbered'}>
                                    {f + 1}.
                                </p>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={'edit'}
                                               value={faq.question}
                                               onChange={() => {
                                               }}
                                               onSubmit={(value) => onInputChange(value, () => updateFaqQuestion(faqsProviders, setFaqsProviders, faq, f, value))}
                                               onAction={(value) => onInputChange(value, () => updateFaqQuestion(faqsProviders, setFaqsProviders, faq, f, value))}
                                               label={`${L10N_APP_TEXTS[selectedLanguage].QUESTION}:`}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].QUESTION}
                                               style={{marginBottom: '0px'}}/>

                                        <Input button={'edit'}
                                               value={faq.answer}
                                               onChange={() => {
                                               }}
                                               onSubmit={(value) => onInputChange(value, () => updateFaqAnswer(faqsProviders, setFaqsProviders, faq, f, value))}
                                               onAction={(value) => onInputChange(value, () => updateFaqAnswer(faqsProviders, setFaqsProviders, faq, f, value))}
                                               label={`${L10N_APP_TEXTS[selectedLanguage].ANSWER}:`}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].ANSWER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className={'row add'} style={{paddingBottom: '36px', width: 'fit-content'}} onClick={() => {
                        onAddNewFaq(faqsProviders, setFaqsProviders, true);
                    }}>
                        <img src={plusBlack} className={'icon-label image-click'} alt={'img'} width={'24px'}
                             style={{paddingRight: '12px'}}/>
                        <p className={'p-text click-light'}>
                            {L10N_APP_TEXTS[selectedLanguage].ADD_QUESTION}
                        </p>
                    </div>
                </div>
            </>

        );
    }

    const parseCategoryNameKey = (category, l10n) => {
        switch (l10n) {
            case L10N.en.value:
                return "name_en"
            case L10N.si.value:
                return "name_si"
            case L10N.it.value:
                return "name_it"
            case L10N.de.value:
                return "name_de"
            case L10N.hr.value:
                return "name_hr"
        }
    };

    const renderCategories = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_CATEGORIES}/>
                <div className={'categories_container'} style={{paddingBottom: '24px'}}>
                    {allCategories.map((cat, c) => (
                        <div key={`${c}_${cat.parent}`} className={'cat-row'}>
                            <p className={'p-label click bold'} style={{letterSpacing: '-1px'}} onClick={() => {
                            }}>{cat.parent[parseCategoryNameKey(cat.parent, selectedLanguage)]}</p>

                            {cat.children.map((child, ch) => (
                                <div key={`${ch}_${child}`} className={'child-row'}>
                                    <p className={'p-label click'} onClick={() => {
                                    }}>{child[parseCategoryNameKey(child, selectedLanguage)]}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </>
        );
    };


    const renderPage = () => {
        if (!token || !isUserAdmin(user)) {
            //displayAlert("Napaka, izpisani ste bili iz seje, prosimo prijavite se ponovno...")
            //onNavigateTo(PAGES.LOGIN);
            return <></>
        }

        return <>
            <div ref={ref}/>
            <Header loggedIn/>
            <animated.div  style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>
            <animated.div style={slideUp}>
                <Footer button={L10N_APP_TEXTS[selectedLanguage].SAVE}
                        onClick={() => triggerAction(CREATE_ACTION.UPDATE_DATA)}/>
            </animated.div>
            <FooterPage/>
        </>
    }

    return (
        renderPage()
    );
}

export default AdminControlPanel;