import logo from "../../assets/images/logo.png";
import icon_heart from "../../assets/images/heart.png";
import icon_shopping_cart from "../../assets/images/shopping-cart.png";
import icon_user from "../../assets/images/user.png";
import headerImage from "../../assets/images/header.png";
import headerImageLoggedOut from "../../assets/images/header_logged_out.png";
import {displayAlert, isUserAdmin, isUserProvider, parseFullName} from "../../helpers/helpers";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {L10N} from "../../constants/enums";
import {loadLocalL10N, logoutUser} from "../../store/actions/actions";
import {useHistory} from "react-router-dom";
import {PAGES} from "../../App";

const DATA = {
    user: {
        full_name: 'Tadej Hiti'
    }
};

function Header(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user);
    const userToken = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    useEffect(() => {
        //dispatch(loadLocalL10N(false));
        setSelectedLanguage(l10n)
        console.log(l10n);

    }, [l10n, selectedLanguage]);

    const addCourse = () => {
        if (!userToken()) {
            displayAlert("Za dodajanje tečaja morate biti prijavljeni kot tečajnik.")
        } else {
            onNavigateTo(PAGES.PROVIDER_EDIT)
        }
    }

    const renderHeader = () => {
        return <div className={'header_container'} style={{backgroundImage: `url(${props.loggedIn ? headerImage : headerImageLoggedOut})`}}>
            <div className={'header'}>
                    <img src={logo} alt="logo" className={'header_logo'} onClick={() =>{
                        onNavigateTo(PAGES.MAIN)
                    }}/>
                <div className={'header_inner_right'}>
                    {props.loggedIn || user && userToken ? <>
                        <p className={'p-header logout'} onClick={() => dispatch(logoutUser(() => {
                            dispatch(logoutUser(() => {
                                onNavigateTo(PAGES.LOGIN);
                            }))
                        }))}>{L10N_APP_TEXTS[selectedLanguage].LOGOUT}</p>
                        {isUserProvider(user) && <div style={{paddingRight: '36px', marginLeft: '-36px'}} onClick={() =>{
                            onNavigateTo(PAGES.PROVIDER_EDIT);
                        }}>
                            <p className={'p-header click'}>{L10N_APP_TEXTS[selectedLanguage].PROFILE_PROVIDER}</p>
                        </div>}
                        <div className={'header_user'} onClick={() =>{
                            if (isUserAdmin(user)) {
                                onNavigateTo(PAGES.CONTROL_PANEL);
                            } else {
                                onNavigateTo(PAGES.HOME);
                            }
                        }}>
                            <img src={icon_user} alt="logo" className={'icon_user'}/>
                            <p className={'p-header'}>{parseFullName(user.full_name)}</p>
                        </div>
                    </> : <>
                        <p className={'p-submit logged_in click'} onClick={() => {addCourse()}}>{L10N_APP_TEXTS[selectedLanguage].ADD_COURSE}</p>
                        <p className={'p-header logged_in click'} onClick={() => {onNavigateTo(PAGES.REGISTER_PROVIDER)}}>{L10N_APP_TEXTS[selectedLanguage].BECOME_LECTURER}</p>
                        <p className={'p-header logged_in click'} onClick={() => {onNavigateTo(PAGES.REGISTER_USER)}}>{L10N_APP_TEXTS[selectedLanguage].REGISTER}</p>
                        <img src={icon_heart} alt="logo" className={'icon_heart logged_in click'}/>
                        <img src={icon_shopping_cart} alt="logo" className={'icon_cart logged_in click'}/>
                    </>
                    }
                </div>
            </div>
        </div>
    }

    return renderHeader();
}

export default Header;