import React, { useEffect } from 'react';

const SubscriptionForm = ({ onClose }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://7894.squalomail.net/forms/16/subscribe.js';
        script.async = true;

        const formDiv = document.createElement('div');
        formDiv.id = 'sqm_form_16';

        document.body.appendChild(formDiv);
        document.body.appendChild(script);

        const onSubmit = (event) => {
            if (event.target.closest('.nsf-btn.bd5')) {
                // Delay the onClose call by 500ms
                setTimeout(() =>{
                    document.body.removeChild(formDiv);
                    document.body.removeChild(script);
                    document.removeEventListener('submit', onSubmit);
                }, 500);
            }
        };

        document.addEventListener('submit', onSubmit);

        return () => {
            document.body.removeChild(formDiv);
            document.body.removeChild(script);
            document.removeEventListener('submit', onSubmit);
        }
    }, [onClose]);

    return null;
};

export default SubscriptionForm;
