import '../css/DropdownCircle.css';
import plusCircleIcon from "../../assets/images/plus-circle.png";
import {useEffect, useState} from "react";
import {L10N} from "../../constants/enums";
import {CREATE_ACTION} from "../../pages/RegisterProvider";
import {useSelector} from "react-redux";


function DropdownCircle(props) {
    const user = useSelector(state => state.reducer.user);
    const provider = useSelector(state => state.reducer.provider);
    const course = useSelector(state => state.reducer.course);

    const [dropdownContent, setDropdownContent] = useState([
        {
            label: 'angleško',
            key: 'l10n_en',
            value: L10N.en
        },
        {
            label: 'slovensko',
            key: 'l10n_si',
            value: L10N.si
        },
        {
            label: 'italijansko',
            key: 'l10n_it',
            value: L10N.it
        },
        {
            label: 'hrvaško',
            key: 'l10n_hr',
            value: L10N.hr
        },
        {
            label: 'nemško',
            key: 'l10n_de',
            value: L10N.de
        }
    ]);
    const [selected, setSelected] = useState([]);
    const [opened, setOpened] = useState(false);
    const [ref, setRef] = useState(0);

    useEffect(() => {
        if (selected) {
            console.log(selected);
        }

    }, [selected]);

    useEffect(() => {
        if (selected && props.user) {
            let a = [];
            if (user.l10n_en) {
                a.push(dropdownContent[0])
            }
            if (user.l10n_si) {
                a.push(dropdownContent[1])
            }
            if (user.l10n_it) {
                a.push(dropdownContent[2])
            }
            if (user.l10n_hr) {
                a.push(dropdownContent[3])
            }
            if (user.l10n_de) {
                a.push(dropdownContent[4])
            }
            setSelected(a)
        }

    }, [user]);

    useEffect(() => {
        if (selected && props.provider) {
            let a = [];
            if (provider?.l10n_en) {
                a.push(dropdownContent[0])
            }
            if (provider?.l10n_si) {
                a.push(dropdownContent[1])
            }
            if (provider?.l10n_it) {
                a.push(dropdownContent[2])
            }
            if (provider?.l10n_hr) {
                a.push(dropdownContent[3])
            }
            if (provider?.l10n_de) {
                a.push(dropdownContent[4])
            }
            setSelected(a)
        }

    }, [provider]);

    useEffect(() => {
        if (selected && props.course) {
            let a = [];
            if (course.l10n_en) {
                a.push(dropdownContent[0])
            }
            if (course.l10n_si) {
                a.push(dropdownContent[1])
            }
            if (course.l10n_it) {
                a.push(dropdownContent[2])
            }
            if (course.l10n_hr) {
                a.push(dropdownContent[3])
            }
            if (course.l10n_de) {
                a.push(dropdownContent[4])
            }
            setSelected(a)
        }

    }, [course]);



    const renderContent = () => {
        let className = `dropdown_content ${opened ? 'open' : 'hidden'}`;

        return (

            <div className={'dropdown_full_width'}>
                <div className={'col'}>
                    <p className={'p-label'}>{props.text}</p>
                    <p className={'p-info'}>{props.info}</p>
                </div>

                <div className={'dropdown_selection'}>
                    {selected.map((content, c) => (
                        <div key={`${c}_${content.value}`} className={'dropdown_col'}>
                            <p className={'p-input'} onClick={(e) => {
                                console.log(c)

                                for (let i = 0; i < selected.length; i++) {
                                    if (selected[i].value.value === content.value.value) {
                                        props.onChange(selected[i].key, false);

                                        selected.splice(i, 1)
                                        setSelected(selected);
                                        setOpened(false);
                                        setRef(ref + 1);
                                        return;
                                    }
                                }

                            }}>{content.label}<span
                                className={'dropdown_close click'}>x</span>{c + 1 < selected.length ? ',' : ''}</p>
                        </div>
                    ))}
                </div>

                <div className={'dropdown_circle'} onClick={(event) => {
                    if (event.target === event.currentTarget) {
                        event.stopPropagation()
                    }
                    setOpened(!opened);
                }}>
                     <div className={'image-click'}>
                         <img src={plusCircleIcon} alt={'plus-circle-icon'} width={'44px'} style={{transform: opened ? 'rotateZ(45deg)' : 'unset'}}/>
                     </div>
                    <div className={className}>
                        {dropdownContent.map((content, c) => (
                            <div key={`${c}_${content.value}`} className={'dropdown_row'}>
                                <p className={'p-label'} onClick={(e) => {
                                    e.stopPropagation();
                                    for (let i = 0; i < selected.length; i++) {
                                        if (selected[i].value.value === content.value.value) {
                                            return;
                                        }
                                    }
                                    props.onChange(content.key, true);
                                    setSelected([...selected, content]);
                                }}>{content.label}</p>
                            </div>
                        ))}
                    </div>
                </div>


            </div>
        );

    }

    return renderContent();
}

export default DropdownCircle;
