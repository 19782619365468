import {
    L10N,
    LOCAL_STORAGE_KEYS,
    USER_ROLES
} from "../constants/enums";
import React from "react";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import countryHR from "../assets/images/country_hr.png";
import countryDE from "../assets/images/country_de.png";
import {L10N_APP_TEXTS} from "../l10n/l10n";

export const isUserAdmin = (user) => {
    return user && parseUserRole(user.user_role) === USER_ROLES.ADMIN;
}
export const isUserProvider = (user) => {
    return user && parseUserRole(user.user_role) === USER_ROLES.PROVIDER;
}
export const isUserCustomer = (user) => {
    return user && parseUserRole(user.user_role) === USER_ROLES.CUSTOMER;
}

export const encapsulateStoreReferralIdToUrl = (url, referral) => {
    return url + '?referralID=' + referral;
};

export const validateUserInput = (params) => {
    for (let i = 0; i < params.length; i++) {
        console.log(JSON.stringify(params[i], null, 4))
        if (params[i] === null || params[i] === '') {
            return false;
        }
    }
    return true;
};

export const validateUserInputNumerical = (params) => {
    for (let i = 0; i < params.length; i++) {
        let param = parsePostDecimalValue(params[i])
        console.log(JSON.stringify(param, null, 4))
        if (isNaN(param)) {
            return false;
        }
    }
    return true;
};

export const parsePriceCommaToDecimalValue = (price) => {
    return price?.toString().replace(",", ".");
}

export const isNewObjValidCheck = (obj, requiredFields, checkNumeric) => {
    let response = {
        isValid: true,
        invalidFields: [],
        errorMessages: []
    }
    // check for required fields
    for (const requiredField in requiredFields) {
        if (!obj[requiredField] || obj[requiredField] === '') {
            response.isValid = false;
            response.invalidFields.push(requiredField);
            response.errorMessages.push('Manjka vrednost v polju: ' + requiredFields[requiredField]);
        }
    }

    if (checkNumeric) {
        // check for correct numerical input
        if (!validateUserInputNumerical([obj.alcohol_percent])) {
            response.isValid = false;
            response.invalidFields.push('alcohol_percent');
            response.errorMessages.push('Neveljaven vnos podatkov! Stopnja alkohola mora biti decimalno število podano brez enote.');
        }
    }

    return response
}

export const parseRestApiResponse = (response, resData, dispatchAction, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    if (Number(response.status) >= 500) {
        console.log("GET DATA ERROR", response.status, resData.error);
        if (onErrorErrorFetchingData) {
            onErrorErrorFetchingData();
        }
    } else if (Number(response.status) >= 400) {
        console.log("GET DATA ERROR", response.status, resData.error);
        if (onErrorUnauthorized) {
            onErrorUnauthorized();
        } else if (onErrorErrorFetchingData) {
            onErrorErrorFetchingData();
        }
    } else if (Number(response.status) >= 200) {
        //console.log("GET DATA SUCCESS", response.status, resData.error);
        if (dispatchAction) {
            dispatchAction().then(() => {
                if (onSuccessAction) {
                    onSuccessAction();
                }
            });
        }
    }
};

export const displayAlert = (message) => {
    return alert(message);
};

export const hasItem = (field, returnField, value, array) => {
    if (!array) {
        return false
    }

    for (let i = 0; i < array.length; i++) {
        if (array[i][field]?.toString() === value?.toString()) {
            return array[i][returnField];
        }
    }
    return null;
};

export const findItem = (field, value, array) => {
    if (!array) {
        return null
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
            return array[i];
        }
    }
    return null;
};

export const findItemValue = (value, array) => {
    if (!array) {
        return null
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i] === value) {
            return array[i];
        }
    }
    return null;
};

export const getIndexOf = (field, value, array) => {
    if (!array) {
        return -1
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
            return i;
        }
    }
    return -1;
};

export const getIndexOfValue = (value, array) => {
    if (!array) {
        return -1
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i] === value) {
            return i;
        }
    }
    return -1;
};

export const getIndexForNthCharOccurrence = (string, subString, index) => {
    if (!string) {
        return -1
    }
    return string.split(subString, index).join(subString).length;
}

export const parseSelectedObjectValues = (list, searchField) => {
    if (!list || list.length === 0) {
        return null;
    }
    let arr = []
    for (let i = 0; i < list.length; i++) {
        if (list[i][searchField]) {
            arr.push(list[i][searchField]);
        }
    }
    return arr;
}

export const parseUserRole = (userRole) => {
    switch (userRole) {

        case USER_ROLES.ADMIN:
            return USER_ROLES.ADMIN;
        case USER_ROLES.PROVIDER:
            return USER_ROLES.PROVIDER;
        case USER_ROLES.CUSTOMER:
            return USER_ROLES.CUSTOMER;
        default:
            console.warn(`Error parsing user role... User role '${userRole}' is Ineligible!`);
            return null;
    }
};

export const isSessionValid = (token) => {
    if (!token) {
        console.log('redirect');
        return false;
    }
    return true;
}

export const mapCaretChars = (string) => {
    if (!string) {
        return null;
    }
    let res = string.replace(/[ŽžČčŠšĆćđĐ]/gi, function (c) {
        switch (c) {
            case 'Ž':
                return '_Z';
            case 'ž':
                return '_z';
            case 'Č':
            case 'Ć':
                return '_C';
            case 'č':
            case 'ć':
                return '_c';
            case 'đ':
                return 'd';
            case 'Đ':
                return 'D';
            case 'Š':
                return '_S';
            case 'š':
                return '_s';
        }
    });
    return res;
};

export const parseMapCaretChars = (string) => {
    if (!string) {
        return null;
    }
    let res = string.replace(/_Z|_z|_C|_c|_S|_s/gi, function (c) {
        switch (c) {
            case '_Z':
                return 'Ž';
            case '_z':
                return 'ž';
            case '_C':
                return 'Č';
            case '_c':
                return 'č';
            case '_S':
                return 'Š';
            case '_s':
                return 'š';
        }
    });
    return res;
};


export const displayDecimalValue = (price) => {
    if (price === null || price === undefined) {
        return '';
    }
    return price.toString().replace(".", ",");
}

export const parsePostDecimalValue = (price) => {
    return price.toString().replace(",", ".");
}

export const displayPrice = (price) => {
    return displayDecimalValue(Number(price).toFixed(2));
}

export const displayValidFromMenuFormat = (date) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    console.log("DATE:", date, `${day}. ${month}. ${year}`)
    return `${day}. ${month}. ${year}`;
};


export const displayDateMonthYear = (date, l10n) => {

    if (!date) {
        return null;
    }

    let d = new Date(date);
    let month = d.getMonth();

    switch (month) {
        case 0:
            month = L10N_APP_TEXTS[l10n].JAN;
            break;
        case 1:
            month = L10N_APP_TEXTS[l10n].FEB;
            break;
        case 2:
            month = L10N_APP_TEXTS[l10n].MAR;
            break;
        case 3:
            month = L10N_APP_TEXTS[l10n].APR;
            break;
        case 4:
            month = L10N_APP_TEXTS[l10n].MAY;
            break;
        case 5:
            month = L10N_APP_TEXTS[l10n].JUN;
            break;
        case 6:
            month = L10N_APP_TEXTS[l10n].JUL;
            break;
        case 7:
            month = L10N_APP_TEXTS[l10n].AUG;
            break;
        case 8:
            month = L10N_APP_TEXTS[l10n].SEP;
            break;
        case 9:
            month = L10N_APP_TEXTS[l10n].OCT;
            break;
        case 10:
            month = L10N_APP_TEXTS[l10n].NOV;
            break;
        case 11:
            month = L10N_APP_TEXTS[l10n].DEC;
            break;
    }
    let year = d.getFullYear();

    return `${month} ${year}`;
};

export const displayDate = (date, dateEnd, l10n) => {

    if (!date) {
        return null;
    }

    let d = new Date(date);
    let dEnd = new Date(dateEnd);

    let month = d.getMonth();
    let day = d.getDay();
    let dayInMonth = d.getDate();

    switch (month) {
        case 0:
            month = L10N_APP_TEXTS[l10n].JAN;
            break;
        case 1:
            month = L10N_APP_TEXTS[l10n].FEB;
            break;
        case 2:
            month = L10N_APP_TEXTS[l10n].MAR;
            break;
        case 3:
            month = L10N_APP_TEXTS[l10n].APR;
            break;
        case 4:
            month = L10N_APP_TEXTS[l10n].MAY;
            break;
        case 5:
            month = L10N_APP_TEXTS[l10n].JUN;
            break;
        case 6:
            month = L10N_APP_TEXTS[l10n].JUL;
            break;
        case 7:
            month = L10N_APP_TEXTS[l10n].AUG;
            break;
        case 8:
            month = L10N_APP_TEXTS[l10n].SEP;
            break;
        case 9:
            month = L10N_APP_TEXTS[l10n].OCT;
            break;
        case 10:
            month = L10N_APP_TEXTS[l10n].NOV;
            break;
        case 11:
            month = L10N_APP_TEXTS[l10n].DEC;
            break;
    }
    switch (day) {
        case 0:
            day = L10N_APP_TEXTS[l10n].MON;
            break;
        case 1:
            day = L10N_APP_TEXTS[l10n].TUE;
            break;
        case 2:
            day = L10N_APP_TEXTS[l10n].WED;
            break;
        case 3:
            day = L10N_APP_TEXTS[l10n].THU;
            break;
        case 4:
            day = L10N_APP_TEXTS[l10n].FRI;
            break;
        case 5:
            day = L10N_APP_TEXTS[l10n].SAT;
            break;
        case 6:
            day = L10N_APP_TEXTS[l10n].SUN;
            break;
    }
    let year = d.getFullYear();

    let h = d.getHours();
    let m = d.getMinutes();

    let hEnd = dEnd.getHours();
    let mEnd = dEnd.getMinutes();

    if (h <= 9) {
        h = "0" + h;
    }
    if (m <= 9) {
        m = "0" + m;
    }
    if (mEnd <= 9) {
        mEnd = "0" + mEnd;
    }
    if (hEnd <= 9) {
        hEnd = "0" + hEnd;
    }

    console.log(hEnd);

    month = month.toLowerCase().substring(0, 3);

    return `${day}, ${dayInMonth}. ${month} ${year}`;
};

export const displayDateWithHour = (date, dateEnd, l10n) => {

    if (!date) {
        return null;
    }

    let d = new Date(date);
    let dEnd = new Date(dateEnd);

    let month = d.getMonth();
    let day = d.getDay();
    let dayInMonth = d.getDate();

    switch (month) {
        case 0:
            month = L10N_APP_TEXTS[l10n].JAN;
            break;
        case 1:
            month = L10N_APP_TEXTS[l10n].FEB;
            break;
        case 2:
            month = L10N_APP_TEXTS[l10n].MAR;
            break;
        case 3:
            month = L10N_APP_TEXTS[l10n].APR;
            break;
        case 4:
            month = L10N_APP_TEXTS[l10n].MAY;
            break;
        case 5:
            month = L10N_APP_TEXTS[l10n].JUN;
            break;
        case 6:
            month = L10N_APP_TEXTS[l10n].JUL;
            break;
        case 7:
            month = L10N_APP_TEXTS[l10n].AUG;
            break;
        case 8:
            month = L10N_APP_TEXTS[l10n].SEP;
            break;
        case 9:
            month = L10N_APP_TEXTS[l10n].OCT;
            break;
        case 10:
            month = L10N_APP_TEXTS[l10n].NOV;
            break;
        case 11:
            month = L10N_APP_TEXTS[l10n].DEC;
            break;
    }
    switch (day) {
        case 0:
            day = L10N_APP_TEXTS[l10n].MON;
            break;
        case 1:
            day = L10N_APP_TEXTS[l10n].TUE;
            break;
        case 2:
            day = L10N_APP_TEXTS[l10n].WED;
            break;
        case 3:
            day = L10N_APP_TEXTS[l10n].THU;
            break;
        case 4:
            day = L10N_APP_TEXTS[l10n].FRI;
            break;
        case 5:
            day = L10N_APP_TEXTS[l10n].SAT;
            break;
        case 6:
            day = L10N_APP_TEXTS[l10n].SUN;
            break;
    }
    let year = d.getFullYear();

    let h = d.getUTCHours();
    let m = d.getUTCMinutes();

    let hEnd = dEnd.getUTCHours();
    let mEnd = dEnd.getUTCMinutes();

    if (h <= 9) {
        h = "0" + h;
    }
    if (m <= 9) {
        m = "0" + m;
    }
    if (mEnd <= 9) {
        mEnd = "0" + mEnd;
    }
    if (hEnd <= 9) {
        hEnd = "0" + hEnd;
    }

    console.log(hEnd);

    month = month.toLowerCase().substring(0, 3);

    return `${day}, ${dayInMonth}. ${month} ${year}, ${h}:${m} - ${hEnd}:${mEnd}`;
};

export const getNextCourseStartDate = (packageDates) => {
    if (!packageDates || packageDates.length <= 0) {
        return null;
    }

    let latest = packageDates[0];
    for (let i = 0; i < packageDates.length; i++) {
        let pD = packageDates[i];

        let d = new Date(pD.start_hour).getTime();
        let now = new Date().getTime();

        if (now <= d && new Date(latest.start_hour).getTime() <= d) {
            latest = pD;
        }
    }
    console.log("LATEST", latest);
    return latest;
};

export const getCourseDatesParsedByStartDatesAndTheirDates = (packageDates, l10n) => {
    if (!packageDates || packageDates.length <= 0) {
        return {startDates: [], restDates: []};
    }

    console.log("ALO", packageDates);

    let sDates = [];
    let rDates = [];

    let tmp = [];
    let tmp2 = [];

    let closed = false;
    let started = true;
    let idx = 0;
    for (let i = 0; i < packageDates.length; i++) {
        let pD = packageDates[i];
        let p = displayDateWithHour(pD.start_hour, pD.end_hour, l10n)

        let d = new Date(pD.start_hour).getTime();
        let now = new Date().getTime();

        /*
        if (now >= d) {
            continue;
        }
         */

        if (pD.start_date) {
            sDates.push(p);

            tmp.push(p)
            closed = true;

        } else if (!pD.start_date && !started) {
            tmp.push(p);
            closed = false;
        }
        if (closed) {
            if (started) {
                started = false;
            } else {
                // tmp2 = tmp
                rDates.push(tmp);
                tmp = [];

                started = true;
            }
        }
    }
    if (!closed) {
        rDates.push(tmp);
    }
    console.log("PARSED", sDates, rDates);
    return {startDates: sDates, restDates: rDates};
};


export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber = (input_str) => {
    let re = /^[a-zA-Z0-9\-().\s]{9,15}$/;

    return re.test(input_str);
}

export const parseSelectedCountryLabel = (country, selectAction, onActionCollapseL10NOptions) => {
    const action = () => {
        selectAction(country)
        onActionCollapseL10NOptions()
    }

    switch (country) {
        case L10N.en.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryEN} alt="EN" width={'27px'}/>
            </div>

        case L10N.it.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryIT} alt="US" width={'27px'}/>
            </div>
        case L10N.si.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countrySI} alt="SI" width={'27px'}/>
            </div>
        case L10N.de.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryDE} alt="DE" width={'27px'}/>
            </div>
        case L10N.hr.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryHR} alt="SI" width={'27px'}/>
            </div>
    }
}

export const parseFullName = (full_name) => {
    if (full_name) {
        if (full_name.indexOf(' ') <= 0) {
            return full_name;
        }
        return full_name.substring(0, full_name.indexOf(' ') + 2) + ".";
    } else {
        return full_name;
    }
}


export const scrollToDiv = (div) => {
    try {
        let id = div;
        const yOffset = -20;
        let element = document.getElementsByClassName(id);
        if (element.length > 0) {
            element = element[0];
        }

        console.log(element, id);

        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        console.log("Scroll to div", element, y + 'px');

        window.scrollTo({top: y, behavior: 'smooth'});
    } catch (e) {
        console.warn(e);
    }
}

export const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
};

export const calculateDiff = (dateString) =>{

    function extractTimes(dateString) {
        const [, timeRange] = dateString.match(/(\d+:\d+\s-\s\d+:\d+)/);
        const [startTime, endTime] = timeRange.split(" - ");
        return { startTime, endTime };
    }

    function calculateTimeDifference(startTime, endTime) {
        const [startHour, startMinute] = startTime.split(":");
        const [endHour, endMinute] = endTime.split(":");

        const start = parseInt(startHour, 10) * 60 + parseInt(startMinute, 10);
        const end = parseInt(endHour, 10) * 60 + parseInt(endMinute, 10);
        return end - start;
    }

    const { startTime, endTime } = extractTimes(dateString);
    const diffInMinutes = calculateTimeDifference(startTime, endTime);

    //console.log(diffInMinutes);

    return diffInMinutes;
}

export const parseHoursDuration = (dates) => {
    function formatTimeDifference(minutes) {
        const diffHours = Math.floor(minutes / 60);
        const diffMinutes = minutes % 60;
        return `${diffHours}h ${diffMinutes.toString().padStart(2, "0")}min`;
    }

    try {
        //console.log("DDATES:", dates);

        let sum = 0;
        if (!dates || dates.length === 0) {
            return sum;
        }

        for (let i = 0; i <dates[0].length; i++) {
            let d = dates[0][i];
            const diffInMinutes = calculateDiff(d);
        //    console.log("MINUTES:", diffInMinutes);

            sum += diffInMinutes;
        }
        const formattedDiff = formatTimeDifference(sum);
        //console.log(formattedDiff)

        return formattedDiff;
    } catch (e) {
        return 0;
    }
}

export const calculateDiffTimestamp = (startDate, endDate) =>{

    function calculateTimeDifference(timeStart, timeEnd) {
      //  console.log("NO", timeStart, timeEnd)

/*
        const start = new Date(timeStart);
        const end = new Date(timeEnd);
        const diffInMinutes = Math.floor((end - start) / 60000);
        return diffInMinutes;
*/

        const start = new Date(timeStart);
        const end = new Date(timeEnd);
        const startHour = start.getHours();
        const startMinute = start.getMinutes();
        const endHour = end.getHours();
        const endMinute = end.getMinutes();

        const diffInMinutes = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);

        return diffInMinutes;

    }

    const diffInMinutes = calculateTimeDifference(startDate, endDate);

   // console.log("MINS: ", diffInMinutes);

    return diffInMinutes;
}

export const parseHoursDurationFromTimestamps = (dates) => {
    function formatTimeDifference(minutes) {
        const diffHours = Math.floor(minutes / 60);
        const diffMinutes = minutes % 60;

        return diffHours;

        return `${diffHours}h ${diffMinutes.toString().padStart(2, "0")}min`;
    }

    try {
        //console.log("DDATES:", dates);

        let sum = 0;
        if (!dates || dates.length === 0) {
            return sum;
        }

        for (let i = 0; i <dates.length; i++) {
            let d = dates[i];
            const diffInMinutes = calculateDiffTimestamp(d.start_hour, d.end_hour);
          //  console.log("MINUTES TIMESTAMP:", diffInMinutes);

            sum += diffInMinutes;
        }

        const formattedDiff = formatTimeDifference(sum);
        //console.log("TOTAL MINUTES:", sum, formattedDiff);


        // console.log(formattedDiff)

        return formattedDiff;
    } catch (e) {
        return 0;
    }
}

export const parseHoursDurationFromTimestampsFormatted = (dates) => {
    function formatTimeDifference(minutes) {
        const diffHours = Math.floor(minutes / 60);
        const diffMinutes = minutes % 60;

        return `${diffHours}h ${diffMinutes.toString().padStart(2, "0")}min`;
    }

    try {
        //console.log("DDATES:", dates);

        let sum = 0;
        if (!dates || dates.length === 0) {
            return sum;
        }

        for (let i = 0; i <dates.length; i++) {
            let d = dates[i];
            const diffInMinutes = calculateDiffTimestamp(d.start_hour, d.end_hour);
            //console.log("MINUTES TIMESTAMP:", diffInMinutes);

            sum += diffInMinutes;
        }

        const formattedDiff = formatTimeDifference(sum);
       // console.log("TOTAL MINUTES:", sum, formattedDiff);


       // console.log(formattedDiff)

        return formattedDiff;
    } catch (e) {
        return 0;
    }
}

// Helper function to get the videoId from YouTube URL
export const getVideoIdFromUrl = (url) => {
    const urlParts = new URL(url);
    return urlParts.searchParams.get('v');
};

export const filterCoursesByCategoryId = (coursesArray, categoryId) => {
    return coursesArray.filter((course) => course.category_id === categoryId);
}
