import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory} from "react-router-dom";
import {L10N, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {
    isRememberMeEnabled, loadAllCategories, loadAllCategoriesParsed, loadAllCourses, loadAllProviders, loadCourseById,
    loadCredentials,
    loadLocalL10N, setCategories, setL10N,
    setRememberMe,
    setSignInCredentials, userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    displayDate, displayDateWithHour, filterCoursesByCategoryId,
    findItem, getCourseDatesParsedByStartDatesAndTheirDates,
    getIndexOf,
    getNextCourseStartDate, parseHoursDurationFromTimestamps, parseHoursDurationFromTimestampsFormatted,
    parseSelectedCountryLabel,
    scrollToDiv
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";

import iconAddress from "../assets/images/i_address.png";
import iconMean from "../assets/images/i_mean.png";
import iconStar from "../assets/images/i_star.png";
import iconSum from "../assets/images/i_sum.png";
import iconNight from "../assets/images/i_night.png";
import iconCalendar from "../assets/images/calendar.png";
import bcgLayer from "../assets/images/bcg-layer.png";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import Title from "../components/js/Title";
import Footer from "../components/js/Footer";
import {CREATE_ACTION} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import Button from "../components/js/Button";
import DropdownCircle from "../components/js/DropdownCircle";
import FooterPage from "../components/js/FooterPage";
import GoogleMap from "../components/js/GoogleMap";
import Filters from "../components/js/Filters";
import Search from "../components/js/Search";
import HeaderLanding from "../components/js/HeaderLanding";
import CustomMap from "../components/js/CustomMap";
import headerLarge from "../assets/images/background_large.png";
import iconHeart from "../assets/images/heart_blue.png";
import iconAcc from "../assets/images/acc.png";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";
import Banner from "../components/js/Banner";
import Header from "../components/js/Header";


function MainPage() {
    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);
    const categories = useSelector(state => state.reducer.categories);

    console.log("CAT", categories);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);


    const [saveData, setSaveData] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState();

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);
    const [allCourses, setAllCourses] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [rerender, setRerender] = useState(false);

    const [isFilterUsed, setIsFilterUsed] = useState(false);


    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                opacity: saveData ? 0 : 0,
                bottom: saveData ? '-30px' : '-30px',
                display: saveData ? 'none' : 'none'
            },
            to: {
                opacity: saveData ? 1 : 0,
                bottom: saveData ? '0' : '0',
                display: saveData ? 'flex' : 'none'
            },
            config: {duration: 200},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);



    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const ref = useRef();
    useEffect(() => {
        dispatch(loadAllProviders((res) => {
        }, (res) => {
        }));
        dispatch(loadAllCourses((res) => {
            setAllCourses(res);
        }, (res) => {
        }));
        dispatch(loadAllCategoriesParsed((res) => {
        }, (res) => {
        }));
        dispatch(loadAllCategories((res) => {
            setAllCategories(res);
        }, (res) => {
        }));
        ref.current.scrollIntoView()

    }, [])


    useEffect(() => {

        if (!search || search === "") {
            console.log(search)
            setIsFilterUsed(false)
            setFilteredCourses([]);
        }
        /*
        dispatch(loadAllCourses((res) => {
            setAllCourses(res);
        }, (res) => {
        }));

         */

    }, [search])

    useEffect(() => {
        if (!allCourses || allCourses.empty) {
        } else {
            scrollToDiv("filtered-content");
        }

    }, [filteredCourses])

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);
    }, [l10n, selectedLanguage]);


    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.REGISTER_USER:
                    onConfirmLoginUser();
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };

    const onConfirmLoginUser = () => {
        setLoadingSpinner(true);

        const onSuccessLogin = () => {
            console.log('redirect success');
            setLoginSuccessful(true);
            setLoadingSpinner(false);
            setCreateTrigger(false);
        };
        const UserAccessNotGranted = () => {
            console.log("Error fetching data");
            setError(ErrorType.USER_ACCESS_NOT_GRANTED);
            setLoadingSpinner(false);
            setCreateTrigger(false);
        };
        const onErrorUnauthorized = () => {
            console.log("Unauthorized");
            setError(ErrorType.UNAUTHORIZED);
            setLoadingSpinner(false);
            setCreateTrigger(false);
        };


        dispatch(userLogin(email, password, (res) => {
            console.log("Login new user successfully!", res);

            onSuccessLogin()

        }, (res) => {
            console.warn("Error logging-in new user..");
            UserAccessNotGranted();

        }, () => {
            onErrorUnauthorized();
        }));

    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error l10n={selectedLanguage} type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };
    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

        return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}
                <Search onChange={(value) => onInputChange(value, setSearch)}
                        placeholder={`${L10N_APP_TEXTS[selectedLanguage].FIND_COURSE}..`}
                        allCourses={allCourses}
                        onSubmit={(filtered) => {
                            setFilteredCourses(filtered)
                        }}/>
                <Filters
                    allCourses={allCourses}
                    categories={allCategories}
                    onSubmit={(filtered) => {
                        setRerender(!rerender);
                        setIsFilterUsed( true);

                        setFilteredCourses(filtered)
                    }}/>

                {renderCourses(filteredCourses, L10N_APP_TEXTS[selectedLanguage].SEARCH,"filtered-content")}
                {/*renderCourses(allCourses, L10N_APP_TEXTS[selectedLanguage].MOST_POPULAR, "popular-content")*/}
                {renderBackgroundLayer()}
                {renderCourses(allCourses, L10N_APP_TEXTS[selectedLanguage].NEW_CONTENT, "new-content")}
                {renderCategories()}
            </div>
        )
    }

    const renderMap = () => {
        return (
            <CustomMap/>
        );
    };

    const renderBackgroundLayer = () => {
        return (
            <>
                <div className={'bcg_layer'} style={{backgroundImage: `url(${bcgLayer})`}}>
                    <Banner/>
                </div>
                <div style={{height: '405px', paddingBottom: '24px'}}/>
            </>
        );
    }

    const parseCategoryNameKey = (category, l10n) => {
        switch (l10n) {
            case L10N.en.value:
                return "name_en"
            case L10N.si.value:
                return "name_si"
            case L10N.it.value:
                return "name_it"
        }
    };


    const selectCategory = (category_id, courses) =>{
        if (!courses || courses.empty) {
            return
        }
        const filteredCourses = filterCoursesByCategoryId(courses, category_id);

        console.log(filteredCourses);

        setRerender(!rerender);
        setIsFilterUsed( true);
        setFilteredCourses(filteredCourses)

        scrollToDiv("filtered-content")
    }

    const renderCategories = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_CATEGORIES}/>
                <div className={'categories_container'}>
                    {categories.map((category, c) => (
                        <div key={`${c}_${category.parent}`} className={'cat-row'}>
                            <p className={'p-label click bold'} style={{letterSpacing: '-1px'}} onClick={() => {
                                selectCategory(category.parent['category_id'], allCourses)
                            }}>{category.parent[parseCategoryNameKey(category.parent, selectedLanguage)]}</p>

                            {category.children.map((child, ch) => (
                                <div key={`${ch}_${child}`} className={'child-row'}>
                                    <p className={'p-label click'} onClick={() => {
                                        selectCategory(child['category_id'], allCourses)
                                    }}>{child[parseCategoryNameKey(child, selectedLanguage)]}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </>
        );
    };



    const renderCourses = (courses, title, anchor) => {

        console.log(rerender)

        if (!selectedLanguage || !courses || courses?.length <= 0) {
            if (filteredCourses === courses && isFilterUsed) {
                return <>
                    <Title dots title={title}/>
                    <p className={'p-normal'} style={{paddingBottom: '80px', fontStyle: 'italic'}}>{L10N_APP_TEXTS[selectedLanguage].NO_SEARCH_RESULTS}</p>
                </>
            }
            return  <></>
        }
        return (
            <>
                {anchor ? <div className={anchor}/> : <></>}
                <Title dots title={title}/>
                <div className={'courses_container scroll ' + anchor} style={{marginBottom: '48px'}}>
                    {courses.map((course, c) => (

                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'}
                             style={{cursor: "pointer"}} onClick={() => {
                            let data = {
                                course_id: course?.course_id,
                            }
                            dispatch(loadCourseById(data, selectedLanguage, (resData) => {
                                console.log(resData);
                                onNavigateTo(PAGES.COURSE + ":" + course?.course_id);
                            }));
                        }}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'} style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>

                                <p className={'p-desc desc p-desc-list'}>{ course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage ? l10n : L10N.si.value, course?.packages[0].l10n)]?.description_list}</p>
                                <p className={'p-desc desc truncate'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{ course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>
                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[0]?.dates)})</p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: 0}}>
                                            <img src={iconStar} className={'icon-info'} alt={'sum_img'}
                                                  width={'14px'}/>
                                            <p className={'p-info'}>{'5.3 (3000)'}</p>
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && ((course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'} style={{display: course?.packages[0] && course?.packages[0].price_discounted ? "flex" : "none"}}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{course?.packages[0] && course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderPage = () => {
        return <>
            <div ref={ref}/>
            {user && token ? <Header loggedIn/> : <HeaderLanding/>}

            {/*<Header loggedIn/>*/}
            <animated.div  style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>
            <animated.div style={fadeIn}>
                {renderMap()}
            </animated.div>
            <FooterPage/>
        </>
    }

    if (loginSuccessful) {
        console.log('redirect');

        onNavigateTo(PAGES.HOME);
        return <></>
    }

    return (
        renderPage()
    );
}

export default MainPage;