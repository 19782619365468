import React, {useState} from "react"
import {Map, Marker} from "pigeon-maps";
import {maptiler} from 'pigeon-maps/providers';
import '../css/CustomMap.css';

export function CustomMap(props) {
    const [center, setCenter] = useState([46.15032069424988, 14.506178534498291])
    const [zoom, setZoom] = useState(8)

    const maptilerProvider = maptiler('Z7hT7wIO9S4IJnmRz9Zk', 'streets')
    return (
        <div className={'custom_map_container'}>
            <Map
                provider={maptilerProvider}
                dprs={[1, 2]} // this provider supports HiDPI tiles
                height={400}
                boxClassname={'custom_map'}
                center={center}
                zoom={zoom}
                metaWheelZoom
                onBoundsChanged={({ center, zoom }) => {
                    setCenter(center)
                    setZoom(zoom)
                }} >
                <Marker width={50} anchor={[46.05146046007696, 14.492159691101703]}/>
            </Map>
        </div>
    )
}

export default CustomMap;