import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory, useParams} from "react-router-dom";

import {L10N, TIERS, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    createCourse,
    createFollowerForCourse,
    createFollowerForProvider,
    getFaqProvider,
    isRememberMeEnabled, loadAllCategories,
    loadAllCategoriesParsed,
    loadAllCourses, loadAllCoursesFollowed,
    loadAllProviders,
    loadCategoryDataById,
    loadCourseById,
    loadCourseImages,
    loadCredentials,
    loadFollowerByUserId, loadFollowerByUserIdforProvider,
    loadFollowerForCourse,
    loadImage,
    loadLecturers,
    loadLocalL10N,
    loadProviderData,
    loadProviderDataById,
    removeFollowerForCourse,
    removeFollowerForProvider,
    setL10N,
    setRememberMe,
    setSignInCredentials,
    userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    displayDate,
    displayDateMonthYear,
    getCourseDatesParsedByStartDatesAndTheirDates,
    getIndexOf,
    getNextCourseStartDate, getVideoIdFromUrl,
    mapCaretChars,
    parseHoursDuration,
    parseHoursDurationFromTimestamps, parseHoursDurationFromTimestampsFormatted,
    parseMapCaretChars,
    parseSelectedCountryLabel,
    scrollToDiv
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import iconStarGray from "../assets/images/i_star.png";

import starBlueFull from "../assets/images/star-blue-full.png";
import iconAcc from "../assets/images/acc.png";
import iconHeart from "../assets/images/heart_blue.png";
import iconHeartFull from "../assets/images/heart-full.png";
import iconHamburger from "../assets/images/hamburger.png";
import iconClose from "../assets/images/close.png";
import iconMessage from "../assets/images/message-circle.png";
import hotelImg from "../assets/images/hotel_bor_slovenia_360.jpg";
import iconMoon from "../assets/images/moon-white.png";
import iconImageExample from "../assets/images/img_example.png";
import iconAddress from "../assets/images/i_address.png";
import iconMapPin from "../assets/images/map-pin.png";
import iconMean from "../assets/images/i_mean.png";
import iconStar from "../assets/images/i_star.png";
import iconSum from "../assets/images/i_sum.png";
import iconNight from "../assets/images/i_night.png";
import courseImg from "../assets/images/course.png";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import cloud from "../assets/images/cloud.png";

import Title from "../components/js/Title";


import Footer from "../components/js/Footer";
import {CREATE_ACTION} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import Button from "../components/js/Button";
import DropdownCircle from "../components/js/DropdownCircle";
import FooterPage from "../components/js/FooterPage";
import GoogleMap from "../components/js/GoogleMap";
import Filters from "../components/js/Filters";
import Search from "../components/js/Search";
import HeaderLanding from "../components/js/HeaderLanding";
import CustomMap from "../components/js/CustomMap";
import {renderDescListInput} from "./CoursePageEdit";
import iconCalendar from "../assets/images/calendar.png";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";
import YouTubeEmbed from "../components/js/YoutubeEmbed";

const FAQ = [
    {question: 'Vprašanje', answer: 'Odgovor..'},
    {question: 'Daljše Vprašanje', answer: 'Odgovor..'},
    {question: 'Še daljše vprašanje za nadaljevanjem?', answer: 'Daljši in podrobnejši odgovor na vprašanje...'},
    {question: 'Še daljše vprašanje za nadaljevanjem?', answer: 'Daljši in podrobnejši odgovor na vprašanje...'},
    {question: 'Vprašanje', answer: 'Odgovor..'},
    {question: 'Vprašanje', answer: 'Odgovor..'},
];

const USER = {
    user_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    full_name: 'Tadej Hiti',
    user_role: USER_ROLES.CUSTOMER,
    password: 'lulček#%hashed',
    email: 'hiti.tadej@gmail.com',
    tel: '+38640306677',
    address: 'Ograde 3',
    l10n_default: L10N.si.value,
    l10n_en: false,
    l10n_si: false,
    l10n_it: false,
};
const PROVIDER = {
    provider_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    images: [iconImageExample, iconImageExample, iconImageExample, iconImageExample, iconImageExample, iconImageExample]
};

export const COURSES = [
    {
        course_id: 'dsfsdf54',
        provider_name: 'Plesna šola vzorec',
        address: 'Židovska steza 5, 1000 Ljubljana, Slovenija',
        name: 'Nega obraza',
        description: 'Masaža obraza, japonski lifting obraza, dvig obrvi, različne tehnike masaže.',
        time_period: 'Online, 05. maj - 24. avg 2022',
        nights: '3',
        hours: '7', //TODO add hours to DB
        price_hour: '3',
        stars: '5,3  (3.000)',
        accommodation_price: '150',
        price: '16,99',
        price_discounted: '9,99',
        dates: ['Ponedeljek, 23. mar 2022, 10.30 - 15.00', 'Torek, 24. mar 2022, 13.13 - 16.00', 'Sreda, 25. mar 2022, 12.30 - 15.00', 'Četrtek, 26. mar 2022, 12.30 - 15.00', 'Petek, 27. mar 2022, 12.30 - 15.00', 'Sobota, 28. mar 2022, 12.30 - 15.00', 'Nedelja, 29. mar 2022, 12.30 - 15.00', 'Ponedeljek, 30. mar 2022, 12.30 - 15.00', 'Torek, 31. mar 2022, 12.30 - 15.00', 'Sreda, 1. apr 2022, 12.00 - 15.00'],
    },
    {
        course_id: 'dsfs45gdf54',
        provider_name: 'Plesna šola vzorec',
        address: 'Židovska steza 5, 1000 Ljubljana, Slovenija',
        name: 'Nega obraza',
        description: 'Masaža obraza, japonski lifting obraza, dvig obrvi, različne tehnike masaže.',
        time_period: 'Online, 05. maj - 24. avg 2022',
        nights: '3',
        hours: '7', //TODO add hours to DB
        price_hour: '3',
        stars: '5,3  (3.000)',
        accommodation_price: '150',
        price: '16,99',
        price_discounted: '9,99',
        dates: ['Ponedeljek, 23. mar 2022, 10.30 - 15.00', 'Torek, 24. mar 2022, 13.13 - 16.00', 'Sreda, 25. mar 2022, 12.30 - 15.00'],
    },
    {
        course_id: 'dsfdfsghsdf54',
        provider_name: 'Plesna šola vzorec',
        address: 'Židovska steza 5, 1000 Ljubljana, Slovenija',
        name: 'Nega obraza',
        description: 'Masaža obraza, japonski lifting obraza, dvig obrvi, različne tehnike masaže.',
        time_period: 'Online, 05. maj - 24. avg 2022',
        nights: '3',
        hours: '7', //TODO add hours to DB
        price_hour: '3',
        stars: '5,3  (3.000)',
        accommodation_price: '150',
        price: '16,99',
        price_discounted: '9,99',
        dates: ['Ponedeljek, 23. mar 2022, 10.30 - 15.00', 'Torek, 24. mar 2022, 13.13 - 16.00', 'Sreda, 25. mar 2022, 12.30 - 15.00'],
    },
    {
        course_id: 'dsfsd46567f54',
        provider_name: 'Plesna šola vzorec',
        address: 'Židovska steza 5, 1000 Ljubljana, Slovenija',
        name: 'Nega obraza',
        description: 'Masaža obraza, japonski lifting obraza, dvig obrvi, različne tehnike masaže.',
        time_period: 'Online, 05. maj - 24. avg 2022',
        nights: '3',
        hours: '7', //TODO add hours to DB
        price_hour: '3',
        stars: '5,3  (3.000)',
        accommodation_price: '150',
        price: '16,99',
        price_discounted: '9,99',
        dates: ['Ponedeljek, 23. mar 2022, 10.30 - 15.00', 'Torek, 24. mar 2022, 13.13 - 16.00', 'Sreda, 25. mar 2022, 12.30 - 15.00'],
    }
];


const FOLLOWING = [
    {
        provider_id: 'dfgdfgdhfj',
        name: 'Plesna Šola Vzorec',
    },
    {
        provider_id: 'dfgdfg8678dhfj',
        name: 'Plesna Šola Vzorec',
    }
];


export const parseLanguagesSpoken = (obj) => {
    let s = "";
    s += obj?.l10n_en ? "angleško, " : "";
    s += obj?.l10n_si ? "slovensko, " : "";
    s += obj?.l10n_it ? "italijansko, " : "";
    s += obj?.l10n_hr ? "hrvaško, " : "";
    s += obj?.l10n_de ? "nemško, " : "";

    if (s && s[s.length - 2] === ",") {
        s = s.substring(0, s.length - 2);
    }
    return (s);
}


function CoursePage() {
    let {course_id: course_id} = useParams();

    course_id = course_id.substring(1);

    console.log(course_id)

    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const course = useSelector(state => state.reducer.course);
    const provider = useSelector(state => state.reducer.provider);
    const l10n = useSelector(state => state.reducer.l10n);

    console.log("COURSE:", course)

    const [selectedGalleryIndex, setSelectedGalleryIndex] = useState(0);

    const [accommodationGroup1, setAccommodationGroup1] = useState(false);
    const [accommodationGroup2, setAccommodationGroup2] = useState(false);
    const [accommodationGroup3, setAccommodationGroup3] = useState(false);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();


    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [openedFaq, setOpenedFaq] = useState(false);

    const [toggleFilters, setToggleFilters] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState();

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);

    const [followCourse, setFollowCourse] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(0);

    const [faqs, setFaqs] = useState([]);

    const [basicGroupStartDates, setBasicGroupStartDates] = useState([]);
    const [advancedGroupStartDates, setAdvancedGroupStartDates] = useState([]);
    const [expertGroupStartDates, setExpertGroupStartDates] = useState([]);

    const [basicGroupDatesParsed, setBasicGroupDatesParsed] = useState([]);
    const [advancedGroupDatesParsed, setAdvancedGroupDatesParsed] = useState([]);
    const [expertGroupDatesParsed, setExpertGroupDatesParsed] = useState([]);

    const [basicGroupStartDateIndex, setBasicGroupStartDateIndex] = useState(0);
    const [advancedGroupStartDateIndex, setAdvancedGroupStartDateIndex] = useState(0);
    const [expertGroupStartDateIndex, setExpertGroupStartDateIndex] = useState(0);

    const [name, setName] = useState(course?.l10n[getIndexOf("l10n", selectedLanguage, course.l10n)]?.name);

    const [category, setCategory] = useState(null);
    const [categoryParent, setCategoryParent] = useState(null);


    const [courseName, setCourseName] = useState(course?.l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.l10n)]?.name);
    const [courseLearn, setCourseLearn] = useState(course?.packages[0].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[0].l10n)]?.description_list);
    const [courseLearn1, setCourseLearn1] = useState(course?.packages[1].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[1].l10n)]?.description_list);
    const [courseLearn2, setCourseLearn2] = useState(course?.packages[2].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[2].l10n)]?.description_list);
    const [courseAddress, setCourseAddress] = useState(course.address);
    const [courseCategory, setCourseCategory] = useState();
    const [courseDesc, setCourseDesc] = useState(course?.packages[0].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[0].l10n)]?.description);
    const [courseNeeds, setCourseNeeds] = useState(course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].requirements_list);

    const [courseYoutubeUrl, setCourseYoutubeUrl] = useState(course?.youtube_url);

    const [courseRewards, setCourseRewards] = useState(course.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].included_list);
    const [courseRewards1, setCourseRewards1] = useState(course.packages[1].l10n[getIndexOf("l10n", selectedLanguage, course.packages[1].l10n)].included_list);
    const [courseRewards2, setCourseRewards2] = useState(course.packages[2].l10n[getIndexOf("l10n", selectedLanguage, course.packages[2].l10n)].included_list);

    const [courseVisits, setCourseVisits] = useState(course?.packages[0]?.visits);
    const [courseVisits1, setCourseVisits1] = useState(course?.packages[1]?.visits);
    const [courseVisits2, setCourseVisits2] = useState(course?.packages[2]?.visits);

    const [coursePrice, setCoursePrice] = useState(course?.packages[0]?.price);
    const [coursePrice1, setCoursePrice1] = useState(course?.packages[1]?.price);
    const [coursePrice2, setCoursePrice2] = useState(course?.packages[2]?.price);

    const [totalPrice, setTotalPrice] = useState(course?.packages[0]?.price);
    const [totalPrice1, setTotalPrice1] = useState(course?.packages[1]?.price);
    const [totalPrice2, setTotalPrice2] = useState(course?.packages[2]?.price);

    const [coursePriceDiscounted, setCoursePriceDiscounted] = useState(course?.packages[0]?.price_discounted);
    const [coursePriceDiscounted1, setCoursePriceDiscounted1] = useState(course?.packages[1]?.price_discounted);
    const [coursePriceDiscounted2, setCoursePriceDiscounted2] = useState(course?.packages[2]?.price_discounted);

    const [courseTraineeAge, setCourseTraineeAge] = useState(course?.packages[0]?.trainees_age);
    const [courseTraineeAge1, setCourseTraineeAge1] = useState(course?.packages[1]?.trainees_age);
    const [courseTraineeAge2, setCourseTraineeAge2] = useState(course?.packages[2]?.trainees_age);

    const [courseTraineeMax, setCourseTraineeMax] = useState(course?.packages[0]?.trainees_max);
    const [courseTraineeMax1, setCourseTraineeMax1] = useState(course?.packages[1]?.trainees_max);
    const [courseTraineeMax2, setCourseTraineeMax2] = useState(course?.packages[2]?.trainees_max);

    const [accDesc, setAccDesc] = useState(course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)]?.accommodation_description);
    const [accName, setAccName] = useState(course?.packages[0]?.accommodation_name);
    const [accNights, setAccNights] = useState(course?.packages[0]?.accommodation_nights);
    const [accPrice, setAccPrice] = useState(course?.packages[0]?.accommodation_price);
    const [accLocation, setAccLocation] = useState(course.packages[0]?.accommodation_address);

    const [logoProvider, setLogoProvider] = useState([]);
    const [logoAccommodation, setLogoAccommodation] = useState([]);
    const [images, setImages] = useState([]);
    const [lecturersProfilePictures, setLecturersProfilePictures] = useState([]);

    const [lecturers, setLecturers] = useState([]);
    const [userFollower, setUserFollower] = useState(null);
    const [userFollowerProvider, setUserFollowerProvider] = useState(null);

    const [allCourses, setAllCourses] = useState([]);
    const [allCategories, setAllCategories] = useState([]);

    const [coursesForCategory, setCoursesForCategory] = useState([]);

    const [isFilterUsed, setIsFilterUsed] = useState(false);
    const [rerender, setRerender] = useState(false);

    const [filteredCourses, setFilteredCourses] = useState([]);
    const [isAccommodationSet, setIsAccommodationSet] = useState(course?.packages[0]?.accommodation_price);



    console.log("IMG", images)

    let priceDis = Number(coursePriceDiscounted) ? 'row inner strikethrough' : 'row inner';
    let priceDis1 = Number(coursePriceDiscounted1) ? 'row inner strikethrough' : 'row inner';
    let priceDis2 = Number(coursePriceDiscounted2) ? 'row inner strikethrough' : 'row inner';

    let finalPrice = Number(coursePriceDiscounted) ? coursePriceDiscounted : coursePrice;
    let finalPrice1 = Number(coursePriceDiscounted1) ? coursePriceDiscounted1 : coursePrice1;
    let finalPrice2 = Number(coursePriceDiscounted2) ? coursePriceDiscounted2 : coursePrice2;

    const reloadCourseL10NData = (course) => {
        console.log("reload")
        let s = course?.l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.l10n)]?.name;
        setCourseName(s ? s : "");

        s = course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].accommodation_description;
        setAccDesc(s ? s : "");

        s = course?.packages[0].accommodation_name;
        setAccName(s ? s : "");

        s = course?.packages[0].accommodation_address;
        setAccLocation(s ? s : "");

        s = course?.packages[0].accommodation_price;
        setAccPrice(s ? s : "");

        s = course?.packages[0].accommodation_nights;
        setAccNights(s ? s : "");

        s = course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage, course.packages[0].l10n)].requirements_list;
        setCourseNeeds(s ? s : "");

        let dl;
        let il;
        let vis;
        let maxNmb;
        let age;
        let p;
        let pD;
        for (let i = 0; i < TIERS.length; i++) {
            dl = course?.packages[i].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[i].l10n)]?.description_list;
            il = course?.packages[i].l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, course.packages[i].l10n)]?.included_list;
            vis = course?.packages[i]?.visits;
            maxNmb = course?.packages[i]?.trainees_max;
            age = course?.packages[i]?.trainees_age;
            p = course?.packages[i]?.price;
            pD = course?.packages[i]?.price_discounted;

            if (i === 0) {
                setCourseLearn(dl ? dl : "");
                setCourseRewards(il ? il : "");
                setCourseVisits(vis ? vis : "");
                setCourseTraineeAge(age ? age : "");
                setCourseTraineeMax(maxNmb ? maxNmb : "");
                setCoursePrice(p ? p : "");
                setCoursePriceDiscounted(pD ? pD : "");
            } else if (i === 1) {
                setCourseLearn1(dl ? dl : "");
                setCourseRewards1(il ? il : "");
                setCourseVisits1(vis ? vis : "");
                setCourseTraineeAge1(age ? age : "");
                setCourseTraineeMax1(maxNmb ? maxNmb : "");
                setCoursePrice1(p ? p : "");
                setCoursePriceDiscounted1(pD ? pD : "");
            } else {
                setCourseLearn2(dl ? dl : "");
                setCourseRewards2(il ? il : "");
                setCourseVisits2(vis ? vis : "");
                setCourseTraineeAge2(age ? age : "");
                setCourseTraineeMax2(maxNmb ? maxNmb : "");
                setCoursePrice2(p ? p : "");
                setCoursePriceDiscounted2(pD ? pD : "");
            }
        }
    }

    console.log(category);


    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                opacity: 0,
                height: 0,
            },
            to: {
                opacity: toggleFilters ? 1 : 0,
                height: toggleFilters ? 'auto' : 0,
                display: toggleFilters ? 'flex' : 'none',
            },
            config: {duration: 150},
        }))


    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const dispatchLoadLecturers = (courseOnly) => {
        dispatch(loadLecturers({provider_id: provider.provider_id}, (resData) => {
            console.log(resData);

            let lecturersUpdated = [];
            let u = [];

            for (let i = 0; i < resData.length; i++) {
                let lec = resData[i];


                if (lec.course_id !== course.course_id) {
                    continue;
                }
                lecturersUpdated.push(lec);
                console.log("LEC[i]:", lec, course.course_id, lec.course_id)

                if (!lec.image_id) {
                    u.push(null)
                    continue;
                }

                dispatch(loadImage({image_id: lec.image_id, type: "LOGO"}, (resData) => {
                    u.push(resData.image)

                    setTimeout(() => {
                        setLecturersProfilePictures(u);
                        console.log("LEC: ", lecturersUpdated)
                    }, 200);
                }, () => {
                }))


            }
            setLecturers(lecturersUpdated);

        }, () => {
        }));
    }

    useEffect(() => {

        if (provider) {
            if (provider?.image_id) {
                dispatch(loadImage({image_id: provider.image_id, type: "LOGO"}, (resData) => {
                    setLogoProvider([{data_url: resData.image}]);
                }, () => {
                    setLogoProvider([]);
                }))
            }
            dispatch(getFaqProvider({provider_id: provider.provider_id}, selectedLanguage, (resData) => {
                setFaqs(resData);
            }))
            dispatchLoadLecturers(true);
        }

    }, [provider]);

    const dispatchLoadFollower = () => {
        if (!user) {
            return;
        }
        dispatch(loadFollowerForCourse({user_id: user.user_id, course_id: course.course_id}, async (resData) => {
            if (!resData) {
                return;
            }
            setFollowCourse(resData?.course_id === course?.course_id)
            setUserFollower(resData);
        }, () => {
            setUserFollower(null);
        }));
    }

    const dispatchLoadFollowerProvider = (provider_id) => {
        if (!user) {
            return;
        }

        dispatch(loadFollowerByUserIdforProvider({user_id: user.user_id, provider_id: provider_id ? provider_id : course?.provider?.provider_id}, async (resData) => {
            if (!resData) {
                setUserFollowerProvider(null);
                return;
            }
            console.log("USER FOLLOWER/PROVIDER", resData);

            setUserFollowerProvider(resData);
        }, () => {
            setUserFollowerProvider(null);
        }));
    }


    useEffect(() => {
        if (user && course) {
            dispatchLoadFollower();
            dispatchLoadFollowerProvider();
        }
    }, [user, course])

    useEffect(() => {
        if (userFollower && course) {
            //setFollowCourse(userFollower?.course_id === course?.course_id)
        }
    }, [userFollower, course])

    useEffect(() => {
        if (!toggleFilters) {
            setFilteredCourses([]);
        }
    }, [toggleFilters])

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);

        if (course) {
            dispatch(loadCourseById({course_id: course.course_id}, selectedLanguage, () => {
            }, () => {
            }))

            reloadCourseL10NData(course);
        }

        //   reloadCourseL10NData(course);


    }, [l10n, selectedLanguage]);

    useEffect(() => {
        console.log(course_id)
        if (course_id) {
            dispatch(loadCourseById({course_id: course_id}, selectedLanguage, (resData) => {
                dispatch(loadProviderDataById({provider_id: resData.provider_id}, selectedLanguage, () => {
                    dispatchLoadFollowerProvider(resData.provider_id);
                    }, () => {
                }))

                dispatch(loadCategoryDataById({category_id: resData.category_id}, selectedLanguage, (resData) => {
                    setCategory(resData)

                    if (resData.parent_id) {
                        dispatch(loadCategoryDataById({category_id: resData.parent_id}, selectedLanguage, (resData) => {
                            setCategoryParent(resData)

                        }, () => {
                        }))
                    }
                }, () => {
                }))
            }, () => {
            }))
        }

    }, [course_id]);

    useEffect(() => {

        if (course.image_id) {
            dispatch(loadImage({image_id: course.image_id, type: "LOGO"}, (resData) => {
                setLogoAccommodation([{data_url: resData.image}]);
            }, () => {
                setLogoAccommodation([]);
            }));
        }

        if (course) {
            dispatch(loadCourseImages({course_id: course.course_id, type: "GALLERY"}, (resData) => {
                //setLogo([{data_url: resData.image}]);

                for (let i = 0; i < resData.length; i++) {
                    resData[i].data_url = resData[i].image;
                    delete resData[i].image;
                }
                setImages(resData);
            }, () => {
                //setLogo([]);
                //updateProviderDataHandler("image_id", null);
            }));


            let a = getCourseDatesParsedByStartDatesAndTheirDates(course?.packages[0]?.dates, selectedLanguage);
            let b = getCourseDatesParsedByStartDatesAndTheirDates(course?.packages[1]?.dates, selectedLanguage);
            let c = getCourseDatesParsedByStartDatesAndTheirDates(course?.packages[2]?.dates, selectedLanguage);

            setBasicGroupStartDates(a?.startDates);
            setAdvancedGroupStartDates(b?.startDates);
            setExpertGroupStartDates(c?.startDates);

            setBasicGroupDatesParsed(a?.restDates);
            setAdvancedGroupDatesParsed(b?.restDates);
            setExpertGroupDatesParsed(c?.restDates);


            const filterCoursesByCategory = (courses, category_id) => {
                return courses.filter(course => course.category_id === category_id);
            };
            let categoryCourses = filterCoursesByCategory(allCourses, course.category_id);
            setCoursesForCategory(categoryCourses);

        }
    }, [course])

    const ref = useRef();

    useEffect(() => {
        /*
        dispatch(loadAllProviders((res) => {
        }, (res) => {
        }));

         */
        dispatch(loadAllCourses((res) => {
            setAllCourses(res);
        }, (res) => {
        }));
        dispatch(loadAllCategories((res) => {
            setAllCategories(res);
        }, (res) => {
        }));
        ref.current.scrollIntoView()

    }, [])



    useEffect(() => {
        if (!search || search === "") {
            console.log(search)
            setIsFilterUsed(false)
            setFilteredCourses([]);
        }
    }, [search])


    const renderCoursesSearch = (courses, title) => {
        if (!selectedLanguage || !courses || courses?.length <= 0) {
            if (filteredCourses === courses && isFilterUsed) {
                return <>
                    <Title dots title={title}/>
                    <p className={'p-normal'} style={{
                        paddingBottom: '80px',
                        fontStyle: 'italic'
                    }}>{L10N_APP_TEXTS[selectedLanguage].NO_SEARCH_RESULTS}</p>
                </>
            }
            return <></>
        }
        return (
            <>
                <Title dots title={title}/>
                <div className={'courses_container scroll'} style={{marginBottom: '48px'}}>
                    {courses.map((course, c) => (

                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'}
                             style={{cursor: "pointer"}} onClick={() => {
                            let data = {
                                course_id: course?.course_id,
                            }
                            dispatch(loadCourseById(data, selectedLanguage, (resData) => {
                                console.log(resData);
                                onNavigateTo(PAGES.COURSE + ":" + course?.course_id);
                            }));
                        }}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>
                                <p className={'p-desc desc'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>
                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[0]?.dates)})</p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconStar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            {/*<p className={'p-info'}>{'5.3 (3000)'}</p> */}
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && ((course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{course?.packages[0] && course?.packages[0].price_discounted + " " + selectedLanguageObj?.priceValue}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };
    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

    const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

        return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const onActionCollapseFaq = (i) => {
        setOpenedFaq(i);
    }

    const displayCollapsibleFaq = (status, question, answer) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header faq-options'} style={{justifyContent: 'flex-end'}}>
                <div className={'faq-question'}>
                    <p className={'p-title'}>{question}</p>
                    {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
                </div>
                {status ? <div className={'faq-desc'}>
                    <p className={'p-header black'}>{answer}</p>
                </div> : <></>}
            </div>
        );
    }

    const renderFaq = () => {
        return (
            <>
                <Title dots title={'FAQs'}/>
                <div className={'faq-container'}>
                    {faqs.map((faq, f) => (
                        <div key={`${f}_${faq.question}`} className={'collapsible-faq-container'}>
                            <Collapsible className={'faq-options'}
                                         transitionTime={400}
                                         onClosing={() => onActionCollapseFaq(false)}
                                         onOpening={() => onActionCollapseFaq(f)}
                                         open={openedFaq === f}
                                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                                         trigger={displayCollapsibleFaq(false, faq.question, faq.answer)}
                                         triggerWhenOpen={displayCollapsibleFaq(true, faq.question, faq.answer)}>
                            </Collapsible>
                        </div>
                    ))}
                </div>
            </>

        );
    }


    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}
                <Search onChange={(value) => onInputChange(value, setSearch)}
                        placeholder={`${L10N_APP_TEXTS[selectedLanguage].FIND_COURSE}..`}
                        allCourses={allCourses}
                        onSubmit={(filtered) => {
                            setFilteredCourses(filtered)
                        }}/>
                <div className={'hamburger_menu'} onClick={() => {
                    setToggleFilters(!toggleFilters);
                }}>
                    <img src={!toggleFilters ? iconHamburger : iconClose} className={'icon-label'} alt={'img'}
                         width={'24px'}/>
                </div>
                <animated.div style={slideUp}>
                    <Filters
                        allCourses={allCourses}
                        categories={allCategories}
                        onSubmit={(filtered) => {
                            setRerender(!rerender);
                            setIsFilterUsed(true);

                            setFilteredCourses(filtered)
                        }}/>
                </animated.div>

                {renderCoursesSearch(filteredCourses, L10N_APP_TEXTS[selectedLanguage].SEARCH, rerender)}

                {renderCourseInfo()}
                {renderGallerySection()}
                {renderYoutubeVideo(courseYoutubeUrl)}
                {renderCoursePackages()}
                {renderCourseRequirements()}
                {renderCourseAccommodation()}
                {renderLecturers()}
                {renderComparisonSection()}
                {/*renderReviews()*/}
                {renderCourses(coursesForCategory, L10N_APP_TEXTS[selectedLanguage].SUGGESTED, "tab_suggested")}
                {renderFaq()}
            </div>
        )
    }

    const renderGallerySection = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].PROVIDER_ABOUT}/>
                <div className={'provider-info-container'}>
                    <div className={'gallery'}>
                        <img src={images && images.length > 0 ? images[selectedGalleryIndex]?.data_url : ""}
                             className={'gallery_img'} alt={'img'}/>
                    </div>
                    <div className={'gallery_row scroll'}>
                        {images.map((image, p) => (
                            <div key={`${p}_img`} className={'gallery_img_cont'} onClick={() => {
                                setSelectedGalleryIndex(p);
                            }}>
                                <img src={image?.data_url} className={'gallery_img'} alt={'img'} height={'80px'}
                                     style={{borderRadius: '24px'}}/>
                            </div>
                        ))}
                    </div>
                    {renderCloudOverlay()}
                </div>
            </>
        );
    }
    console.log(accommodationGroup1, accommodationGroup2, accommodationGroup3);

    const renderYoutubeVideo = (videoUrl) => {
        if (!videoUrl) {
            return <></>
        }
        let videoId = getVideoIdFromUrl(videoUrl)
        return (
            <div>
                <YouTubeEmbed embedId={videoId} />
            </div>
        );
    }

    const renderAccommodationCheckbox = (value, setValue) => {
        console.log("ACC", isAccommodationSet)
        return (
            <div className={'checkbox-container checkbox-accommodation'} style={{alignSelf: 'baseline'}}>
                <label>
                    <input type="checkbox"
                           checked={value}
                           onChange={() => {
                               setValue(!value);
                           }}
                           style={{marginLeft: '6px'}}
                    />
                </label>
            </div>
        );
    }

    const onNextDate = (index, setIndex, dates) => {
        console.log(index, setIndex, dates);

        let i = index;
        i++;
        if (i >= dates.length) {
            i = 0;
        }
        setIndex(i);
    };

    const onPreviousDate = (index, setIndex, dates) => {
        console.log(index, dates);

        let i = index;
        i--;
        if (i < 0) {
            i = dates.length - 1;
        }
        setIndex(i);
    };

    const renderPackage = (finalPrice, priceDis, j) => {

        console.log(coursePrice, coursePriceDiscounted)
        return <>
            <div className={'comparison-section col'}>
                <div className={'course-package-col scroll'}
                     style={{height: '200px', maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
                    <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].BASIC_GROUP}</p>
                    {renderDescListInput(courseLearn)}
                </div>

                <div className={'row-cloud row space-between'}
                     style={{padding: '24px 48px 12px 60px', marginBottom: '-8px'}}>
                    <p className={'click'} style={{fontSize: '20px'}} onClick={() => {
                        onPreviousDate(basicGroupStartDateIndex, setBasicGroupStartDateIndex, basicGroupStartDates);
                    }}>{`<`}</p>
                    <p className={'p-normal'}
                       style={{fontWeight: 600}}>{basicGroupStartDates ? basicGroupStartDates[basicGroupStartDateIndex] : ''}</p>
                    <p className={'click'} style={{fontSize: '20px'}} onClick={() => {
                        onNextDate(basicGroupStartDateIndex, setBasicGroupStartDateIndex, basicGroupStartDates);
                    }}>{`>`}</p>
                </div>

                <div className={'reservations-cloud scroll'}
                     style={{height: '200px', maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
                    {basicGroupDatesParsed[basicGroupStartDateIndex] && basicGroupDatesParsed[basicGroupStartDateIndex].map((date, d) => (
                        <div key={`${d}_${date}`} className={'reservation-col-date'}>

                            <div className={'reservation-row cloud'}>
                                <p className={'p-text'}>{date.split(',')[0]}</p>
                                <p className={'p-normal'}>{date.split(',')[1]}</p>
                                <p className={'p-normal'}>{date.split(',')[2]}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'row-cloud row center'}>
                    <p className={'p-normal'}>{courseVisits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[j]?.dates)})</p>
                </div>
                <div className={'course-package-col scroll'}
                     style={{
                         height: '200px',
                         maxHeight: '200px',
                         overflowY: 'auto',
                         overflowX: 'hidden',
                         width: '100%'
                     }}>
                    {renderDescListInput(courseRewards)}
                </div>
                <div className={'accommodation-cloud'}>
                    <div className={'reservation-row cloud accommodation'}>

                        <div className={'row inner'}>
                            {!isAccommodationSet || isAccommodationSet === "" ? L10N_APP_TEXTS[selectedLanguage].NO_ACCOMMODATION :
                                <>
                                    {renderAccommodationCheckbox(accommodationGroup1, setAccommodationGroup1)}
                                    <div className={'row inner'}>
                                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].PRICE_FOR} {accNights} {L10N_APP_TEXTS[selectedLanguage].NIGHTS}</p>
                                        <p className={'p-normal'}>{accPrice} {selectedLanguageObj?.priceValue}</p>
                                    </div>
                                </>
                            }

                        </div>
                        {
                            coursePrice && coursePriceDiscounted? <div className={priceDis} style={{flexWrap: 'wrap'}}>
                                <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE__DEFAULT_PRICE}</p>
                                <p className={'p-normal'}>{coursePrice} {selectedLanguageObj?.priceValue}</p>
                            </div> : <></>
                        }
                        {
                            coursePrice && coursePriceDiscounted?  <div className={'row inner'}
                                                                          style={{flexWrap: 'wrap', opacity: Number(coursePriceDiscounted) ? 1 : 0}}>
                                <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                <p className={'p-normal'}>{coursePriceDiscounted} {selectedLanguageObj?.priceValue}</p>
                            </div> : <></>
                        }
                        {
                            coursePrice && !coursePriceDiscounted ?
                                <><div className={'row inner'}
                                       style={{flexWrap: 'wrap', opacity: Number(coursePrice) ? 1 : 0}}>
                                    <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                    <p className={'p-normal'}>{coursePrice} {selectedLanguageObj?.priceValue}</p>
                                </div>
                                    <div className={'row inner'}
                                         style={{flexWrap: 'wrap', opacity: Number(coursePriceDiscounted) ? 1 : 0}}>
                                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                        <p className={'p-normal'}>{coursePriceDiscounted} {selectedLanguageObj?.priceValue}</p>
                                    </div></>: <></>
                        }


                    </div>
                </div>

                <div className={'cloud row center'}>
                    <p className={'p-submit large click'}>{L10N_APP_TEXTS[selectedLanguage].BUY} {accommodationGroup1 ? finalPrice + Number(accPrice) : finalPrice} {selectedLanguageObj?.priceValue}</p>
                </div>
            </div>
        </>
    };

    const renderPackage1 = (finalPrice, priceDis) => {
        if (!finalPrice) {
            return <></>
        }
        return <>
            <div className={'comparison-section col '}>
                <div className={'course-package-col scroll'}
                     style={{height: '200px', maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
                    <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].ADVANCED_GROUP}</p>
                    {renderDescListInput(courseLearn1)}
                </div>

                <div className={'row-cloud row space-between'}
                     style={{padding: '24px 48px 12px 60px', marginBottom: '-8px'}}>
                    <p className={'click'} style={{fontSize: '20px'}} onClick={() => {
                        onPreviousDate(advancedGroupStartDateIndex, setAdvancedGroupStartDateIndex, advancedGroupStartDates);
                    }}>{`<`}</p>
                    <p className={'p-normal'}
                       style={{fontWeight: 600}}>{advancedGroupStartDates ? advancedGroupStartDates[advancedGroupStartDateIndex] : ''}</p>
                    <p className={'click'} style={{fontSize: '20px'}} onClick={() => {
                        onNextDate(advancedGroupStartDateIndex, setAdvancedGroupStartDateIndex, advancedGroupStartDates);
                    }}>{`>`}</p>
                </div>

                <div className={'reservations-cloud scroll'}
                     style={{height: '200px', maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
                    {advancedGroupDatesParsed[advancedGroupStartDateIndex] && advancedGroupDatesParsed[advancedGroupStartDateIndex].map((date, d) => (
                        <div key={`${d}_${date}`} className={'reservation-col-date'}>
                            <div className={'reservation-row cloud'}>
                                <p className={'p-text'}>{date.split(',')[0]}</p>
                                <p className={'p-normal'}>{date.split(',')[1]}</p>
                                <p className={'p-normal'}>{date.split(',')[2]}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'row-cloud row center'}>
                    <p className={'p-normal'}>{courseVisits1} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDuration(advancedGroupDatesParsed)})</p>
                </div>
                <div className={'course-package-col scroll'}
                     style={{height: '200px', maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
                    {renderDescListInput(courseRewards1)}
                </div>
                <div className={'accommodation-cloud'}>
                    <div className={'reservation-row cloud accommodation'}>
                        <div className={'row inner'}>
                            {!accNights ? L10N_APP_TEXTS[selectedLanguage].NO_ACCOMMODATION :
                                <>
                                    {renderAccommodationCheckbox(accommodationGroup2, setAccommodationGroup2)}

                                    <div className={'row inner'}>
                                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].PRICE_FOR} {accNights} {L10N_APP_TEXTS[selectedLanguage].NIGHTS}</p>
                                        <p className={'p-normal'}>{accPrice} {selectedLanguageObj?.priceValue}</p>
                                    </div>
                                </>
                            }

                        </div>

                        {
                            coursePrice1 && coursePriceDiscounted1? <div className={priceDis} style={{flexWrap: 'wrap'}}>
                                <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE__DEFAULT_PRICE}</p>
                                <p className={'p-normal'}>{coursePrice1} {selectedLanguageObj?.priceValue}</p>
                            </div> : <></>
                        }
                        {
                            coursePrice1 && coursePriceDiscounted1?  <div className={'row inner'}
                                                                         style={{flexWrap: 'wrap', opacity: Number(coursePriceDiscounted1) ? 1 : 0}}>
                                <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                <p className={'p-normal'}>{coursePriceDiscounted1} {selectedLanguageObj?.priceValue}</p>
                            </div> : <></>
                        }
                        {
                            coursePrice1 && !coursePriceDiscounted1 ?
                                <><div className={'row inner'}
                                     style={{flexWrap: 'wrap', opacity: Number(coursePrice1) ? 1 : 0}}>
                                    <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                    <p className={'p-normal'}>{coursePrice1} {selectedLanguageObj?.priceValue}</p>
                                </div>
                                <div className={'row inner'}
                                     style={{flexWrap: 'wrap', opacity: Number(coursePriceDiscounted1) ? 1 : 0}}>
                                    <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                    <p className={'p-normal'}>{coursePriceDiscounted1} {selectedLanguageObj?.priceValue}</p>
                                </div></>: <></>
                        }

                    </div>
                </div>

                <div className={'cloud row center'}>
                    <p className={'p-submit large click'}>{L10N_APP_TEXTS[selectedLanguage].BUY} {accommodationGroup2 ? finalPrice + Number(accPrice) : finalPrice} {selectedLanguageObj?.priceValue}</p>
                </div>
            </div>
        </>
    };

    const renderPackage2 = (finalPrice, priceDis) => {
        if (!finalPrice) {
            return <></>
        }
        return <>
            <div className={'comparison-section col'}>
                <div className={'course-package-col scroll'}
                     style={{height: '200px', maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
                    <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].EXPERT_GROUP}</p>
                    {renderDescListInput(courseLearn2)}
                </div>

                <div className={'row-cloud row space-between'}
                     style={{padding: '24px 48px 12px 60px', marginBottom: '-8px'}}>
                    <p className={'click'} style={{fontSize: '20px'}} onClick={() => {
                        onPreviousDate(expertGroupStartDateIndex, setExpertGroupStartDateIndex, expertGroupStartDates);
                    }}>{`<`}</p>
                    <p className={'p-normal'}
                       style={{fontWeight: 600}}>{expertGroupStartDates ? expertGroupStartDates[expertGroupStartDateIndex] : ''}</p>
                    <p className={'click'} style={{fontSize: '20px'}} onClick={() => {
                        onNextDate(expertGroupStartDateIndex, setExpertGroupStartDateIndex, expertGroupStartDates);
                    }}>{`>`}</p>
                </div>

                <div className={'reservations-cloud scroll'}
                     style={{height: '200px', maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
                    {expertGroupDatesParsed[expertGroupStartDateIndex] && expertGroupDatesParsed[expertGroupStartDateIndex].map((date, d) => (
                        <div key={`${d}_${date}`} className={'reservation-col-date'}>

                            <div className={'reservation-row cloud'}>
                                <p className={'p-text'}>{date.split(',')[0]}</p>
                                <p className={'p-normal'}>{date.split(',')[1]}</p>
                                <p className={'p-normal'}>{date.split(',')[2]}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'row-cloud row center'}>
                    <p className={'p-normal'}>{courseVisits2} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDuration(expertGroupDatesParsed)})</p>
                </div>
                <div className={'course-package-col scroll'}
                     style={{height: '200px', maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden'}}>
                    {renderDescListInput(courseRewards2)}
                </div>
                <div className={'accommodation-cloud'}>
                    <div className={'reservation-row cloud accommodation'}>
                        <div className={'row inner'}>
                            {!accNights ? L10N_APP_TEXTS[selectedLanguage].NO_ACCOMMODATION :
                                <>
                                    {renderAccommodationCheckbox(accommodationGroup3, setAccommodationGroup3)}

                                    <div className={'row inner'}>
                                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].PRICE_FOR} {accNights} {L10N_APP_TEXTS[selectedLanguage].NIGHTS}</p>
                                        <p className={'p-normal'}>{accPrice} {selectedLanguageObj?.priceValue}</p>
                                    </div>
                                </>
                            }
                        </div>
                        {
                            coursePrice2 && coursePriceDiscounted2? <div className={priceDis} style={{flexWrap: 'wrap'}}>
                                <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE__DEFAULT_PRICE}</p>
                                <p className={'p-normal'}>{coursePrice2} {selectedLanguageObj?.priceValue}</p>
                            </div> : <></>
                        }
                        {
                            coursePrice2 && coursePriceDiscounted2?  <div className={'row inner'}
                                                                        style={{flexWrap: 'wrap', opacity: Number(coursePriceDiscounted2) ? 1 : 0}}>
                                <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                <p className={'p-normal'}>{coursePriceDiscounted2} {selectedLanguageObj?.priceValue}</p>
                            </div> : <></>
                        }
                        {
                            coursePrice2 && !coursePriceDiscounted2 ?
                                <><div className={'row inner'}
                                       style={{flexWrap: 'wrap', opacity: Number(coursePrice2) ? 1 : 0}}>
                                    <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                    <p className={'p-normal'}>{coursePrice2} {selectedLanguageObj?.priceValue}</p>
                                </div>
                                    <div className={'row inner'}
                                         style={{flexWrap: 'wrap', opacity: Number(coursePriceDiscounted2) ? 1 : 0}}>
                                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                                        <p className={'p-normal'}>{coursePriceDiscounted2} {selectedLanguageObj?.priceValue}</p>
                                    </div></>: <></>
                        }
                    </div>
                </div>

                <div className={'cloud row center'}>
                    <p className={'p-submit large click'}>{L10N_APP_TEXTS[selectedLanguage].BUY} {accommodationGroup3 ? finalPrice + Number(accPrice) : finalPrice} {selectedLanguageObj?.priceValue}</p>
                </div>
            </div>
        </>
    };

    const renderComparisonSection = () => {


        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].COURSE_COMPARISON}/>
                <div className={'tab_compare comparison-container row'}>
                    <div className={'comparison-legend col start'} style={{height: '900px'}}>
                        <p className={'p-header black'}
                           style={{
                               alignSelf: 'flex-start',
                               paddingBottom: '130px'
                           }}>{L10N_APP_TEXTS[selectedLanguage].WE_WILL_LEARN}</p>
                        <p className={'p-header black'}
                           style={{
                               alignSelf: 'flex-start',
                               paddingBottom: '36px'
                           }}>{L10N_APP_TEXTS[selectedLanguage].START}</p>

                        <p className={'p-header black'} style={{
                            alignSelf: 'flex-start',
                            paddingBottom: '205px'
                        }}>{L10N_APP_TEXTS[selectedLanguage].COURSE_DATES}</p>

                        <div className={'row'} style={{paddingBottom: '40px'}}>
                            <p className={'p-header black'}
                               style={{alignSelf: 'flex-start'}}>{L10N_APP_TEXTS[selectedLanguage].TOTAL_VISITS_HOURS}</p>
                            <img src={iconSum} className={'icon-info'} alt={'img_acc'} height={'18px'} width={'18px'}/>
                        </div>
                        <p className={'p-header black'} style={{
                            alignSelf: 'flex-start',
                            paddingBottom: '190px'
                        }}>{L10N_APP_TEXTS[selectedLanguage].COURSE_RECEIVABLES}</p>
                        <div className={'row'} style={{paddingBottom: '4px'}}>
                            <p className={'p-header black'}
                               style={{
                                   alignSelf: 'flex-start',
                                   paddingRight: '8px'
                               }}>{L10N_APP_TEXTS[selectedLanguage].FILTER_ACCOMMODATION}</p>
                            <img src={iconMoon} className={'icon-info'} alt={'img_acc'} height={'18px'} width={'18px'}/>
                        </div>
                        <div className={'row'} style={{paddingBottom: '70px'}}>
                            <p className={'p-header black'}
                               style={{alignSelf: 'flex-start'}}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                            <img src={iconMean} className={'icon-info'} alt={'img_acc'} height={'18px'} width={'18px'}/>
                        </div>
                        <p className={'p-header black'}
                           style={{alignSelf: 'flex-start'}}>{L10N_APP_TEXTS[selectedLanguage].FOR_PAYMENT}</p>
                    </div>

                    <div className={'comparison-scroll row scroll'}>
                        {renderPackage(finalPrice, priceDis, 0)}
                        {renderPackage1(finalPrice1, priceDis1)}
                        {renderPackage2(finalPrice2, priceDis2)}
                    </div>

                </div>
            </>

        );
    }

    const renderReviews = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].REVIEWS}/>
                <div className={'reviews-container'}>
                    <div className={'review-left'}>
                        <img src={starBlueFull} className={'star-review'} alt={'img'} width={'60px'}/>
                        <p className={'p-review'}>4,7 (4.052)</p>
                    </div>
                    <div className={'review-right'}>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '30%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_SPACE})</p>
                        </div>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '50%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_PRO})</p>
                        </div>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '60%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_SPEED})</p>
                        </div>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '80%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_CONTACT})</p>
                        </div>
                        <div className={'review-bar-container'}>
                            <div className={'review-bar'}>
                                <div className={'review-fill'} style={{width: '90%'}}>
                                    <p className={'p-review-small'}>4,2</p>
                                </div>
                            </div>
                            <p className={'p-info grey'}>({L10N_APP_TEXTS[selectedLanguage].REVIEW_BANG_FOR_BUCK})</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }


    const renderLecturers = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].LECTURERS}/>
                <div className={'lecturers-container'}>

                    {lecturers.map((lecturer, index) => (
                        <div className={'lecturers-item'}>
                            <div className={'lecturers-col'}>
                                <img src={lecturersProfilePictures[index]} alt={'lecturer-img'}
                                     width={'80px'} style={{
                                    borderRadius: '45px',
                                    height: '90px',
                                    width: '90px',
                                    objectFit: 'cover',
                                    marginRight: '24px'
                                }}/>

                                <p className={'p-header black lecturer'}
                                   style={{paddingLeft: '0'}}>{lecturer.full_name}</p>
                            </div>
                            <div className={'lecturer-desc'}>
                                <p className={'p-text'}
                                   style={{maxWidth: '300px'}}>{lecturer.l10n[getIndexOf("l10n", selectedLanguage, lecturer.l10n)].description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </>

        );
    }

    const renderCoursePackages = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].COURSE_LEARN}/>
                <div className={'course-packages-container'}>
                    <div className={'course-package-col'}>
                        <p className={'p-title'} style={{
                            color: selectedPackage === 0 ? '#29ABE2' : 'black',
                            textDecoration: selectedPackage === 0 ? 'underline' : 'unset'
                        }} onClick={() => {
                            setSelectedPackage(0);
                        }}>{L10N_APP_TEXTS[selectedLanguage].BASIC_GROUP}</p>
                        <div style={{opacity: selectedPackage === 0 ? 1 : 0}}>
                            {renderDescListInput(courseLearn)}
                        </div>
                    </div>
                    <div className={'course-package-col'}>
                        <p className={'p-title'}> | </p>
                    </div>
                    <div className={'course-package-col'}>
                        <p className={'p-title'} style={{
                            color: selectedPackage === 1 ? '#29ABE2' : 'black',
                            textDecoration: selectedPackage === 1 ? 'underline' : 'unset'
                        }} onClick={() => {
                            setSelectedPackage(1);
                        }}>{L10N_APP_TEXTS[selectedLanguage].ADVANCED_GROUP}</p>
                        <div style={{opacity: selectedPackage === 1 ? 1 : 0}}>
                            {renderDescListInput(courseLearn1)}
                        </div>
                    </div>
                    <div className={'course-package-col'}>
                        <p className={'p-title'}> | </p>
                    </div>
                    <div className={'course-package-col'}>
                        <p className={'p-title'} style={{
                            color: selectedPackage === 2 ? '#29ABE2' : 'black',
                            textDecoration: selectedPackage === 2 ? 'underline' : 'unset'
                        }} onClick={() => {
                            setSelectedPackage(2);
                        }}>{L10N_APP_TEXTS[selectedLanguage].EXPERT_GROUP}</p>
                        <div style={{opacity: selectedPackage === 2 ? 1 : 0}}>
                            {renderDescListInput(courseLearn2)}
                        </div>
                    </div>


                </div>
                <div className={'provider-row desc'}>
                    <p className={'p-header black'}>
                        {courseDesc}
                    </p>
                </div>
            </>
        );
    }


    const renderCourseRequirements = () => {
        return (
            <>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].COURSE_NEEDS}/>
                <div className={'course-packages-container'}>
                    <div className={'course-package-col needs'}>
                        {renderDescListInput(courseNeeds)}
                    </div>
                </div>
            </>
        );
    }

    const renderCourseAccommodation = () => {
        return (
            <>

                {!isAccommodationSet || isAccommodationSet === "" ? <></> :
                    <>
                        <Title dots title={L10N_APP_TEXTS[selectedLanguage].COURSE_ACC}/>

                        <div className={'course-accommodation-container'}>
                            <div className={'course-accommodation-row'}>
                                <img src={logoAccommodation[0]?.data_url} alt={'accommodation-img'} width={'300px'}/>
                                <div className={'accommodation-desc'}>
                                    <p className={'p-text accommodation'}>{accDesc}</p>

                                    <p className={'p-text accommodation'}>{L10N_APP_TEXTS[selectedLanguage].PRICE_FOR} {accNights} {L10N_APP_TEXTS[selectedLanguage].NIGHTS}
                                        {" " + accPrice} {selectedLanguageObj?.priceValue} ({(accPrice / accNights).toFixed(2)} {selectedLanguageObj?.priceValue} / {L10N_APP_TEXTS[selectedLanguage].NIGHT}</p>
                                </div>
                            </div>
                            <div className={'course-col-bottom-inner-row'} style={{alignItems: 'baseline'}}>
                                <img src={iconMapPin} className={'icon-info'} alt={'sum_img'} width={'24px'}/>
                                <p className={'p-location'} onClick={() => {
                                    let t = accLocation?.split(" ");
                                    let s = "";
                                    for (let i = 0; i < t.length; i++) {
                                        s += (t[i] + "+");
                                    }
                                    openInNewTab(`https://www.google.com/maps/place/${s}`)
                                }}>{accLocation}</p>
                            </div>
                        </div>
                    </>
                }
            </>
        );
    }

    const getPackageStartDates = () => {
        if (selectedPackage === 0) {
            return basicGroupStartDates ? basicGroupStartDates[basicGroupStartDateIndex] : '';
        } else if (selectedPackage === 1) {
            return advancedGroupStartDates ? advancedGroupStartDates[advancedGroupStartDateIndex] : '';
        } else if (selectedPackage === 2) {
            return expertGroupStartDates ? expertGroupStartDates[expertGroupStartDateIndex] : '';
        }
    }

    const getPackageRestDates = (idx) => {
        if (selectedPackage === 0) {
            return basicGroupDatesParsed ? basicGroupDatesParsed[idx] : [];
        } else if (selectedPackage === 1) {
            return advancedGroupDatesParsed ? advancedGroupDatesParsed[idx] : [];
        } else if (selectedPackage === 2) {
            return expertGroupDatesParsed ? expertGroupDatesParsed[idx] : [];
        }
    }

    const getPackageRestDatesIdx = () => {
        if (selectedPackage === 0) {
            return basicGroupStartDateIndex;
        } else if (selectedPackage === 1) {
            return advancedGroupStartDateIndex;
        } else if (selectedPackage === 2) {
            return expertGroupStartDateIndex;
        }
    }

    const renderCloudPackagesPrices = (coursePrice, coursePriceDiscounted, priceDis) => {
        return <>
            {
                coursePrice && coursePriceDiscounted? <div className={priceDis} style={{flexWrap: 'wrap'}}>
                    <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE__DEFAULT_PRICE}</p>
                    <p className={'p-normal'}>{coursePrice} {selectedLanguageObj?.priceValue}</p>
                </div> : <></>
            }
            {
                coursePrice && coursePriceDiscounted?  <div className={'row inner'}
                                                              style={{flexWrap: 'wrap', opacity: Number(coursePriceDiscounted) ? 1 : 0}}>
                    <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                    <p className={'p-normal'}>{coursePriceDiscounted} {selectedLanguageObj?.priceValue}</p>
                </div> : <></>
            }
            {
                coursePrice && !coursePriceDiscounted ?
                    <><div className={'row inner'}
                           style={{flexWrap: 'wrap', opacity: Number(coursePrice) ? 1 : 0}}>
                        <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                        <p className={'p-normal'}>{coursePrice} {selectedLanguageObj?.priceValue}</p>
                    </div>
                        <div className={'row inner'}
                             style={{flexWrap: 'wrap', opacity: Number(coursePriceDiscounted) ? 1 : 0}}>
                            <p className={'p-normal'}>{L10N_APP_TEXTS[selectedLanguage].COURSE_PRICE}</p>
                            <p className={'p-normal'}>{coursePriceDiscounted} {selectedLanguageObj?.priceValue}</p>
                        </div></>: <></>
            }</>
    }

    const renderCloudOverlay = () => {
        let totalPrice = selectedPackage === 0 ? (accommodationGroup1 ? finalPrice + accPrice : finalPrice) :
            selectedPackage === 1 ? (accommodationGroup2 ? finalPrice1 + accPrice : finalPrice1) :
                (accommodationGroup3 ? finalPrice2 + accPrice : finalPrice2);

        return (
            <div className={'course-cloud-container'}
                 style={{marginTop: toggleFilters && filteredCourses?.length <= 0 ? '140px' : filteredCourses?.length > 0 ? '720px' : 0}}>
                <div className={'cloud-row contact'} onClick={() => {
                }}>
                    <img src={cloud} className={'icon-info'} alt={'img_acc'} width={'28px'}/>
                </div>
                <div className={'course-packages-container'}>
                    <div className={'course-package-col'}>
                        <p className={'p-text'} style={{
                            color: selectedPackage === 0 ? '#29ABE2' : 'black',
                            textDecoration: selectedPackage === 0 ? 'underline' : 'unset'
                        }} onClick={() => {
                            setSelectedPackage(0);
                        }}>{L10N_APP_TEXTS[selectedLanguage].BASIC_GROUP}</p>
                        <div style={{opacity: selectedPackage === 0 ? 1 : 0}}>
                            {renderDescListInput(courseLearn)}
                        </div>
                    </div>

                    {
                        !advancedGroupStartDates || advancedGroupStartDates?.length <= 0 ? '' :
                            <div className={'course-package-col'}>
                                <p className={'p-text'}> | </p>
                            </div>
                    }

                    <div className={'course-package-col'}>
                        {
                            !advancedGroupStartDates || advancedGroupStartDates?.length <= 0 ? '' :
                                <>

                                    <p className={'p-text'} style={{
                                        color: selectedPackage === 1 ? '#29ABE2' : 'black',
                                        textDecoration: selectedPackage === 1 ? 'underline' : 'unset'
                                    }} onClick={() => {
                                        setSelectedPackage(1);
                                    }}>{L10N_APP_TEXTS[selectedLanguage].ADVANCED_GROUP}</p>
                                    <div style={{opacity: selectedPackage === 1 ? 1 : 0}}>
                                        {renderDescListInput(courseLearn1)}
                                    </div>
                                </>
                        }

                    </div>

                    {
                        !expertGroupStartDates || expertGroupStartDates?.length <= 0 ? '' :
                            <div className={'course-package-col'}>
                                <p className={'p-text'}> | </p>
                            </div>
                    }

                    <div className={'course-package-col'}>

                        {
                            !expertGroupStartDates || expertGroupStartDates?.length <= 0 ? '' :
                                <>
                                    <p className={'p-text'} style={{
                                        color: selectedPackage === 2 ? '#29ABE2' : 'black',
                                        textDecoration: selectedPackage === 2 ? 'underline' : 'unset'
                                    }} onClick={() => {
                                        setSelectedPackage(2);
                                    }}>{L10N_APP_TEXTS[selectedLanguage].EXPERT_GROUP}</p>
                                    <div style={{opacity: selectedPackage === 2 ? 1 : 0}}>
                                        {renderDescListInput(courseLearn2)}
                                    </div>
                                </>
                        }

                    </div>
                </div>


                <div className={'row-cloud row space-between'}
                     style={{padding: '24px 24px 0 36px', marginBottom: '-8px'}}>
                    <p className={'p-text'} style={{
                        alignSelf: 'flex-start',
                        marginTop: '24px',
                        width: '130px'
                    }}>{L10N_APP_TEXTS[selectedLanguage].START}</p>
                    <p className={'click'} style={{fontSize: '20px'}} onClick={() => {
                        if (selectedPackage === 0) {
                            onPreviousDate(basicGroupStartDateIndex, setBasicGroupStartDateIndex, basicGroupStartDates);
                        } else if (selectedPackage === 1) {
                            onPreviousDate(advancedGroupStartDateIndex, setAdvancedGroupStartDateIndex, advancedGroupStartDates);
                        } else if (selectedPackage === 2) {
                            onPreviousDate(expertGroupStartDateIndex, setExpertGroupStartDateIndex, expertGroupStartDates);
                        }
                    }}>{`<`}</p>
                    <p className={'p-normal'} style={{
                        fontWeight: '600',
                        minWidth: '260px',
                        textAlign: 'center'
                    }}>{getPackageStartDates()}</p>
                    <p className={'click'} style={{fontSize: '20px'}} onClick={() => {
                        if (selectedPackage === 0) {
                            onNextDate(basicGroupStartDateIndex, setBasicGroupStartDateIndex, basicGroupStartDates);
                        } else if (selectedPackage === 1) {
                            onNextDate(advancedGroupStartDateIndex, setAdvancedGroupStartDateIndex, advancedGroupStartDates);
                        } else if (selectedPackage === 2) {
                            onNextDate(expertGroupStartDateIndex, setExpertGroupStartDateIndex, expertGroupStartDates);
                        }
                    }}>{`>`}</p>
                </div>

                <div className={'reservations-cloud scroll'}
                     style={{maxHeight: '100px', overflowY: 'auto', overflowX: 'hidden'}}>

                    <p className={'p-text'} style={{
                        alignSelf: 'flex-start',
                        paddingLeft: '24px',
                        marginBottom: '-24px'
                    }}>{L10N_APP_TEXTS[selectedLanguage].COURSE_DATES}:</p>
                    {getPackageRestDates(getPackageRestDatesIdx()) && getPackageRestDates(getPackageRestDatesIdx()).map((date, d) => (
                        <div key={`${d}_${date}`} className={'reservation-col-date'}>
                            <div className={'reservation-row cloud'}>
                                <p className={'p-text'}>{date.split(',')[0]}</p>
                                <p className={'p-text'}>{date.split(',')[1]}</p>
                                <p className={'p-text'}>{date.split(',')[2]}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'accommodation-cloud'}>
                    {!isAccommodationSet || isAccommodationSet === "" ? <></> :
                        <>
                            <div className={'row'} style={{height: 0, marginBottom: '-10px', marginTop: '12px'}}>
                                <p className={'p-text'} style={{
                                    alignSelf: 'flex-start',
                                    paddingRight: '8px',
                                }}>{L10N_APP_TEXTS[selectedLanguage].FILTER_ACCOMMODATION}</p>
                                <img src={iconMoon} className={'icon-info'} alt={'img_acc'} height={'18px'} width={'18px'}/>
                            </div>
                        </>
                    }

                    <div className={'reservation-row cloud accommodation'}>

                        {!isAccommodationSet || isAccommodationSet === "" ? <></> :
                            <>
                                <div className={'row inner'}>
                                    {renderAccommodationCheckbox(selectedPackage === 0 ? accommodationGroup1 : selectedPackage === 1 ? accommodationGroup2 : accommodationGroup3, selectedPackage === 0 ? setAccommodationGroup1 : selectedPackage === 1 ? setAccommodationGroup2 : setAccommodationGroup3)}

                                    <div className={'row inner'}>
                                        <p className={'p-text'}>{L10N_APP_TEXTS[selectedLanguage].PRICE_FOR} {accNights} {L10N_APP_TEXTS[selectedLanguage].NIGHTS}</p>
                                        <p className={'p-text'}>{accPrice} {selectedLanguageObj?.priceValue}</p>
                                    </div>
                                </div>
                            </>
                        }

                        {selectedPackage === 0 && renderCloudPackagesPrices(coursePrice, coursePriceDiscounted, priceDis)}
                        {selectedPackage === 1 && renderCloudPackagesPrices(coursePrice1, coursePriceDiscounted1, priceDis1)}
                        {selectedPackage === 2 && renderCloudPackagesPrices(coursePrice2, coursePriceDiscounted2, priceDis2)}
                    </div>
                </div>

                <div className={'cloud row space-between'}>
                    <p className={'p-title'}
                       style={{cursor: 'pointer'}} onClick={() => {
                        scrollToDiv('tab_compare');
                    }}>{L10N_APP_TEXTS[selectedLanguage].COURSE_COMPARISON}</p>
                    <p className={'p-submit large click'}>{L10N_APP_TEXTS[selectedLanguage].BUY} {totalPrice} {selectedLanguageObj?.priceValue}</p>
                </div>
            </div>
        );
    }

    const parseL10NCategory = (category) => {
        switch (selectedLanguage) {
            case L10N.si.value:
                return category?.name_si
            case L10N.en.value:
                return category?.name_en
            case L10N.it.value:
                return category?.name_it
        }
    }


    const renderCourseInfo = () => {
            return (
                <div className={'provider-info-container course-info'}>
                    <p className={'p-pageinfo'}>
                        {parseL10NCategory(categoryParent)} {categoryParent ? ">" : ""}<span> {parseL10NCategory(category)}</span>
                    </p>
                    <div className={'provider-row'} style={{justifyContent: 'flex-start'}}>
                        <p className={'p-header black'} style={{paddingTop: '24px'}}>{courseName}</p>

                        <div className={'row_inner_left'} style={{justifyContent: 'flex-end', paddingLeft: '24px', alignSelf: 'flex-end'}}>
                            <p className={'p-xlarge'} style={{fontWeight: 300}}></p>

                            <div className={'row_inner_right-icons'}>
                                {course?.packages[0]?.accommodation_price ?
                                    <img src={iconAcc} className={'icon-info'} alt={'img_acc'} width={'25px'}/> : <></>}

                                <div className={'follow-heart'} onClick={() => {
                                    //setFollowCourse(!followCourse);

                                    if (userFollower?.course_id === course?.course_id) {
                                        dispatch(removeFollowerForCourse({
                                            course_id: course.course_id,
                                            user_id: user.user_id
                                        }, async (resData) => {
                                            if (!resData) {
                                                return;
                                            }
                                            setFollowCourse(false)

                                            dispatchLoadFollower();
                                        }, () => {
                                            dispatchLoadFollower();
                                        }));
                                    } else {
                                        dispatch(createFollowerForCourse({
                                            course_id: course.course_id,
                                            user_id: user.user_id
                                        }, async (resData) => {
                                            if (!resData) {
                                                return;
                                            }
                                            dispatchLoadFollower();
                                        }, () => {
                                            dispatchLoadFollower();
                                        }));
                                    }

                                }}>
                                    <img src={followCourse ? iconHeartFull : iconHeart} className={'icon-info'}
                                         alt={'img_acc'} width={'24px'}/>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className={'provider-row'}>
                        <div className={'row_inner_left'}>
                            <p className={'p-desc click-light'} onClick={() => {
                                onNavigateTo(PAGES.PROVIDER + ":" + course?.provider?.provider_id);
                            }}>{course?.provider?.name}</p>

                            <div className={'row_inner_right'}>
                                <div className={'follow p-submit'} onClick={() => {
                                    if (userFollowerProvider?.provider_id === course?.provider?.provider_id) {
                                        dispatch(removeFollowerForProvider({provider_id: course?.provider?.provider_id, user_id: user.user_id}, async (resData) => {
                                            if (!resData) {
                                                return;
                                            }
                                            dispatchLoadFollowerProvider();
                                        }, () => {
                                            dispatchLoadFollowerProvider();
                                        }));
                                    } else {
                                        dispatch(createFollowerForProvider({provider_id: course?.provider?.provider_id, user_id: user.user_id}, async (resData) => {
                                            if (!resData) {
                                                return;
                                            }
                                            dispatchLoadFollowerProvider();
                                        }, () => {
                                            dispatchLoadFollowerProvider();
                                        }));
                                    }

                                }}>
                                    <span>{userFollowerProvider ? L10N_APP_TEXTS[selectedLanguage].UNFOLLOW : L10N_APP_TEXTS[selectedLanguage].FOLLOW}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={'poi'}>
                        <div className={'course-col-bottom-inner-row'} style={{alignItems: 'baseline'}} onClick={() => {
                            let t = accLocation?.split(" ");
                            let s = "";
                            for (let i = 0; i < t.length; i++) {
                                s += (t[i] + "+");
                            }
                            openInNewTab(`https://www.google.com/maps/place/${s}`)
                        }}>
                            <img src={iconMapPin} className={'icon-info'} alt={'sum_img'} width={'24px'}/>
                            <p className={'p-location'}>{courseAddress}</p>
                        </div>
                    </div>
                    <div className={'provider-row facts'}>
                        <div className={'row_inner_left'}>

                            {/*
                                <p className={'p-text'}>{L10N_APP_TEXTS[selectedLanguage].THIS_SCHOOL_FOLLOWS_1}<span
                                style={{
                                    color: '#29ABE2',
                                    textDecoration: 'unset'
                                }}>160</span> {L10N_APP_TEXTS[selectedLanguage].THIS_SCHOOL_FOLLOWS_2}</p>
                            <p className={'p-text del'}> | </p>
                            */}

                            <p className={'p-text'}>{L10N_APP_TEXTS[selectedLanguage].MEMBERSHIP_START_DATE_PRETTY} {displayDateMonthYear(provider?.date_create, selectedLanguage)}</p>
                            <p className={'p-text del'}> | </p>
                            <img src={iconStarGray} className={'icon-info'} alt={'sum_img'} width={'16px'}/>
                            <p className={'p-info'}>4,7 (4052)</p>
                        </div>
                    </div>

                    <div className={'course-info-container'}>
                        <div className={'provider-row course-info'}>
                            <p className={'p-text'}>{'Starost:'}
                                <span> {selectedPackage === 0 ? courseTraineeAge : selectedPackage === 1 ? courseTraineeAge1 : courseTraineeAge2}</span> {L10N_APP_TEXTS[selectedLanguage].YEARS}
                            </p>
                        </div>
                        <div className={'provider-row course-info'}>
                            <p className={'p-text'}>{'Velikost skupine: do'}
                                <span> {selectedPackage === 0 ? courseTraineeMax : selectedPackage === 1 ? courseTraineeMax1 : courseTraineeMax2}</span> {L10N_APP_TEXTS[selectedLanguage].THIS_SCHOOL_FOLLOWS_2}
                            </p>
                        </div>
                    </div>

                    <div className={'provider-row languages'}>
                        <p className={'p-text'}>{`${L10N_APP_TEXTS[selectedLanguage].LANGUAGE_SPOKEN_PLURAL}:`}
                            <span style={{color: '#29ABE2'}}>{parseLanguagesSpoken(course)}</span>
                        </p>
                    </div>
                </div>
            );
        }
    ;
    /*
        const renderCourses = (courses) => {
            return (
                <>
                    <Title dots title={L10N_APP_TEXTS[selectedLanguage].SUGGESTED}/>
                    <div className={'courses_container scroll'}>
                        {courses.map((course, c) => (
                            <div key={`${c}_${course.course_id}`} className={'course-col'}>
                                <div className={'course-col-top'}>
                                    <div className={'icon-heart-container'} onClick={() => {
                                    }}>
                                        <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                    </div>
                                    <img src={courseImg} alt={'course_img'} width={'290px'}/>
                                </div>
                                <div className={'label-accommodation-container'}>
                                    <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                         width={'50px'}/>
                                </div>
                                <div className={'course-col-bottom'}>
                                    <p className={'p-desc'}>{course.provider_name}</p>
                                    <p className={'p-large'}>{course.name}</p>
                                    <p className={'p-desc desc'}>{course.description}</p>

                                    <div className={'course-col-bottom-inner-row'}>
                                        <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                        <p className={'p-info'}>{course.time_period}</p>
                                    </div>
                                    <div className={'course-col-bottom-inner'}>
                                        <div className={'course-col-bottom-inner-col'}>
                                            <div className={'course-col-bottom-inner-row'}>
                                                <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                                <p className={'p-info'}>{course.nights} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({course.hours} {L10N_APP_TEXTS[selectedLanguage].HOURS})</p>
                                            </div>
                                            <div className={'course-col-bottom-inner-row'}>
                                                <img src={iconStar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                                <p className={'p-info'}>{course.stars}</p>
                                            </div>
                                        </div>
                                        <div className={'course-col-bottom-inner-col'}>
                                            <div className={'course-col-bottom-inner-row'}>
                                                <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                                <p className={'p-info'}>{course.price_hour} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR}</p>
                                            </div>
                                            <div className={'course-col-bottom-inner-row'}>
                                                <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                     width={'14px'}/>
                                                <p className={'p-info'}>{course.accommodation_price} {selectedLanguageObj?.priceValue}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-row price'}>
                                        <p className={'p-price'}>{course.price} {selectedLanguageObj?.priceValue}</p>
                                        <p className={'p-discount-price'}>{course.price_discounted} {selectedLanguageObj?.priceValue}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        };
    */

    const renderCourses = (courses, title, anchor) => {

        if (!selectedLanguage || !courses || courses?.length <= 0) {
            return <>
                <Title dots title={title}/>
                <p className={'p-normal'} style={{
                    paddingBottom: '80px',
                    fontStyle: 'italic'
                }}>{L10N_APP_TEXTS[selectedLanguage].NO_SEARCH_RESULTS}</p>
            </>
            return <></>
        }
        return (
            <>
                {anchor ? <div className={anchor}/> : <></>}
                <Title dots title={title}/>
                <div className={'courses_container scroll ' + anchor} style={{marginBottom: '48px'}}>
                    {courses.map((course, c) => (

                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'}
                             style={{cursor: "pointer"}} onClick={() => {
                            let data = {
                                course_id: course?.course_id,
                            }
                            dispatch(loadCourseById(data, selectedLanguage, (resData) => {
                                console.log(resData);
                                onNavigateTo(PAGES.COURSE + ":" + course?.course_id);
                            }));
                        }}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'} style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>

                                <p className={'p-desc desc p-desc-list'}>{course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage ? l10n : L10N.si.value, course?.packages[0].l10n)]?.description_list}</p>
                                <p className={'p-desc desc truncate'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>
                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[0]?.dates)})</p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: 0}}>
                                            <img src={iconStar} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{'5.3 (3000)'}</p>
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && ((course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}
                                             style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'}
                                       style={{display: course?.packages[0] && course?.packages[0].price_discounted ? "flex" : "none"}}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{(course?.packages[0] && course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) + " " + selectedLanguageObj?.priceValue}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderPage = () => {
        return <>
            <div ref={ref}/>
            <Header/>
            {/*<Header loggedIn/>*/}
            <animated.div style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>
            <FooterPage/>
        </>
    }

    if (loginSuccessful) {
        console.log('redirect');

        onNavigateTo(PAGES.HOME);
        return <></>
    }

    return (
        renderPage()
    );
}

export default CoursePage;