import {useCallback, useEffect, useRef, useState} from "react";
import {useSpring, animated} from 'react-spring'
import {useHistory} from "react-router-dom";
import {L10N} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    isRememberMeEnabled,
    loadCredentials,
    loadLocalL10N, setL10N,
    setRememberMe,
    setSignInCredentials, userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {isUserAdmin, parseSelectedCountryLabel} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import Input from "../components/js/Input";

import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import Title from "../components/js/Title";
import Footer from "../components/js/Footer";
import {CREATE_ACTION, renderErrorMessage} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import FooterPage from "../components/js/FooterPage";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";

function Login() {
    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);

    const isRememberMeSet = useSelector(state => state.reducer.rememberMe);
    const savedCredentials = useSelector(state => state.reducer.savedCredentials);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [_rememberMe, _setRememberMe] = useState();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [saveData, setSaveData] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState(false);

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                //transform: saveData ? 'translateY(-100%)' : 'translateY(-100%)',
                opacity: saveData ? 0 : 0,
                bottom: saveData ? '-30px' : '-30px',
                display: saveData ? 'none' : 'none'
            },
            to: {
                //transform: saveData ? 'translateY(0%)' : 'translateY(0%)',
                opacity: saveData ? 1 : 0,
                bottom: saveData ? '0' : '0',
                display: saveData ? 'flex' : 'none'
            },
            config: {duration: 200},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, [])

    useEffect(() => {

        dispatch(loadLocalL10N(true));

        dispatch(loadCredentials());
        dispatch(isRememberMeEnabled());
    }, []);


    useEffect(() => {
        if (savedCredentials.email && savedCredentials.password) {
            setEmail(savedCredentials.email);
            setPassword(savedCredentials.password)
        }

    }, [savedCredentials])


    useEffect(() => {
        if (isRememberMeSet) {
            _setRememberMe(isRememberMeSet);
        }
    }, [isRememberMeSet])

    useEffect(() => {
        if (loginSuccessful) {
            if (_rememberMe) {
                dispatch(setSignInCredentials(email, password, true));
            } else {
                dispatch(setSignInCredentials('', '', true));
            }
            dispatch(setRememberMe(_rememberMe));
        }
    }, [loginSuccessful]);

    useEffect(() => {
        if (email && password) {
            setSaveData(true);
        } else {
            if (saveData) {
                setSaveData(false);
            }
        }
    }, [email, password]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.LOGIN_USER:
                    onConfirmLoginUser();
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };

    const onConfirmLoginUser = () => {
        setLoadingSpinner(true);

        const onCleanup = () => {
            setTimeout(() => {
                setCreateTrigger(false);
                setLoadingSpinner(false);
                setSaveData(false);
            }, 500)
        }

        const onSuccessLogin = () => {
            console.log('redirect success');
            setLoginSuccessful(true);

            onCleanup();
        };
        const UserAccessNotGranted = () => {
            console.log("Error fetching data");
            setError(ErrorType.USER_ACCESS_NOT_GRANTED);

            onCleanup();
        };
        const onErrorUnauthorized = () => {
            console.log("Unauthorized");
            setError(ErrorType.UNAUTHORIZED);

            onCleanup();
        };


        dispatch(userLogin(email, password, (res) => {
            console.log("Login new user successfully!", res);

            onSuccessLogin()

        }, (res) => {
            console.warn("Error logging-in new user..");
            UserAccessNotGranted();

        }, () => {
            onErrorUnauthorized();
        }));

    };

    const renderRememberMeCheckBox = () => {
        return (
            <div className={'checkbox-container remember'} style={{alignSelf: 'baseline'}}>
                <label style={{display: 'flex', alignItems: 'baseline'}}>
                    <input type="checkbox"
                           style={{marginRight: '6px'}}
                           checked={_rememberMe}
                           onChange={() => {
                               console.log(!_rememberMe);
                               _setRememberMe(!_rememberMe)
                           }}
                    />
                    {L10N_APP_TEXTS[selectedLanguage].LOGIN_REMEMBER}
                </label>
            </div>
        );
    };

    const onResetPassword = () => {
        onNavigateTo(PAGES.RESET_PASSWORD);
    };

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

   const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

       return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const renderFooter = () => {

        return <div className={'footer-container'}>
            {
                <div className={'footer'}>

                </div>
            }
        </div>
    }

    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}

                <Title dots title={L10N_APP_TEXTS[selectedLanguage].LOGIN_WELCOME_BACK}/>
                <div className={'form-container'} style={{marginBottom: '0'}}>
                    <div className={'form_col'} style={{width: 'auto'}}>
                        <Input login email={email}
                               loadLoginCredentials
                               onChange={(value) => onInputChange(value, setEmail)}
                               onSubmit={() => {
                               }}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_EMAIL}
                               label={L10N_APP_TEXTS[selectedLanguage].LOGIN_EMAIL}
                               style={{marginBottom: '10px'}}/>
                        <Input login password={password}
                               loadLoginCredentials
                               onChange={(value) => onInputChange(value, setPassword)} onSubmit={createTrigger}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_PASSWORD}
                               label={L10N_APP_TEXTS[selectedLanguage].LOGIN_PASSWORD}
                               style={{marginBottom: '0px'}}/>
                        <p className={'p-small click'} style={{
                            marginTop: '20px',
                            alignSelf: 'flex-end',
                            fontSize: '0.71em'
                        }} onClick={() => {
                            onResetPassword();
                        }}>{L10N_APP_TEXTS[selectedLanguage].FORGOT_PASSWORD}</p>
                    </div>
                </div>
                {renderErrorMessage(error, selectedLanguage)}
                {renderRememberMeCheckBox()}

                <p className={'p-small click'} style={{
                    marginTop: '80px'
                }} onClick={() => {
                    onNavigateTo(PAGES.REGISTER_USER)
                }}>{L10N_APP_TEXTS[selectedLanguage].LOGIN_CREATE_ACCOUNT}</p>

            </div>
        )
    }

    const renderPage = () => {
        return <>
            <div ref={ref}/>
            {user && token ? <Header loggedIn/> : <Header/>}
            <animated.div style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>

            <animated.div style={slideUp}>
                <Footer button={L10N_APP_TEXTS[selectedLanguage].SUBMIT}
                        onClick={() => triggerAction(CREATE_ACTION.LOGIN_USER)}/>
            </animated.div>
        </>

    }

    if (loginSuccessful) {
        console.log('redirect');

        if (isUserAdmin(user)) {
            onNavigateTo(PAGES.CONTROL_PANEL);
        } else {
            onNavigateTo(PAGES.HOME);
        }
        return <></>
    }

    return (
        renderPage()
    );
}

export default Login;