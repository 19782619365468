import logo from "../../assets/images/logo.png";
import headerLarge from "../../assets/images/background_large.png";
import {parseFullName} from "../../helpers/helpers";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {L10N} from "../../constants/enums";
import {loadLocalL10N} from "../../store/actions/actions";
import '../css/HeaderHome.css';
import {PAGES} from "../../App";
import {useHistory} from "react-router-dom";



function HeaderLanding(props) {
    const dispatch = useDispatch();

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    useEffect(() => {
        //dispatch(loadLocalL10N(false));
        setSelectedLanguage(l10n)
        console.log(l10n);

    }, [l10n, selectedLanguage]);

    const renderHeader = () => {
        return <div className={'header_container large'}
                    style={{backgroundImage: `url(${headerLarge})`}}>
            <div className={'header'}>
                <img src={logo} alt="logo" className={'header_logo'} onClick={() =>{
                    onNavigateTo(PAGES.MAIN)
                }}/>
                <div className={'header_inner_right'}>
                    <p className={'p-header logged_in click'} onClick={() => {onNavigateTo(PAGES.REGISTER_PROVIDER)}}>{L10N_APP_TEXTS[selectedLanguage].BECOME_LECTURER}</p>
                    <p className={'p-header logged_in click'} onClick={() => {onNavigateTo(PAGES.REGISTER_USER)}}>{L10N_APP_TEXTS[selectedLanguage].REGISTER}</p>
                    <p className={'p-header logged_in click'} onClick={() => {onNavigateTo(PAGES.LOGIN)}}>{L10N_APP_TEXTS[selectedLanguage].SIGN_IN}</p>
                    <p className={'p-header logged_in click'} style={{pointerEvents: 'none', fontWeight: 300}}>{L10N[selectedLanguage?.toLowerCase()]?.priceValueLabel}</p>
                </div>
            </div>
            <div className={'header_landing'}>
                <p className={'p-landing'}>{L10N_APP_TEXTS[selectedLanguage].FIND_COURSE}</p>
            </div>
        </div>
    }

    return renderHeader();
}

export default HeaderLanding;