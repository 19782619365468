import {useSelector} from "react-redux";
import {useState} from "react";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {L10N} from "../../constants/enums";
import headerImage from "../../assets/images/header.png";
import {TailSpin} from "react-loader-spinner";
import colors from "../../constants/colors";
import icon_user from "../../assets/images/user.png";

function Footer(props) {

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const renderFooter = () => {
        return <div className={'footer_container'}>
            <div className={'footer_bcg_container'}>
                <img src={headerImage} alt="footer" className={'footer_bcg'}/>
            </div>
            <div className={'footer'}>
                <div className={'footer_inner_right'}>
                    {loadingSpinner ?
                        <div style={{position: 'fixed', bottom: '50pt'}}>
                            <TailSpin
                                type="TailSpin"
                                color={colors.secondary}
                                height={42}
                                width={42}
                                style={{paddingTop: 52, paddingBottom: 45}}
                            />
                        </div>
                        :
                        <p className={'p-header save click'} onClick={() => {
                            if (props.onClick) {
                                setLoadingSpinner(true);
                                props.onClick();
                                setLoadingSpinner(false);
                            }
                        }}>{props.button}</p>
                    }
                </div>
            </div>
        </div>
    }

    return renderFooter();
}

export default Footer;