import '../css/Footer.css';
import '../css/Banner.css';

import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {L10N} from "../../constants/enums";
import {useHistory} from "react-router-dom";
import {PAGES} from "../../App";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import iconPresentation from "../../assets/images/ikona-izvajalci tečaja.png";
import iconPeople from "../../assets/images/ikona-tecajniki.png";
import banner1Bcg from "../../assets/images/1.png";
import banner2Bcg from "../../assets/images/2.png";
import Button from "./Button";
import headerLarge from "../../assets/images/background_large.png";


function Banner(props) {
    let dispatch = useDispatch();

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);

        console.log("FOOTER L10N:", l10n);

        if (l10n) {
            setSelectedLanguage(l10n)
        }

    }, [l10n]);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const renderDots = () => {
        return (<div className={'row dots'}>
            <span className="dot"/>
            <span className="dot"/>
            <span className="dot space"/>
        </div>);
    }

    const renderContent = () => {
        return <div className={'banner-container-row'}>
            {renderBanner1()}
            {renderBanner2()}

        </div>
    }

    const renderBanner1 = () => {
        return (
            <>
                <div className={'row banner-container banner-1'} style={{backgroundImage: `url(${banner1Bcg})`}}>
                    <div className={'col img-container'}>
                        <img src={iconPresentation} className={'icon-bcg'} alt={'img'} style={{
                            width: '160px',
                            height: '120px'
                        }}/>
                    </div>

                    <div className={'col container-1'}>
                        <p className={'p-title-banner'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_1_TITLE}</p>

                        <div className={'row container-2'}>
                            <div className={'col'}>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_1_P1}</p>
                                </div>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_1_P2}</p>
                                </div>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_1_P3}</p>
                                </div>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_1_P4}</p>
                                </div>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_1_P5}</p>
                                </div>
                            </div>
                        </div>

                        <br/>
                        <div className={'row'}>
                            <Button style={{marginRight: '12px'}} text={L10N_APP_TEXTS[selectedLanguage].BANNER_1_BTN1}
                                    onClick={() => onNavigateTo(PAGES.REGISTER_PROVIDER)}/>
                            <Button text={L10N_APP_TEXTS[selectedLanguage].BANNER_1_BTN2}
                                    onClick={() => onNavigateTo(PAGES.PRESENTATION_PROVIDER)}/>
                        </div>
                    </div>

                </div>

            </>
        );

    }
    const renderBanner2 = () => {
        return (
            <>
                <div className={'row banner-container banner-2'} style={{backgroundImage: `url(${banner2Bcg})`}}>


                    <div className={'col container-1'}>
                        <p className={'p-title-banner'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_2_TITLE}</p>

                        <div className={'row container-2'}>
                            <div className={'col'}>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_2_P1}</p>
                                </div>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_2_P2}</p>
                                </div>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_2_P3}</p>
                                </div>
                                <div className={'row'}>
                                    {renderDots()}
                                    <p className={'p-desc'}>{L10N_APP_TEXTS[selectedLanguage].BANNER_2_P4}</p>
                                </div>
                            </div>
                        </div>

                        <br/>
                        <div className={'row'}>
                            <Button style={{marginRight: '12px'}} text={L10N_APP_TEXTS[selectedLanguage].BANNER_2_BTN1}
                                    onClick={() => onNavigateTo(PAGES.REGISTER_USER)}/>
                            <Button text={L10N_APP_TEXTS[selectedLanguage].BANNER_2_BTN2}
                                    onClick={() => onNavigateTo(PAGES.PRESENTATION_USER)}/>
                        </div>
                    </div>

                    <div className={'col img-container'}>
                        <img src={iconPeople} className={'icon-bcg'} alt={'img'} style={{
                            width: '160px',
                            height: '120px'
                        }}/>
                    </div>

                </div>

            </>
        );

    }

    return renderContent()
}

export default Banner;
