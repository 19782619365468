export const USER_ROLES = {
    ALL: 'ALL',
    ADMIN: 'ADMIN',
    PROVIDER: 'IZVAJALEC',
    CUSTOMER: 'UPORABNIK',
};

export const SESSION_STORAGE_KEYS = {
    USER: '_bravooo_user',
    PROVIDER: '_bravooo_provider',
    COURSE: '_bravooo_course',
    USER_TOKEN: '_bravooo_user_token',
    REMEMBER_EMAIL: '_bravooo_saved_email',
    REMEMBER_PASSWORD: '_bravooo_saved_password',
    REMEMBER: '_bravooo_remember_me_sign_in',
    L10N: '_bravooo_l10n_key',
    ADMIN: '_bravooo_admin',
};

export const PRINT_EMAIL_RECIPIENTS = {
    PRINT_ADDRESS: 'hiti.tadej@gmail.com'
}

export const LOCAL_STORAGE_KEYS = {
    CREDENTIALS: 'CREDENTIALS'
}

export const L10N = {
    si: {label: 'Slovenščina', value: 'SI', priceValue: '€', priceValueLabel: '€ (EUR)', country: 'Slovenija'},
    en: {label: 'English (US)', value: 'EN', priceValue: '$', priceValueLabel: '$ (USD)', country: 'Slovenia'},
    it: {label: 'Italiano', value: 'IT', priceValue: '€', priceValueLabel: '€ (EUR)', country: 'Italia'},
    hr: {label: 'Hrvatski', value: 'HR', priceValue: '€', priceValueLabel: '€ (EUR)', country: 'Hrvatska'},
    de: {label: 'Deutsch', value: 'DE', priceValue: '€', priceValueLabel: '€ (EUR)', country: 'Austria'},
}

export const COUNTRIES = {
    si: {label: 'Slovenia', value: 'SI'},
    en: {label: 'United Kingdom', value: 'EN'},
    it: {label: 'Italy', value: 'IT'},
    hr: {label: 'Croatia', value: 'HR'},
    de: {label: 'Germany', value: 'DE'},
}

export const parseCountryObject = (l10n) => {

    switch (l10n) {
        case COUNTRIES.si.value: return COUNTRIES.si;
        case COUNTRIES.en.value: return COUNTRIES.en;
        case COUNTRIES.it.value: return COUNTRIES.it;
        case COUNTRIES.hr.value: return COUNTRIES.hr;
        case COUNTRIES.de.value: return COUNTRIES.de;
    }
}

export const COUNTRIES_ARRAY = [
    COUNTRIES.si, COUNTRIES.en, COUNTRIES.it, COUNTRIES.de, COUNTRIES.hr
]

export const GROUPS_SIZE = [
    {label: '1 - 2', value: '1-2'},
    {label: '3 - 5', value: '3-5'},
    {label: '5 - 10', value: '5-10'},
    {label: '10+', value: '10+'},
]

export const STARS_FILTER = [
    {label: 'naraščajoče', value: '+'},
    {label: 'padajoče', value: '-'},
    {label: '1', value: '1'},
    {label: '2', value: '2'},
    {label: '3', value: '3'},
    {label: '4', value: '4'},
    {label: '5', value: '5'},
]

export const PRICE_FILTER = [
    {label: 'naraščajoče', value: '+'},
    {label: 'padajoče', value: '-'},
    {label: '0 - 50', value: '0-50'},
    {label: '50 - 100', value: '50-100'},
    {label: '100 - 200', value: '100-200'},
    {label: '200+', value: '200+'},
]

export const TIER = {
    A: 'A',
    B: 'B',
    C: 'C',
}

export const REGIONS = [
    "Pomurska",
    "Podravska",
    "Koroška",
    "Savinjska",
    "Zasavska",
    "Spodnjeposavska",
    "Jugovzhodna Slovenija",
    "Osrednjeslovenska",
    "Gorenjska",
    "Notranjsko - kraška",
    "Goriška",
    "Obalno - kraška",
    "Ostalo"
];

export const TIERS = [TIER.A, TIER.B, TIER.C];


