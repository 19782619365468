import {useSelector} from "react-redux";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import {useEffect, useState} from "react";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {
    COUNTRIES,
    COUNTRIES_ARRAY,
    GROUPS_SIZE,
    L10N,
    PRICE_FILTER,
    REGIONS,
    STARS_FILTER
} from "../../constants/enums";
import {findItem, getIndexOf, scrollToDiv} from "../../helpers/helpers";

import '../css/Filters.css';

import iconCategory from "../../assets/images/kategorija.png";
import iconLocation from "../../assets/images/map-pin.png";
import iconPrice from "../../assets/images/cena.png";
import iconAccommodation from "../../assets/images/moon.png";
import iconGroupSize from "../../assets/images/število uporabnikov.png";
import iconDate from "../../assets/images/calendar.png";
import iconOnline from "../../assets/images/online.png";
import iconStars from "../../assets/images/star-blue.png";
import Dropdown from "./Dropdown";
import {CATEGORIES} from "../../pages/CoursePageEdit";

function Filters(props) {

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [c, setC] = useState(0);

    const [selectedTab, setSelectedTab] = useState();
    const [tabs, setTabs] = useState([]);

    const [tabsSelected, setSelectedTabs] = useState([]);

    const [category, setCategory] = useState();
    const [categoryObj, setCategoryObj] = useState();
    const [location, setLocation] = useState();
    const [groupSize, setGroupSize] = useState();
    const [stars, setStars] = useState();
    const [price, setPrice] = useState();

    const [accommodation, setAccommodation] = useState(false);
    const [online, setOnline] = useState();

    const [date, setDate] = useState([new Date(), new Date()]);

    const [filterTabs, setFilterTabs] = useState([[], []]);

    useEffect(() => {
        setSelectedLanguage(l10n)
        console.log(l10n);
        setC(c + 1);
        setTabs(getTabs());

    }, [l10n, selectedLanguage]);

    useEffect(() => {
        filterCourses(filterTabs);

    }, [categoryObj, price, accommodation, online, groupSize, location]);


    const getTabs = () => {
        return [
            {
                text: L10N_APP_TEXTS[selectedLanguage].FILTER_CATEGORY,
                anchor_id: 'tab_category',
                img: iconCategory
            },
            /*
            {
                text: L10N_APP_TEXTS[selectedLanguage].FILTER_LOCATION,
                anchor_id: 'tab_location',
                img: iconLocation
            },

             */
            {
                text: L10N_APP_TEXTS[selectedLanguage].FILTER_PRICE,
                anchor_id: 'tab_price',
                img: iconPrice
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].FILTER_ACCOMMODATION,
                anchor_id: 'tab_accommodation',
                img: iconAccommodation
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].FILTER_GROUP_SIZE,
                anchor_id: 'tab_group_size',
                img: iconGroupSize
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].FILTER_DATE,
                anchor_id: 'tab_date',
                img: iconDate
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].FILTER_ONLINE,
                anchor_id: 'tab_online',
                img: iconOnline
            },
            /*
            {
                text: L10N_APP_TEXTS[selectedLanguage].FILTER_STARS,
                anchor_id: 'tab_stars',
                img: iconStars
            },

             */
        ]
    };

    const addToSelectedFilters = (tab) => {
        let found = findItem('anchor_id', tab.anchor_id, tabsSelected);
        let foundIdx = getIndexOf('anchor_id', tab.anchor_id, tabsSelected);

        if (found) {
            console.log(`Tab already selected: `, tab.anchor_id);
            //tabsSelected.splice(foundIdx, 1);
        //    removeFromSelectedFilters(tab);
       //     removeFilter(tab.anchor_id)
       //     filterCourses(filterTabs)
        } else {
            tabsSelected.push(tab);
        }
    };

    const removeFromSelectedFilters = (tab) => {
        let indexFound = getIndexOf('anchor_id', tab.anchor_id, tabsSelected);
        let indexFoundOriginal = getIndexOf('anchor_id', tab.anchor_id, tabs);

        console.log(indexFound, indexFoundOriginal)

        if (indexFoundOriginal === 0) {
            setCategory(null);
        } else if (indexFoundOriginal === 1) {
            setLocation(null);
        } else if (indexFoundOriginal === 2) {
            setPrice(null);
        } else if (indexFoundOriginal === 3) {
            setAccommodation(null);
        } else if (indexFoundOriginal === 4) {
            setGroupSize(null);
        } else if (indexFoundOriginal === 5) {
            setDate([new Date(), new Date()]);
        } else if (indexFoundOriginal === 6) {
            setOnline(null);
        } else if (indexFoundOriginal === 7) {
            setStars(null);
        }

        if (indexFound < 0) {
            console.log(`Tab is not selected: `, tab.anchor_id);
        } else {
            let u = [...tabsSelected];

            u.splice(indexFound, 1);

            setSelectedTabs(u);
            setSelectedTab(null);
        }
    };

    const filterCourses = (filteredTabs) => {
        console.log("tabs", tabsSelected);

        let tabs = filteredTabs[0];
        let filters = filteredTabs[1];


        let f = [];
        let allCourses = props.allCourses;
        console.log(props.allCourses)
        for (let i = 0; i < allCourses.length; i++) {
            let course = allCourses[i];
            console.log("xx", course, tabs, filters);

            let checks = true;
            for (let j = 0; j < tabs.length; j++) {
                let tab = tabs[j];
                let filter = filters[j];

                if (tab === "tab_category") {
                    if (course.category_id === filter.category_id) {
                        checks = checks && true;
                    } else {
                        checks = false;
                    }
                }
                if (tab === "tab_price") {
                    if (filter === "+") {
                        f = allCourses.sort((a, b) => a.packages[0]?.price_discounted - b.packages[0]?.price_discounted);
                        checks = false;
                        break;
                    } else if (filter === "-") {
                        f = allCourses.sort((a, b) => b.packages[0]?.price_discounted - a.packages[0]?.price_discounted);
                        checks = false;
                        break;
                    } else {
                        let prices = filter.split("-");
                        if (course?.packages[0]?.price_discounted >= prices[0] && course.packages[0]?.price_discounted <= prices[1]) {
                            checks = checks && true;
                        } else {
                            checks = false;
                        }
                    }
                }
                if (tab === "tab_accommodation") {
                    if (course?.packages[0]?.accommodation) {
                        checks = checks && true;
                    } else {
                        checks = false;
                    }
                }
                if (tab === "tab_group_size") {
                    console.log(filter, course?.packages[0]?.trainees_max)
                    let max;
                    if (filter === '10+') {
                        max = 999;
                    } else {
                        max = filter.split("-")[1];
                    }
                    if (course?.packages[0]?.trainees_max <= max) {
                        checks = checks && true;
                    } else {
                        checks = false;
                    }
                }
                if (tab === "tab_online") {
                    if (course?.online) {
                        checks = checks && true;
                    } else {
                        checks = false;
                    }
                }
                if (tab === "tab_location") {
                    if (course?.region === filter) {
                        checks = checks && true;
                    } else {
                        checks = false;
                    }
                }
            }
            if (checks) {
                f.push(course);
            }
            console.log("mm", f)
        }
        //   setFilteredCourses(f);

        if (props.onSubmit) {
            props.onSubmit(f);
        }

        //   console.log("FFF", f, filter)
    }

    const addFilter = (value, tab) => {
        let t = [...filterTabs];
        for (let i = 0; i < filterTabs[0].length; i++) {
            if (filterTabs[0][i] === tab) {
                t[1][i] = value;
                setFilterTabs(t);
                return;
            }
        }
        t[0].push(tab);
        t[1].push(value);

        setFilterTabs(t);
    }


    const removeFilter = (tab) => {
        let t = [...filterTabs];
        for (let i = 0; i < filterTabs[0].length; i++) {
            if (filterTabs[0][i] === tab) {

                t[0].splice(i, 1);
                t[1].splice(i, 1);

                setFilterTabs(t);
                return;
            }
        }
    }

    const renderFilterValue = (tab, searchAnchor, filterResultValue) => {
        let found = findItem('anchor_id', tab.anchor_id, tabsSelected) && tab.anchor_id === searchAnchor;

        return (
            <div className={'row'} style={{display: !found ? 'none' : 'flex', margin: '8px 0'}} onClick={() => {

                removeFilter(searchAnchor)
                removeFromSelectedFilters(tab);
               // filterCourses(filterTabs)

               // removeFilter(searchAnchor);

            }}>
                <p className={'remove'} style={{paddingRight: '8px', margin: 0}}>{!filterResultValue ? '' : `x`}</p>
                <p className={'p-normal'}>{filterResultValue}</p>
            </div>

        );
    };

    const renderCategoryPopup = (tab) => {
        return (
            <div className={'custom-dropdown'}
                 style={{display: (category || tab.anchor_id !== 'tab_category' || selectedTab?.anchor_id !== tab.anchor_id) ? 'none' : 'flex'}}>
                <Dropdown grey
                          value={category}
                          style={{marginTop: '12px'}}
                          onChange={(value) => {
                              let v = selectedLanguage === L10N.si.value ? value?.name_si : selectedLanguage === L10N.en.value ? value?.name_en : value?.name_it
                              setCategory(v);
                              setCategoryObj(value);
                              console.log("SET", props.categories)
                              addToSelectedFilters(tab);
                              addFilter(value, "tab_category");
                          }}
                          categories
                          options={props.categories ? props.categories : []}
                          placeholder={L10N_APP_TEXTS[selectedLanguage].FILTER_CATEGORY}/>
            </div>

        );
    };

    const renderLocationPopup = (tab) => {
        return (
            <div className={'custom-dropdown'}
                 style={{display: (location || tab.anchor_id !== 'tab_location' || selectedTab?.anchor_id !== tab.anchor_id) ? 'none' : 'flex'}}>
                <Dropdown grey
                          value={location}
                          style={{marginTop: '12px'}}
                          onChange={(value) => {
                              setLocation(value)
                              addToSelectedFilters(tab);
                              addFilter(value, "tab_location");
                          }}
                          options={REGIONS}
                          placeholder={L10N_APP_TEXTS[selectedLanguage].FILTER_REGION}/>
            </div>
        );
    };

    const renderGroupSizePopup = (tab) => {
        return (
            <div className={'custom-dropdown'}
                 style={{display: (groupSize || tab.anchor_id !== 'tab_group_size' || selectedTab?.anchor_id !== tab.anchor_id) ? 'none' : 'flex'}}>
                <Dropdown grey
                          value={groupSize}
                          style={{marginTop: '12px'}}
                          onChange={(value) => {
                              setGroupSize(value)
                              addToSelectedFilters(tab);
                              addFilter(value, "tab_group_size");
                          }}
                          options={GROUPS_SIZE}
                          placeholder={L10N_APP_TEXTS[selectedLanguage].FILTER_GROUP_SIZE}/>
            </div>
        );
    };

    const renderStarsPopup = (tab) => {
        return (
            <div className={'custom-dropdown'}
                 style={{display: (stars || tab.anchor_id !== 'tab_stars' || selectedTab?.anchor_id !== tab.anchor_id) ? 'none' : 'flex'}}>
                <Dropdown grey
                          value={stars}
                          style={{marginTop: '12px'}}
                          onChange={(value) => {
                              setStars(value)
                              addToSelectedFilters(tab);
                          }}
                          options={STARS_FILTER}
                          placeholder={L10N_APP_TEXTS[selectedLanguage].FILTER_STARS}/>
            </div>
        );
    };

    const renderPricePopup = (tab) => {
        return (
            <div className={'custom-dropdown'}
                 style={{display: (price || tab.anchor_id !== 'tab_price' || selectedTab?.anchor_id !== tab.anchor_id) ? 'none' : 'flex'}}>
                <Dropdown grey
                          value={price}
                          style={{marginTop: '12px'}}
                          onChange={(value) => {
                              setPrice(value)
                              addToSelectedFilters(tab);
                              addFilter(value, "tab_price");
                          }}
                          options={PRICE_FILTER}
                          placeholder={L10N_APP_TEXTS[selectedLanguage].FILTER_PRICE}/>
            </div>
        );
    };

    const renderDatePopup = (tab, hide) => {
        return (
            <div className={'custom-dropdown'}
                 style={{display: (!hide || tab.anchor_id !== 'tab_date' || selectedTab?.anchor_id !== tab.anchor_id) ? 'none' : 'flex'}}>

                <DateRangePicker onChange={(value) => {
                    setDate(value);

                }} value={date} minDate={new Date()} clearIcon={null}/>
            </div>
        );
    };

    const renderTabs = () => {
        if (!tabs) {
            return null;
        }

        console.log(tabsSelected, date[0].toISOString(), date[1].toISOString())

        return (
            <div className={'tabs-horizontal filters'} style={{
                alignSelf: 'center',
                height: 'fit-content',
                minHeight: '120px',
                padding: 0,
                paddingLeft: '24px',
                maxHeight: 'unset',
                overflow: selectedTab ? 'unset' : 'scroll'
            }}>
                {tabs.map((tab, c) => (
                    <div key={`${c}_${tab.text}`} id={`selected_${tab.text === selectedTab?.text}`}
                         className={'tab-outer-container'} onClick={(event) => {
                        if (event.target === event.currentTarget) {
                            event.stopPropagation()
                            return;
                        }

                        setSelectedTab(tab);
                        /*scrollToDiv(tab.anchor_id);*/


                        if (tab.anchor_id === 'tab_accommodation' && !accommodation) {
                            addToSelectedFilters(tab);
                            addFilter(true, "tab_accommodation");
                            setAccommodation(L10N_APP_TEXTS[selectedLanguage].YES);
                        } else if (tab.anchor_id === 'tab_online' && !online) {
                            addToSelectedFilters(tab);
                            addFilter(true, "tab_online");
                            setOnline(L10N_APP_TEXTS[selectedLanguage].YES);
                        } else if (tab.anchor_id === 'tab_date') {
                            addToSelectedFilters(tab);
                        //    addFilter(tab, "tab_date");
                        }
                    }}>
                        <img src={tab.img} alt={'filter-img'} style={{
                            margin: findItem('anchor_id', tab.anchor_id, tabsSelected) ? '-12px 0 12px 0' : '0',
                            transition: 'margin 1s ease 0s'
                        }} width={'42px'}/>
                        <p className={'p-tab click'}>{tab.text}</p>

                        {renderFilterValue(tab, 'tab_category', category)}
                        {/*renderFilterValue(tab, 'tab_location', location)*/}
                        {renderFilterValue(tab, 'tab_price', price)}
                        {renderFilterValue(tab, 'tab_accommodation', accommodation)}
                        {renderFilterValue(tab, 'tab_group_size', groupSize)}
                        {renderFilterValue(tab, 'tab_date', date[0] ? `${date[0].toLocaleDateString()} - ${date[1].toLocaleDateString()}` : false)}
                        {renderFilterValue(tab, 'tab_online', online)}
                        {/*renderFilterValue(tab, 'tab_stars', stars)*/}

                        {renderCategoryPopup(tab)}
                        {/*renderLocationPopup(tab)*/}
                        {renderPricePopup(tab)}
                        {renderGroupSizePopup(tab)}
                        {renderDatePopup(tab, tab.anchor_id === selectedTab?.anchor_id)}
                        {/*renderStarsPopup(tab)*/}
                    </div>

                ))}
            </div>
        );
    };

    return renderTabs();
}

export default Filters;