import React, {useState, useEffect} from "react";
import Select from "react-select";

import '../css/Dropdown.css';
import Button from "./Button";
import {L10N} from "../../constants/enums";
import {useSelector} from "react-redux";
import {L10N_APP_TEXTS} from "../../l10n/l10n";

const parseDataToOptionsObject = (data) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        let el = {
            value: JSON.parse(JSON.stringify(data[i].value ? data[i].value : data[i])),
            label: data[i].label ? data[i].label : data[i].full_name ? data[i].full_name : data[i].name_default ? data[i].name_default : data[i]
        }

        obj.push(el);
    }
    //console.log(obj);
    return obj
};

const parseDataToOptionsObjectL10NCategories = (data, l10n) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        let el = {};
        switch (l10n) {
            case L10N.si.value:
                el = {value: JSON.parse(JSON.stringify(data[i])), label: data[i].name_si}
                break;
            case L10N.en.value:
                el = {value: JSON.parse(JSON.stringify(data[i])), label: data[i].name_en}
                break;
            case L10N.it.value:
                el = {value: JSON.parse(JSON.stringify(data[i])), label: data[i].name_it}
                break;
        }
        obj.push(el);
    }
    //console.log(obj);
    return obj
};

const parseDataToOptionsObjectL10NLecturers = (data, l10n) => {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        let el = {};
        switch (l10n) {
            case L10N.si.value:
                el = {
                    value: JSON.parse(JSON.stringify(data[i])),
                    label: data[i].full_name ? data[i].full_name : L10N_APP_TEXTS[l10n].LECTURERS_CHOOSE
                }
                break;
            case L10N.en.value:
                el = {
                    value: JSON.parse(JSON.stringify(data[i])),
                    label: data[i].full_name ? data[i].full_name : L10N_APP_TEXTS[l10n].LECTURERS_CHOOSE
                }
                break;
            case L10N.it.value:
                el = {
                    value: JSON.parse(JSON.stringify(data[i])),
                    label: data[i].full_name ? data[i].full_name : L10N_APP_TEXTS[l10n].LECTURERS_CHOOSE
                }
                break;
        }
        obj.push(el);
    }
    console.log(obj);
    return obj
};

function Dropdown(props) {
    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [selectedOption, setSelectedOption] = useState(props.value);
    const [options, setOptions] = useState((props.options));

    useEffect(() => {
        if (l10n) {
            setSelectedLanguage(l10n ? l10n : L10N.si.value)
        }
    }, [l10n])

    useEffect(() => {
        if (props.value) {
            setSelectedOption(props.value);
            console.log(props.value);
        }
    }, [props.value])

    useEffect(() => {
        if (props.options) {
            setOptions(props.options)
        }
    }, [props.options])


    useEffect(() => {
        if (props.categories) {
            setOptions(parseDataToOptionsObjectL10NCategories(options, selectedLanguage));
        }
        if (props.lecturers) {
            setOptions(parseDataToOptionsObjectL10NLecturers(options, selectedLanguage));
        }
    }, [l10n, selectedLanguage])


    const onInputChange = (selectedOption) => {
        const {onChange} = props;
        const {onAction} = props;

        setSelectedOption(selectedOption);
        onChange(selectedOption.value);
        if (onAction) {
            onAction(selectedOption.value);
            console.log("ON ACTION:", selectedOption.value);
        }
    }

    const onInputChangeText = (selectedOption) => {
        if (props.categories) {
            console.log(selectedOption);
            if (selectedOption?.length >= 3) {
                setOptions(parseDataToOptionsObjectL10NCategories(options, selectedLanguage));
            }
        }
    }

    let className = 'select-default';
    let classNamePrefix = 'select-default';

    if (props.grey) {
        className += ' grey';
    }

    let inputContent = () => {
        return (
            <Select
                className={className}
                classNamePrefix={classNamePrefix}
                defaultValue={selectedOption}
                onChange={onInputChange}
                onInputChange={onInputChangeText}
                options={props.categories ? parseDataToOptionsObjectL10NCategories(props.options, selectedLanguage) : props.lecturers ? parseDataToOptionsObjectL10NLecturers(options, selectedLanguage) : parseDataToOptionsObject(props.options)}
                placeholder={props.placeholder}
                noOptionsMessage={() => "Seznam je prazen.."}
            />
        );
    };

    return (
        <form style={{height: '40px', ...props.style}}>
            <label className={'input-label'}>
                {props.label && <p className={'p-label'} style={{color: 'black'}}>{props.label}</p>}
                {inputContent()}
                <div style={{opacity: 0, pointerEvents: 'none'}}>
                    {<Button text={'edit'}/>}
                </div>
            </label>
        </form>
    );
}

export default Dropdown;