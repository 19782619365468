import {
    SET_APP_FIRST_OPEN,
    SET_USER_DATA,
    REMEMBER_ME,
    REMEMBER_ME_CREDENTIALS,
    loadSessionUser,
    SET_USER_TOKEN,
    L10N,
    loadSessionUserToken,
    SET_CREDENTIALS,
    loadLocalStorageData,
    SET_COURSES_FOLLOWED,
    SET_CATEGORIES_FOLLOWED,
    SET_PROVIDERS_FOLLOWED,
    SET_CATEGORIES,
    SET_COURSES,
    SET_PROVIDERS,
    SET_USERS,
    setLocalL10N,
    SET_PROVIDER_DATA,
    loadSessionProvider, SET_COURSE, loadSessionCourse, SET_ADMIN_DATA, loadSessionAdmin,
} from '../actions/actions';
import {SESSION_STORAGE_KEYS} from "../../constants/enums";
import {L10N as L10N_ENUM}  from "../../constants/enums"

const initialState = {
    admin: loadSessionAdmin(SESSION_STORAGE_KEYS.ADMIN),
    appFirstOpen: true,
    savedCredentials: {
        email: "",
        password: ""
    },
    loginCredentials: {
        email: "",
        password: ""
    },
    rememberMe: false,
    userToken: loadSessionUserToken(SESSION_STORAGE_KEYS.USER_TOKEN),
    user: loadSessionUser(SESSION_STORAGE_KEYS.USER),
    provider: loadSessionProvider(SESSION_STORAGE_KEYS.PROVIDER),
    l10n: loadLocalStorageData(SESSION_STORAGE_KEYS.L10N) ? loadLocalStorageData(SESSION_STORAGE_KEYS.L10N) : 'SI',
    providers: [],
    courses: [],
    categories: [],
    providers_followed: [],
    courses_followed: [],
    categories_followed: [],
    users: [],
    course: loadSessionCourse(SESSION_STORAGE_KEYS.COURSE),
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_APP_FIRST_OPEN:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.appFirstOpen}`);
            return {...state, appFirstOpen: action.appFirstOpen};
        case L10N:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.l10n}`);
            return {...state, l10n: action.l10n};
        case REMEMBER_ME:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.rememberMe}`);
            return {...state, rememberMe: action.rememberMe};
        case REMEMBER_ME_CREDENTIALS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, saved email: ${JSON.stringify(action.email, null, 4)}, saved password: ${JSON.stringify(action.password, null, 4)}`);
            return {...state, savedCredentials: {email: action.email, password: action.password}};
        case SET_USER_TOKEN:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, token: ${JSON.stringify(action.token, null, 4)}`);
            return {...state, userToken: action.token};
        case SET_USER_DATA:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, user: ${JSON.stringify(action.user, null, 4)}`);
            return {...state, user: action.user};
        case SET_ADMIN_DATA:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, user: ${JSON.stringify(action.user, null, 4)}`);
            return {...state, admin: action.user};
        case SET_PROVIDER_DATA:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, provider: ${JSON.stringify(action.provider, null, 4)}`);
            return {...state, provider: action.provider};
        case SET_CREDENTIALS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, email: ${JSON.stringify(action.email, null, 4)},  pass: ${JSON.stringify(action.password, null, 4)}`);
            return {...state, loginCredentials: {email: action.email, password: action.password}};
        case SET_PROVIDERS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.providers}`);
            return {...state, providers: action.providers};
        case SET_COURSES:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.courses}`);
            return {...state, courses: action.courses};
        case SET_CATEGORIES:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${JSON.stringify(action.categories, null, 4)}`);
            return {...state, categories: action.categories};
        case SET_PROVIDERS_FOLLOWED:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.providers_followed}`);
            return {...state, providers_followed: action.providers_followed};
        case SET_COURSES_FOLLOWED:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.courses_followed}`);
            return {...state, courses_followed: action.courses_followed};
        case SET_CATEGORIES_FOLLOWED:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.categories_followed}`);
            return {...state, categories_followed: action.categories_followed};
        case SET_USERS:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, value: ${action.users}`);
            return {...state, users: action.users};
        case SET_COURSE:
            console.log(`Action ${JSON.stringify(action.type, null, 4)}, user: ${JSON.stringify(action.course, null, 4)}`);
            return {...state, course: action.course};

        default:
            console.log(`Undefined fire action: ${JSON.stringify(action.type, null, 4)}, value: ${JSON.stringify(action, null, 4)}`);
            return state;
    }
};

export default reducer;