import '../css/FooterPage.css';
import iconInstagram from "../../assets/images/instagram.png";
import iconFacebook from "../../assets/images/facebook.png";
import iconTwitter from "../../assets/images/twitter.png";
import iconYoutube from "../../assets/images/youtube.png";
import iconGlobe from "../../assets/images/globe.png";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {L10N} from "../../constants/enums";
import {useHistory} from "react-router-dom";
import {PAGES} from "../../App";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {getDocData} from "../../store/actions/actions";
import SubscriptionForm from "./SubscriptionForm";

function FooterPage(props) {
    let dispatch = useDispatch();

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);


    const [openSubscribe, setOpenSubscribe] = useState(false);

    const baseUrl = window.location.protocol + '//' + window.location.hostname  + (window.location.port ? ':' + window.location.port : '');

    useEffect(() => {
        //setSelectedLanguage(l10n)
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);

        console.log("FOOTER L10N:", l10n);

        if (l10n) {
            setSelectedLanguage(l10n)
        }

    }, [l10n]);

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);

    const onPreviewFile = async (doc_type, l10n) => {
        dispatch(getDocData(doc_type, l10n, (res) => {
            // Set the data URI state with the Base64-encoded PDF data

            /*
            console.log(res)

            // Open the PDF in a new tab using an <a> tag
            const link = document.createElement('a');
            link.href = res;
            link.target = '_blank';
            link.click();

             */

            let byteCharacters = atob(res.data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], {type: 'application/pdf;base64'});
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }, (res) => {
        }));
    };


    const renderContent = () => {
        return (
            <>
                <div className={'footer_page_container'}>
                    <div className={'footer_col'}>
                        <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].USERS_FOOTER}</p>
                        <p className={'p-desc click-light'} onClick={() => onNavigateTo(PAGES.PRESENTATION_USER)}>{L10N_APP_TEXTS[selectedLanguage].BENEFITS_PROVIDER}</p>
                        <p className={'p-desc click-light'} onClick={() =>   onPreviewFile("TERMS_OF_USE_USERS", selectedLanguage)}>{L10N_APP_TEXTS[selectedLanguage].PRIVACY_POLICY_USERS}</p>
                        <p className={'p-desc click-light'} onClick={() => onNavigateTo(PAGES.PRESENTATION_USER)}>{L10N_APP_TEXTS[selectedLanguage].FAQ_LABEL}</p>
                        <br/>
                        <p className={'p-desc click-light'} onClick={() => onNavigateTo(PAGES.REGISTER_USER)}>{L10N_APP_TEXTS[selectedLanguage].REGISTER_FOOTER}</p>
                    </div>
                    <div className={'footer_col'}>
                        <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].PROVIDERS_FOOTER}</p>
                        <p className={'p-desc click-light'} onClick={() => onNavigateTo(PAGES.PRESENTATION_PROVIDER)}>{L10N_APP_TEXTS[selectedLanguage].BENEFITS_PROVIDER}</p>
                        <p className={'p-desc click-light'} onClick={() => onNavigateTo(PAGES.REGISTER_PROVIDER)}>{L10N_APP_TEXTS[selectedLanguage].JOIN_FAMILY}</p>
                        <p className={'p-desc click-light'} onClick={() => onPreviewFile("TERMS_OF_USE_PROVIDERS", selectedLanguage)}>{L10N_APP_TEXTS[selectedLanguage].PRIVACY_POLICY_PROVIDERS}</p>
                        <p className={'p-desc click-light'} onClick={() => onNavigateTo(PAGES.PRESENTATION_PROVIDER)}>{L10N_APP_TEXTS[selectedLanguage].FAQ_LABEL}</p>
                        <br/>
                        <p className={'p-desc click-light'}  onClick={() => onNavigateTo(PAGES.LOGIN)}>{L10N_APP_TEXTS[selectedLanguage].PROVIDER_FOOTER}</p>
                    </div>
                    <div className={'footer_col'}>
                        <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].CONTACT}</p>
                        <p className={'p-desc click-light'}>Bravooo d.o.o.</p>
                        <a href={"tel:038641864556"}> <p className={'p-desc click-light'}>041 864 556</p></a>
                        <a href={"mailto:ask@bravo.com"}><p className={'p-desc click-light'}>ask@bravo.com</p></a>

                        <div className={'social_row'}>
                            <img src={iconInstagram} className={'icon-info'} alt={'icon_img'} width={'21px'}
                                 onClick={() => {
                                 }}/>
                            <img src={iconFacebook} className={'icon-info'} alt={'icon_img'} width={'21px'}
                                 onClick={() => {
                                 }}/>
                            <img src={iconTwitter} className={'icon-info'} alt={'icon_img'} width={'21px'}
                                 onClick={() => {
                                 }}/>
                            <img src={iconYoutube} className={'icon-info'} alt={'icon_img'} width={'21px'}
                                 onClick={() => {
                                 }}/>
                        </div>
                        <p className={'p-desc click-light'}  onClick={() => {
                            setOpenSubscribe(true);
                        }}>{L10N_APP_TEXTS[selectedLanguage].NEWSLETTER_SUBSCRIBE}</p>
                        <br/>
                        <p className={'p-desc click-light'}  onClick={() => {
                            onPreviewFile("PRIVACY_POLICY", selectedLanguage)
                        }}>{L10N_APP_TEXTS[selectedLanguage].PRIVACY_POLICY}</p>
                        <p className={'p-desc click-light'} onClick={() => {
                            onPreviewFile("TERMS_OF_USE", selectedLanguage)
                        }}>{L10N_APP_TEXTS[selectedLanguage].TERMS_OF_USE}</p>
                        <p className={'p-desc click-light'}  onClick={() => {
                            onPreviewFile("COOKIES", selectedLanguage)
                        }}>{L10N_APP_TEXTS[selectedLanguage].COOKIES}</p>
                    </div>
                </div>
                <div className={'copyright_row'}>
                    <div className={'copyright_row left'}>
                        <img src={iconGlobe} className={'icon-info'} alt={'icon_img'} width={'20px'} onClick={() => {
                        }}/>
                        <p className={'p-copyright'} style={{textTransform: 'uppercase'}}>{selectedLanguageObj?.country}</p>
                        <p className={'p-copyright delimiter'}>|</p>
                        <p className={'p-copyright'}>{selectedLanguageObj?.label}</p>
                        <p className={'p-copyright delimiter'}>|</p>
                        <p className={'p-copyright'}>{selectedLanguageObj?.priceValueLabel}</p>
                    </div>
                    <div className={'copyright_row right'}>
                        <p className={'p-copyright'} onClick={() => {
                            onPreviewFile("TERMS_OF_USE", selectedLanguage)
                        }}>{L10N_APP_TEXTS[selectedLanguage].TERMS_OF_USE}</p>
                        <p className={'p-copyright'} onClick={() => {
                            onPreviewFile("PRIVACY_POLICY", selectedLanguage)
                        }}>{L10N_APP_TEXTS[selectedLanguage].PRIVACY_POLICY}</p>
                        <p className={'p-copyright'} onClick={() => {
                            onPreviewFile("COOKIES", selectedLanguage)
                        }}>{L10N_APP_TEXTS[selectedLanguage].COOKIES}</p>
                    </div>
                </div>

                {openSubscribe ? <SubscriptionForm/> : <></> }
            </>
        );

    }

    return renderContent();
}

export default FooterPage;
