import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {combineReducers, createStore, applyMiddleware} from "redux";
import {Provider, useDispatch, useSelector} from "react-redux";
import ReduxThunk from 'redux-thunk';
import MainReducer from './store/reducers/reducer';

import './App.css';

import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import RegisterProvider from "./pages/RegisterProvider";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ResetPasswordLogin from "./pages/ResetPasswordLogin";
import MainPage from "./pages/MainPage";
import ProviderPage from "./pages/ProviderPage";
import CoursePage from "./pages/CoursePage";
import CoursePageEdit from "./pages/CoursePageEdit";
import ProviderPageEdit from "./pages/ProviderPageEdit";
import RegisterUser from "./pages/RegisterUser";
import {useEffect} from "react";
import {loadAllCategoriesParsed, loadAllCourses, loadAllProviders} from "./store/actions/actions";
import AdminControlPanel from "./pages/AdminControlPanel";
import PresentationProvider from "./pages/PresentationProvider";
import PresentationUser from "./pages/PresentationUser";
import CookieNotice from "./components/js/CookieNotice";

export const PAGES = {
    REGISTER_PROVIDER: '/register-provider',
    REGISTER_USER: '/register-user',
    LOGIN: '/login',
    MAIN: '/',
    HOME: '/home',
    RESET_PASSWORD: '/password-reset',
    PROVIDER: '/provider',
    PROVIDER_EDIT: '/provider-edit',
    COURSE: '/course',
    COURSE_EDIT: '/course-edit',
    CONTROL_PANEL: '/control-panel',
    PRESENTATION_PROVIDER: '/welcome-provider',
    PRESENTATION_USER: '/welcome-user',
}

const STRIPE_TEST_PKEY = 'pk_test_51LDxicCFtDlw5oIQn2hrNwJagKMtie3iamGPxFopZtfiTDV3LLI53kxqbKlBP36jdWP3khjqYntNbxSxgHFTHulp00jSmitClr';
const STRIPE_PKEY = 'pk_live_51LDxicCFtDlw5oIQkpHNl03kWo5Od6Jo1HQN5JmsX7tB4Qk8UUBnnz8iO0RIggV9XJzLxnVOL0gIeP3gVv5KHYBC00wshtC6fn';

const stripePromise = loadStripe(STRIPE_TEST_PKEY);

function App() {
    const rootReducer = combineReducers({
        reducer: MainReducer
    });
    const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
    console.log(stripePromise)

    return (
        <>
            <Elements stripe={stripePromise}>
                <Provider store={store}>
                    <Router>
                        <Switch>
                            <Route path={'/'} exact component={MainPage}/>
                            <Route path={PAGES.LOGIN} component={Login}/>
                            <Route path={PAGES.REGISTER_PROVIDER} component={RegisterProvider}/>
                            <Route path={PAGES.REGISTER_USER} component={RegisterUser}/>
                            <Route path={PAGES.RESET_PASSWORD} component={ResetPasswordLogin}/>
                            <Route path={PAGES.HOME} component={Home}/>
                            <Route path={PAGES.CONTROL_PANEL} component={AdminControlPanel}/>
                            <Route path={PAGES.COURSE_EDIT} component={CoursePageEdit}/>
                            <Route path={PAGES.PROVIDER_EDIT} component={ProviderPageEdit}/>
                            <Route path={PAGES.PROVIDER + ":provider_id"} component={ProviderPage}/>
                            <Route path={PAGES.COURSE + ":course_id"} component={CoursePage}/>
                            <Route path={PAGES.PRESENTATION_PROVIDER} component={PresentationProvider}/>
                            <Route path={PAGES.PRESENTATION_USER} component={PresentationUser}/>
                        </Switch>
                    </Router>
                    <CookieNotice />
                </Provider>
            </Elements>
        </>

    );
}

export default App;
