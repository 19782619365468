import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {L10N} from "../../constants/enums";
import {scrollToDiv} from "../../helpers/helpers";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import parse from 'html-react-parser';
import Button from "./Button";

function RTEditor(props) {

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [content, setContent] = useState("");

    useEffect(() => {
        setSelectedLanguage(l10n)
        console.log(l10n);
    }, [l10n, selectedLanguage]);

    useEffect(() => {
        if (props.text) {
            try {
                setContent(props.text)
            } catch (e) {}
        }
    }, [props.text]);


    const renderContent = () => {
        return (<>
                <div className={'p-title'} style={{paddingBottom: '12px'}}>{props.title}</div>
                <div className={'rteditor'}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setContent(data)
                            console.log({event, editor, data});
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                </div>
                <div className={'rteditor-output'}>
                    <div className={'row'}>
                        <Button text={"SHRANI"} style={{margin: '12px 0'}} onClick={() => {
                            try {
                                JSON.stringify(content);
                                props.onSubmit(content)
                            } catch (e) {}
                        }}/>
                    </div>
                    {content ?
                        <div className={'p-desc'} style={{padding: '12px 0', fontWeight: 600}}>{L10N_APP_TEXTS[selectedLanguage].PREVIEW}</div>
                        : <></>}

                    <div>{parse(content)}</div>
                </div>
            </>

        );
    };

    return renderContent();
}

export default RTEditor;