import React, {useEffect, useState} from "react";

import '../css/Input.css';
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import Button from "./Button";
import {useSelector} from "react-redux";
import {L10N} from "../../constants/enums";


function Input(props) {
    const [input, setInput] = useState('');

    const [disabled, setDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('edit');

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    useEffect(() => {
        if (l10n) {
            setSelectedLanguage(l10n);
            if (disabled) {
                setButtonText(L10N_APP_TEXTS[l10n].EDIT);
            } else {
                setButtonText(L10N_APP_TEXTS[l10n].SAVE?.toLowerCase());
            }
        }
    }, [l10n])

    useEffect(() => {
        if (props.user && props.email) {
            setInput(props.email);
        }
    }, [props.user, props.email])


    useEffect(() => {
        if (props.value || props.value === "") {
            setInput(props.value);
        }
    }, [props.value])

    useEffect(() => {
        if (props.button) {
            if (buttonText === 'edit') {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
            console.log(buttonText, disabled);
        }
    }, [props.button])

    useEffect(() => {
        if (props.button) {
            if (disabled) {
                setButtonText(L10N_APP_TEXTS[selectedLanguage].EDIT);
            } else {
                setButtonText(L10N_APP_TEXTS[selectedLanguage].SAVE?.toLowerCase());
            }
        }
    }, [disabled])

    useEffect(() => {
        if (props.login && props.email) {
            setInput(props.email);
        }
        if (props.login && props.password) {
            setInput(props.password);
        }
    }, [props.login, props.email, props.password])

    const onActionSubmit = (event) => {
        if (props.onSubmit) {

            if (props.button) {
                setDisabled(!disabled);
            }
            event.preventDefault();
            props.onSubmit(input);
        }
    };

    const onInputChange = (value) => {
        const {onChange} = props;

        setInput(value);
        onChange(value);
    }

    let className = 'input-default';
    if (props.white) {
        className += ' input-white';
    }
    if (props.login) {
        className += ' input-login';
    }
    if (props.loadUserActivation) {
        className += ' input-loadUserActivation'
    }
    if (props.shadow) {
        className += ' input-shadow';
    }
    if (props.textarea) {
        className += ' input-textarea';
    }
    if (props.price) {
        className += ' input-price';
    }

    let type = 'text';
    if (props.password) {
        type = 'password';
    } else if (props.email) {
        type = 'email';
    }
    let inputContent = () => {
        if (props.textarea) {
            return <>
                <textarea
                    disabled={props.disabled ? props.disabled : disabled}
                    className={className}
                    placeholder={props.placeholder}
                    style={props.style}
                    value={input ? input : ''}
                    onBlur={props.onBlur}
                    onSubmit={props.onSubmit}
                    onChange={(event) => onInputChange(event.target.value)}/>

                {props.button ?
                    <Button text={props.button ? buttonText : ''} onClick={() => {
                        if (props.button) {
                            setDisabled(!disabled);
                        }
                        if (props.button && !disabled && props.onAction) {
                            console.log("SUBMIT DATA");
                            props.onAction(input);
                        }
                    }}/> : <></>}
            </>


        } else {
            return <>
                <input disabled={props.disabled ? props.disabled : disabled} type={type} className={className}
                       placeholder={props.placeholder}
                       style={props.style}
                       value={input ? input : ''}
                       onBlur={props.onBlur}
                       name={props.login && props.email ? "email" : ""}
                       autoComplete={props.login && props.password ? "new-password" : props.login && props.email ? "email" : "off"}
                       onChange={(event) => onInputChange(event.target.value)}/>

                {props.button ?
                    <Button text={props.button ? buttonText : ''} onClick={() => {
                        if (props.button) {
                            setDisabled(!disabled);
                        }
                        if (props.button && !disabled && props.onAction) {
                            console.log("SUBMIT DATA");
                            props.onAction(input);
                        }
                    }}/> : <></>}

            </>
        }
    };

    return (
        <form onSubmit={onActionSubmit}>
            <label className={'input-label'}>
                {props.label &&
                <p className={'p-label'}>{props.label}</p>
                }
                {inputContent()}
            </label>
        </form>
    );
}

export default Input;