import {useCallback, useEffect, useRef, useState} from "react";
import ImageUploading from "react-images-uploading";
import {useSpring, animated} from 'react-spring'
import {useHistory} from "react-router-dom";
import {COUNTRIES_ARRAY, L10N, parseCountryObject, USER_ROLES} from "../constants/enums";
import {PAGES} from "../App";

import {TailSpin} from "react-loader-spinner";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {BsQuestionCircleFill} from "react-icons/bs";
import {
    addCourse, createCourse,
    createFaq,
    createImage,
    createLecturer,
    createLecturers,
    deleteFaq,
    deleteImage, deleteLecturer,
    getFaqProvider,
    isRememberMeEnabled, loadCourseById, loadCourseImages, loadCoursesForProvider,
    loadCredentials, loadFollowersForProvider,
    loadImage,
    loadImages,
    loadLecturers,
    loadLocalL10N,
    loadProviderData,
    setL10N,
    setRememberMe,
    setSignInCredentials, updateCourseActive,
    updateFaq,
    updateImage,
    updateLecturer, updateLecturerData, updateLecturerDataL10N,
    updateProviderData,
    updateProviderDataL10N,
    updateUserData,
    userLogin
} from "../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import Message, {ErrorType} from "../components/js/Message";
import {L10N_APP_TEXTS} from "../l10n/l10n";
import {
    capitalize, displayDate,
    displayDateMonthYear,
    findItem,
    getIndexOf, getNextCourseStartDate, parseHoursDurationFromTimestamps, parseHoursDurationFromTimestampsFormatted,
    parseSelectedCountryLabel,
    scrollToDiv
} from "../helpers/helpers";
import colors from "../constants/colors";
import Collapsible from "react-collapsible";
import Header from "../components/js/Header";
import Input from "../components/js/Input";
import plusCircleIcon from "../assets/images/plus-circle.png";
import iconStarGray from "../assets/images/i_star.png";
import logoImg from "../assets/images/logo.png";
import starBlueFull from "../assets/images/star-blue-full.png";
import starBrownFull from "../assets/images/star-brown.png";
import iconAcc from "../assets/images/acc.png";
import iconHeart from "../assets/images/heart_blue.png";

import iconPlus from "../assets/images/plus.png";
import iconInfo from "../assets/images/info.png";
import plusBlack from "../assets/images/plus-black.png";
import iconImageExample from "../assets/images/img_example.png";
import iconAddress from "../assets/images/i_address.png";
import iconImg from "../assets/images/icon_img.png";
import iconMapPin from "../assets/images/map-pin.png";
import iconMean from "../assets/images/i_mean.png";
import iconStar from "../assets/images/i_star.png";
import iconSum from "../assets/images/i_sum.png";
import iconNight from "../assets/images/i_night.png";
import courseImg from "../assets/images/course.png";
import countryIT from "../assets/images/country_it.png";
import countryEN from "../assets/images/country_en.png";
import countrySI from "../assets/images/country_si.png";
import cloud from "../assets/images/cloud.png";

import Title from "../components/js/Title";

import Footer from "../components/js/Footer";
import {CREATE_ACTION} from "./RegisterProvider";
import ScrollableTabs from "../components/js/ScrollableTabs";
import Button from "../components/js/Button";
import DropdownCircle from "../components/js/DropdownCircle";
import FooterPage from "../components/js/FooterPage";
import GoogleMap from "../components/js/GoogleMap";
import Filters from "../components/js/Filters";
import Search from "../components/js/Search";
import HeaderLanding from "../components/js/HeaderLanding";
import CustomMap from "../components/js/CustomMap";
import Dropdown from "../components/js/Dropdown";
import ScrollableTabsEdit from "../components/js/ScrollableTabsEdit";
import ScrollableTabsCourses from "../components/js/ScrollableTabsCourses";
import {COURSES} from "./CoursePage";
import countryDE from "../assets/images/country_de.png";
import countryHR from "../assets/images/country_hr.png";
import iconCalendar from "../assets/images/calendar.png";

const CATEGORIES = [
    'Analogna fotografija',
    'Obdelava fotografije',
    'Kozmetika',
]

const FAQ = [
    {question: 'Vprašanje', answer: 'Odgovor..'},
    {question: 'Daljše Vprašanje', answer: 'Odgovor..'},
];

const USER = {
    user_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    full_name: 'Tadej Hiti',
    user_role: USER_ROLES.CUSTOMER,
    password: 'lulček#%hashed',
    email: 'hiti.tadej@gmail.com',
    tel: '+38640306677',
    address: 'Ograde 3',
    l10n_default: L10N.si.value,
    l10n_en: false,
    l10n_si: false,
    l10n_it: false,
};
const PROVIDER = {
    provider_id: 'sdfdfgdf3-dsfs4-fsdf444-sadasd33',
    images: [iconImageExample, iconImageExample, iconImageExample, iconImageExample, iconImageExample, iconImageExample]
};


const FOLLOWING = [
    {
        provider_id: 'dfgdfgdhfj',
        name: 'Plesna Šola Vzorec',
    },
    {
        provider_id: 'dfgdfg8678dhfj',
        name: 'Plesna Šola Vzorec',
    }
];

export const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

function ProviderPageEdit() {
    let dispatch = useDispatch();

    const user = useSelector(state => state.reducer.user);
    const token = useSelector(state => state.reducer.userToken);
    const l10n = useSelector(state => state.reducer.l10n);

    const provider = useSelector(state => state.reducer.provider);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [isL10NOptionsOpen, setIsL10NOptionsOpen] = useState(false);

    const [providerName, setProviderName] = useState(provider?.name);
    const [providerDesc, setProviderDesc] = useState(provider?.l10n[getIndexOf("l10n", l10n ? l10n : L10N.si.value, provider.l10n)]?.description);
    const [providerAddress, setProviderAddress] = useState(provider?.address);
    const [providerPostCity, setProviderPostCity] = useState(provider?.post);
    const [country, setCountry] = useState(provider?.country);

    const [providerVat, setProviderVat] = useState(provider?.vat);
    const [providerRegNmb, setProviderRegNmb] = useState(provider?.registration_nmb);
    const [providerBankAcc, setProviderBankAcc] = useState(provider?.bank_account_nmb);
    const [providerBankName, setProviderBankName] = useState(provider?.bank_name);

    const [providerYoutubeUrl, setProviderYoutubeUrl] = useState(provider?.youtube_url);

    const [faq, setFaq] = useState(
        [
            {question: 'Vprašanje', answer: 'Odgovor..'},
            {question: 'Daljše Vprašanje', answer: 'Odgovor..'},
        ]
    );

    const [lecturers, setLecturers] = useState([]);

    const [faqs, setFaqs] = useState([]);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(null);


    const [openedFaq, setOpenedFaq] = useState(false);

    const [toggleFilters, setToggleFilters] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState();

    const [createTrigger, setCreateTrigger] = useState(false);
    const [action, setAction] = useState(null);

    const [followCourse, setFollowCourse] = useState();
    const [selectedCourseTab, setSelectedCourseTab] = useState(null);

    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);


    const [logo, setLogo] = useState([]);
    const [images, setImages] = useState([]);

    const [myCourses, setMyCourses] = useState([]);
    const [myCoursesArchived, setMyCoursesArchived] = useState([]);

    console.log("MY", myCourses)
    console.log(myCoursesArchived)

    const [lecturersProfilePictures, setLecturersProfilePictures] = useState([]);

    const [followers, setFollowers] = useState("");

    const maxNumberImageUploads = 10;

    const dispatchLoadLecturers = () => {
        dispatch(loadLecturers({provider_id: provider.provider_id}, (resData) => {
            console.log(resData);
            setLecturers(resData);

            let u = [];

            for (let i = 0; i < resData.length; i++) {
                let lec = resData[i];
                if (!lec.image_id) {
                    u.push(null)
                    continue;
                }
                dispatch(loadImage({image_id: lec.image_id, type: "LOGO"}, (resData) => {
                    u.push(resData.image)

                    setTimeout(() => {
                        setLecturersProfilePictures(u);
                    }, 200);
                }, () => {
                }))
            }
        }, () => {

        }));
    }

    const dispatchLoadCourses = () => {
        dispatch(loadCoursesForProvider({provider_id: provider.provider_id}, async (resData) => {
            if (!resData) {
                return;
            }
            let archived = [];
            let courses = [];

            for (let i = 0; i <= resData.length; i++) {
                let c = resData[i];

                if (!c) {
                    return;
                }

                if (c.active) {
                    courses.push(c);
                } else {
                    archived.push(c);
                }

                setMyCourses(courses);
                setMyCoursesArchived(archived);
            }

        }, () => {
        }));
    }

    const onChangeLecturersProfilePictureUpload = (imageList, addUpdateIndex) => {
        // data for submit
        console.log("LIST", imageList, "INDEX", addUpdateIndex);

        console.log("lecturersProfilePictures", lecturersProfilePictures);

        let tmp = lecturersProfilePictures;
        tmp[addUpdateIndex] = imageList[0].data_url;

        if (!tmp[addUpdateIndex].image_id) {
            dispatch(createImage({base64: imageList[0].data_url, type: 'LOGO'}, (resData) => {
                updateLecturerDataHandler("image_id", resData.image_id, lecturers[addUpdateIndex]);
            }, () => {
            }))
        } else {
            dispatch(updateImage({image_id: provider.image_id, base64: imageList[0].data_url}, (resData) => {
                updateLecturerDataHandler("image_id", resData.image_id, lecturers[addUpdateIndex]);
            }, () => {
            }))
        }

        setLecturersProfilePictures([...tmp]);
    };

    const onChangeImageUpload = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);


        if (!imageList || imageList.length <= 0) {
            return;
        }

        for (let i = 0; i < imageList.length; i++) {
            console.log(images[i]?.image_id);

            if (!images[i]) {
                dispatch(createImage({
                    provider_id: provider.provider_id,
                    base64: imageList[i].data_url,
                    type: 'GALLERY'
                }, (resData) => {
                    //     updateProviderDataHandler("image_id", resData.image_id);

                    imageList[i].image_id = resData.image_id
                    //setImages(imageList);

                }, () => {
                }))
            } else {
                dispatch(updateImage({image_id: images[i]?.image_id, base64: imageList[i].data_url}, (resData) => {
                    //       updateProviderDataHandler("image_id", resData.image_id);
                    imageList[i].image_id = resData.image_id
                    //setImages(imageList);

                }, () => {
                }))
            }
        }

        setImages(imageList);

    };

    const onChangeLogoUpload = (imageList, addUpdateIndex) => {
        // data for submit
        setLogo(imageList);

        if (!imageList || imageList.length <= 0) {
            return;
        }

        if (!provider.image_id) {
            dispatch(createImage({base64: imageList[0].data_url, type: 'LOGO', provider_id: provider.provider_id}, (resData) => {
                updateProviderDataHandler("image_id", resData.image_id);
            }, () => {
            }))
        } else {
            dispatch(updateImage({image_id: provider.image_id, base64: imageList[0].data_url}, (resData) => {
                updateProviderDataHandler("image_id", resData.image_id);
            }, () => {
            }))
        }
    };

    const renderUploadLecturersProfilePictureContainer = (index) => {

        const renderUploadArea = (i, isDragging, onImageUpload, dragProps) => {

            return (
                <div className={'upload-section col'}>
                    <button
                        className={'upload-area-button'}
                        onClick={onImageUpload}
                        {...dragProps}
                    >
                        <div className={'upload-area-container col'}
                             style={{border: isDragging ? "1px dotted black" : 'none', pointerEvents: 'none'}}>
                            <p className={'p-info'}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                            <div className={'upload-area upload-small col'}>
                                <img src={plusCircleIcon} className={'icon-label'} alt={'img'} width={'28px'}
                                     style={{padding: '6px', paddingBottom: 0}}/>
                            </div>
                        </div>
                    </button>
                </div>

            );
        }
        const renderUploadedItemsArea = (i, imageList, onImageUpdate, onImageRemove) => {


            return (
                <div className={'uploaded-gallery row scroll'}>
                    {lecturersProfilePictures.map((image, index) => (
                        <div key={index} className="uploaded-image-item">

                            {index === i && <>
                                <img src={image} alt="" width="80"/>
                                <div className="image-item__btn-wrapper row center">
                                    <p className={'p-info underline click-light'}
                                       style={{cursor: 'pointer'}}
                                       onClick={onImageUpdate}>{L10N_APP_TEXTS[selectedLanguage].UPDATE}
                                    </p>
                                    {/*
                                       <p className={'p-text remove click'}
                                   style={{position: 'absolute', marginLeft: '90px', marginTop: '-220px'}}
                                   onClick={() => onImageRemove(index)}>x</p>
                                */}
                                </div>
                            </>}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <ImageUploading
                single
                value={lecturersProfilePictures[index]}
                onChange={(imageList) => onChangeLecturersProfilePictureUpload(imageList, index)}
                maxNumber={1}
                dataURLKey="data_url"
                acceptType={["jpg", "png"]}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload-container col logo lecturer">
                        {!lecturersProfilePictures[index] && renderUploadArea(index, isDragging, onImageUpload, dragProps)}
                        {renderUploadedItemsArea(index, lecturersProfilePictures, onImageUpdate, onImageRemove)}
                    </div>
                )}
            </ImageUploading>
        );
    }

    const renderUploadLogoContainer = () => {
        const renderUploadArea = (isDragging, onImageUpload, dragProps) => {
            return (
                <div className={'upload-section col'}>
                    <button
                        className={'upload-area-button'}
                        onClick={onImageUpload}
                        {...dragProps}
                    >
                        <div className={'upload-area-container col'}
                             style={{border: isDragging ? "1px dotted black" : 'none', pointerEvents: 'none'}}>
                            <p className={'p-info'}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                            <div className={'upload-area upload-small col'}>
                                <img src={plusCircleIcon} className={'icon-label'} alt={'img'} width={'28px'}
                                     style={{padding: '6px', paddingBottom: 0}}/>
                            </div>
                        </div>
                    </button>
                </div>

            );
        }
        const renderUploadedItemsArea = (imageList, onImageUpdate, onImageRemove) => {
            return (
                <div className={'uploaded-gallery row scroll'}>
                    {logo.map((image, index) => (
                        <div key={index} className="uploaded-image-item">
                            <img src={image.data_url} alt="" width="80"/>
                            <div className="image-item__btn-wrapper row center">
                                <p className={'p-info underline click-light'}
                                   style={{cursor: 'pointer'}}
                                   onClick={() => {
                                       onImageUpdate(index);
                                   }}>{L10N_APP_TEXTS[selectedLanguage].UPDATE}
                                </p>
                                <p className={'p-text remove click'}
                                   style={{position: 'absolute', marginLeft: '90px', marginTop: '-220px'}}
                                   onClick={() => {
                                       onImageRemove(index);

                                       dispatch(deleteImage({image_id: provider.image_id}, (resData) => {
                                           updateProviderDataHandler("image_id", null);
                                       }, () => {
                                       }))

                                   }}>x</p>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <ImageUploading
                multiple
                value={logo}
                onChange={onChangeLogoUpload}
                maxNumber={1}
                dataURLKey="data_url"
                acceptType={["jpg", "png"]}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload-container col logo">
                        {logo?.length <= 0 && renderUploadArea(isDragging, onImageUpload, dragProps)}
                        {renderUploadedItemsArea(imageList, onImageUpdate, onImageRemove)}
                    </div>
                )}
            </ImageUploading>
        );
    }


    const renderUploadImagesContainer = () => {
        const renderUploadArea = (isDragging, onImageUpload, dragProps) => {
            return (
                <div className={'upload-section col'}>
                    <button
                        className={'upload-area-button'}
                        onClick={onImageUpload}
                        {...dragProps}
                    >
                        <div className={'upload-area-container'}
                             style={{border: isDragging ? "1px dotted black" : 'none', pointerEvents: 'none'}}>
                            <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                            <div className={'upload-area upload-small col'}>
                                <div className={'col'}>
                                    <p className={'p-info'}>{L10N_APP_TEXTS[selectedLanguage].IMAGE}</p>
                                    <img src={iconImg} className={'icon-label'} alt={'img'} width={'80px'}
                                         style={{padding: '12px'}}/>
                                </div>
                                <img src={plusCircleIcon} className={'icon-label'} alt={'img'} width={'80px'}
                                     style={{padding: '12px'}}/>
                            </div>
                        </div>
                    </button>

                    <ul>
                        <li>{L10N_APP_TEXTS[selectedLanguage].UPLOAD_IMAGES_INFO_1}</li>
                        <li>{L10N_APP_TEXTS[selectedLanguage].UPLOAD_IMAGES_INFO_2}</li>
                    </ul>
                </div>

            );
        }
        const renderUploadedItemsArea = (imageList, onImageUpdate, onImageRemove) => {
            return (
                <div className={'uploaded-gallery row scroll'}>
                    {imageList.map((image, index) => (
                        <div key={index} className="uploaded-image-item">
                            <img src={image.data_url} alt="" width="100"/>
                            <div className="image-item__btn-wrapper row space-between">
                                <p className={'p-info underline click-light'} style={{cursor: 'pointer'}}
                                   onClick={() => onImageUpdate(index)}>{L10N_APP_TEXTS[selectedLanguage].UPDATE}
                                </p>
                                <p className={'p-info remove click'} onClick={() => {

                                    dispatch(deleteImage({image_id: images[index].image_id}, (resData) => {
                                        onImageRemove(index);

                                        dispatch(loadImages({
                                            provider_id: provider.provider_id,
                                            type: "GALLERY"
                                        }, (resData) => {
                                            console.log(resData);
                                            //setLogo([{data_url: resData.image}]);

                                            for (let i = 0; i < resData.length; i++) {
                                                resData[i].data_url = resData[i].image;
                                                delete resData[i].image;
                                            }
                                            setImages(resData);


                                        }, () => {
                                            //setLogo([]);
                                            //updateProviderDataHandler("image_id", null);

                                        }))
                                    }, () => {
                                    }))
                                }}>x</p>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <ImageUploading
                multiple
                value={images}
                onChange={onChangeImageUpload}
                maxNumber={maxNumberImageUploads}
                dataURLKey="data_url"
                acceptType={["jpg", "png"]}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload-container col">
                        {renderUploadArea(isDragging, onImageUpload, dragProps)}

                        {imageList.length > 0 ?
                            <p className={'p-info underline remove click-light'} style={{paddingLeft: '24px'}}
                               onClick={onImageRemoveAll}>{L10N_APP_TEXTS[selectedLanguage].REMOVE_ALL_IMAGES}</p> : <></>
                        }
                        {renderUploadedItemsArea(imageList, onImageUpdate, onImageRemove)}
                    </div>
                )}
            </ImageUploading>
        );
    }


    const [fadeIn, setFadeIn] = useState(useSpring(
        {
            from: {opacity: 0},
            to: {opacity: 1},
            config: {duration: 700},
            delay: 200,
        }))

    const [slideUp, setSlideUp] = useState(useSpring(
        {
            from: {
                opacity: 0,
                height: 0,
            },
            to: {
                opacity: toggleFilters ? 1 : 0,
                height: toggleFilters ? 'auto' : 0,
                display: toggleFilters ? 'flex' : 'none',
            },
            config: {duration: 100},
        }))

    let history = useHistory();
    const onNavigateTo = useCallback(((path) => {
        history.push(path);
    }), [history]);


    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, [])

    useEffect(() => {
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);
        setProviderDesc(provider?.l10n[getIndexOf("l10n", selectedLanguage, provider.l10n)])
    }, [l10n, selectedLanguage]);

    useEffect(() => {
        if (user) {
            dispatch(loadProviderData(user.user_id, selectedLanguage,
                () => {
                },
                () => {
                }))
        }
    }, [user]);

    useEffect(() => {
        if (provider && selectedLanguage) {
            console.log(provider)

            let desc = provider?.l10n[getIndexOf("l10n", selectedLanguage, provider.l10n)].description;
            setProviderDesc(desc)

            dispatch(getFaqProvider({provider_id: provider.provider_id}, selectedLanguage, (resData) => {
                setFaqs(resData);
            }))

            if (provider.image_id) {
                dispatch(loadImage({image_id: provider.image_id, type: "LOGO"}, (resData) => {
                    setLogo([{data_url: resData.image}]);
                }, () => {
                    setLogo([]);
                    updateProviderDataHandler("image_id", null);
                }))
            }
            if (provider) {
                dispatch(loadImages({provider_id: provider.provider_id, type: "GALLERY"}, (resData) => {
                    console.log(resData);

                    for (let i = 0; i < resData.length; i++) {
                        resData[i].data_url = resData[i].image;
                        delete resData[i].image;
                    }
                    setImages(resData);


                }, () => {

                }));
                dispatchLoadLecturers();
                dispatchLoadCourses();
            }

        }

    }, [provider, selectedLanguage]);

    useEffect(() => {
        if (createTrigger) {
            switch (action) {
                case CREATE_ACTION.UPDATE_DATA:
                    break;
                default:
                    console.warn("Create action not implemented..", action);
            }
        }
    }, [createTrigger]);

    const triggerAction = (action) => {
        setAction(action);
        setCreateTrigger(true);
    };


    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error l10n={selectedLanguage} type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error l10n={selectedLanguage} type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    const displayCollapsibleL10NHeader = (status) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header l10n-options'} style={{justifyContent: 'flex-end'}}>
                {parseSelectedCountryLabel(selectedLanguage, setSelectedLanguage, () => onActionCollapseL10NOptions(false))}
                {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
            </div>
        );
    }

    const onActionCollapseL10NOptions = (state) => {
        setIsL10NOptionsOpen(state);
    }

    const renderL10NOption = (l10nKey, imgData) => {
        return (
            <div onClick={() => {
                onActionCollapseL10NOptions(false)
                setSelectedLanguage(l10nKey)
                dispatch(setL10N(l10nKey, true))

                //   setPromoUrl(window.location?.host.toString() + PAGES.ANALYSIS + "/referralID=" + user.referral_id + "/store=" + l10nKey)
            }}
                 className={'country-label-container'}
                 style={{alignSelf: 'center'}}>
                <img src={imgData} alt={l10nKey} width={'27px'}/>
            </div>
        );
    }

   const renderL10NOptions = () => {
        const showOtherAvailableL10N = (selectedLanguage) => {

            renderL10NOption(L10N.en.value, countryEN);
            renderL10NOption(L10N.it.value, countryIT);
            renderL10NOption(L10N.de.value, countryDE);
            renderL10NOption(L10N.hr.value, countryHR);
            renderL10NOption(L10N.si.value, countrySI);

            switch (selectedLanguage) {
                case L10N.en:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
                case L10N.it:
                    renderL10NOption(L10N.si.value, countrySI);
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;

                case L10N.si:
                default:
                    renderL10NOption(L10N.en.value, countryEN);
                    renderL10NOption(L10N.it.value, countryIT);
                    renderL10NOption(L10N.hr.value, countryHR);
                    renderL10NOption(L10N.de.value, countryDE);
                    break;
            }
        };

       return (
            <Collapsible className={'l10n-options'}
                         transitionTime={400}
                         onClosing={() => onActionCollapseL10NOptions(false)}
                         onOpening={() => onActionCollapseL10NOptions(true)}
                         open={isL10NOptionsOpen}
                         easing={'cubic-bezier(0.2, 0.94, 0.86, 1.1) 0s'}
                         trigger={displayCollapsibleL10NHeader(false)}
                         triggerWhenOpen={displayCollapsibleL10NHeader(true)}>
                <div className={'l10n-options-content'}>
                    {showOtherAvailableL10N(selectedLanguage)}
                    {selectedLanguage !== L10N.si.value && renderL10NOption(L10N.si.value, countrySI)}
                    {selectedLanguage !== L10N.en.value && renderL10NOption(L10N.en.value, countryEN)}
                    {selectedLanguage !== L10N.it.value && renderL10NOption(L10N.it.value, countryIT)}
                    {selectedLanguage !== L10N.hr.value && renderL10NOption(L10N.hr.value, countryHR)}
                    {selectedLanguage !== L10N.de.value && renderL10NOption(L10N.de.value, countryDE)}
                </div>
            </Collapsible>
        );
    }

    const displayCollapsibleFaq = (status, question, answer) => {
        let arrowStyle = {width: '24px', height: '24px', color: colors.secondary};
        return (
            <div className={'collapsible-header faq-options'} style={{justifyContent: 'flex-end'}}>
                <div className={'faq-question'}>
                    <p className={'p-title'}>{question}</p>
                    {status ? <IoIosArrowUp style={arrowStyle}/> : <IoIosArrowDown style={arrowStyle}/>}
                </div>
                {status ? <div className={'faq-desc'}>
                    <p className={'p-header black'}>{answer}</p>
                </div> : <></>}
            </div>
        );
    }

    const onActionCollapseFaq = (i) => {
        setOpenedFaq(i);
    }

    const updateFaqQuestion = (item, i, value) => {
        let updated = [...faqs];
        updated[i].question = value;

        let u = updated[i];
        u.user_id = user.user_id;

        dispatch(updateFaq(u, selectedLanguage, (resData) => {
            updated[i] = resData;
            setFaqs(updated);
        }));
    }

    const updateFaqAnswer = (item, i, value) => {
        let updated = [...faqs];
        updated[i].answer = value;

        console.log(updated[i], value)

        let u = updated[i];
        u.user_id = user.user_id;

        dispatch(updateFaq(u, selectedLanguage, (resData) => {
            updated[i] = resData;
            setFaqs(updated);
        }));
    }

    const onRemoveFAQPair = (index) => {
        let updated = [...faqs];

        let u = updated[index]
        u.user_id = user.user_id;

        dispatch(deleteFaq(u, selectedLanguage, (resData) => {
            updated.splice(index, 1);
            setFaqs(updated);
        }));
    }

    const onAddNewFaq = () => {
        let updated = [...faqs];
        let u = {
            question: null, answer: null,
            provider_id: provider.provider_id,
            user_id: provider.user_id,
            admin_user_id: null
        }
        dispatch(createFaq(u, selectedLanguage, (resData) => {
            updated.push(resData);
            setFaqs(updated);
        }))
    }

    const renderFaqEdit = () => {
        return (
            <>
                <div className={'tab_faq'}/>
                <Title dots title={'FAQs'}/>
                <div className={'faq-container'}>

                    <div className={'row'} style={{paddingBottom: '36px'}}>
                        <p className={'p-text'}>
                            {L10N_APP_TEXTS[selectedLanguage].FAQ_INFO}
                        </p>
                        <img src={iconInfo} className={'icon-label'} alt={'img'} width={'16px'}
                             style={{paddingLeft: '12px'}}/>
                    </div>

                    {faqs.map((faq, f) => (
                        <div key={`${f}_${faq.question}`} className={'collapsible-faq-container'}>

                            <p className={'p-text remove click-light'}
                               onClick={() => onRemoveFAQPair(f)} style={{
                                fontWeight: '600',
                                position: 'absolute',
                                marginLeft: '-36px',
                                marginTop: '-2px'
                            }}>x</p>

                            <div className={'faq-edit'}>
                                <p className={'p-faq-numbered'}>
                                    {f + 1}.
                                </p>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={'edit'}
                                               value={faq.question}
                                               onChange={() => {
                                               }}
                                               onSubmit={(value) => onInputChange(value, () => updateFaqQuestion(faq, f, value))}
                                               onAction={(value) => onInputChange(value, () => updateFaqQuestion(faq, f, value))}
                                               label={`${L10N_APP_TEXTS[selectedLanguage].QUESTION}:`}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].QUESTION}
                                               style={{marginBottom: '0px'}}/>

                                        <Input button={'edit'}
                                               value={faq.answer}
                                               onChange={() => {
                                               }}
                                               onSubmit={(value) => onInputChange(value, () => updateFaqAnswer(faq, f, value))}
                                               onAction={(value) => onInputChange(value, () => updateFaqAnswer(faq, f, value))}
                                               label={`${L10N_APP_TEXTS[selectedLanguage].ANSWER}:`}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].ANSWER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className={'row add'} style={{paddingBottom: '36px', width: 'fit-content'}} onClick={() => {
                        onAddNewFaq();
                    }}>
                        <img src={plusBlack} className={'icon-label image-click'} alt={'img'} width={'24px'}
                             style={{paddingRight: '12px'}}/>
                        <p className={'p-text click-light'}>
                            {L10N_APP_TEXTS[selectedLanguage].ADD_QUESTION}
                        </p>
                    </div>
                </div>
            </>

        );
    }


    const renderBody = () => {
        return (
            <div className={'flex-page-container'} style={{width: 'inherit'}}>
                {renderL10NOptions()}
                <ScrollableTabsEdit provider/>

                {renderProviderInfo()}
                {renderEditProviderInfo()}
                {renderUploadImagesContainer()}
                {renderLecturers()}

                {renderCourses(myCourses, false)}
                {renderCoursesArchived(myCoursesArchived, true)}
                {addCourse()}
                {renderMyDataEdit()}
                {renderFaqEdit()}
                {renderStats()}
            </div>
        )
    }
    const addCourse = () => {
        return (
            <>
                <div className={'tab_course_add'}/>
                <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].ADD_NEW_COURSE}</p>

                <div className={'add-course-card'}>
                    <div className={'add-course-card-top'}>
                        <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].ADD}</p>
                    </div>
                    <div className={'add-course-card-bottom'}>
                        <div className={'image-click click'} onClick={() => {

                            let data = {
                                user_id: user.user_id,
                                provider_id: provider.provider_id
                            }
                            dispatch(createCourse(data, selectedLanguage, (resData) => {
                                console.log(resData);
                                onNavigateTo(PAGES.COURSE_EDIT);
                            }));

                        }}>
                            <img src={iconPlus} className={'icon-label'} alt={'img'} width={'120px'}/>
                        </div>
                    </div>
                </div>
            </>

        );
    }

    /*
    const renderCourses = (courses, title, anchor) => {

        if (!selectedLanguage || !courses || courses?.length <= 0) {
            return <>
                <Title dots title={title}/>
                <p className={'p-normal'} style={{
                    paddingBottom: '80px',
                    fontStyle: 'italic'
                }}>{L10N_APP_TEXTS[selectedLanguage].NO_SEARCH_RESULTS}</p>
            </>
            return <></>
        }
        return (
            <>
                {anchor ? <div className={anchor}/> : <></>}
                <Title dots title={title}/>
                <div className={'courses_container scroll ' + anchor} style={{marginBottom: '48px'}}>
                    {courses.map((course, c) => (

                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'}
                             style={{cursor: "pointer"}} onClick={() => {
                            let data = {
                                course_id: course?.course_id,
                            }
                            dispatch(loadCourseById(data, selectedLanguage, (resData) => {
                                console.log(resData);
                                onNavigateTo(PAGES.COURSE + ":" + course?.course_id);
                            }));
                        }}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>

                                <p className={'p-desc desc p-desc-list'}>{course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage ? l10n : L10N.si.value, course?.packages[0].l10n)]?.description_list}</p>
                                <p className={'p-desc desc truncate'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>
                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: 0}}>
                                            <img src={iconStar} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{'5.3 (3000)'}</p>
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && (course?.packages[0].price_discounted / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}
                                             style={{display: course?.packages[0] && course?.packages[0].accommodation_price ? "flex" : "none"}}>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'}
                                       style={{display: course?.packages[0] && course?.packages[0].price_discounted ? "flex" : "none"}}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{(course?.packages[0] && course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) + " " + selectedLanguageObj?.priceValue}</p>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };


     */


    const renderCourses = (courses, archived) => {

        return (
            <>
                <div className={'tab_all_courses'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].MY_COURSES}/>
                <ScrollableTabsCourses onChange={(value) => {
                    setSelectedCourseTab(value)
                }}/>

                <div className={'tab_courses_published'}/>
                <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].MY_COURSES_PUBLISHED}</p>

                <div className={'courses_container scroll'}>
                    {courses.map((course, c) => (

                        !course?.packages || course?.packages?.length <= 0 ? <></> :
                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'} style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>

                                <p className={'p-desc desc p-desc-list'}>{course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage ? l10n : L10N.si.value, course?.packages[0].l10n)]?.description_list}</p>
                                <p className={'p-desc desc truncate'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>
                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[0]?.dates)})</p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: 0}}>
                                            <img src={iconStar} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{'5.3 (3000)'}</p>
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && ((course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}
                                             style={{opacity: course?.packages[0] && course?.packages[0].accommodation_price ? 1 : 0}}>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'}
                                       style={{display: course?.packages[0] && course?.packages[0].price_discounted ? "flex" : "none"}}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{(course?.packages[0] && course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) + " " + selectedLanguageObj?.priceValue}</p>
                                </div>

                            </div>



                            <div className={'course-edit-buttons'}>
                                <div className={'custom-button-edit p-submit'} onClick={() => {
                                    let data = {
                                        course_id: course?.course_id,
                                    }
                                    dispatch(loadCourseById(data, selectedLanguage, (resData) => {
                                        console.log(resData);
                                        onNavigateTo(PAGES.COURSE_EDIT);
                                    }));
                                }}>{capitalize(L10N_APP_TEXTS[selectedLanguage].EDIT)}</div>
                                <div className={'custom-button-edit p-submit'} onClick={() => {
                                    updateActiveHandler(course, false);
                                }}>{L10N_APP_TEXTS[selectedLanguage].ARCHIVE}</div>
                                <div className={'custom-button-edit p-submit'} onClick={() => {
                                }}>{L10N_APP_TEXTS[selectedLanguage].PARTICIPANTS}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderCoursesArchived = (courses, archived) => {
        return (
            <>
                <div className={'tab_courses_archived'}/>
                <p className={'p-title'}>{L10N_APP_TEXTS[selectedLanguage].MY_COURSES_ARCHIVED}</p>

                <div className={'courses_container scroll'}>
                    {courses.map((course, c) => (
                        <div key={`${c}_${course.course_id}`} className={'course-col course-col-edit'} style={{opacity: 0.6}}>
                            <div className={'course-col-top'}>
                                <div className={'icon-heart-container'} onClick={() => {
                                }}>
                                    <img src={iconHeart} className={'icon-label'} alt={'img'} width={'24px'}/>
                                </div>
                                <img src={course.logo?.image} alt={'course_img'} width={'290px'}/>
                            </div>
                            <div className={'label-accommodation-container'}>
                                <img src={iconAcc} alt={'img_accommodation'} className={'icon-label accommodation'}
                                     width={'50px'}/>
                            </div>
                            <div className={'course-col-bottom'}>
                                <p className={'p-desc'}>{course?.provider?.name}</p>
                                <p className={'p-large'}>{course?.l10n && course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name ? course?.l10n[getIndexOf("l10n", selectedLanguage, course?.l10n)]?.name : ""}</p>

                                <p className={'p-desc desc p-desc-list'}>{course?.packages[0].l10n[getIndexOf("l10n", selectedLanguage ? l10n : L10N.si.value, course?.packages[0].l10n)]?.description_list}</p>
                                <p className={'p-desc desc truncate'}>{course?.packages[0] && course?.packages[0]?.l10n[getIndexOf("l10n", selectedLanguage, course?.packages[0].l10n)]?.description}</p>

                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconCalendar} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.packages[0]?.dates?.length > 0 && displayDate(getNextCourseStartDate(course?.packages[0]?.dates)?.start_hour, getNextCourseStartDate(course?.packages[0]?.dates)?.end_hour, selectedLanguage)}</p>
                                </div>
                                <div className={'course-col-bottom-inner-row'}>
                                    <img src={iconAddress} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                    <p className={'p-info'}>{course?.address}</p>
                                </div>
                                <div className={'course-col-bottom-inner'}>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconSum} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0]?.visits} {L10N_APP_TEXTS[selectedLanguage].DAYS} ({parseHoursDurationFromTimestampsFormatted(course?.packages[0]?.dates)})</p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'} style={{opacity: 0}}>
                                            <img src={iconStar} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{'5.3 (3000)'}</p>
                                        </div>
                                    </div>
                                    <div className={'course-col-bottom-inner-col'}>
                                        <div className={'course-col-bottom-inner-row'}>
                                            <img src={iconMean} className={'icon-info'} alt={'sum_img'} width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && ((course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) / (parseHoursDurationFromTimestamps(course?.packages[0]?.dates))).toFixed(2)} {selectedLanguageObj?.priceValue} {L10N_APP_TEXTS[selectedLanguage].PER_HOUR} </p>
                                        </div>
                                        <div className={'course-col-bottom-inner-row'}
                                             style={{display: course?.packages[0] && course?.packages[0].accommodation_price ? "flex" : "none"}}>
                                            <img src={iconNight} className={'icon-info'} alt={'sum_img'}
                                                 width={'14px'}/>
                                            <p className={'p-info'}>{course?.packages[0] && course?.packages[0].accommodation_price + " " + selectedLanguageObj?.priceValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'course-col-bottom-inner-row price'}>
                                    <p className={'p-price'}
                                       style={{display: course?.packages[0] && course?.packages[0].price_discounted ? "flex" : "none"}}>{course?.packages[0] && course?.packages[0].price + " " + selectedLanguageObj?.priceValue}</p>
                                    <p className={'p-discount-price'}>{(course?.packages[0] && course?.packages[0].price_discounted ? course?.packages[0].price_discounted : course?.packages[0].price) + " " + selectedLanguageObj?.priceValue}</p>
                                </div>

                            </div>
                            <div className={'course-edit-buttons'}>

                                <div className={'custom-button-edit p-submit'} onClick={() => {
                                    updateActiveHandler(course, true);
                                }}>{L10N_APP_TEXTS[selectedLanguage].UNARCHIVE}</div>
                                <div className={'custom-button-edit p-submit'} onClick={() => {
                                }}>{L10N_APP_TEXTS[selectedLanguage].PARTICIPANTS}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };


    const updateActiveHandler = (course, value) => {
        let updateData = course;
        updateData["user_id"] = user.user_id;
        updateData["active"] = value;
        dispatch(updateCourseActive(updateData, selectedLanguage, () => {
            dispatchLoadCourses();
        }));
    };

    const updateProviderDataHandler = (key, value) => {
        let updateData = provider;
        updateData["user_id"] = user.user_id;
        updateData[key] = value;
        dispatch(updateProviderData(updateData, selectedLanguage, () => {
        }));
    };

    const updateLecturerDataHandler = (key, value, lecturer) => {
        let updateData = lecturer;
        updateData["user_id"] = user.user_id;
        updateData[key] = value;
        dispatch(updateLecturerData(updateData, () => {
        }));
    };

    const updateProviderL10nDataHandler = (key, value) => {
        let updateData = provider;
        updateData["user_id"] = user.user_id;
        updateData[key] = value;
        dispatch(updateProviderDataL10N(updateData, selectedLanguage, () => {
        }));
    };

    const updateLecturerL10nDataHandler = (key, value, lecturer) => {
        let updateData = lecturer;
        updateData["user_id"] = user.user_id;
        updateData[key] = value;
        dispatch(updateLecturerDataL10N(updateData, selectedLanguage, () => {
        }));
    };


    const renderEditProviderInfo = () => {
        return (
            <div className={'course-edit provider-edit tab_presentation'}>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_PRESENTATION}/>
                <div className={'form-container'} style={{marginBottom: '0'}}>
                    <div className={'form_col form-row'}>
                        <Input button={'edit'}
                               value={providerName}
                               onChange={(value) => onInputChange(value, setProviderName)}
                               onSubmit={() => updateProviderDataHandler("name", providerName)}
                               onAction={() => updateProviderDataHandler("name", providerName)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PROVIDER_NAME}
                               label={`${L10N_APP_TEXTS[selectedLanguage].PROVIDER_NAME}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input button={'edit'}
                               value={providerAddress}
                               onChange={(value) => onInputChange(value, setProviderAddress)}
                               onSubmit={() => updateProviderDataHandler("address", providerAddress)}
                               onAction={() => updateProviderDataHandler("address", providerAddress)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].ENTER_ADDRESS}
                               label={`${L10N_APP_TEXTS[selectedLanguage].ADDRESS}:`}
                               style={{marginBottom: '0px'}}/>
                        <Input button={'edit'}
                               value={providerPostCity}
                               onChange={(value) => onInputChange(value, setProviderPostCity)}
                               onSubmit={() => updateProviderDataHandler("address", providerPostCity)}
                               onAction={() => updateProviderDataHandler("address", providerPostCity)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].ENTER_POST_CITY}
                               label={`${L10N_APP_TEXTS[selectedLanguage].POST_CITY}:`}
                               style={{marginBottom: '0px'}}/>

                        <Input button={'edit'}
                               textarea
                               value={providerDesc}
                               onChange={(value) => onInputChange(value, setProviderDesc)}
                               onSubmit={() => updateProviderL10nDataHandler("description", providerDesc)}
                               onAction={() => updateProviderL10nDataHandler("description", providerDesc)}
                               label={`${L10N_APP_TEXTS[selectedLanguage].PROVIDER_DESC}:`}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].PROVIDER_DESC}
                               style={{marginBottom: '0px', width: '480px'}}/>


                        <div className={'custom-dropdown'}>
                            <Dropdown grey
                                      style={{height: '80px'}}
                                      label={`${L10N_APP_TEXTS[selectedLanguage].ENTER_COUNTRY}:`}
                                      onChange={setCountry}
                                      onAction={(value) => updateProviderDataHandler("country", value)}
                                      options={COUNTRIES_ARRAY}
                                      value={parseCountryObject(country)}

                                      placeholder={L10N_APP_TEXTS[selectedLanguage].COUNTRY}/>
                        </div>


                        <DropdownCircle text={`${L10N_APP_TEXTS[selectedLanguage].LANGUAGE_SPOKEN_PLURAL}:`}
                                        info={L10N_APP_TEXTS[selectedLanguage].LANGUAGE_SPOKEN_PLURAL_INFO}
                                        provider
                                        onChange={(key, value) => {
                                            updateProviderDataHandler(key, value);
                                        }}/>


                        <div style={{paddingTop: '24px'}}/>
                        <Input button={'edit'}
                               value={providerYoutubeUrl}
                               onChange={(value) => onInputChange(value, setProviderYoutubeUrl)}
                               onSubmit={() => updateProviderDataHandler("youtube_url", providerYoutubeUrl)}
                               onAction={() => updateProviderDataHandler("youtube_url", providerYoutubeUrl)}
                               placeholder={L10N_APP_TEXTS[selectedLanguage].YOUTUBE}
                               label={`${L10N_APP_TEXTS[selectedLanguage].VIDEO_URL}:`}
                        />

                    </div>
                </div>
                {renderErrorMessage()}

            </div>
        );
    }

    const renderMyDataEdit = () => {
        return (
            <>
                <div className={'tab_personal_data'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].TAB_PERSONAL_DATA}/>

                <div className={'course-edit provider-edit'}>
                    <Title title={L10N_APP_TEXTS[selectedLanguage].BANK_ACCOUNT}/>
                    <div className={'form-container'} style={{marginBottom: '0'}}>
                        <div className={'form_col form-row'}>
                            <Input button={'edit'}
                                   value={providerVat}
                                   onChange={(value) => onInputChange(value, setProviderVat)}
                                   onSubmit={() => updateProviderDataHandler("vat", providerVat)}
                                   onAction={() => updateProviderDataHandler("vat", providerVat)}
                                   placeholder={L10N_APP_TEXTS[selectedLanguage].VAT_NUMBER}
                                   label={`${L10N_APP_TEXTS[selectedLanguage].VAT_NUMBER}:`}
                                   style={{marginBottom: '0px'}}/>
                            <Input button={'edit'}
                                   value={providerRegNmb}
                                   onChange={(value) => onInputChange(value, setProviderRegNmb)}
                                   onSubmit={() => updateProviderDataHandler("registration_nmb", providerRegNmb)}
                                   onAction={() => updateProviderDataHandler("registration_nmb", providerRegNmb)}
                                   placeholder={L10N_APP_TEXTS[selectedLanguage].REGISTRATION_NUMBER}
                                   label={`${L10N_APP_TEXTS[selectedLanguage].REGISTRATION_NUMBER}:`}
                                   style={{marginBottom: '0px'}}/>
                            <Input button={'edit'}
                                   value={providerBankAcc}
                                   onChange={(value) => onInputChange(value, setProviderBankAcc)}
                                   onSubmit={() => updateProviderDataHandler("bank_account_nmb", providerBankAcc)}
                                   onAction={() => updateProviderDataHandler("bank_account_nmb", providerBankAcc)}
                                   placeholder={L10N_APP_TEXTS[selectedLanguage].BANK_ACCOUNT}
                                   label={`${L10N_APP_TEXTS[selectedLanguage].BANK_ACCOUNT}:`}
                                   style={{marginBottom: '0px'}}/>

                            <Input button={'edit'}
                                   value={providerBankName}
                                   onChange={(value) => onInputChange(value, setProviderBankName)}
                                   onSubmit={() => updateProviderDataHandler("bank_name", providerBankName)}
                                   onAction={() => updateProviderDataHandler("bank_name", providerBankName)}
                                   label={`${L10N_APP_TEXTS[selectedLanguage].BANK_NAME}:`}
                                   placeholder={L10N_APP_TEXTS[selectedLanguage].BANK_NAME}
                                   style={{marginBottom: '0px'}}/>

                        </div>
                    </div>
                    {renderErrorMessage()}

                </div>
            </>

        );
    }

    const addNewLecturer = () => {

        let lec = {
            user_id: provider.provider_id,
            provider_id: provider.provider_id,
            full_name: null,
        };
        dispatch(createLecturer(lec, (resData) => {
            console.log(resData);
            //setLogo([{data_url: resData.image}]);
            let lec = [...lecturers, resData];

            setLecturers(lec);
        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);

        }));
    }

    const updateLecturerL10N = (item, i, value) => {
        let lec = [...lecturers];
        lec[i].description = value;

        lec[i].user_id = user.user_id;
        dispatch(updateLecturerDataL10N(lec[i], selectedLanguage, (resData) => {
            console.log(resData);
            //setLogo([{data_url: resData.image}]);
            lec[i] = resData;

            setLecturers(lec);
        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);

        }));
    }

    const updateLecturerFullName = (item, i, value) => {
        let lec = [...lecturers];
        lec[i].full_name = value;

        lec[i].user_id = user.user_id;
        dispatch(updateLecturerData(lec[i], (resData) => {
            console.log(resData);
            //setLogo([{data_url: resData.image}]);
            lec[i] = resData;

            setLecturers(lec);
        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);

        }));


    }

    const chooseLecturer = (value) => {
        let foundLecturer = findItem('lecturer_id', value.lecturer_id, lecturers);
        if (foundLecturer) {
            return;
        }

        let lec = [...lecturers, value];
        setLecturers(lec);
    }
    const removeLecturer = (value) => {
        let foundLecturerIndex = getIndexOf('lecturer_id', value.lecturer_id, lecturers);

        console.log(foundLecturerIndex);

        if (foundLecturerIndex < 0) {
            return;
        }

        let lec = [...lecturers];

        lec[foundLecturerIndex].user_id = user.user_id;

        dispatch(deleteLecturer(lec[foundLecturerIndex], (resData) => {
            console.log(resData);

            lec.splice(foundLecturerIndex, 1);

            dispatchLoadLecturers();
        }, () => {
            //setLogo([]);
            //updateProviderDataHandler("image_id", null);

        }));
    }

    const renderLecturers = () => {
        console.log(lecturersProfilePictures);

        return (
            <>
                <div className={'row'}>
                    <Title dots title={L10N_APP_TEXTS[selectedLanguage].LECTURERS}/>
                    <div className={'circle-button'}>
                        <img src={plusCircleIcon} className={'icon-info'} onClick={addNewLecturer} alt={'sum_img'}
                             width={'36px'}/>
                    </div>
                </div>

                <div className={'lecturers-container edit'} style={{flexWrap: 'wrap'}}>

                    {lecturers.map((item, i) => (
                        <div key={`${i}_${item}`} className={'lecturers-item'}>
                            <div className={'lecturers-col'}>
                                <p className={'p-text remove click-light'} onClick={() => removeLecturer(item)} style={{
                                    position: 'absolute',
                                    marginLeft: '69px',
                                    marginTop: '-16px',
                                    fontWeight: 400
                                }}>x</p>


                                {renderUploadLecturersProfilePictureContainer(i)}

                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={'edit'}
                                               value={item.full_name}
                                               onChange={() => {
                                               }}
                                               onAction={(value) => onInputChange(value, () => updateLecturerFullName(item, i, value))}
                                               onSubmit={(value) => onInputChange(value, () => updateLecturerFullName(item, i, value))}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].NAME_SURNAME}
                                               style={{marginBottom: '0px', width: 'fit-content', maxWidth: '140px'}}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'course-packages-container course-description lecturer'}>
                                <div className={'form-container'} style={{marginBottom: '0'}}>
                                    <div className={'form_col form-row'}>
                                        <Input button={'edit'}
                                               value={item.l10n[getIndexOf("l10n", selectedLanguage, item.l10n)]?.description ? item.l10n[getIndexOf("l10n", selectedLanguage, item.l10n)]?.description : ""}
                                               textarea
                                               onChange={() => {

                                               }}
                                               onAction={(value) => onInputChange(value, () => updateLecturerL10N(item, i, value))}
                                               onSubmit={(value) => onInputChange(value, () => updateLecturerL10N(item, i, value))}
                                               placeholder={L10N_APP_TEXTS[selectedLanguage].COURSE_LECTURER}
                                               style={{marginBottom: '0px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>

        );
    }

    const renderDescListInput = (input, className) => {
        if (!className) {
            className = 'dynamic-ul';
        }

        let s = input?.split(',');
        if (!s || s.length === 0) {
            return <ul></ul>
        }
        return (
            <ul className={className}>
                {s.map((item, i) => (
                    <li key={`${i}_${item}`}>
                        {item}
                    </li>
                ))}
            </ul>
        );
    }

    const dispatchLoadFollowers = () => {
        dispatch(loadFollowersForProvider({provider_id: provider.provider_id}, async (resData) => {
            if (!resData) {
                return;
            }
            setFollowers(resData.length)

        }, () => {
        }));
    }

    const handleEmailClickContact = () => {
        const email = "ask@bravooo.com";
        const subject = L10N_APP_TEXTS[selectedLanguage].CONTACT_REQ;
        const body = '';

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink, '_blank');
    };

    const renderProviderInfo = () => {
        return (
            <div className={'provider-info-container course-info course-edit'} style={{paddingBottom: '60px'}}>
                <p className={'p-pageinfo'}>
                    {L10N_APP_TEXTS[selectedLanguage].ADMINISTRATION} > <span>{L10N_APP_TEXTS[selectedLanguage].ADMINISTRATION_PROVIDER}</span>
                </p>

                <div className={'provider-row'}>
                    <div className={'row_inner_left'}>
                        {renderUploadLogoContainer()}
                        <p className={'p-xlarge'}>{providerName}</p>
                        <div className={'row_inner_right'}>

                        </div>
                    </div>
                    <div className={'row_inner_right'}>
                        <div className={'contact-us-button p-submit'}  onClick={() => {
                            handleEmailClickContact()
                        }}>
                            <span>{L10N_APP_TEXTS[selectedLanguage].CONTACT_BUTTON}</span>
                            <img src={logoImg} className={'icon-info'} alt={'icon_img'} height={'15px'}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderStats = () => {
        return (
            <>
                <div className={'tab_stats'}/>
                <Title dots title={L10N_APP_TEXTS[selectedLanguage].STATS}/>
                <div className={'stats-container col'}>
                    <div className={'row'}>
                        <>{/*
                          <p className={'p-text'}>{L10N_APP_TEXTS[selectedLanguage].THIS_SCHOOL_FOLLOWS_1}
                            <span
                                style={{
                                    color: '#29ABE2',
                                    textDecoration: 'unset',
                                    fontWeight: 500
                                }}> {0}</span> {L10N_APP_TEXTS[selectedLanguage].THIS_SCHOOL_FOLLOWS_2}.</p>
                        */}</>

                    </div>
                    <div className={'row'}>
                        <p className={'p-text'}>{`${L10N_APP_TEXTS[selectedLanguage].MEMBERSHIP_START_DATE} ${displayDateMonthYear(provider?.date_create, selectedLanguage)}`}</p>
                    </div>

                    {
                        /*
                               <div className={'row'}
                         style={{zoom: 0.66, paddingTop: 0, marginTop: '-12px'}}>
                        <img src={starBrownFull} className={'star-review'} alt={'img'} width={'36px'}
                             style={{paddingRight: '24px'}}/>
                        <p className={'p-review'}>0,0<span
                            style={{fontWeight: '400', paddingLeft: '24px'}}>(4.052)</span></p>
                    </div>

                        * */
                    }


                    <div className={'row'} style={{paddingBottom: '120px'}}>
                        {/*
                            <div className={'button-label'} style={{marginRight: '24px'}}>
                            <span>{L10N_APP_TEXTS[selectedLanguage].FOLLOW}</span>
                              </div>
                        */}


                        {
                            /*
                             <p className={'p-text'}>{L10N_APP_TEXTS[selectedLanguage].FOLLOWERS}
                            <span style={{
                                fontWeight: 500,
                                color: '#29ABE2'
                            }}> {followers}</span> {L10N_APP_TEXTS[selectedLanguage].THIS_SCHOOL_FOLLOWS_2}</p>

                            */
                        }

                    </div>
                </div>
            </>
        );
    }


    const renderPage = () => {
        return <>
            <div ref={ref}/>
            <Header loggedIn/>
            {/*<Header loggedIn/>*/}
            <animated.div  style={fadeIn} className={'page'}>
                {renderBody()}
            </animated.div>
            <FooterPage/>
        </>
    }

    if (loginSuccessful) {
        console.log('redirect');

        onNavigateTo(PAGES.HOME);
        return <></>
    }

    return (
        renderPage()
    );
}

export default ProviderPageEdit;