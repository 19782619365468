import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {L10N_APP_TEXTS} from "../../l10n/l10n";
import {L10N} from "../../constants/enums";
import {scrollToDiv} from "../../helpers/helpers";


function ScrollableTabsEdit(props) {

    const l10n = useSelector(state => state.reducer.l10n);
    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    const [c, setC] = useState(0);

    const [selectedTab, setSelectedTab] = useState();
    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        setSelectedLanguage(l10n)
        console.log(l10n);
        setC(c + 1);

        if (props.course) {
            setTabs(getTabsCourseEdit());
        } else if (props.provider) {
            setTabs(getTabsProviderEdit());
        }

    }, [l10n, selectedLanguage]);

const getTabsProviderEdit = () => {
    return [
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_PRESENTATION,
            anchor_id: 'tab_presentation'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].ALL_COURSES,
            anchor_id: 'tab_all_courses'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].FAQ,
            anchor_id: 'tab_faq'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_PERSONAL_DATA,
            anchor_id: 'tab_personal_data'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_RECEIVABLES,
            anchor_id: 'tab_receivables'
        },
        {
            text: L10N_APP_TEXTS[selectedLanguage].TAB_STATS,
            anchor_id: 'tab_stats'
        },
    ]
};

    const getTabsCourseEdit = () => {
        return [
            {
                text: L10N_APP_TEXTS[selectedLanguage].COURSE_PRESENTATION,
                anchor_id: 'tab_presentation'
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].COURSE_LEARN,
                anchor_id: 'tab_learn'
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].COURSE_NEEDS,
                anchor_id: 'tab_needs'
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].COURSE_ACC,
                anchor_id: 'tab_acc'
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].LECTURERS,
                anchor_id: 'tab_lecturers'
            },
            {
                text: L10N_APP_TEXTS[selectedLanguage].COURSE_COMPARISON,
                anchor_id: 'tab_compare'
            },
        ]
    };


    const renderTabs = () => {
        if (!tabs) {
            return null;
        }
        return (
            <div className={'tabs-horizontal'} style={{alignSelf: 'center', height: 'fit-content', padding: 0}}>
                {tabs.map((tab, c) => (
                    <div key={`${c}_${tab.text}`} id={`selected_${tab.text === selectedTab?.text}`}
                         className={'tab-outer-container'}>
                        <p className={'p-tab click'} onClick={() => {
                            setSelectedTab(tab);
                            scrollToDiv(tab.anchor_id);
                        }}>{tab.text}</p>
                    </div>
                ))}
            </div>
        );
    };

    return renderTabs();
}

export default ScrollableTabsEdit;