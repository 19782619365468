import React, { useState, useEffect } from "react";
import '../css/CookieNotice.css';
import {useSelector} from "react-redux";
import {L10N} from "../../constants/enums";
import {L10N_APP_TEXTS} from "../../l10n/l10n";

function CookieNotice() {
    const l10n = useSelector(state => state.reducer.l10n);

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);
    const [selectedLanguageObj, setSelectedLanguageObj] = useState(L10N[selectedLanguage]);

    const [showNotice, setShowNotice] = useState(true);

    useEffect(() => {
        //setSelectedLanguage(l10n)
        setSelectedLanguageObj(L10N[l10n?.toLowerCase()]);
        console.log("COOKIES L10N:", l10n);

        if (l10n) {
            setSelectedLanguage(l10n)
        }

    }, [l10n]);

    const handleOk = () => {
        localStorage.setItem("cookieSession", "true");
        setShowNotice(false);
    };

    useEffect(() => {
        const cookieSession = localStorage.getItem("cookieSession");
        if (cookieSession) {
            setShowNotice(false);
        }
    }, []);

    return (
        <>
            {showNotice && (
                <div className={'cookies-bar'}>
                    <p className={'p-info p-cookies'}>{L10N_APP_TEXTS[selectedLanguage].COOKIE_NOTICE}</p>
                    <button onClick={handleOk}>OK</button>
                </div>
            )}
        </>
    );
}

export default CookieNotice;