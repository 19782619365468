export const L10N_APP_TEXTS = {

    EN: {
        PREVIEW: 'Preview',
        SEARCH: 'Filtered courses',
        NO_SEARCH_RESULTS: 'No search results',
        HOME_WELCOME: 'Hello',
        YOUR_USERNAME_TITLE: 'Your username',
        YOUR_EMAIL_TITLE: 'Your e-mail',
        YOUR_REFERRAL_ID: 'Enter your referral (Synergy) ID',
        YOUR_ANALYSIS_URL: 'Your referral lifestyle analysis link',
        FORGOT_PASSWORD: 'Forgot password? Reset it',
        APP_TITLE_1: 'SYNERGY',
        APP_TITLE_2: 'LIFESTYLE ANALYSIS',
        APP_PROMO: 'Your path to optimal health',
        LOGIN_WELCOME_BACK: 'Welcome back',
        LOGIN_EMAIL: 'Enter your email',
        LOGIN_PASSWORD: 'Enter password',
        LOGIN_REMEMBER: 'Remember me',
        LOGIN_CREATE_ACCOUNT: 'Don\'t have an account yet? RegisterProvider here',
        LOGIN_BUTTON: 'SIGN IN',
        REGISTER_TITLE: 'Join us',
        REGISTER_NAME: 'Enter your full name',
        REGISTER_BUTTON: 'REGISTER',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Already have an account? Sign in',
        RESULT_TITLE: 'YOUR RESULTS',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Password',
        SAVE: 'SAVE',
        COPIED: 'Link copied',
        ANALYSIS_INSTRUCTIONS: 'Answer the questions with YES or NO',
        ANALYSIS_START: ' START THE QUESTIONNAIRE',
        YES: 'YES',
        NO: 'NO',
        ANALYSIS_GENERATE_RESULT: 'GENERATE RESULT',
        ANALYSIS_SHARE_RESULTS: 'For a more detailed explanation of your Lifestyle analysis and information on Synergy solutions, you can share your results with the person who sent you the analysis.',
        SUBSCRIBE: 'SUBSCRIBE',
        SUBSCRIBE_JOIN: 'Enroll today!',
        RESET_PASSWORD: 'RESET PASSWORD',
        RESET_PASSWORD_TITLE: 'Reset forgotten password',
        GENERATE_RESULT_ERROR: 'Error. Missing fields.',
        DATA_UPDATED: 'Data successfully updated.',
        PASSWORD_RESET_SUCCESS: 'Password updated successfully. Please login again.',
        NAME: 'Name',
        NEED_SUPPORT: 'Need support?',
        STATS: 'Your Stats',
        COMPLETED: 'COMPLETED',
        SHARED_BACK: 'SHARED BACK',
        SHARED_PERCENTAGE: 'SHARED',
        LOGOUT: 'Logout',
        CONTACT: 'Contact',
        NAME_SURNAME: 'Name and surname',
        ENTER_TELEPHONE: 'Enter telephone nmb.',
        TELEPHONE: 'Telephone nmb.',
        REGISTER: 'Registration',
        COMPANY_INFO: 'Company info',
        COMPANY_NAME: 'Company name',
        ADDRESS: 'Address',
        COMPANY_LOGO: 'Logo',
        POST_CITY: 'Post code and city',
        COUNTRY: 'Country',
        ENTER_COMPANY_NAME: 'Enter company name',
        ENTER_ADDRESS: 'Enter address',
        ENTER_COMPANY_LOGO: 'Upload logo',
        ENTER_POST_CITY: 'Enter post code and city',
        ENTER_COUNTRY: 'Choose country',
        BECOME_LECTURER: 'Become a lecturer',
        ADD_COURSE: 'SUBMIT COURSE',
        TAB_MY_RESERVATIONS: 'My bookings',
        TAB_RESERVATIONS_ARCHIVE: 'Archive of past bookings',
        TAB_FOLLOWING: 'Following',
        TAB_FOLLOWING_COURSES: 'I am interested in',
        TAB_PERSONAL_DATA: 'Settings',
        TAB_CATEGORIES: 'Categories',
        TAB_PAYMENTS: 'Payments',
        FOLLOW: 'Follow',
        UNFOLLOW: 'Unfollow',
        EDIT: 'edit',
        LANGUAGE_SPOKEN: 'I speak:',
        FILTER_CATEGORY_PARENT: 'Parent category',
        FILTER_CATEGORY: 'Category',
        FILTER_LOCATION: 'Location',
        FILTER_PRICE: 'Price',
        FILTER_ACCOMMODATION: 'Accommodation',
        FILTER_GROUP_SIZE: 'Group size',
        FILTER_DATE: 'Date',
        FILTER_ONLINE: 'Online',
        FILTER_STARS: 'Review',
        FIND_COURSE: 'Find a course of your choice.',
        SUGGESTED: 'Suggested for you',
        MOST_POPULAR: 'Most popular offers',
        NEW_CONTENT: 'New offers',
        PROVIDER_ABOUT: 'Who are we and what we offer',
        LECTURERS: 'Lecturers',
        REVIEWS: 'Participants\' reviews',
        ALL_COURSES: 'All courses',
        COURSE_LEARN: 'We will learn',
        COURSE_NEEDS: 'You will need',
        COURSE_ACC: 'Accommodation',
        COURSE_COMPARISON: 'Compare',
        RECOMMENDED: 'Suggested for you',
        ADD_NEW_COURSE: 'Add new course',
        NAME_COURSE: 'Course title',
        COURSE_DESCRIPTION: 'Course description...',
        COURSE_LECTURER: 'Course lecturer description...',
        ACC_NAME: 'Name',
        PRICE: 'Price',
        ACC_NIGHTS: 'Stays',
        ACC_NIGHTS_PLACEHOLDER: 'Number of nights',
        ACC_LOCATION: 'Location',
        TAB_PRESENTATION: 'Company presentation',
        TAB_RECEIVABLES: 'Transfers received',
        TAB_STATS: 'Statistics',
        FAQ: 'FAQs',
        PROVIDER_NAME: 'Company name',
        PROVIDER_DESC: 'Company description',
        MY_COURSES: 'My courses',
        COURSES: 'Courses',
        ARCHIVE: 'Archive',
        UNARCHIVE: 'Un-archive',
        PARTICIPANTS: 'Participants',
        ADD: 'Add',
        QUESTION: 'Question',
        ANSWER: 'Answer',
        FAQ_INFO: 'Write frequently asked questions and answers',
        ADD_QUESTION: 'Add question & answer',
        BANK_ACCOUNT: 'Bank account',
        VAT_NUMBER: 'Tax ID (VAT) number',
        REGISTRATION_NUMBER: 'Registration number',
        BANK_NAME: 'Account opened at bank',
        COURSE_PRESENTATION: 'Course presentation',
        IMAGE: 'image',
        UPDATE: 'Update',
        REMOVE_ALL_IMAGES: 'Remove all images',
        LECTURERS_CHOOSE: 'Choose course lecturers',
        ADMINISTRATION: 'Administration',
        ADMINISTRATION_PROVIDER: 'Company administration',
        CONTACT_BUTTON: 'CONTACT!',
        LANGUAGE_SPOKEN_PLURAL: 'We speak',
        LANGUAGE_SPOKEN_PLURAL_INFO: 'Your presentation will be presented in all selected languages.',
        UPLOAD_IMAGES_INFO_1: 'Upload photos of type .png or .jpg.',
        UPLOAD_IMAGES_INFO_2: 'We recommend attaching your authentic photos.',
        THIS_SCHOOL_FOLLOWS_1: 'This school has already attended ',
        THIS_SCHOOL_FOLLOWS_2: 'trainees',
        MEMBERSHIP_START_DATE: 'Member since: ',
        ADMINISTRATION_COURSE: 'Course administration',
        COURSE_LANGUAGES: 'Add and select the languages in which communication takes place on the course.',
        ACC_DESC: 'Accommodation description',
        BASIC_GROUP: 'Basic group',
        ADVANCED_GROUP: 'Advanced group',
        EXPERT_GROUP: 'Expert group',
        WE_WILL_LEARN: 'We will learn',
        START: 'Starts on',
        COURSE_DATES: 'Course dates',
        TOTAL_VISITS_HOURS: 'Total visits and hours',
        COURSE_RECEIVABLES: 'You receive on the course',
        NUMBER_OF_ATTENDEES: 'Number of trainees',
        AGE_OF_ATTENDEES: 'Age of trainees',
        COURSE_PRICE: 'Course price',
        VISITS_REPEATS_PLACEHOLDER: 'No. visits..',
        VISITS_HOURS_PLACEHOLDER: 'No. hours..',
        COURSE_RECEIVABLES_PLACEHOLDER: 'Course receivables..',
        NUMBER_OF_ATTENDEES_PLACEHOLDER: 'Max no. of trainees',
        AGE_OF_ATTENDEES_PLACEHOLDER: 'Age (from - to)',
        COURSE_PRICE_REGULAR_PLACEHOLDER: 'Regular price..',
        COURSE_PRICE_DISCOUNT_PLACEHOLDER: 'Discount price..',
        DAYS: 'days',
        HOURS: 'hours',
        PER_HOUR: 'per hour',
        ADD_TO_CALENDAR:'Add to calendar',
        PAID: 'Paid:',
        RESERVATION_NUMBER: 'No. reservation:',
        PRINT: 'Print',
        CANCEL_RESCHEDULE: 'Cancel / Reschedule',
        CONTACT_BUTTON_SMALL: 'Contact',
        MEMBERSHIP_START_DATE_PRETTY: 'Member since',
        YEARS: 'years',
        IN_SCHOOL_WE_SPEAK: 'In school we speak:',
        REVIEW_SPACE: 'space',
        REVIEW_PRO: 'professionalism',
        REVIEW_SPEED: 'responsiveness',
        REVIEW_CONTACT: 'information',
        REVIEW_BANG_FOR_BUCK: 'ratio between quality and price',
        PROVIDER_COURSES: 'Course providers',
        BUY: 'Buy',
        FOR_PAYMENT: 'For payment',
        PRICE_FOR: 'Price for',
        NIGHTS: 'nights',
        HOME: 'Home',
        MY_PROFILE: 'My profile',
        VIDEO_URL: 'Video presentation',
        YOUTUBE: 'Youtube - video url',
        FOLLOWERS: 'Your offers are followed by',
        ACC_PIC: 'Accommodation picture',
        REGISTER_PROMO: 'Join community of course providers today',
        COURSE__DEFAULT_PRICE: 'Course default price',
        START_DATE_INFO: 'With checkbox tick dates that should represent course start dates',
        ERROR_TEL: 'Wrong telephone format. Please enter correct telephone number.',
        ERROR_EMAIL: 'Wrong email format specified. Please enter correct email address.',
        SUBMIT: "SUBMIT",
        JAN: 'January',
        FEB: 'February',
        MAR: 'March',
        APR: 'April',
        MAY: 'May',
        JUN: 'June',
        JUL: 'July',
        AUG: 'August',
        SEP: 'September',
        OCT: 'October',
        NOV: 'November',
        DEC: 'December',
        FILTER_REGION: 'Region',
        MON: 'Monday',
        TUE: 'Tuesday',
        WED: 'Wednesday',
        THU: 'Thursday',
        FRI: 'Friday',
        SAT: 'Saturday',
        SUN: 'Sunday',
        PROFILE_PROVIDER: 'Provider profile',
        FOLLOWING: 'You are currently not following any course providers.',
        FOLLOWING_COURSES: 'You are currently not following any course providers.',
        ADMIN_PROFILE: 'Admin control panel',
        TAB_ADMIN_1: 'Presentation pages content',
        TAB_ADMIN_2: 'Presentation pages FAQ',
        TAB_ADMIN_3: 'Preview of registered providers',
        SHORT_DESC: 'Short description',
        LONG_DESC: 'Long description',
        PROMO_TEXT: 'Promo text',
        FOR_USER: ' for users',
        FOR_PROVIDER: ' for course providers',
        USERS_LIST: 'List of users',
        PROVIDERS_LIST: 'List of providers',
        EDIT_CATEGORIES: 'Edit categories',
        EDIT_INFO: 'Edit already existing category',
        ADD_INFO: 'Create a new category',
        PROVIDERS_TOTAL: 'Total number of all providers',
        USERS_TOTAL: 'Total number of all providers',
        UPLOAD_DOCUMENTS: 'Upload legal documents',
        TERMS_OF_USE: 'Terms of Use',
        PRIVACY_POLICY: 'Privacy Policy',
        BENEFITS_USER: 'Landing page',
        BENEFITS_PROVIDER: 'Landing page',
        JOIN_FAMILY: 'Join the family of course providers',
        COOKIES: 'Cookies policy',
        PROVIDER_LIST: 'Course provider',
        PROVIDER_ADDRESS: 'Course provider address',
        EMAIL: 'Email',
        USER: 'User',
        PREVIEW_DOC: 'Preview uploaded document',
        ABOUT: 'About',
        SHOP: 'Shop',
        SIGN_IN: 'Sign in',
        HELP: 'Help',
        COOKIE_NOTICE: 'This site uses cookies. By continuing to browse the site, you are agreeing to our use of cookies.',
        CONTACT_REQ: 'Contact request',
        NO_ACCOMMODATION: 'No accommodation',
        PROVIDERS_FAQ: 'Providers FAQ',
        USERS_FAQ: 'Users FAQ',
        PRIVACY_POLICY_USERS: 'General Terms and Conditions for Course Participants',
        PRIVACY_POLICY_PROVIDERS: 'General Terms and Conditions for Course Providers',
        FAQ_LABEL: 'Frequently Asked Questions',
        REGISTER_FOOTER: 'Register and be the first to know',
        PROVIDER_FOOTER: 'Present Your Course',
        NEWSLETTER_SUBSCRIBE: 'Subscribe to Newsletter',
        PRIVACY_FOOTER: 'Privacy Statement',
        COOKIES_FOOTER: 'Cookie Policy',
        DICTIONARY_FOOTER: 'Glossary of Terms',
        USERS_FOOTER: 'Course Participants',
        PROVIDERS_FOOTER: 'Course Providers',
        CONTACT_FOOTER: 'Contact',
        NIGHT: 'night',
        BANNER_1_TITLE: 'Predstavi tečaj, izobraževanje ali delavnico',
        BANNER_1_P1: 'Večja vidljivost, novi trgi, novi tečajniki',
        BANNER_1_P2: 'Dodana vrednost',
        BANNER_1_P3: 'Prihranite čas in denar',
        BANNER_1_P4: 'Brezplačna predstavitev',
        BANNER_1_P5: 'Pozdravi nove tečajnike',
        BANNER_1_BTN1: 'Registriraj se in objavi tečaj',
        BANNER_1_BTN2: 'Izvedi več',
        BANNER_2_TITLE: 'Najdi tečaj po svoji meri:',
        BANNER_2_P1: 'Tečaji za prosti čas',
        BANNER_2_P2: 'Tečaji za strokovna znanja',
        BANNER_2_P3: 'Pregledna ponudba',
        BANNER_2_P4: 'Varna in enostavna rezervacija',
        BANNER_2_BTN1: 'Registriraj se in sledi tečajem',
        BANNER_2_BTN2: 'Izvedi več',
    },
    IT: {
        PREVIEW: 'Preview',
        NO_SEARCH_RESULTS: "Nessun risultato di ricerca",
        SEARCH: 'Corsi filtrati',
        HOME_WELCOME: 'Ciao',
        YOUR_USERNAME_TITLE: 'Il tuo nome utente',
        YOUR_EMAIL_TITLE: 'La tua email',
        YOUR_REFERRAL_ID: 'Inserisci l\'ID di riferimento',
        YOUR_ANALYSIS_URL: 'Il tuo link di analisi dello stile di vita di riferimento',
        FORGOT_PASSWORD: 'Ha dimenticato la password? Reimpostalo',
        APP_TITLE_1: 'ANALISI',
        APP_TITLE_2: 'DELLO STILE DI VITA',
        APP_PROMO: 'Il tuo percorso verso una salute ottimale',
        LOGIN_WELCOME_BACK: 'Ben tornato',
        LOGIN_EMAIL: 'Inserisci il tuo nome email',
        LOGIN_PASSWORD: 'Inserire la password',
        LOGIN_REMEMBER: 'Ricordati di me',
        LOGIN_CREATE_ACCOUNT: 'Non hai ancora un conto? Registrati qui',
        LOGIN_BUTTON: 'ACCEDERE',
        REGISTER_TITLE: 'Unisciti a noi',
        REGISTER_NAME: 'Inserisci il tuo nome',
        REGISTER_BUTTON: 'REGISTRATI',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Hai già un account? Iscrizione',
        RESULT_TITLE: 'IL TUO RISULTATO',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Parola d\'ordine',
        SAVE: 'SALVA',
        COPIED: 'Link copiato',
        ANALYSIS_INSTRUCTIONS: 'Rispondi alle domande con SI o NO',
        ANALYSIS_START: 'INIZIA CON UN QUESTIONARIO',
        YES: 'SI',
        NO: 'NO',
        ANALYSIS_GENERATE_RESULT: 'GENERARE UN RISULTATO',
        ANALYSIS_SHARE_RESULTS: 'Per una spiegazione più dettagliata della tua analisi sullo stile di vita e informazioni sulle soluzioni Synergy, puoi condividere i tuoi risultati con la persona che ti ha inviato l\'analisi.',
        SUBSCRIBE_JOIN: 'Diventare membro!',
        SUBSCRIBE: 'ACQUISTO ABBONAMENTO',
        RESET_PASSWORD: 'RESETTA LA PASSWORD',
        RESET_PASSWORD_TITLE: 'Reimposta password dimenticata',
        GENERATE_RESULT_ERROR: 'Errore durante la generazione del risultato. Controlla e prova a inserire di nuovo la tua email.',
        DATA_UPDATED: 'Dati aggiornati con successo.',
        PASSWORD_RESET_SUCCESS: 'Password aggiornata correttamente. Per favore esegui l\'accesso di nuovo.',
        NAME: 'Nome',
        NEED_SUPPORT: 'Hai bisogno di supporto?',
        STATS: 'Le tue statistiche',
        COMPLETED: 'COMPLETATO',
        SHARED_BACK: 'CONDIVISO INDIETRO',
        SHARED_PERCENTAGE: 'CONDIVISO',
        LOGOUT: 'Esci',
        CONTACT: 'Contatto',
        NAME_SURNAME: 'Nome e cognome',
        TELEPHONE: 'Numero di telefono',
        REGISTER: 'Registrazione',
        COMPANY_INFO: 'Informazioni sull\'azienda',
        COMPANY_NAME: 'Nome della ditta',
        ADDRESS: 'Indirizzo',
        COMPANY_LOGO: 'Logo',
        POST_CITY: 'CAP e città',
        COUNTRY: 'Paese',
        ENTER_COMPANY_NAME: 'Inserisci il nome dell\'azienda',
        ENTER_ADDRESS: 'Inserisci indirizzo',
        ENTER_COMPANY_LOGO: 'Carica logo',
        ENTER_POST_CITY: 'Inserisci il CAP e la città',
        ENTER_COUNTRY: 'Seleziona il paese',
        ENTER_TELEPHONE: 'Immettere il numero di tel.',
        BECOME_LECTURER: 'Diventare un insegnante',
        ADD_COURSE: 'INVIA CORSO',
        TAB_MY_RESERVATIONS: 'Le mie prenotazioni',
        TAB_RESERVATIONS_ARCHIVE: 'Archivio delle prenotazioni passate',
        TAB_FOLLOWING_COURSES: 'Sono interessato a',
        TAB_FOLLOWING: 'Seguente',
        TAB_PERSONAL_DATA: 'Impostazioni',
        TAB_CATEGORIES: 'Categorie',
        TAB_PAYMENTS: 'Pagamenti',
        FOLLOW: 'Seguire',
        UNFOLLOW: 'Non seguire',
        EDIT: 'modificare',
        LANGUAGE_SPOKEN: 'I speak:',
        FILTER_CATEGORY: 'Categoria',
        FILTER_LOCATION: 'Posizione',
        FILTER_PRICE: 'Prezzo',
        FILTER_ACCOMMODATION: 'Struttura ricettiva',
        FILTER_GROUP_SIZE: 'Dimensione del gruppo',
        FILTER_DATE: 'Data',
        FILTER_ONLINE: 'Online',
        FILTER_STARS: 'Revisione',
        FIND_COURSE: 'Trova un corso a tua scelta.',
        SUGGESTED: 'Suggerito',
        MOST_POPULAR: 'Offerte più popolari',
        NEW_CONTENT: 'Nuove offerte',
        PROVIDER_ABOUT: 'Chi siamo e cosa facciamo',
        LECTURERS: 'Insegnanti',
        REVIEWS: 'Valutazioni degli studenti',
        ALL_COURSES: 'Tutti i corsi',
        COURSE_LEARN: 'Lo scopriremo',
        COURSE_NEEDS: 'Ne avrai bisogno',
        COURSE_ACC: 'Struttura ricettiva',
        COURSE_COMPARISON: 'Confrontare',
        ADD_NEW_COURSE: 'Aggiungi nuovo corso',
        NAME_COURSE: 'Titolo del corso',
        COURSE_DESCRIPTION: 'Descrizione del corso...',
        COURSE_LECTURER: 'Docente del corso...',
        ACC_NAME: 'Nome',
        PRICE: 'Prezoo',
        ACC_NIGHTS: 'Soggiorni',
        ACC_NIGHTS_PLACEHOLDER: 'Numero di notti',
        ACC_LOCATION: 'Posizione',
        TAB_PRESENTATION: 'Presentazione della compagnia',
        TAB_RECEIVABLES: 'Rimesse ricevute',
        TAB_STATS: 'Statistiche',
        FAQ: 'FAQs',
        PROVIDER_NAME: 'Nome della ditta',
        PROVIDER_DESC: 'Descrizione dell\'azienda',
        MY_COURSES: 'I miei corsi',
        MY_COURSES_PUBLISHED: 'Corsi pubblicati',
        MY_COURSES_ARCHIVED: 'Archivio del corso',
        MY_COURSES_ADD: 'Aggiungi un corso',
        COURSES: 'Corsi',
        ARCHIVE: 'Archivio',
        PARTICIPANTS: 'Partecipanti',
        ADD: 'Inserisci',
        QUESTION: 'Domanda',
        ANSWER: 'Risposta',
        FAQ_INFO: 'Scrivi domande e risposte frequenti',
        ADD_QUESTION: 'Aggiungi domanda',
        BANK_ACCOUNT: 'Conto bancario',
        VAT_NUMBER: 'No. Fiscale',
        REGISTRATION_NUMBER: 'No. conto corrente aziendale',
        BANK_NAME: 'Il conto viene aperto presso la banca',
        COURSE_PRESENTATION: 'Presentazione del corso',
        IMAGE: 'immagine',
        UPDATE: 'aggiornare',
        REMOVE_ALL_IMAGES: 'Rimuovi tutte le immagini',
        LECTURERS_CHOOSE: 'Scegli i docenti del corso',
        ADMINISTRATION: 'Amministrazione',
        ADMINISTRATION_PROVIDER: 'Amministrazione aziendale',
        CONTACT_BUTTON: 'CONTATTO!',
        LANGUAGE_SPOKEN_PLURAL: 'Noi parliamo',
        LANGUAGE_SPOKEN_PLURAL_INFO: 'La tua presentazione sarà presentata in tutte le lingue selezionate.',
        UPLOAD_IMAGES_INFO_1: 'Carica foto di tipo .png o .jpg.',
        UPLOAD_IMAGES_INFO_2: 'Ti consigliamo di allegare le tue foto autentiche.',
        THIS_SCHOOL_FOLLOWS_1: 'Questa scuola, ha già frequentato ',
        THIS_SCHOOL_FOLLOWS_2: 'tirocinanti',
        MEMBERSHIP_START_DATE: 'Inizio iscrizione: ',
        ADMINISTRATION_COURSE: 'Amministrazione del corso',
        COURSE_LANGUAGES: 'Aggiungi e seleziona le lingue in cui avviene la comunicazione nel corso.',
        ACC_DESC: 'Descrizione dell\'alloggio',
        BASIC_GROUP: 'Gruppo base',
        ADVANCED_GROUP: 'Gruppo avanzato',
        EXPERT_GROUP: 'Gruppo di esperti',
        WE_WILL_LEARN: 'Lo scopriremo',
        START: 'L\'inizio',
        COURSE_DATES: 'Il corso è in corso',
        TOTAL_VISITS_HOURS: 'Totale visite e ore',
        COURSE_RECEIVABLES: 'Ricevi sul corso',
        NUMBER_OF_ATTENDEES: 'Numero di tirocinanti',
        AGE_OF_ATTENDEES: 'Età dei tirocinanti',
        COURSE_PRICE: 'Prezzo del corso',
        VISITS_REPEATS_PLACEHOLDER: 'Bene. visite..',
        VISITS_HOURS_PLACEHOLDER: 'Bene. ore..',
        COURSE_RECEIVABLES_PLACEHOLDER: 'Crediti corso..',
        NUMBER_OF_ATTENDEES_PLACEHOLDER: 'Massimo n. di tirocinanti',
        AGE_OF_ATTENDEES_PLACEHOLDER: 'Età (da - a)',
        COURSE_PRICE_REGULAR_PLACEHOLDER: 'Prezzo regolare..',
        COURSE_PRICE_DISCOUNT_PLACEHOLDER: 'Prezzo scontato..',
        DAYS: 'giorni',
        HOURS: 'ore',
        PER_HOUR: 'all\'ora',
        ADD_TO_CALENDAR:'Aggiungi al calendario',
        PAID: 'Pagato:',
        RESERVATION_NUMBER: 'No. prenotazioni:',
        PRINT: 'Stampa',
        CANCEL_RESCHEDULE: 'Annulla / Rinvia',
        CONTACT_BUTTON_SMALL: 'Mettiti in contatto',
        MEMBERSHIP_START_DATE_PRETTY: 'Membro da',
        YEARS: 'anni',
        IN_SCHOOL_WE_SPEAK: 'A scuola si parla:',
        REVIEW_SPACE: 'spazio',
        REVIEW_PRO: 'professionalità',
        REVIEW_SPEED: 'reattività',
        REVIEW_CONTACT: 'informazione',
        REVIEW_BANG_FOR_BUCK: 'rapporto tra qualità e prezzo',
        PROVIDER_COURSES: 'Fornitori di corsi',
        BUY: 'Acquistare',
        FOR_PAYMENT: 'Per il pagamento',
        PRICE_FOR: 'Prezzo per',
        NIGHTS: 'notti',
        HOME: 'Casa',
        MY_PROFILE: 'Il mio profilo',
        VIDEO_URL: 'Presentazione video',
        YOUTUBE: 'Youtube - URL video',
        FOLLOWERS: 'Le tue offerte sono seguite da',
        ACC_PIC: 'Immagine dell\'alloggio',
        REGISTER_PROMO: 'Unisciti alla community dei corsi pubblicati nell\'area',
        COURSE__DEFAULT_PRICE: 'Prezzo predefinito del corso',
        START_DATE_INFO: 'Con le date di spunta della casella di controllo che dovrebbero rappresentare le date di inizio del corso',
        ERROR_TEL: 'Formato del telefono errato. Si prega di inserire il numero di telefono corretto.',
        ERROR_EMAIL: 'Formato email specificato errato. Si prega di inserire l\'indirizzo email corretto.',
        SUBMIT: 'INVIA',
        UNARCHIVE: 'Annulla archiviazione',
        JAN: 'Gennaio',
        FEB: 'Febbraio',
        MAR: 'Marzo',
        APR: 'Aprile',
        MAY: 'Maggio',
        JUN: 'Giugno',
        JUL: 'Luglio',
        AUG: 'Agosto',
        SEP: 'Settembre',
        OCT: 'Ottobre',
        NOV: 'Novembre',
        DEC: 'Dicembre',
        FILTER_REGION: 'Regione',
        MON: 'Lunedi',
        TUE: 'Lunedi martedì',
        WED: 'Mercoledì',
        THU: 'Giovedì',
        FRI: 'Venerdì',
        SAT: 'Sabato',
        SUN: 'Domenica',
        PROFILE_PROVIDER: 'Profilo Formatore',
        FOLLOWING: 'Attualmente non stai seguendo nessun fornitore di corsi.',
        FOLLOWING_COURSES: "Attualmente non stai seguendo nessun fornitore di corsi.",
        ADMIN_PROFILE: 'Pannello di controllo amministratore',
        TAB_ADMIN_1: 'Contenuto delle pagine di presentazione',
        TAB_ADMIN_2: 'FAQ delle pagine di presentazione',
        TAB_ADMIN_3: 'Anteprima dei fornitori registrati',
        SHORT_DESC: 'Breve descrizione',
        LONG_DESC: 'Descrizione lunga',
        PROMO_TEXT: 'Testo promozionale',
        FOR_USER: ' per utenti',
        FOR_PROVIDER: ' per fornitori di corsi',
        USERS_LIST: 'Elenco utenti',
        PROVIDERS_LIST: 'Elenco fornitori',
        EDIT_CATEGORIES: 'Modifica categorie',
        FILTER_CATEGORY_PARENT: 'Categoria padre',
        EDIT_INFO: 'Modifica categoria esistente',
        ADD_INFO: 'Crea una nuova categoria',
        PROVIDERS_TOTAL: 'Numero totale di fornitori',
        USERS_TOTAL: 'Numero totale di utenti',
        UPLOAD_DOCUMENTS: 'Carica documenti legali',
        TERMS_OF_USE: 'Termini di utilizzo',
        PRIVACY_POLICY: 'Informativa sulla privacy',
        BENEFITS_USER: 'Landing page',
        BENEFITS_PROVIDER: 'Landing page',
        JOIN_FAMILY: 'Entra a far parte della famiglia degli insegnanti',
        COOKIES: 'Politica sui cookie',
        PROVIDER_LIST: 'Elenco dei fornitori',
        PROVIDER_ADDRESS: 'Indirizzo del fornitore',
        EMAIL: 'E-mail',
        USER: 'Utente',
        PREVIEW_DOC: 'Anteprima del documento caricato',
        ABOUT: 'Chi siamo',
        SHOP: 'Negozio',
        SIGN_IN: 'Accedi',
        HELP: 'Aiuto',
        COOKIE_NOTICE: 'Questo sito utilizza i cookie. Continuando a navigare nel sito, accetti il nostro utilizzo dei cookie.',
        CONTACT_REQ: 'Contact request',
        NO_ACCOMMODATION: 'No accommodation',
        PROVIDERS_FAQ: 'Providers FAQ',
        USERS_FAQ: 'Users FAQ',
        PRIVACY_POLICY_USERS: 'Termini e Condizioni Generali per i Partecipanti al Corso',
        PRIVACY_POLICY_PROVIDERS: 'Termini e Condizioni Generali per i Provider di Corsi',
        FAQ_LABEL: 'Domande Frequenti',
        REGISTER_FOOTER: 'Registrati e sii il primo a sapere',
        PROVIDER_FOOTER: 'Presenta il tuo Corso',
        NEWSLETTER_SUBSCRIBE: 'Iscriviti alla Newsletter',
        PRIVACY_FOOTER: 'Informativa sulla Privacy',
        COOKIES_FOOTER: 'Informativa sui Cookie',
        DICTIONARY_FOOTER: 'Glossario dei Termini',
        USERS_FOOTER: 'Partecipanti al corso',
        PROVIDERS_FOOTER: 'Provider del corso',
        CONTACT_FOOTER: 'Contatto',
        NIGHT: 'night',
        BANNER_1_TITLE: 'Predstavi tečaj, izobraževanje ali delavnico',
        BANNER_1_P1: 'Večja vidljivost, novi trgi, novi tečajniki',
        BANNER_1_P2: 'Dodana vrednost',
        BANNER_1_P3: 'Prihranite čas in denar',
        BANNER_1_P4: 'Brezplačna predstavitev',
        BANNER_1_P5: 'Pozdravi nove tečajnike',
        BANNER_1_BTN1: 'Registriraj se in objavi tečaj',
        BANNER_1_BTN2: 'Izvedi več',
        BANNER_2_TITLE: 'Najdi tečaj po svoji meri:',
        BANNER_2_P1: 'Tečaji za prosti čas',
        BANNER_2_P2: 'Tečaji za strokovna znanja',
        BANNER_2_P3: 'Pregledna ponudba',
        BANNER_2_P4: 'Varna in enostavna rezervacija',
        BANNER_2_BTN1: 'Registriraj se in sledi tečajem',
        BANNER_2_BTN2: 'Izvedi več',
    },
    SI: {
        PREVIEW: 'Predogled',
        NO_SEARCH_RESULTS: 'Brez zadetkov',
        SEARCH: 'Filtrirani tečaji',
        HOME_WELCOME: 'Pozdravljen',
        YOUR_USERNAME_TITLE: 'Vaše uporabniško ime',
        YOUR_EMAIL_TITLE: 'Vaša e-pošta',
        YOUR_REFERRAL_ID: 'Vpišite referral (Synergy) ID',
        YOUR_ANALYSIS_URL: 'Vaš referral link za lifestyle analizo',
        FORGOT_PASSWORD: 'Pozabljeno geslo? Ponastavite ga',
        APP_TITLE_1: 'ANALIZA',
        APP_TITLE_2: 'ŽIVLJENJSKEGA STILA',
        APP_PROMO: 'Vaša pot do optimalnega zdravja',
        LOGIN_WELCOME_BACK: 'Dobrodošel nazaj',
        LOGIN_EMAIL: 'Vpišite e-pošto',
        LOGIN_PASSWORD: 'Vpišite geslo',
        LOGIN_REMEMBER: 'Zapomni si me',
        LOGIN_CREATE_ACCOUNT: 'Še nimate računa? Registrirajte se',
        LOGIN_BUTTON: 'PRIJAVA',
        REGISTER_TITLE: 'Pridruži se',
        REGISTER_NAME: 'Vpišite vaše polno ime',
        REGISTER_BUTTON: 'REGISTRACIJA',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Že imate račun? Vpišite se',
        RESULT_TITLE: 'VAŠ REZULTAT',
        PLACEHOLDER_EMAIL: 'E-pošta',
        PLACEHOLDER_PASSWORD: 'Geslo',
        SAVE: 'SHRANI',
        COPIED: 'Povezava kopirana',
        ANALYSIS_INSTRUCTIONS: 'Na vprašanja odgovarjajte z DA ali NE.',
        ANALYSIS_START: 'PRIČNI Z VPRAŠALNIKOM',
        YES: 'DA',
        NO: 'NE',
        ANALYSIS_GENERATE_RESULT: 'GENERIRAJ REZULTAT',
        ANALYSIS_SHARE_RESULTS: 'Za bolj podrobno razlago vaše Lifestyle analize ter informacije o Synergy rešitvah, lahko delite vaše rezultate z osebo, ki vam je analizo poslala.',
        SUBSCRIBE_JOIN: 'Postanite član!',
        SUBSCRIBE: 'NAKUP NAROČNINE',
        RESET_PASSWORD: 'PONASTAVI GESLO',
        RESET_PASSWORD_TITLE: 'Ponastavitev gesla',
        GENERATE_RESULT_ERROR: 'Napaka pri generiranju rezultata. Prosimo preverite in poskusite znova vnesti vašo e-pošto.',
        DATA_UPDATED: 'Podatki uspešno posodobljeni.',
        PASSWORD_RESET_SUCCESS: 'Geslo je uspešno posodobljeno. Prosimo, da se ponovno prijavite.',
        NAME: 'Ime',
        NEED_SUPPORT: 'Potrebujete pomoč?',
        STATS: 'Vaša statistika',
        COMPLETED: 'IZPOLNJENI',
        SHARED_BACK: 'DELJENO NAZAJ',
        SHARED_PERCENTAGE: 'DELJENO',
        BASIC_PROGRAM: 'OSNOVNI PROGRAM',
        OPTIMAL_PROGRAM: 'OPTIMALNI PROGRAM',
        LOGOUT: 'Odjava',
        CONTACT: 'Kontakt',
        NAME_SURNAME: 'Ime in priimek',
        ENTER_TELEPHONE: 'Vnesite telefonsko št.',
        TELEPHONE: 'Telefonska št.',
        REGISTER: 'Registracija',
        COMPANY_INFO: 'Podatki podjetja',
        COMPANY_NAME: 'Naziv podjetja',
        ADDRESS: 'Naslov',
        COMPANY_LOGO: 'Logotip',
        POST_CITY: 'Poštna št. in mesto',
        COUNTRY: 'Država',
        ENTER_COMPANY_NAME: 'Vnesite naziv podjetja',
        ENTER_ADDRESS: 'Vnesite naslov',
        ENTER_COMPANY_LOGO: 'Naložite logotip/sliko',
        ENTER_POST_CITY: 'Vnesite poštno št. in mesto',
        ENTER_COUNTRY: 'Izberite državo',
        BECOME_LECTURER: 'Postani učitelj',
        ADD_COURSE: 'DODAJ TEČAJ!',
        TAB_MY_RESERVATIONS: 'Moje rezervacije',
        TAB_RESERVATIONS_ARCHIVE: 'Arhiv preteklih rezervacij',
        TAB_FOLLOWING_COURSES: 'Zanima me',
        TAB_FOLLOWING: 'Komu sledim',
        TAB_PERSONAL_DATA: 'Moji podatki',
        TAB_CATEGORIES: 'Kategorije',
        TAB_PAYMENTS: 'Plačila',
        FOLLOW: 'Sledi',
        UNFOLLOW: 'Sledim',
        EDIT: 'uredi',
        LANGUAGE_SPOKEN: 'Govorim:',
        FILTER_CATEGORY: 'Kategorija',
        FILTER_LOCATION: 'Lokacija',
        FILTER_PRICE: 'Cena',
        FILTER_ACCOMMODATION: 'Namestitev',
        FILTER_GROUP_SIZE: 'Velikost skupine',
        FILTER_DATE: 'Datum',
        FILTER_ONLINE: 'Online',
        FILTER_STARS: 'Ocene',
        FIND_COURSE: 'Poišči tečaj po svoji meri.',
        SUGGESTED: 'Predlagano zate',
        MOST_POPULAR: 'Najbolj priljubljene ponudbe',
        NEW_CONTENT: 'Nove ponudbe',
        PROVIDER_ABOUT: 'Kdo smo in kaj počnemo',
        LECTURERS: 'Učitelji',
        REVIEWS: 'Ocene tečajnikov',
        ALL_COURSES: 'Vsi tečaji',
        COURSE_LEARN: 'Spoznali bomo',
        COURSE_NEEDS: 'Potreboval boš',
        COURSE_ACC: 'Namestitev',
        COURSE_COMPARISON: 'Primerjaj',
        ADD_NEW_COURSE: 'Dodaj nov tečaj',
        NAME_COURSE: 'Naslov tečaja',
        COURSE_DESCRIPTION: 'Opis tečaja...',
        COURSE_LECTURER: 'Opis učitelja...',
        ACC_NAME: 'Naziv',
        PRICE: 'Cena',
        ACC_NIGHTS: 'Nočitve',
        ACC_NIGHTS_PLACEHOLDER: 'Število nočitev',
        ACC_LOCATION: 'Lokacija',
        ACC_LOCATION_PLACEHOLDER: 'Vpiši naslov namestitve',
        TAB_PRESENTATION: 'Predstavitev podjetja',
        TAB_RECEIVABLES: 'Prejeta nakazila',
        TAB_STATS: 'Statistika',
        FAQ: 'FAQs',
        PROVIDER_NAME: 'Ime podjetja',
        PROVIDER_DESC: 'Opis podjetja',
        MY_COURSES: 'Moji tečaji',
        MY_COURSES_PUBLISHED: 'Objavljeni tečaji',
        MY_COURSES_ARCHIVED: 'Arhiv tečajev',
        MY_COURSES_ADD: 'Dodaj tečaj',
        COURSES: 'Tečaji',
        ARCHIVE: 'V arhiv',
        PARTICIPANTS: 'Tečajniki',
        ADD: 'Dodaj',
        QUESTION: 'Vprašanje',
        ANSWER: 'Odgovor',
        FAQ_INFO: 'Napiši pogosta vprašanja in odgovore',
        ADD_QUESTION: 'Dodaj vprašanje',
        BANK_ACCOUNT: 'Št. poslovnega tekočega računa',
        VAT_NUMBER: 'Davčna št.',
        REGISTRATION_NUMBER: 'Matična št.',
        BANK_NAME: 'Račun je odprt na banki',
        COURSE_PRESENTATION: 'Predstavitev tečaja',
        IMAGE: 'slika',
        UPDATE: 'posodobi',
        REMOVE_ALL_IMAGES: 'Odstrani vse slike',
        LECTURERS_CHOOSE: 'Izberi učitelje',
        ADMINISTRATION: 'Administracija',
        ADMINISTRATION_PROVIDER: 'Administracija izvajalca',
        CONTACT_BUTTON: 'KONTAKTIRAJ',
        LANGUAGE_SPOKEN_PLURAL: 'Govorimo',
        LANGUAGE_SPOKEN_PLURAL_INFO: 'Vaša, predstavitev bo predstavljena v vseh izbranih jezikih.',
        UPLOAD_IMAGES_INFO_1: 'Naloži fotografije tipa .png ali .jpg.',
        UPLOAD_IMAGES_INFO_2: 'Priporočamo priložitev vaših avtentičnih fotografij.',
        THIS_SCHOOL_FOLLOWS_1: 'Te šole se je udeležilo že',
        THIS_SCHOOL_FOLLOWS_2: 'tečajnikov',
        MEMBERSHIP_START_DATE: 'Začetek članstva:',
        ADMINISTRATION_COURSE: 'Administracija tečaja',
        COURSE_LANGUAGES: 'Dodaj in izberi jezike, v katerih poteka komunikacija na tečaju.',
        ACC_DESC: 'Opis namestitve',
        BASIC_GROUP: 'Osnovna skupina',
        ADVANCED_GROUP: 'Napredna skupina',
        EXPERT_GROUP: 'Expert skupina',
        WE_WILL_LEARN: 'Spoznali bomo',
        START: 'Pričetek',
        COURSE_DATES: 'Tečaj poteka',
        TOTAL_VISITS_HOURS: 'Skupaj obiskov in ur',
        COURSE_RECEIVABLES: 'Na tečaju prejmete',
        NUMBER_OF_ATTENDEES: 'Število tečajnikov',
        AGE_OF_ATTENDEES: 'Starost tečajnikov',
        COURSE_PRICE: 'Cena tečaja',
        VISITS_REPEATS_PLACEHOLDER: 'Št. obiskov..',
        VISITS_HOURS_PLACEHOLDER: 'Št. ur..',
        COURSE_RECEIVABLES_PLACEHOLDER: 'Na tečaju prejmete..',
        NUMBER_OF_ATTENDEES_PLACEHOLDER: 'Največje št. tečajnikov',
        AGE_OF_ATTENDEES_PLACEHOLDER: 'Starost (od - do)',
        COURSE_PRICE_REGULAR_PLACEHOLDER: 'Redna cena..',
        COURSE_PRICE_DISCOUNT_PLACEHOLDER: 'Popust cena..',
        DAYS: 'dni',
        HOURS: 'ur',
        PER_HOUR: 'na uro',
        ADD_TO_CALENDAR:'Dodaj v koledar',
        PAID: 'Plačano:',
        RESERVATION_NUMBER: 'Št. rezervacije:',
        PRINT: 'Natisni',
        CANCEL_RESCHEDULE: 'Odpovej / Prestavi',
        CONTACT_BUTTON_SMALL: 'Kontaktiraj',
        MEMBERSHIP_START_DATE_PRETTY: 'Član od',
        YEARS: 'let',
        IN_SCHOOL_WE_SPEAK: 'V šoli govorimo:',
        REVIEW_SPACE: 'prostor',
        REVIEW_PRO: 'strokovnost',
        REVIEW_SPEED: 'odzivnost',
        REVIEW_CONTACT: 'obveščenost',
        REVIEW_BANG_FOR_BUCK: 'razmerje med kakovostjo in ceno',
        PROVIDER_COURSES: 'Ponudniki tečajev',
        BUY: 'Kupi',
        FOR_PAYMENT: 'Za plačilo',
        PRICE_FOR: 'Cena za',
        NIGHTS: 'noči',
        HOME: 'Domov',
        MY_PROFILE: 'Moj profil',
        VIDEO_URL: 'Video predstavitev',
        YOUTUBE: 'Youtube - url link do videa',
        FOLLOWERS: 'Vašim ponudbam sledi',
        ACC_PIC: 'Slika namestitve',
        REGISTER_PROMO: 'Pridruži se skupnosti registriranih tečajnikov po Sloveniji',
        COURSE__DEFAULT_PRICE: 'Redna cena tečaja',
        START_DATE_INFO: 'Označite datume, ki naj predstavljajo datume začetka tečaja',
        ERROR_TEL: 'Napačen format telefonske št. Prosimo vnesite pravilno telefonsko številko.',
        ERROR_EMAIL: 'Naveden je napačen format elektronske pošte. Vnesite pravilen e-poštni naslov.',
        SUBMIT: 'POTRDI',
        UNARCHIVE: 'Aktiviraj',
        JAN: 'Januar',
        FEB: 'Februar',
        MAR: 'Marec',
        APR: 'April',
        MAY: 'Maj',
        JUN: 'Junij',
        JUL: 'Julij',
        AUG: 'Avgust',
        SEP: 'September',
        OCT: 'Oktober',
        NOV: 'November',
        DEC: 'December',
        FILTER_REGION: 'Regija',
        MON: 'Ponedeljek',
        TUE: 'Torek',
        WED: 'Sreda',
        THU: 'Četrtek',
        FRI: 'Petek',
        SAT: 'Sobota',
        SUN: 'Nedelja',
        PROFILE_PROVIDER: 'Profil izvajalca',
        FOLLOWING: 'Trenutno še ne sledite nobenemu ponudniku tečajev.',
        FOLLOWING_COURSES: "Trenutno še ne sledite nobenemu tečaju.",
        ADMIN_PROFILE: 'Admin nadzorna plošča',
        TAB_ADMIN_1: 'Vsebina predstavitvenih strani',
        TAB_ADMIN_2: 'FAQ predstavitvenih strani',
        TAB_ADMIN_3: 'Pregled registriranih tečajnikov',
        SHORT_DESC: 'Kratek opis',
        LONG_DESC: 'Daljši opis',
        PROMO_TEXT: 'Promocijski naziv',
        FOR_USER: ' za uporabnike',
        FOR_PROVIDER: ' za tečajnike',
        USERS_LIST: 'Pregled uporabnikov',
        PROVIDERS_LIST: 'Pregled izvajalcev',
        EDIT_CATEGORIES: 'Pregled kategorij',
        FILTER_CATEGORY_PARENT: 'Glavna kategorija',
        EDIT_INFO: 'Urejanje že obstoječe kategorije.',
        ADD_INFO: 'Ustvari novo kategorijo.',
        PROVIDERS_TOTAL: 'Število vseh izvajalcev tečajev',
        USERS_TOTAL: 'Število vseh uporabnikov (tečajniki)',
        UPLOAD_DOCUMENTS: 'Pravni dokumenti',
        TERMS_OF_USE: 'Politika poslovanja',
        PRIVACY_POLICY: 'Politika zasebnosti',
        BENEFITS_USER: 'Pristajalna stran',
        BENEFITS_PROVIDER: 'Pristajalna stran',
        JOIN_FAMILY: 'Pridruži se družini izvajalcev tečajev',
        COOKIES: 'Politika piškotkov',
        PROVIDER_LIST: 'Izvajalec tečaja',
        PROVIDER_ADDRESS: 'Naslov izvajalca',
        EMAIL: 'E-pošta',
        USER: 'Uporabnik',
        PREVIEW_DOC: 'Predogled naloženega dokumenta',
        ABOUT: 'O nas',
        SHOP: 'Trgovina',
        SIGN_IN: 'Prijava',
        HELP: 'Podpora',
        COOKIE_NOTICE: 'To spletno mesto uporablja piškotke. Z nadaljevanjem brskanja po strani se strinjate z našo uporabo piškotkov.',
        CONTACT_REQ: 'Oddaj povpraševanje',
        NO_ACCOMMODATION: 'Brez nočitve',
        PROVIDERS_FAQ: 'Ponudniki tečajev FAQ',
        USERS_FAQ: 'Uporabniki FAQ',
        PRIVACY_POLICY_USERS: 'Splošni pogoji za tečajnike',
        PRIVACY_POLICY_PROVIDERS: 'Splošni pogoji za izvajalce tečaja',
        FAQ_LABEL: 'Pogosta vprašanja in odgovori',
        REGISTER_FOOTER: 'Registrirajte se in bodite prvi obveščeni',
        PROVIDER_FOOTER: 'Predstavite svoj tečaj',
        NEWSLETTER_SUBSCRIBE: 'Prijavi se na e-novice',
        PRIVACY_FOOTER: 'Izjava o zasebnosti',
        COOKIES_FOOTER: 'Izjava o piškotkih',
        DICTIONARY_FOOTER: 'Slovar izrazov',
        USERS_FOOTER: 'Tečajniki',
        PROVIDERS_FOOTER: 'Izvajalci tečajev',
        CONTACT_FOOTER: 'Kontakt',
        NIGHT: 'noč',
        BANNER_1_TITLE: 'Predstavi tečaj, izobraževanje ali delavnico',
        BANNER_1_P1: 'Večja vidljivost, novi trgi, novi tečajniki',
        BANNER_1_P2: 'Dodana vrednost',
        BANNER_1_P3: 'Prihranite čas in denar',
        BANNER_1_P4: 'Brezplačna predstavitev',
        BANNER_1_P5: 'Pozdravi nove tečajnike',
        BANNER_1_BTN1: 'Registriraj se in objavi tečaj',
        BANNER_1_BTN2: 'Izvedi več',
        BANNER_2_TITLE: 'Najdi tečaj po svoji meri:',
        BANNER_2_P1: 'Tečaji za prosti čas',
        BANNER_2_P2: 'Tečaji za strokovna znanja',
        BANNER_2_P3: 'Pregledna ponudba',
        BANNER_2_P4: 'Varna in enostavna rezervacija',
        BANNER_2_BTN1: 'Registriraj se in sledi tečajem',
        BANNER_2_BTN2: 'Izvedi več',
    },
    DE: {
        PREVIEW: 'Preview',
        NO_SEARCH_RESULTS: 'Keine Suchergebnisse gefunden',
        SEARCH: 'Gefilterte Kurse',
        HOME_WELCOME: 'Willkommen',
        YOUR_USERNAME_TITLE: 'Ihr Benutzername',
        YOUR_EMAIL_TITLE: 'Ihre E-Mail-Adresse',
        YOUR_REFERRAL_ID: 'Geben Sie Ihre Referral (Synergy) ID ein',
        YOUR_ANALYSIS_URL: 'Ihr Lifestyle-Analyse-Referral-Link',
        FORGOT_PASSWORD: 'Passwort vergessen? Setzen Sie es zurück',
        APP_TITLE_1: 'ANALYSE',
        APP_TITLE_2: 'DES LEBENSSTILS',
        APP_PROMO: 'Ihr Weg zur optimalen Gesundheit',
        LOGIN_WELCOME_BACK: 'Willkommen zurück',
        LOGIN_EMAIL: 'Geben Sie Ihre E-Mail-Adresse ein',
        LOGIN_PASSWORD: 'Geben Sie Ihr Passwort ein',
        LOGIN_REMEMBER: 'Erinnere dich an mich',
        LOGIN_CREATE_ACCOUNT: 'Sie haben noch keinen Account? Registrieren Sie sich',
        LOGIN_BUTTON: 'EINLOGGEN',
        REGISTER_TITLE: 'Melden Sie sich an',
        REGISTER_NAME: 'Geben Sie Ihren vollständigen Namen ein',
        REGISTER_BUTTON: 'REGISTRIERUNG',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Haben Sie bereits ein Konto? Einloggen',
        RESULT_TITLE: 'IHR ERGEBNIS',
        PLACEHOLDER_EMAIL: 'E-Mail-Adresse',
        PLACEHOLDER_PASSWORD: 'Passwort',
        SAVE: 'SPEICHERN',
        COPIED: 'Link kopiert',
        ANALYSIS_INSTRUCTIONS: 'Beantworten Sie die Fragen mit JA oder NEIN.',
        ANALYSIS_START: 'MIT DEM FRAGEBOGEN BEGINNEN',
        YES: 'JA',
        NO: 'NEIN',
        ANALYSIS_GENERATE_RESULT: 'ERGEBNIS GENERIEREN',
        ANALYSIS_SHARE_RESULTS: 'Für eine detailliertere Erklärung Ihrer Lifestyle-Analyse und Informationen zu Synergy-Lösungen können Sie Ihre Ergebnisse mit der Person teilen, die Ihnen die Analyse gesendet hat.',
        SUBSCRIBE_JOIN: 'Werden Sie Mitglied!',
        SUBSCRIBE: 'ABONNEMENT KAUFEN',
        RESET_PASSWORD: 'PASSWORT ZURÜCKSETZEN',
        RESET_PASSWORD_TITLE: 'Passwort zurücksetzen',
        GENERATE_RESULT_ERROR: 'Fehler beim Generieren des Ergebnisses. Bitte überprüfen Sie Ihre E-Mail-Adresse und versuchen Sie es erneut.',
        DATA_UPDATED: 'Daten erfolgreich aktualisiert.',
        PASSWORD_RESET_SUCCESS: 'Passwort erfolgreich aktualisiert. Bitte melden Sie sich erneut an.',
        NAME: 'Name',
        NEED_SUPPORT: 'Brauchen Sie Hilfe?',
        STATS: 'Ihre Statistiken',
        COMPLETED: 'ABGESCHLOSSEN',
        SHARED_BACK: 'GETEILT ZURÜCK',
        SHARED_PERCENTAGE: 'GETEILT',
        BASIC_PROGRAM: 'BASISPROGRAMM',
        OPTIMAL_PROGRAM: 'OPTIMALES PROGRAMM',
        LOGOUT: 'Abmelden',
        CONTACT: 'Kontakt',
        NAME_SURNAME: 'Vor- und Nachname',
        ENTER_TELEPHONE: 'Telefonnummer eingeben',
        TELEPHONE: 'Telefonnummer',
        REGISTER: 'Registrierung',
        COMPANY_INFO: 'Unternehmensdaten',
        COMPANY_NAME: 'Name des Unternehmens',
        ADDRESS: 'Adresse',
        COMPANY_LOGO: 'Firmenlogo',
        POST_CITY: 'Postleitzahl und Stadt',
        COUNTRY: 'Land',
        ENTER_COMPANY_NAME: 'Geben Sie den Firmennamen ein',
        ENTER_ADDRESS: 'Geben Sie die Adresse ein',
        ENTER_COMPANY_LOGO: 'Logo/Bild hochladen',
        ENTER_POST_CITY: 'Geben Sie Postleitzahl und Stadt ein',
        ENTER_COUNTRY: 'Land auswählen',
        BECOME_LECTURER: 'Lehrer werden',
        ADD_COURSE: 'KURS HINZUFÜGEN!',
        TAB_MY_RESERVATIONS: 'Meine Buchungen',
        TAB_RESERVATIONS_ARCHIVE: 'Archiv vergangener Buchungen',
        TAB_FOLLOWING_COURSES: 'Interessiert mich',
        TAB_FOLLOWING: 'Wem ich folge',
        TAB_PERSONAL_DATA: 'Meine Daten',
        TAB_CATEGORIES: 'Kategorien',
        TAB_PAYMENTS: 'Zahlungen',
        FOLLOW: 'Folgen',
        UNFOLLOW: 'Entfernen',
        EDIT: 'Bearbeiten',
        LANGUAGE_SPOKEN: 'Ich spreche:',
        FILTER_CATEGORY: 'Kategorie',
        FILTER_LOCATION: 'Standort',
        FILTER_PRICE: 'Preis',
        FILTER_ACCOMMODATION: 'Unterkunft',
        FILTER_GROUP_SIZE: 'Gruppengröße',
        FILTER_DATE: 'Datum',
        FILTER_ONLINE: 'Online',
        FILTER_STARS: 'Bewertungen',
        FIND_COURSE: 'Finden Sie einen Kurs, der zu Ihnen passt.',
        SUGGESTED: 'Vorgeschlagen für Sie',
        MOST_POPULAR: 'Beliebteste Angebote',
        NEW_CONTENT: 'Neue Angebote',
        PROVIDER_ABOUT: 'Wer wir sind und was wir tun',
        LECTURERS: 'Dozenten',
        REVIEWS: 'Bewertungen der Kursteilnehmer',
        ALL_COURSES: 'Alle Kurse',
        COURSE_LEARN: 'Wir werden lernen',
        COURSE_NEEDS: 'Du brauchst',
        COURSE_ACC: 'Unterkunft',
        COURSE_COMPARISON: 'Vergleiche',
        ADD_NEW_COURSE: 'Neuen Kurs hinzufügen',
        NAME_COURSE: 'Kursname',
        COURSE_DESCRIPTION: 'Kursbeschreibung...',
        COURSE_LECTURER: 'Dozentenbeschreibung...',
        ACC_NAME: 'Name',
        PRICE: 'Preis',
        ACC_NIGHTS: 'Übernachtungen',
        ACC_NIGHTS_PLACEHOLDER: 'Anzahl der Übernachtungen',
        ACC_LOCATION: 'Ort',
        ACC_LOCATION_PLACEHOLDER: 'Geben Sie die Unterkunftsadresse ein',
        TAB_PRESENTATION: 'Unternehmensvorstellung',
        TAB_RECEIVABLES: 'Erhaltene Zahlungen',
        TAB_STATS: 'Statistiken',
        FAQ: 'FAQs',
        PROVIDER_NAME: 'Name des Unternehmens',
        PROVIDER_DESC: 'Unternehmensbeschreibung',
        MY_COURSES: 'Meine Kurse',
        MY_COURSES_PUBLISHED: 'Veröffentlichte Kurse',
        MY_COURSES_ARCHIVED: 'Archivierte Kurse',
        MY_COURSES_ADD: 'Kurs hinzufügen',
        COURSES: 'Kurse',
        ARCHIVE: 'Archiv',
        PARTICIPANTS: 'Kursteilnehmer',
        ADD: 'Hinzufügen',
        QUESTION: 'Frage',
        ANSWER: 'Antwort',
        FAQ_INFO: 'Häufig gestellte Fragen und Antworten schreiben',
        ADD_QUESTION: 'Frage hinzufügen',
        BANK_ACCOUNT: 'Geschäftskonto-Nummer',
        VAT_NUMBER: 'Umsatzsteuer-Identifikationsnummer',
        REGISTRATION_NUMBER: 'Handelsregisternummer',
        BANK_NAME: 'Konto eröffnet bei',
        COURSE_PRESENTATION: 'Kursvorstellung',
        IMAGE: 'Bild',
        UPDATE: 'aktualisieren',
        REMOVE_ALL_IMAGES: 'Alle Bilder entfernen',
        LECTURERS_CHOOSE: 'Dozenten auswählen',
        ADMINISTRATION: 'Verwaltung',
        ADMINISTRATION_PROVIDER: 'Provider-Verwaltung',
        CONTACT_BUTTON: 'KONTAKTIERE UNS',
        LANGUAGE_SPOKEN_PLURAL: 'Wir sprechen',
        LANGUAGE_SPOKEN_PLURAL_INFO: 'Ihre Präsentation wird in allen ausgewählten Sprachen präsentiert.',
        UPLOAD_IMAGES_INFO_1: 'Laden Sie Bilder vom Typ .png oder .jpg hoch.',
        UPLOAD_IMAGES_INFO_2: 'Wir empfehlen, Ihre authentischen Bilder beizufügen.',
        THIS_SCHOOL_FOLLOWS_1: 'An dieser Schule haben bereits',
        THIS_SCHOOL_FOLLOWS_2: 'Schüler teilgenommen',
        MEMBERSHIP_START_DATE: 'Mitgliedschaftsbeginn:',
        ADMINISTRATION_COURSE: 'Kursverwaltung',
        COURSE_LANGUAGES: 'Fügen Sie Sprachen hinzu und wählen Sie diese aus, in denen die Kommunikation im Kurs stattfindet.',
        ACC_DESC: 'Beschreibung der Unterkunft',
        BASIC_GROUP: 'Grundgruppe',
        ADVANCED_GROUP: 'Fortgeschrittene Gruppe',
        EXPERT_GROUP: 'Experten-Gruppe',
        WE_WILL_LEARN: 'Wir werden lernen',
        START: 'Beginn',
        COURSE_DATES: 'Kursdaten',
        TOTAL_VISITS_HOURS: 'Gesamtbesuche und Stunden',
        COURSE_RECEIVABLES: 'Empfangen Sie auf dem Kurs',
        NUMBER_OF_ATTENDEES: 'Anzahl der Teilnehmer',
        AGE_OF_ATTENDEES: 'Alter der Teilnehmer',
        COURSE_PRICE: 'Kurspreis',
        VISITS_REPEATS_PLACEHOLDER: 'Anzahl der Besuche..',
        VISITS_HOURS_PLACEHOLDER: 'Stundenanzahl..',
        COURSE_RECEIVABLES_PLACEHOLDER: 'Empfangen Sie auf dem Kurs..',
        NUMBER_OF_ATTENDEES_PLACEHOLDER: 'Maximale Anzahl von Teilnehmern',
        AGE_OF_ATTENDEES_PLACEHOLDER: 'Alter (von - bis)',
        COURSE_PRICE_REGULAR_PLACEHOLDER: 'Regulärer Preis..',
        COURSE_PRICE_DISCOUNT_PLACEHOLDER: 'Rabattpreis..',
        DAYS: 'Tage',
        HOURS: 'Stunden',
        PER_HOUR: 'pro Stunde',
        ADD_TO_CALENDAR:'Zum Kalender hinzufügen',
        PAID: 'Bezahlt:',
        RESERVATION_NUMBER: 'Reservierungsnummer:',
        PRINT: 'Drucken',
        CANCEL_RESCHEDULE: 'Stornieren / Verschieben',
        CONTACT_BUTTON_SMALL: 'Kontaktieren',
        MEMBERSHIP_START_DATE_PRETTY: 'Mitglied seit',
        YEARS: 'Jahre',
        IN_SCHOOL_WE_SPEAK: 'In der Schule sprechen wir:',
        REVIEW_SPACE: 'Platz',
        REVIEW_PRO: 'Kompetenz',
        REVIEW_SPEED: 'Reaktionsgeschwindigkeit',
        REVIEW_CONTACT: 'Benachrichtigung',
        REVIEW_BANG_FOR_BUCK: 'Preis-Leistungs-Verhältnis',
        PROVIDER_COURSES: 'Kursanbieter',
        BUY: 'Kaufen',
        FOR_PAYMENT: 'Zur Bezahlung',
        PRICE_FOR: 'Preis für',
        NIGHTS: 'Nächte',
        HOME: 'Zuhause',
        MY_PROFILE: 'Mein Profil',
        VIDEO_URL: 'Video-Präsentation',
        YOUTUBE: 'Youtube-URL-Link zum Video',
        FOLLOWERS: 'Ihren Angeboten folgen',
        ACC_PIC: 'Unterkunftsbild',
        REGISTER_PROMO: 'Tritt der Community registrierter Schüler in Slowenien bei',
        COURSE__DEFAULT_PRICE: 'Standardkurspreis',
        START_DATE_INFO: 'Markieren Sie die Daten, die den Start des Kurses darstellen sollen',
        ERROR_TEL: 'Falsches Telefonnummer-Format. Bitte geben Sie eine gültige Telefonnummer ein.',
        ERROR_EMAIL: 'Falsches E-Mail-Format. Geben Sie eine gültige E-Mail-Adresse ein.',
        SUBMIT: 'BESTÄTIGEN',
        UNARCHIVE: 'Aktivieren',
        JAN: 'Januar',
        FEB: 'Februar',
        MAR: 'März',
        APR: 'April',
        MAY: 'Mai',
        JUN: 'Juni',
        JUL: 'Juli',
        AUG: 'August',
        SEP: 'September',
        OCT: 'Oktober',
        NOV: 'November',
        DEC: 'Dezember',
        FILTER_REGION: 'Region',
        MON: 'Montag',
        TUE: 'Dienstag',
        WED: 'Mittwoch',
        THU: 'Donnerstag',
        FRI: 'Freitag',
        SAT: 'Samstag',
        SUN: 'Sonntag',
        PROFILE_PROVIDER: 'Profil Anbieter',
        FOLLOWING: 'Sie folgen derzeit keinem Kursanbieter.',
        FOLLOWING_COURSES: "Sie folgen derzeit keinem Kurs.",
        ADMIN_PROFILE: 'Administrations-Dashboard',
        TAB_ADMIN_1: 'Inhalt der Vorstellungsseiten',
        TAB_ADMIN_2: 'FAQ für Vorstellungsseiten',
        TAB_ADMIN_3: 'Übersicht über registrierte Kursteilnehmer',
        SHORT_DESC: 'Kurze Beschreibung',
        LONG_DESC: 'Längere Beschreibung',
        PROMO_TEXT: 'Promotions-Titel',
        FOR_USER: ' für Benutzer',
        FOR_PROVIDER: ' für Anbieter',
        USERS_LIST: 'Benutzerübersicht',
        PROVIDERS_LIST: 'Anbieterübersicht',
        EDIT_CATEGORIES: 'Kategorien bearbeiten',
        FILTER_CATEGORY_PARENT: 'Hauptkategorie',
        EDIT_INFO: 'Bearbeitung einer vorhandenen Kategorie.',
        ADD_INFO: 'Neue Kategorie erstellen.',
        PROVIDERS_TOTAL: 'Anzahl der Kursanbieter insgesamt',
        USERS_TOTAL: 'Anzahl der Benutzer (Kursteilnehmer) insgesamt',
        UPLOAD_DOCUMENTS: 'Rechtsdokumente hochladen',
        TERMS_OF_USE: 'Geschäftsbedingungen',
        PRIVACY_POLICY: 'Datenschutzerklärung',
        BENEFITS_USER: 'Pristajalna stran',
        BENEFITS_PROVIDER: 'Pristajalna stran',
        JOIN_FAMILY: 'Werden Sie Teil der Kursanbieter-Familie',
        COOKIES: 'Cookie-Richtlinie',
        PROVIDER_LIST: 'Kursanbieter',
        PROVIDER_ADDRESS: 'Adresse des Anbieters',
        EMAIL: 'E-Mail',
        USER: 'Benutzer',
        PREVIEW_DOC: 'Vorschau des hochgeladenen Dokuments',
        ABOUT: 'Über uns',
        SHOP: 'Einkaufen',
        SIGN_IN: 'Anmelden',
        HELP: 'Hilfe',
        COOKIE_NOTICE: 'Diese Seite verwendet Cookies. Indem Sie weiter auf der Website surfen, stimmen Sie unserer Verwendung von Cookies zu.',
        CONTACT_REQ: 'Kontaktanfrage',
        NO_ACCOMMODATION: 'No accommodation',
        PROVIDERS_FAQ: 'Providers FAQ',
        USERS_FAQ: 'Users FAQ',
        PRIVACY_POLICY_USERS: 'Allgemeine Geschäftsbedingungen für Kursteilnehmer',
        PRIVACY_POLICY_PROVIDERS: 'Allgemeine Geschäftsbedingungen für Kursanbieter',
        FAQ_LABEL: 'Häufig gestellte Fragen',
        REGISTER_FOOTER: 'Registriere dich und sei der Erste, der informiert wird',
        PROVIDER_FOOTER: 'Präsentiere deinen Kurs',
        NEWSLETTER_SUBSCRIBE: 'Abonniere den Newsletter',
        PRIVACY_FOOTER: 'Datenschutzerklärung',
        COOKIES_FOOTER: 'Cookie-Richtlinie',
        DICTIONARY_FOOTER: 'Glossar der Begriffe',
        USERS_FOOTER: 'Kursteilnehmer',
        PROVIDERS_FOOTER: 'Kursanbieter',
        CONTACT_FOOTER: 'Kontakt',
        NIGHT: 'night',
        BANNER_1_TITLE: 'Predstavi tečaj, izobraževanje ali delavnico',
        BANNER_1_P1: 'Večja vidljivost, novi trgi, novi tečajniki',
        BANNER_1_P2: 'Dodana vrednost',
        BANNER_1_P3: 'Prihranite čas in denar',
        BANNER_1_P4: 'Brezplačna predstavitev',
        BANNER_1_P5: 'Pozdravi nove tečajnike',
        BANNER_1_BTN1: 'Registriraj se in objavi tečaj',
        BANNER_1_BTN2: 'Izvedi več',
        BANNER_2_TITLE: 'Najdi tečaj po svoji meri:',
        BANNER_2_P1: 'Tečaji za prosti čas',
        BANNER_2_P2: 'Tečaji za strokovna znanja',
        BANNER_2_P3: 'Pregledna ponudba',
        BANNER_2_P4: 'Varna in enostavna rezervacija',
        BANNER_2_BTN1: 'Registriraj se in sledi tečajem',
        BANNER_2_BTN2: 'Izvedi več',
    },
    HR: {
        PREVIEW: 'Preview',
        NO_SEARCH_RESULTS: 'Nema rezultata pretrage',
        SEARCH: 'Filtrirani tečajevi',
        HOME_WELCOME: 'Dobrodošli',
        YOUR_USERNAME_TITLE: 'Vaše korisničko ime',
        YOUR_EMAIL_TITLE: 'Vaša e-pošta',
        YOUR_REFERRAL_ID: 'Upišite referral (Synergy) ID',
        YOUR_ANALYSIS_URL: 'Vaš referral link za analizu životnog stila',
        FORGOT_PASSWORD: 'Zaboravili ste lozinku? Resetujte je',
        APP_TITLE_1: 'ANALIZA',
        APP_TITLE_2: 'ŽIVOTNOG STILA',
        APP_PROMO: 'Vaš put do optimalnog zdravlja',
        LOGIN_WELCOME_BACK: 'Dobrodošli nazad',
        LOGIN_EMAIL: 'Upišite e-poštu',
        LOGIN_PASSWORD: 'Upišite lozinku',
        LOGIN_REMEMBER: 'Zapamti me',
        LOGIN_CREATE_ACCOUNT: 'Nemate račun? Registrujte se',
        LOGIN_BUTTON: 'PRIJAVA',
        REGISTER_TITLE: 'Pridružite se',
        REGISTER_NAME: 'Upišite vaše puno ime',
        REGISTER_BUTTON: 'REGISTRACIJA',
        REGISTER_ALREADY_HAVE_ACCOUNT: 'Već imate račun? Prijavite se',
        RESULT_TITLE: 'VAŠ REZULTAT',
        PLACEHOLDER_EMAIL: 'E-pošta',
        PLACEHOLDER_PASSWORD: 'Lozinka',
        SAVE: 'SPREMI',
        COPIED: 'Link je kopiran',
        ANALYSIS_INSTRUCTIONS: 'Na pitanja odgovarajte sa DA ili NE.',
        ANALYSIS_START: 'POČNI UPITNIK',
        YES: 'DA',
        NO: 'NE',
        ANALYSIS_GENERATE_RESULT: 'GENERIRAJ REZULTAT',
        ANALYSIS_SHARE_RESULTS: 'Za detaljnije objašnjenje vaše Lifestyle analize i informacije o Synergy rješenjima, možete podijeliti vaše rezultate sa osobom koja vam je analizu poslala.',
        SUBSCRIBE_JOIN: 'Postanite član!',
        SUBSCRIBE: 'KUPITE PRETPLATU',
        RESET_PASSWORD: 'RESETUJ LOZINKU',
        RESET_PASSWORD_TITLE: 'Resetovanje lozinke',
        GENERATE_RESULT_ERROR: 'Greška pri generisanju rezultata. Molimo provjerite i pokušajte ponovo unijeti vašu e-poštu.',
        DATA_UPDATED: 'Podaci uspješno ažurirani.',
        PASSWORD_RESET_SUCCESS: 'Lozinka je uspješno ažurirana. Molimo prijavite se ponovo.',
        NAME: 'Ime',
        NEED_SUPPORT: 'Potrebna vam je podrška?',
        STATS: 'Vaša statistika',
        COMPLETED: 'ISPUNJENI',
        SHARED_BACK: 'PODIJELJENI NATRAG',
        SHARED_PERCENTAGE: 'PODIJELJENO',
        BASIC_PROGRAM: 'OSNOVNI PROGRAM',
        OPTIMAL_PROGRAM: 'OPTIMALNI PROGRAM',
        LOGOUT: 'Odjava',
        CONTACT: 'Kontakt',
        NAME_SURNAME: 'Ime i prezime',
        ENTER_TELEPHONE: 'Unesite telefonski broj',
        TELEPHONE: 'Telefonski broj',
        REGISTER: 'Registracija',
        COMPANY_INFO: 'Informacije o tvrtki',
        COMPANY_NAME: 'Naziv tvrtke',
        ADDRESS: 'Adresa',
        COMPANY_LOGO: 'Logo tvrtke',
        POST_CITY: 'Poštanski broj i grad',
        COUNTRY: 'Država',
        ENTER_COMPANY_NAME: 'Unesite naziv tvrtke',
        ENTER_ADDRESS: 'Unesite adresu',
        ENTER_COMPANY_LOGO: 'Prenesite logo/sliku tvrtke',
        ENTER_POST_CITY: 'Unesite poštanski broj i grad',
        ENTER_COUNTRY: 'Odaberite državu',
        BECOME_LECTURER: 'Postanite predavač',
        ADD_COURSE: 'DODAJ TEČAJ!',
        TAB_MY_RESERVATIONS: 'Moje rezervacije',
        TAB_RESERVATIONS_ARCHIVE: 'Arhiva prošlih rezervacija',
        TAB_FOLLOWING_COURSES: 'Interesantni tečajevi',
        TAB_FOLLOWING: 'Pratim',
        TAB_PERSONAL_DATA: 'Moji osobni podaci',
        TAB_CATEGORIES: 'Kategorije',
        TAB_PAYMENTS: 'Plaćanja',
        FOLLOW: 'Prati',
        UNFOLLOW: 'Prekini pratiti',
        EDIT: 'Uredi',
        LANGUAGE_SPOKEN: 'Govorim:',
        FILTER_CATEGORY: 'Kategorija',
        FILTER_LOCATION: 'Lokacija',
        FILTER_PRICE: 'Cijena',
        FILTER_ACCOMMODATION: 'Smještaj',
        FILTER_GROUP_SIZE: 'Veličina grupe',
        FILTER_DATE: 'Datum',
        FILTER_ONLINE: 'Online',
        FILTER_STARS: 'Ocjene',
        FIND_COURSE: 'Pronađite tečaj koji vam odgovara.',
        SUGGESTED: 'Predloženo za vas',
        MOST_POPULAR: 'Najpopularnije ponude',
        NEW_CONTENT: 'Novi sadržaji',
        PROVIDER_ABOUT: 'O nama',
        LECTURERS: 'Predavači',
        REVIEWS: 'Ocjene polaznika',
        ALL_COURSES: 'Svi tečajevi',
        COURSE_LEARN: 'Naučit ćete',
        COURSE_NEEDS: 'Što je potrebno',
        COURSE_ACC: 'Smještaj',
        COURSE_COMPARISON: 'Usporedi',
        ADD_NEW_COURSE: 'Dodaj novi tečaj',
        NAME_COURSE: 'Naziv tečaja',
        COURSE_DESCRIPTION: 'Opis tečaja...',
        COURSE_LECTURER: 'Opis predavača...',
        ACC_NAME: 'Naziv',
        PRICE: 'Cijena',
        ACC_NIGHTS: 'Noćenja',
        ACC_NIGHTS_PLACEHOLDER: 'Broj noćenja',
        ACC_LOCATION: 'Lokacija',
        ACC_LOCATION_PLACEHOLDER: 'Upišite adresu smještaja',
        TAB_PRESENTATION: 'Prezentacija tvrtke',
        TAB_RECEIVABLES: 'Primljene uplate',
        TAB_STATS: 'Statistika',
        FAQ: 'Najpogostejša vprašanja',
        PROVIDER_NAME: 'Ime ponudnika',
        PROVIDER_DESC: 'Opis ponudnika',
        MY_COURSES: 'Moji tečaji',
        MY_COURSES_PUBLISHED: 'Objavljeni tečaji',
        MY_COURSES_ARCHIVED: 'Arhiv tečajev',
        MY_COURSES_ADD: 'Dodaj tečaj',
        COURSES: 'Tečaji',
        ARCHIVE: 'V arhiv',
        PARTICIPANTS: 'Udeleženci',
        ADD: 'Dodaj',
        QUESTION: 'Vprašanje',
        ANSWER: 'Odgovor',
        FAQ_INFO: 'Napiši najpogostejša vprašanja in odgovore',
        ADD_QUESTION: 'Dodaj vprašanje',
        BANK_ACCOUNT: 'TRR',
        VAT_NUMBER: 'Davčna številka',
        REGISTRATION_NUMBER: 'Matična številka',
        BANK_NAME: 'Banka',
        COURSE_PRESENTATION: 'Predstavitev tečaja',
        IMAGE: 'Slika',
        UPDATE: 'Posodobi',
        REMOVE_ALL_IMAGES: 'Odstrani vse slike',
        LECTURERS_CHOOSE: 'Izberi predavatelje',
        ADMINISTRATION: 'Upravljanje',
        ADMINISTRATION_PROVIDER: 'Upravljanje ponudnika',
        CONTACT_BUTTON: 'Kontaktiraj',
        LANGUAGE_SPOKEN_PLURAL: 'Govorimo',
        LANGUAGE_SPOKEN_PLURAL_INFO: 'Vaša predstavitev bo na voljo v vseh izbranih jezikih.',
        UPLOAD_IMAGES_INFO_1: 'Naložite fotografije v formatu .png ali .jpg',
        UPLOAD_IMAGES_INFO_2: 'Priporočamo uporabo avtentičnih fotografij.',
        THIS_SCHOOL_FOLLOWS_1: 'Te šole se je udeležilo že',
        THIS_SCHOOL_FOLLOWS_2: 'udeležencev',
        MEMBERSHIP_START_DATE: 'Začetek članstva:',
        ADMINISTRATION_COURSE: 'Upravljanje tečaja',
        COURSE_LANGUAGES: 'Dodaj in izberi jezike, ki se uporabljajo na tečaju',
        ACC_DESC: 'Opis nastanitve',
        BASIC_GROUP: 'Osnovna skupina',
        ADVANCED_GROUP: 'Napredna skupina',
        EXPERT_GROUP: 'Ekspertna skupina',
        WE_WILL_LEARN: 'Spoznali bomo',
        START: 'Začetek',
        COURSE_DATES: 'Datumi tečaja',
        TOTAL_VISITS_HOURS: 'Skupaj obiskov in ur',
        COURSE_RECEIVABLES: 'Kaj boste prejeli na tečaju',
        NUMBER_OF_ATTENDEES: 'Število udeležencev',
        AGE_OF_ATTENDEES: 'Starost udeležencev',
        COURSE_PRICE: 'Cena tečaja',
        VISITS_REPEATS_PLACEHOLDER: 'Ponovi obiske..',
        VISITS_HOURS_PLACEHOLDER: 'Št. ur..',
        COURSE_RECEIVABLES_PLACEHOLDER: 'Kaj boste prejeli..',
        NUMBER_OF_ATTENDEES_PLACEHOLDER: 'Največje št. udeležencev',
        AGE_OF_ATTENDEES_PLACEHOLDER: 'Starost (od - do)',
        COURSE_PRICE_REGULAR_PLACEHOLDER: 'Redna cena..',
        COURSE_PRICE_DISCOUNT_PLACEHOLDER: 'Cena z popustom..',
        DAYS: 'dni',
        HOURS: 'ur',
        PER_HOUR: 'na uro',
        ADD_TO_CALENDAR:'Dodaj v koledar',
        PAID: 'Plačano:',
        RESERVATION_NUMBER: 'Št. rezervacije:',
        PRINT: 'Natisni',
        CANCEL_RESCHEDULE: 'Prekliči / Prestavi',
        CONTACT_BUTTON_SMALL: 'Kontaktiraj',
        MEMBERSHIP_START_DATE_PRETTY: 'Član od',
        YEARS: 'let',
        IN_SCHOOL_WE_SPEAK: 'V šoli govorimo:',
        REVIEW_SPACE: 'prostor',
        REVIEW_PRO: 'strokovnost',
        REVIEW_SPEED: 'odzivnost',
        REVIEW_CONTACT: 'obveščenost',
        REVIEW_BANG_FOR_BUCK: 'razmerje med kakovostjo in ceno',
        PROVIDER_COURSES: 'Ponudniki tečajev',
        BUY: 'Kupi',
        FOR_PAYMENT: 'Za plačilo',
        PRICE_FOR: 'Cena za',
        NIGHTS: 'noči',
        HOME: 'Domov',
        MY_PROFILE: 'Moj profil',
        VIDEO_URL: 'Video predstavitev',
        YOUTUBE: 'Youtube - URL povezave do videa',
        FOLLOWERS: 'Sledilci vaših ponudb',
        ACC_PIC: 'Slika namestitve',
        REGISTER_PROMO: 'Pridružite se skupnosti registriranih tečajnikov po Sloveniji',
        COURSE__DEFAULT_PRICE: 'Redna cena tečaja',
        START_DATE_INFO: 'Označite datume, ki naj predstavljajo datume začetka tečaja',
        ERROR_TEL: 'Napačen format telefonske številke. Prosimo vnesite pravilno telefonsko številko.',
        ERROR_EMAIL: 'Naveden je napačen format elektronske pošte. Vnesite pravilen e-poštni naslov.',
        SUBMIT: 'POTRDI',
        UNARCHIVE: 'Aktiviraj',
        JAN: 'Siječanj',
        FEB: 'Veljača',
        MAR: 'Ožujak',
        APR: 'Travanj',
        MAY: 'Svibanj',
        JUN: 'Lipanj',
        JUL: 'Srpanj',
        AUG: 'Kolovoz',
        SEP: 'Rujan',
        OCT: 'Listopad',
        NOV: 'Studeni',
        DEC: 'Prosinac',
        FILTER_REGION: 'Regija',
        MON: 'Ponedjeljak',
        TUE: 'Utorak',
        WED: 'Srijeda',
        THU: 'Četvrtak',
        FRI: 'Petak',
        SAT: 'Subota',
        SUN: 'Nedjelja',
        PROFILE_PROVIDER: 'Profil polaznika',
        FOLLOWING: 'Trenutno ne pratite nijednog ponuđača tečajeva.',
        FOLLOWING_COURSES: "Trenutno ne pratite nijednog tečaja.",
        ADMIN_PROFILE: 'Admin nadzorna ploča',
        TAB_ADMIN_1: 'Sadržaj stranice',
        TAB_ADMIN_2: 'FAQ stranice',
        TAB_ADMIN_3: 'Pregled registriranih polaznika',
        SHORT_DESC: 'Kratki opis',
        LONG_DESC: 'Dugi opis',
        PROMO_TEXT: 'Promotivni naziv',
        FOR_USER: ' za korisnike',
        FOR_PROVIDER: ' za polaznike',
        USERS_LIST: 'Popis korisnika',
        PROVIDERS_LIST: 'Popis pružatelja usluga',
        EDIT_CATEGORIES: 'Upravljanje kategorijama',
        FILTER_CATEGORY_PARENT: 'Glavna kategorija',
        EDIT_INFO: 'Uređivanje postojeće kategorije.',
        ADD_INFO: 'Stvaranje nove kategorije.',
        PROVIDERS_TOTAL: 'Broj svih pružatelja usluga',
        USERS_TOTAL: 'Broj svih korisnika (polaznika)',
        UPLOAD_DOCUMENTS: 'Pravni dokumenti',
        TERMS_OF_USE: 'Politika poslovanja',
        PRIVACY_POLICY: 'Politika privatnosti',
        BENEFITS_USER: 'Pristajalna stran',
        BENEFITS_PROVIDER: 'Pristajalna stran',
        JOIN_FAMILY: 'Pridruži se obitelji pružatelja usluga',
        COOKIES: 'Politika kolačića',
        PROVIDER_LIST: 'Pružatelj usluga',
        PROVIDER_ADDRESS: 'Adresa pružatelja usluga',
        EMAIL: 'E-pošta',
        USER: 'Korisnik',
        PREVIEW_DOC: 'Pregled dokumenta',
        ABOUT: 'O nama',
        SHOP: 'Trgovina',
        SIGN_IN: 'Prijava',
        HELP: 'Pomoć',
        COOKIE_NOTICE: 'Ova stranica koristi kolačiće. Nastavkom pregledavanja stranice pristajete na našu upotrebu kolačića.',
        CONTACT_REQ: 'Povpraševanje po oglasu',
        NO_ACCOMMODATION: 'Bez nastanitve',
        PROVIDERS_FAQ: 'Providers FAQ',
        USERS_FAQ: 'Users FAQ',
        PRIVACY_POLICY_USERS: 'Opći uvjeti za sudionike tečaja',
        PRIVACY_POLICY_PROVIDERS: 'Opći uvjeti za pružatelje tečajeva',
        FAQ_LABEL: 'Česta pitanja i odgovori',
        REGISTER_FOOTER: 'Registrirajte se i budite prvi obaviješteni',
        PROVIDER_FOOTER: 'Predstavite svoj tečaj',
        NEWSLETTER_SUBSCRIBE: 'Pretplatite se na bilten',
        PRIVACY_FOOTER: 'Izjava o privatnosti',
        COOKIES_FOOTER: 'Izjava o kolačićima',
        DICTIONARY_FOOTER: 'Rječnik pojmova',
        USERS_FOOTER: 'Polaznici tečaja',
        PROVIDERS_FOOTER: 'Pružatelji tečajeva',
        CONTACT_FOOTER: 'Kontakt',
        NIGHT: 'night',
        BANNER_1_TITLE: 'Predstavi tečaj, izobraževanje ali delavnico',
        BANNER_1_P1: 'Večja vidljivost, novi trgi, novi tečajniki',
        BANNER_1_P2: 'Dodana vrednost',
        BANNER_1_P3: 'Prihranite čas in denar',
        BANNER_1_P4: 'Brezplačna predstavitev',
        BANNER_1_P5: 'Pozdravi nove tečajnike',
        BANNER_1_BTN1: 'Registriraj se in objavi tečaj',
        BANNER_1_BTN2: 'Izvedi več',
        BANNER_2_TITLE: 'Najdi tečaj po svoji meri:',
        BANNER_2_P1: 'Tečaji za prosti čas',
        BANNER_2_P2: 'Tečaji za strokovna znanja',
        BANNER_2_P3: 'Pregledna ponudba',
        BANNER_2_P4: 'Varna in enostavna rezervacija',
        BANNER_2_BTN1: 'Registriraj se in sledi tečajem',
        BANNER_2_BTN2: 'Izvedi več',
    }
};